import { render, staticRenderFns } from "./LotItem.vue?vue&type=template&id=1bd6649d&scoped=true"
import script from "./LotItem.vue?vue&type=script&lang=js"
export * from "./LotItem.vue?vue&type=script&lang=js"
import style0 from "./LotItem.vue?vue&type=style&index=0&id=1bd6649d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd6649d",
  null
  
)

export default component.exports