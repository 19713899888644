<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-dialog
      :value="isShow"
      @click:outside="closeDialog"
      :max-width="900"
      @keydown.esc="closeDialog"
    >
      <Loading />
      <v-card>
        <v-card-title id="sub-bar">
          <span id="lbl-screen-name">{{ $t("label.lbl_registerSection") }}</span>
        </v-card-title>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <v-container fluid>
              <v-row class="search-row">
                <!-- 発地 -->
                <div class="search-autocomplete first-search-item">
                  <v-autocomplete
                    dense
                    v-model="pickupPointSelected"
                    :items="pickupPointList"
                    :label="$t('label.lbl_pickupPointRelay')"
                    :hint="setSrhPickup()"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    :rules="[rules.inputRequired]"
                    @change="changePickup"
                    :search-input.sync="getPickupSelected"
                    :error-messages="pickupPointAlertMessage"
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <span class="require">*</span>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <!-- 着地 -->
                <div class="search-autocomplete first-search-item">
                  <v-autocomplete
                    dense
                    v-model="deliveryPointSelected"
                    :items="deliveryPointList"
                    :label="$t('label.lbl_deliveryPointRelay')"
                    :hint="setSrhDelivery()"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    :rules="[rules.inputRequired]"
                    @change="changeDelivery"
                    :search-input.sync="getDeliverySelected"
                    :error-messages="deliveryPointAlertMessage"
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <span class="require">*</span>
              </v-row>
              <v-row style="margin-left: 3px">
                <div class="search-row-exeBtn pt-2">
                  <!-- 閉じるボタン -->
                  <div style="float: left">
                    <v-btn color="primary" class="api-btn" @click="closeDialog">
                      {{ $t("btn.btn_close") }}
                    </v-btn>
                  </div>
                  <!-- 登録ボタン -->
                  <div style="float: right">
                    <v-btn
                      color="primary"
                      class="api-btn"
                      @click="insertRegisterSection(pickupPointSid, deliveryPointSid)"
                    >
                      {{ $t("btn.btn_insert") }}
                    </v-btn>
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="false"
      :closeFlag="true"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import SimpleDialog from "@/components/SimpleDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import { i18n } from "../../lang/lang.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";

export default {
  name: "RegisterSectionDialog",
  components: {
    Loading,
    SimpleDialog,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    // 他画面から呼び出される際に、呼び出し元の画面の画面IDを受け取る。
    screenId: {
      type: String,
    },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 発地
    pickupPointSelected: "",
    pickupPointList: [],
    pickupPointSid: "",
    getPickupSelected: "",
    pickupPointAlertMessage: "",
    // 着地
    deliveryPointSelected: "",
    deliveryPointList: [],
    deliveryPointSid: "",
    getDeliverySelected: "",
    deliveryPointAlertMessage: "",

    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
  }),

  methods: {
    setSrhPickup() {
      return commonFunction.getListSetName(this.pickupPointList, this.pickupPointSelected);
    },
    setSrhDelivery() {
      return commonFunction.getListSetName(this.deliveryPointList, this.deliveryPointSelected);
    },

    changePickup(value) {
      // 集荷先を選択時
      let val = this.pickupPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintPickup = val.text;
        this.getPickupSelected = val.text;
        this.pickupPointSid = val.value;
      }
      this.pickupPointList = this.pickupPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintPickup = "";
        this.pickupPointSid = "";
      } else {
        // 処理なし
      }
    },
    changeDelivery(value) {
      // 配達先を選択時
      let val = this.deliveryPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintDelivery = val.text;
        this.getDeliverySelected = val.text;
        this.deliveryPointCd = val.customerCode;
        this.deliveryPointSid = val.value;
      }
      this.deliveryPointList = this.deliveryPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintDelivery = "";
        this.deliveryPointCd = "";
        this.deliveryPointSid = "";
      } else {
        // 処理なし
      }
    },
    closeDialog() {
      // 発地
      this.pickupPointSelected = "";
      this.pickupPointList = [];
      this.pickupPointSid = "";
      this.getPickupSelected = "";
      this.pickupPointAlertMessage = "";
      // 着地
      this.deliveryPointSelected = "";
      this.deliveryPointList = [];
      this.deliveryPointSid = "";
      this.getDeliverySelected = "";
      this.deliveryPointAlertMessage = "";
      this.$emit("update:isShow", false);
    },

    insertRegisterSection(pickupPoint, deliveryPoint) {
      if (this.$refs.form.validate()) {
        if (pickupPoint == deliveryPoint) {
          this.infoDialog.isOpen = true;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.message = i18n.tc("check.chk_sameFromToBase");
          this.infoDialog.firstPageFlag = true;
          return;
        }

        this.callApi(pickupPoint, deliveryPoint);
      }
    },

    callApi(pickupPoint, deliveryPoint) {
      this.loadingCounter = 1;
      // APIリクエスト作成
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = this.screenId; // 画面ID
      body.reqIdv.pickupPointRelaySid = pickupPoint; // 発地SID
      body.reqIdv.deliveryPointRelaySid = deliveryPoint; // 着地SID

      // APIスタブ開始
      // this.$httpClient.securePost = function (url, body, config) {
      //   body;
      //   config;
      //   if (url == appConfig.API_URL.MST_REGISTER_SECTION_CD_MASTER) {
      //     var value = new Promise((resolve) => {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //           },
      //           resIdv: {},
      //         },
      //       });
      //     }).catch((ex) => {
      //       ex;
      //     });
      //     return value;
      //   }
      // };
      // APIスタブ終了

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(区間マスタコード登録)
          .securePost(appConfig.API_URL.MST_REGISTER_SECTION_CD_MASTER, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.closeDialog();
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            } else {
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            this.loadingCounter = 0;
          });
      });
    },
  },

  watch: {
    getPickupSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点検索キーワード
          params.baseSearchKeyword = val;

          const pickupListTmp = getParameter.getTmsBizBaseMst(params);
          //   const pickupListTmp = [
          //     {
          //       text: "002（VCJ埼玉SC）",
          //       value: "2400000002",
          //       customerCode: "4400710",
          //       name: "VCJ埼玉SC",
          //     },
          //     {
          //       text: "003（VC東名横浜）",
          //       value: "2400000003",
          //       customerCode: "4400711",
          //       name: "VC東名横浜",
          //     },
          //     {
          //       text: "004（VC港北NT）",
          //       value: "2400000004",
          //       ustomerCode: "4400712",
          //       name: "VC港北NT",
          //     },
          //     {
          //       text: "005（VC目黒）",
          //       value: "2400000005",
          //       customerCode: "4400713",
          //       name: "VC目黒",
          //     },
          //     {
          //       text: "006（VC練馬）",
          //       value: "2400000006",
          //       customerCode: "4400714",
          //       name: "VC練馬",
          //     },
          //     {
          //       text: "007（VC杉並）",
          //       value: "2400000007",
          //       customerCode: "4400715",
          //       name: "VC杉並",
          //     },
          //     {
          //       text: "008（VC世田谷）",
          //       value: "2400000008",
          //       customerCode: "4400716",
          //       name: "VC世田谷",
          //     },
          //   ];
          Promise.all([pickupListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.pickupPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },
    getDeliverySelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.deliveryPointList = [];
        //   this.deliveryPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";

          // 拠点検索キーワード
          params.baseSearchKeyword = val;

          const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
          //   const deliveryListTmp = [
          //     {
          //       text: "002（VCJ埼玉SC）",
          //       value: "2400000002",
          //       customerCode: "4400710",
          //       name: "VCJ埼玉SC",
          //     },
          //     {
          //       text: "003（VC東名横浜）",
          //       value: "2400000003",
          //       customerCode: "4400711",
          //       name: "VC東名横浜",
          //     },
          //     {
          //       text: "004（VC港北NT）",
          //       value: "2400000004",
          //       customerCode: "4400712",
          //       name: "VC港北NT",
          //     },
          //     {
          //       text: "005（VC目黒）",
          //       value: "2400000005",
          //       customerCode: "4400713",
          //       name: "VC目黒",
          //     },
          //     {
          //       text: "006（VC練馬）",
          //       value: "2400000006",
          //       customerCode: "4400714",
          //       name: "VC練馬",
          //     },
          //     {
          //       text: "007（VC杉並）",
          //       value: "2400000007",
          //       customerCode: "4400715",
          //       name: "VC杉並",
          //     },
          //     {
          //       text: "008（VC世田谷）",
          //       value: "2400000008",
          //       customerCode: "4400716",
          //       name: "VC世田谷",
          //     },
          //   ];

          Promise.all([deliveryListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.deliveryPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
          this.pickupPointAlertMessage = "";
          this.deliveryPointAlertMessage = "";
        }
      }
    },
  },
};
</script>
