<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_tansportOrderList" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <!-- 1行目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="shipperClientSelected"
                :items="shipperClientList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhShipperClient()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :search-input.sync="shipperClientInput"
                @change="changeShipperClient"
                :rules="[rules.isValue2Required(inquiryNumber, shipperClientSelected)]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 受注日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDateFrom"
                :label="$t('label.lbl_orderDateFrom')"
                @change="changeDateFrom($event, 'order')"
                :rules="[
                  rules.inputRequiredCreate(orderDateFrom, orderDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateFromCal"
                  @input="orderDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 受注日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDateTo"
                :label="$t('label.lbl_orderDateTo')"
                @change="changeDateTo($event, 'order')"
                :rules="[
                  rules.inputRequiredCreate(orderDateTo, orderDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateToCal"
                  @input="orderDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 元請営業所 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="orgOfficeSelected"
                :items="orgOfficeList"
                :label="$t('label.lbl_orgOfficeName')"
                :hint="setSrhOffice()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changeOrgOffice"
                :search-input.sync="orgOfficeInputSync"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>

          <!-- 2段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!-- 出荷元 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupPointSelected"
                :items="pickupPointList"
                :label="$t('label.lbl_pickupPoint')"
                :hint="setSrhPickup()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changePickup"
                :search-input.sync="getPickupSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 集荷予定日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDateFrom"
                :label="$t('label.lbl_pickupScheduleDateFrom')"
                @change="changeDateFrom($event, 'pickup')"
                :rules="[
                  rules.inputRequiredCreate(pickupScheduleDateFrom, pickupScheduleDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateFromCal"
                  @input="pickupScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 集荷予定日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDateTo"
                :label="$t('label.lbl_pickupScheduleDateTo')"
                @change="changeDateTo($event, 'pickup')"
                :rules="[
                  rules.inputRequiredCreate(pickupScheduleDateTo, pickupScheduleDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="pickupAlertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateToCal"
                  @input="pickupScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- お届け先 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPointSelected"
                :items="deliveryPointList"
                :label="$t('label.lbl_deliveryPoint')"
                :hint="setSrhDelivery()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changeDelivery"
                :search-input.sync="getDeliverySelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 到着日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDateFrom"
                :label="$t('label.lbl_deliveryScheduleDateFrom')"
                @change="changeDateFrom($event, 'delivery')"
                :rules="[
                  rules.inputRequiredCreate(deliveryScheduleDateFrom, deliveryScheduleDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateFromCal"
                  @input="deliveryScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 到着日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDateTo"
                :label="$t('label.lbl_deliveryScheduleDateTo')"
                @change="changeDateTo($event, 'delivery')"
                :rules="[
                  rules.inputRequiredCreate(deliveryScheduleDateTo, deliveryScheduleDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="deliveryAlertMessage"
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-200"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateToCal"
                  @input="deliveryScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 3段目 -->

          <v-row class="search-row">
            <!-- お客様管理番号 -->
            <div class="add-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="inquiryNumber"
                :label="$t('label.lbl_inquiryNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.onlyOneInputRequired(
                    inquiryNumber,
                    freightOrderSid,
                    $t('label.lbl_inquiryNumber'),
                    $t('label.lbl_freightOrderSid')
                  ),
                ]"
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 受注番号 -->
            <div class="search-textbox-voucherNo">
              <v-text-field
                outlined
                dense
                v-model="freightOrderSid"
                :label="$t('label.lbl_freightOrderSid')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                hide-details
                :rules="[
                  rules.onlyOneInputRequired(
                    inquiryNumber,
                    freightOrderSid,
                    $t('label.lbl_inquiryNumber'),
                    $t('label.lbl_freightOrderSid')
                  ),
                ]"
              ></v-text-field>
            </div>
          </v-row>
          <v-row style="padding-top: 0px">
            <!-- 4段目 -->
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true, true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area" style="float: right">
                <!--ルート登録ボタン-->
                <v-btn class="other-btn" @click="openAddRoute()">
                  {{ $t("btn.btn_addRoute") }}
                </v-btn>
              </div>
              <div class="btn-area" style="float: right">
                <!--CSV出力ボタン-->
                <v-btn class="other-btn" @click="csvOutput()">
                  {{ $t("btn.btn_transportOrderCsvDown") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" style="margin-top: 10px">
              <div class="btn-area" style="float: left">
                <!--一括ボタン-->
                <v-btn class="other-btn" id="batchBtn" @click="batchSelect()">
                  {{ $t("btn.btn_batch") }}
                </v-btn>
              </div>

              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="columnDefs"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          height="501px"
          :options.sync="sortOptions"
          hide-default-header
          hide-default-footer
        >
          <!-- ヘッダー -->
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <template v-for="(colDef, colDefIdx) in columnDefs">
                  <th
                    role="columnHeader"
                    scope="col"
                    :class="getColumnClass(colDef, true)"
                    :style="getColumnStyle(colDef)"
                    :key="colDefIdx"
                  >
                    <div v-for="(itemDef, itemDefIdx) in colDef.items" :key="itemDefIdx">
                      <template v-if="itemDef.value == 'check'">
                        <input
                          type="checkbox"
                          :checked="isAllSelected"
                          @click="selectAllCheck"
                          style="transform: scale(2)"
                        />
                      </template>
                      <template v-else>
                        <span>{{ itemDef.text }}</span>
                      </template>
                    </div>
                  </th>
                </template>
              </tr>
            </thead>
          </template>

          <!-- 明細行 -->
          <template v-slot:item="{ item, index }">
            <tr>
              <template v-for="(colDef, colDefIdx) in columnDefs">
                <td :class="getColumnClass(colDef, false)" :key="colDefIdx">
                  <template v-for="(itemDef, itemDefIdx) in colDef.items">
                    <div :class="getColItemClass(colDef, itemDefIdx)" :key="itemDefIdx">
                      <!-- 選択 -->
                      <template v-if="itemDef.value == 'check'">
                        <input
                          type="checkbox"
                          v-model="item.check"
                          :value="listCheckbox"
                          @click="checkRow(item, index)"
                          @blur="blurRowCheck()"
                          style="transform: scale(2)"
                          :key="itemDefIdx"
                        />
                      </template>
                      <!-- 詳細ボタン -->
                      <template v-else-if="itemDef.value == 'detail'">
                        <v-btn small @click="openUpdateDelete(item)" text>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <!-- 受託/受託解除ボタン -->
                      <template v-else-if="itemDef.value == 'orderAcceptStatus'">
                        <v-btn
                          class="other-btn orderAcceptButtn"
                          @click="clickAcceptOrderButton(item)"
                          :class="orderAcceptButtonVisibility(item)"
                        >
                          {{ orderAcceptButtonCaption(item) }}
                        </v-btn>
                      </template>
                      <template v-else>
                        <span v-html="showValue(item[itemDef.value])"></span>
                      </template>
                    </div>
                  </template>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <!-- CSV出力確認ダイアログ -->
      <CsvOutputDialog
        :isShow.sync="csvOutputDialog.isOpen"
        :message="csvOutputDialog.message"
        :screenFlag="csvOutputDialog.screenFlag"
        :okAction="csvOutputDialog.okAction"
        :redMessage="csvOutputDialog.redMessage"
        :changeFlag="csvOutputDialog.changeFlag"
      />
      <v-dialog v-model="showWarningMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ warningMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeWarningMessage"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import CsvOutputDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_TOD_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    CsvOutputDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_100: false,
    // 取引先
    shipperClientSelected: "",
    shipperClientList: [],
    shipperClientHint: "",
    // 受注日
    orderDateFrom: "",
    orderDateFromMenu: false,
    orderDateFromCal: "",
    orderDateTo: "",
    orderDateToMenu: false,
    orderDateToCal: "",
    // 元請営業所
    orgOfficeSelected: "",
    orgOfficeList: [],
    orgOfficeHint: "",
    // 出荷元
    pickupPointSelected: "",
    pickupPointSid: "",
    pickupPointList: [],
    productHintPickup: "",
    // 集荷予定日
    pickupScheduleDateFrom: "",
    pickupScheduleDateFromCal: "",
    pickupScheduleDateFromMenu: false,
    pickupScheduleDateTo: "",
    pickupScheduleDateToCal: "",
    pickupScheduleDateToMenu: false,
    // お届け先
    deliveryPointSelected: "",
    deliveryPointList: [],
    deliveryPointSid: "",
    // 到着日
    deliveryScheduleDateFrom: "",
    deliveryScheduleDateFromCal: "",
    deliveryScheduleDateFromMenu: false,
    deliveryScheduleDateTo: "",
    deliveryScheduleDateToCal: "",
    deliveryScheduleDateToMenu: false,

    // お客様管理番号
    inquiryNumber: "",
    // 受注番号
    freightOrderSid: "",
    // 表示件数
    totalCount: "",
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // 表示件数切替ボタン
    itemsPerPageBtn: "100",
    inputList: [],
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 初期データ
    defaultData: [],

    // テーブルの列定義
    columnDefs: [
      // 選択
      {
        items: [
          {
            text: "選択",
            value: "check",
          },
        ],
        width: "50px",
        align: "center",
      },
      // No
      {
        items: [
          {
            text: "No",
            value: "No",
          },
        ],
        width: "60px",
        align: "center",
      },
      // 受注日・受注番号
      {
        items: [
          {
            text: i18n.tc("label.lbl_orderDate"),
            value: "orderDate",
          },
          {
            text: i18n.tc("label.lbl_freightOrderSid"),
            value: "freightOrderSid",
          },
        ],
        width: "150px",
        align: "center",
      },
      // エリア・取引先
      {
        items: [
          {
            text: i18n.tc("label.lbl_area"),
            value: "deliveryAreaName",
          },
          {
            text: i18n.tc("label.lbl_supplier"),
            value: "shipperClientName",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // 出荷元・お届け先
      {
        items: [
          {
            text: i18n.tc("label.lbl_pickupPoint"),
            value: "pickupPointName",
          },
          {
            text: i18n.tc("label.lbl_deliveryPoint"),
            value: "deliveryPointName",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // 集荷予定日・到着日
      {
        items: [
          {
            text: i18n.tc("label.lbl_pickupScheduleDate"),
            value: "pickupScheduleDate",
          },
          {
            text: i18n.tc("label.lbl_deliveryScheduleDate"),
            value: "deliveryScheduleDate",
          },
        ],
        minWidth: "140px",
        align: "center",
      },
      // 集荷予定時間・到着時間
      {
        items: [
          {
            text: i18n.tc("label.lbl_pickupScheduleTime"),
            value: "pickupScheduleTime",
          },
          {
            text: i18n.tc("label.lbl_deliveryScheduleTime"),
            value: "deliveryScheduleTime",
          },
        ],
        minWidth: "160px",
        align: "center",
      },
      // ルート設定・公開／非公開
      {
        items: [
          {
            text: i18n.tc("label.lbl_routeDefinedStatus"),
            value: "routeDefinedStatusName",
          },
          {
            text: i18n.tc("label.lbl_privateFlg"),
            value: "privateName",
          },
        ],
        minWidth: "160px",
        align: "center",
      },
      // 詳細・受託
      {
        items: [
          {
            text: i18n.tc("label.lbl_Detail"),
            value: "detail",
          },
          {
            text: i18n.tc("label.lbl_orderAccept"),
            value: "orderAcceptStatus",
          },
        ],
        minWidth: "110px",
        align: "center",
      },
      // 元請営業所・担当営業所
      {
        items: [
          {
            text: i18n.tc("label.lbl_orgOfficeName"),
            value: "orgOfficeName",
          },
          {
            text: i18n.tc("label.lbl_transportOffice"),
            value: "deliveryOfficeName",
          },
        ],
        minWidth: "200px",
        align: "center",
      },
      // 品名
      {
        items: [
          {
            text: i18n.tc("label.lbl_productCodeName"),
            value: "itemName",
          },
        ],
        align: "center",
        minWidth: "350px",
        maxWidth: "800px",
      },
      // 小口数・重量計KG
      {
        items: [
          {
            text: i18n.tc("label.lbl_smallLotQuantity"),
            value: "smallLotQuantity",
          },
          {
            text: i18n.tc("label.lbl_totalWeight"),
            value: "totalWeight",
          },
        ],
        minWidth: "120px",
        align: "center",
      },
      // お客様管理番号
      {
        items: [
          {
            text: i18n.tc("label.lbl_inquiryNumber"),
            value: "inquiryNum",
          },
        ],
        minWidth: "250px",
        align: "center",
      },
    ],

    // メニュー
    openMenu: null,
    shipperClientInput: "",
    orgOfficeInputSync: "",
    getPickupSelected: "",
    getDeliverySelected: "",

    // 全選択チェックボックス
    isAllSelected: false,

    // 一括選択関連
    lastFocusRow: -1,

    searchParam: {},
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    pickupAlertMessage: "",
    deliveryAlertMessage: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    csvOutputDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    showWarningMessage: false,
    warningMessage: "",
    routeDefinedStatus: "",
  }),

  created() {
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
      // 検索条件復元
      this.shipperClientSelected = this.searchParam.shipperClientSelected;
      this.orderDateFrom = this.searchParam.orderDateFrom;
      this.orderDateTo = this.searchParam.orderDateTo;
      this.orgOfficeSelected = this.searchParam.orgOfficeSelected;
      this.pickupPointSelected = this.searchParam.pickupPointSelected;
      this.pickupScheduleDateFrom = this.searchParam.pickupScheduleDateFrom;
      this.pickupScheduleDateTo = this.searchParam.pickupScheduleDateTo;
      this.deliveryPointSelected = this.searchParam.deliveryPointSelected;
      this.deliveryScheduleDateFrom = this.searchParam.deliveryScheduleDateFrom;
      this.deliveryScheduleDateTo = this.searchParam.deliveryScheduleDateTo;
      this.inquiryNumber = this.searchParam.inquiryNumber;
      this.freightOrderSid = this.searchParam.freightOrderSid;
      this.checkFlg = this.$route.params.checkFlg;
      // 選択項目ヒント文字列文字列復元
      this.shipperClientHint = this.searchParam.shipperClientHint;
      this.orgOfficeHint = this.searchParam.orgOfficeHint;
      this.productHintPickup = this.searchParam.productHintPickup;
      this.productHintDelivery = this.searchParam.productHintDelivery;
      // 選択項目選択肢復元
      this.shipperClientList = this.searchParam.shipperClientList;
      this.orgOfficeList = this.searchParam.orgOfficeList;
      this.pickupPointList = this.searchParam.pickupPointList;
      this.deliveryPointList = this.searchParam.deliveryPointList;
      // カレンダー項目設定値復元
      this.orderDateFromCal = this.dateCalc(this.orderDateFrom);
      this.orderDateToCal = this.dateCalc(this.orderDateTo);
      this.pickupScheduleDateFromCal = this.dateCalc(this.pickupScheduleDateFrom);
      this.pickupScheduleDateToCal = this.dateCalc(this.pickupScheduleDateTo);
      this.deliveryScheduleDateFromCal = this.dateCalc(this.deliveryScheduleDateFrom);
      this.deliveryScheduleDateToCal = this.dateCalc(this.deliveryScheduleDateTo);

      this.getApiList(true, true);
    }
  },

  methods: {
    link() {
      location.href = this.returnURL;
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },

    /**
     * 受注一覧画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckboxBtn = [];
        this.chengeListCheckbox = [];
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
          this.chengeListCheckbox.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
          // 編集用チェックボックスリストを初期化
          this.chengeListCheckbox = [];
        }
        this.isAllSelected = false;
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const params = {};
      if (isNaN(val)) {
        // 取引先名称
        params.supplierName = val;
      } else {
        // 取引先コード（前方一致）
        params.supplierCode = val;
      }
      const clientList = getParameter.getTmsBizCustomerMst(params);
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.shipperClientList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.loadingCounter = 0;
    },

    /**
     * 受託/受託解除ボタン押下
     */
    clickAcceptOrderButton(item) {
      let confirmDialog = this.confirmDialog;
      let lbl_shortDateFormat = i18n.tc("label.lbl_shortDateFormat");
      let lbl_colon = ":";
      confirmDialog.message =
        i18n.tc("label.lbl_confirmAcceptOrder_" + item.orderAcceptStatus) +
        " " +
        // 受注番号
        i18n.tc("label.lbl_freightOrderSid") +
        ":" +
        item.freightOrderSid +
        " " +
        // 顧客名
        item.shipperClientName +
        " " +
        // 集荷地
        i18n.tc("label.lbl_pickup") +
        lbl_colon +
        this.toStringDate(lbl_shortDateFormat, new Date(item.pickupScheduleDate)) +
        " " +
        // 納品地
        i18n.tc("label.lbl_delivery") +
        lbl_colon +
        (
          this.toStringDate(lbl_shortDateFormat, new Date(item.deliveryScheduleDate)) +
          " " +
          item.deliveryScheduleTime
        ).trim() +
        " " +
        // 小口数
        item.smallLotsQuantity +
        i18n.tc("label.lbl_smallLot") +
        " " +
        // 総重量(kg)
        item.totalWeight +
        i18n.tc("label.lbl_kg") +
        " " +
        // 代表品名
        i18n.tc("label.lbl_typicalItemName") +
        lbl_colon +
        item.typicalItemName +
        " " +
        // 代表荷姿
        i18n.tc("label.lbl_packing") +
        lbl_colon +
        item.typicalPackingNacName;

      confirmDialog.isOpen = true;
      confirmDialog.screenFlag = true;
      confirmDialog.changeFlag = false;
      confirmDialog.okAction = () => this.acceptOrder(item);
    },
    acceptOrder(item) {
      let body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_004;
      let reqIdv = body.reqIdv;
      reqIdv.freightOrderSid = item.freightOrderSid;
      reqIdv.orderAcceptStatus = item.orderAcceptStatus;
      reqIdv.updateDatetime = item.updateDatetime;
      this.$httpClient
        // 接続先のAPI_URLを入力
        .securePost(appConfig.API_URL.BIZ_ACCEPT_ORDER, body, appConfig.APP_CONFIG)
        .then((response) => {
          // console.debug("getApiList(checkBoxFlag) Response", response);
          const jsonData = JSON.parse(JSON.stringify(response.data));
          // 成功
          if (jsonData.resCom.resComCode == "000") {
            this.getApiList(false, false);
          } else {
            // エラー時
            this.infoDialog.message = jsonData.resCom.resComMessage;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 検索/CSV出力用パラメータ設定
     */
    createSearchParams(params) {
      // 取引先
      if (this.shipperClientSelected) {
        params.shipperClientCd = this.shipperClientSelected;
      }
      // 発注日From
      if (this.orderDateFrom) {
        params.orderFromDate = dateTimeHelper.convertUTC(this.orderDateFrom);
      }
      // 発注日To
      if (this.orderDateTo) {
        params.orderToDate = dateTimeHelper.convertUTC(this.orderDateTo + " 23:59:59.999");
      }
      // 元請営業所SID
      if (this.orgOfficeSelected) {
        params.orgOfficeSid = this.orgOfficeSelected;
      }
      // 集荷拠点SID
      if (this.pickupPointSid) {
        params.pickupPointSid = this.pickupPointSid;
      } else if (this.pickupPointSelected) {
        params.pickupPointSid = this.pickupPointSelected;
      }
      // 集荷予定日From
      if (this.pickupScheduleDateFrom) {
        params.pickupScheduleFromDate = dateTimeHelper.convertUTC(this.pickupScheduleDateFrom);
      }
      // 集荷予定日To
      if (this.pickupScheduleDateTo) {
        params.pickupSchedulToDate = dateTimeHelper.convertUTC(
          this.pickupScheduleDateTo + " 23:59:59.999"
        );
      }
      // 配達拠点SID
      if (this.deliveryPointSid) {
        params.deliveryPointSid = this.deliveryPointSid;
      } else if (this.deliveryPointSelected) {
        params.deliveryPointSid = this.deliveryPointSelected;
      }
      // 配達予定日From
      if (this.deliveryScheduleDateFrom) {
        params.deliveryScheduleFromDate = dateTimeHelper.convertUTC(this.deliveryScheduleDateFrom);
      }
      // 配達予定日To
      if (this.deliveryScheduleDateTo) {
        params.deliveryScheduleToDate = dateTimeHelper.convertUTC(
          this.deliveryScheduleDateTo + " 23:59:59.999"
        );
      }
      // お客様問合せ番号
      if (this.inquiryNumber) {
        params.inquiryNumber = this.inquiryNumber;
      }
      // 貨物受注SID
      if (this.freightOrderSid) {
        params.freightOrderSid = this.freightOrderSid;
      }
    },

    checkSearchParams() {
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";
      // 発注日FromToの日付、入力チェック
      if (new Date(this.orderDateFrom).getTime() > new Date(this.orderDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }
      // 集荷予定日FromToの日付、入力チェック
      if (this.pickupScheduleDateFrom && this.pickupScheduleDateTo) {
        if (
          new Date(this.pickupScheduleDateFrom).getTime() >
          new Date(this.pickupScheduleDateTo).getTime()
        ) {
          this.pickupAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      // 到着日FromToの日付、入力チェック
      if (this.deliveryScheduleDateFrom && this.deliveryScheduleDateTo) {
        if (
          // FromToの日付、入力チェック
          new Date(this.deliveryScheduleDateFrom).getTime() >
          new Date(this.deliveryScheduleDateTo).getTime()
        ) {
          this.deliveryAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      return check;
    },

    /**
     * 事業所・営業所マスタ取得
     */
    getMstOffice(params) {
      const config = this.$httpClient.createGetApiRequestConfig();
      Object.assign(config.params, params);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_OFFICE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // const jsonData = {
            //   resCom: {
            //     resComPagination: {
            //       totalPage: 1,
            //       totalRecord: 100,
            //     },
            //     resComCode: "000",
            //   },
            //   reqCom: {
            //     reqComComponentId: "P-TOD-002",
            //     reqComCompanySid: null,
            //     reqComOfficeSid: null,
            //     reqComDeviceImei: "WEB",
            //     reqComAppVersion: "24.11.01",
            //     reqComExecUser: null,
            //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
            //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
            //     reqComLanguageCd: "ja",
            //     reqComTimeZone: "Asia/Tokyo",
            //   },
            //   resIdv: {
            //     timeZone: "Asia/Tokyo",
            //     officeInfoList: [
            //       {
            //         officeSid: "2100000019",
            //         officeCode: "019",
            //         officeName: "日高営業所",
            //         officeNameKana: "ヒダカエイギョウショ",
            //         officeShortName: "日高営業所",
            //         officeShortNameKana: "ヒダカエイギョウショ",
            //         postCode: "3501213",
            //         address1: "埼玉県日高市",
            //         address2: "高萩995-1",
            //         telephoneNumber: "042-985-1972",
            //         telephoneNumber2: null,
            //         faxNumber: "042-985-1973",
            //         updateDatetime: "2024/07/18 15:00:00",
            //       },
            //     ],
            //   },
            // };
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [];
              jsonData.resIdv.officeInfoList.forEach((row) => {
                list.push({
                  text: `${row.officeCode ?? ""} ` + `（${row.officeName ?? ""}）`,
                  value: row.officeSid ?? "",
                  name: row.officeName ?? "",
                });
              });
              resolve(list);
            } else {
              // エラー時
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("orderList getMstOffice Err", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },

    /**
     * 受注一覧画面： 検索ボタン押下処理
     */
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      let check = this.checkSearchParams();

      if (check == 0) {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.searchParam && this.checkFlg) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;

        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          this.pickupAlertMessage = "";
          this.deliveryAlertMessage = "";

          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }
          // 検索条件を保存
          this.searchParam.shipperClientSelected = this.shipperClientSelected;
          this.searchParam.orderDateFrom = this.orderDateFrom;
          this.searchParam.orderDateTo = this.orderDateTo;
          this.searchParam.orgOfficeSelected = this.orgOfficeSelected;
          this.searchParam.pickupPointSelected = this.pickupPointSelected;
          this.searchParam.pickupScheduleDateFrom = this.pickupScheduleDateFrom;
          this.searchParam.pickupScheduleDateTo = this.pickupScheduleDateTo;
          this.searchParam.deliveryPointSelected = this.deliveryPointSelected;
          this.searchParam.deliveryScheduleDateFrom = this.deliveryScheduleDateFrom;
          this.searchParam.deliveryScheduleDateTo = this.deliveryScheduleDateTo;
          this.searchParam.inquiryNumber = this.inquiryNumber;
          this.searchParam.freightOrderSid = this.freightOrderSid;
          // 選択項目ヒント文字列保存
          this.searchParam.shipperClientHint = this.shipperClientHint;
          this.searchParam.orgOfficeHint = this.orgOfficeHint;
          this.searchParam.productHintPickup = this.productHintPickup;
          this.searchParam.productHintDelivery = this.productHintDelivery;
          // 選択項目選択肢保存
          this.searchParam.shipperClientList = this.shipperClientList;
          this.searchParam.orgOfficeList = this.orgOfficeList;
          this.searchParam.pickupPointList = this.pickupPointList;
          this.searchParam.deliveryPointList = this.deliveryPointList;

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          let config = this.$httpClient.createGetApiRequestConfig();
          this.createSearchParams(config.params);
          // ページャー処理
          if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;

          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }

          return new Promise((resolve) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_CUSTOMER_ORDER_SEARCH, config)
              .then((response) => {
                // console.debug("getApiList(checkBoxFlag) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // const jsonData = {
                //   resCom: {
                //     resComPagination: {
                //       totalPage: 1,
                //       totalRecord: 100,
                //     },
                //     resComCode: "000",
                //   },
                //   reqCom: {
                //     reqComComponentId: "P-TOD-002",
                //     reqComCompanySid: null,
                //     reqComOfficeSid: null,
                //     reqComDeviceImei: "WEB",
                //     reqComAppVersion: "24.11.01",
                //     reqComExecUser: null,
                //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
                //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
                //     reqComLanguageCd: "ja",
                //     reqComTimeZone: "Asia/Tokyo",
                //   },
                //   resIdv: {
                //     timeZone: "Asia/Tokyo",
                //     orders: [
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000000",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "0",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "1",
                //         orgOfficeSid: "2100000019",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000000",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000000",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000001",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "0",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "2",
                //         orgOfficeSid: "2100000019",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000001",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000001",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000002",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "1",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "1",
                //         orgOfficeSid: "2100000019",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000002",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000002",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000003",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "1",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "2",
                //         orgOfficeSid: "2100000019",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000003",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000003",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000004",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "0",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "1",
                //         orgOfficeSid: "2100000116",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000004",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000004",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000005",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "0",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "2",
                //         orgOfficeSid: "2100000116",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000005",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000005",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000006",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "1",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "1",
                //         orgOfficeSid: "2100000116",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000006",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000006",
                //       },
                //       {
                //         orderDate: "2022/02/06 13:17:00.000",
                //         freightOrderSid: "38000000000000000007",
                //         deliveryArea: "04208",
                //         deliveryAreaName: "宮城県角田市",
                //         shipperClientCd: "4400745",
                //         shipperClientName: "ボルボ・カー・ジャパン㈱",
                //         pickupPointSid: "2100000097",
                //         pickupPointName: "柏原営業所",
                //         deliveryPointSid: "2100000116",
                //         deliveryPointName: "岩沼営業所",
                //         pickupScheduleDate: "2022/02/07 13:17:00.000",
                //         deliveryScheduleDate: "2022/08/02 13:17:00.000",
                //         pickupScheduleTime: "12:00 - 14:00",
                //         deliveryScheduleTime: "15:00 - 16:00",
                //         routeDefinedStatus: "1",
                //         privateFlg: "0",
                //         privateName: "",
                //         orderAcceptStatus: "2",
                //         orgOfficeSid: "2100000116",
                //         orgOfficeName: "日高営業所",
                //         deliveryOfficeSid: "2100000028",
                //         deliveryOfficeName: "浜松営業所",
                //         itemName: "EX30",
                //         smallLotsQuantity: "1",
                //         totalWeight: "300.00",
                //         inquiryNum: "48000000000000000007",
                //         typicalItemName: "EX30",
                //         typicalPackingNacName: "箱",
                //         businessNo: "123456",
                //         updateDatetime: "2022/02/06 13:17:00.000",
                //         freightOrderUpdateDatetime: "2022/02/06 13:17:00.000",
                //         privateInfSid: null,
                //         invNumSid: "58000000000000000007",
                //       },
                //     ],
                //   },
                // };

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];

                  // 取得した受注一覧データを表示用に変換(必要な項目のみ)
                  jsonData.resIdv.orders.forEach((row) => {
                    row.check = false;
                    row.No = jsonData.resIdv.orders.indexOf(row) + 1;

                    // 受注日
                    row.orderDate = dateTimeHelper.convertUTC2JST(row.orderDate).substr(0, 10);

                    // 集荷予定日（画面表示用=JST:秒数なし）
                    if (!row.pickupScheduleDate) {
                      row.pickupScheduleDate = "";
                    } else {
                      row.pickupScheduleDate = dateTimeHelper
                        .convertUTC2JST(row.pickupScheduleDate)
                        .substr(0, 10);
                    }

                    // 到着日
                    row.deliveryScheduleDate = dateTimeHelper
                      .convertUTC2JST(row.deliveryScheduleDate)
                      .substr(0, 10);

                    // ルート設定済状態
                    row.routeDefinedStatusName =
                      row.routeDefinedStatus == "1"
                        ? i18n.tc("label.lbl_already")
                        : i18n.tc("label.lbl_notYet");

                    // 重量計KG
                    row.weight = commonFunction.getDelimiterFew(row.totalWeight, 9, 3);

                    // 小口数
                    row.smallLotQuantity = commonFunction.getDelimiter(row.smallLotsQuantity, 5);
                    list.push(row);
                  });
                  this.inputList = list;

                  if (!checkBoxFlag) {
                    // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                    for (var k = 0; k < this.inputList.length; k++) {
                      for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                        if (this.inputList[k].No == this.listCheckboxBtn[j].No) {
                          this.inputList[k].check = true;
                        }
                      }
                    }
                  } else {
                    // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                    this.listCheckboxBtn.length = 0;
                  }

                  // ページング検索の場合の全選択チェックボックス処理
                  if (this.isAllSelected) {
                    this.isAllSelected = true;
                    for (var i = 0; i < this.inputList.length; i++) {
                      this.inputList[i].check = true;
                    }
                  }
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
        // this.loadingCounter = 0;
      }
    },

    // 列単位のHTMLクラス指定
    getColumnClass(colDef, isHeader) {
      let result = "";
      if (colDef.align) {
        result += " text-" + colDef.align;
      }
      if (isHeader) {
        if (colDef.class) {
          result += " " + colDef.class;
        }
      }
      return result.trim();
    },

    // 列単位のHTMLスタイル指定
    getColumnStyle(colDef) {
      let result = "";
      if (colDef.minWidth) {
        result += " min-width: " + colDef.minWidth + ";";
      }
      if (colDef.maxWidth) {
        result += " max-width: " + colDef.maxWidth + ";";
      }
      if (colDef.minWidth || colDef.maxWidth) {
        result += " width: auto;";
      }
      if (colDef.width) {
        result += " width: " + colDef.width + ";";
        if (!colDef.minWidth) {
          result += " min-width: " + colDef.width + ";";
        }
      }
      return result.trim();
    },

    // 項目単位のHTMLクラス指定
    getColItemClass(colDef, itemDefIdx) {
      let result = "";
      /* 項目ごとに定義したスタイルを参照できるようにする */
      result += " " + colDef.items[itemDefIdx].value;
      /* ほかの項目と共通のスタイル指定の参照 */
      if (colDef.items[itemDefIdx].class) {
        result += " " + colDef.items[itemDefIdx].class;
      }
      return result.trim();
    },

    // 受託/解除ボタンの表示有無
    orderAcceptButtonVisibility(item) {
      // v-show だと display が none になり 体裁が崩れるので visibility で制御する
      let loginOfficeSID = sessionStorage.getItem("sales_office_sid");
      // let loginOfficeSID = "2100000019";
      if (item.orgOfficeSid === loginOfficeSID) {
        return "hidden";
      }
      return item.routeDefinedStatus != "0" || item.orderAcceptStatus == "0" ? "hidden" : "visible";
    },

    // 受託/解除ボタンのキャプション
    orderAcceptButtonCaption(item) {
      if (item.orderAcceptStatus == "1") {
        return i18n.tc("btn.btn_acceptOrder");
      } else {
        return i18n.tc("btn.btn_refuseOrder");
      }
    },

    // 曜日つき日付変換
    toStringDate(formatString, date) {
      let result = dateTimeHelper.toStringDate(formatString, date);
      const dayOfWeekShort = i18n.tc("label.lbl_dayOfWeek" + date.getDay() + "Short");
      result = result.replace(/WW/g, dayOfWeekShort);
      return result;
    },

    /**
     * 受注一覧画面： CSV出力ボタン押下
     */
    csvOutput() {
      if (this.checkSearchParams() > 0) {
        return;
      }
      if (this.$refs.form.validate() == false) {
        return;
      }
      this.csvOutputDialog.message = messsageUtil.getMessage("P-TOD-002_001_C");
      this.csvOutputDialog.isOpen = true;
      this.csvOutputDialog.screenFlag = true;
      this.csvOutputDialog.changeFlag = false;
      this.csvOutputDialog.okAction = this.csvOutputOk;
    },

    /**
     * 日付大小比較
     */
    compDate(dateF, dateT) {
      if (dateF && dateT) {
        if (new Date(dateF).getTime() > new Date(dateT).getTime()) {
          return false;
        }
      }
      return true;
    },

    /**
     * 日付の値をカレンダー用に変換
     */
    dateCalc(dateValue) {
      return dateValue == null ? null : dateTimeHelper.dateCalc(dateValue);
    },

    /**
     * 受注一覧画面： CSV出力開始
     */
    csvOutputOk() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      // 共通パラメータの設定
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_004;
      // 検索条件設定
      this.createSearchParams(body.reqIdv);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(受注CSV出力)
          .securePost(appConfig.API_URL.BIZ_TRANSPORT_ORDERS_CSV, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.filePath);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.dataOutPutDialog = false;
          });
      });
    },

    /**
     * 警告ダイアログ OKボタン押下
     */
    closeWarningMessage() {
      this.showWarningMessage = false;
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.searchParam) {
        // console.debug(this.shipperClientSelected);
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.shipperClientSelected = "";
        this.orderDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orderDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orgOfficeSelected = "";
        this.pickupPointSelected = "";
        this.pickupScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryPointSelected = "";
        this.deliveryScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.inquiryNumber = "";
        this.freightOrderSid = "";
      }
      this.getMstCode();
    },

    /**
     * 受注修正・削除画面を開く
     */
    openUpdateDelete(item) {
      // 修正画面へ渡す値を設定
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_003,
        params: {
          // 送り状SID
          invNumSid: item.invNumSid,

          // 検索条件
          searchParam: this.searchParam,
        },
      });
    },

    /**
     * ルート登録画面を開く
     */
    openAddRoute(item) {
      // １つでも選択されているか
      if (this.listCheckboxBtn.length == 0) {
        // 警告ダイアログ表示
        this.showWarningMessage = true;
        this.warningMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }

      this.showWarningMessage = false;
      let transportInfoList = [];
      let firstOrder = this.listCheckboxBtn[0];
      this.listCheckboxBtn.some((order) => {
        if (!this.isCompatibleOrder(order, firstOrder)) {
          // 警告ダイアログ表示
          this.showWarningMessage = true;
          this.warningMessage = i18n.tc("check.chk_incompatibleOrder", 0, [order.freightOrderSid]);
          return true;
        }
        transportInfoList.push({
          freightOrderSid: order.freightOrderSid,
        });
        this.routeDefinedStatus = order.routeDefinedStatus;
        return false;
      });
      if (this.showWarningMessage) {
        return;
      }

      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_006,
        params: {
          // 受注情報リスト
          transportInfoList: transportInfoList,
          routeDefinedStatus: this.routeDefinedStatus,
          // 検索条件
          searchParam: this.searchParam,
        },
      });
    },

    /**
     * ルート遷移画面 遷移可否チェック
     */
    checkSelectedOrders() {
      let result = true;

      return result;
    },

    /**
     * チェックボックス押下処理
     */
    checkRow(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);

        // 最後にチェックした行を記憶しておく
        this.lastFocusRow = index;
      } else {
        // チェックを外した場合

        // 外したレコードの受注番号を取得
        let inputListOutlistNo = this.inputList[index].freightOrderSid;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].freightOrderSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }

        // 最後にチェックした行を消去しておく
        this.lastFocusRow = -1;
      }
    },

    /**
     * チェックボックスフォーカス外れ処理
     * 最終選択行をクリアする
     */
    blurRowCheck() {
      if (this.lastFocusRow >= 0) {
        // 少し待ってからクリアする
        setTimeout(() => {
          this.lastFocusRow = -1;
        }, 200);
      }
    },

    /**
     * 一括ボタン押下処理
     */
    batchSelect() {
      if (this.lastFocusRow >= 0) {
        let rowidx = this.lastFocusRow;
        let lastSelOrder = this.inputList[rowidx];
        if (lastSelOrder.check == true) {
          let listCheckboxBtn = this.listCheckboxBtn;
          this.inputList
            // 出荷元・お届け先・集荷予定日・集荷予定時間・到着日・到着時間・担当営業所が一致する行を検索
            .filter((order) => {
              let result = order.check == false && this.isCompatibleOrder(order, lastSelOrder);
              return result;
            })
            // その行を選択した状態にする
            .forEach((order) => {
              order.check = true;
              listCheckboxBtn.push(order);
              this.chengeListCheckbox.push(order);
            });
        }
      }
    },

    /**
     * 受注データどうしの互換性チェック
     */
    isCompatibleOrder(order1, order2) {
      let result =
        order1.pickupPointSid == order2.pickupPointSid &&
        order1.deliveryPointSid == order2.deliveryPointSid &&
        order1.pickupScheduleDate == order2.pickupScheduleDate &&
        order1.pickupScheduleTime == order2.pickupScheduleTime &&
        order1.deliveryScheduleDate == order2.deliveryScheduleDate &&
        order1.deliveryScheduleTime == order2.deliveryScheduleTime &&
        order1.deliveryOfficeSid == order2.deliveryOfficeSid;
      return result;
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 日付Fromを－１日します。
     */
    prevFromDate(param) {
      switch (param) {
        case "order":
          this.orderDateFromCal = commonFunction.addDate(this.orderDateFromCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = commonFunction.addDate(
            this.pickupScheduleDateFromCal,
            ""
          );
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = commonFunction.addDate(
            this.deliveryScheduleDateFromCal,
            ""
          );
          break;
      }
    },

    /**
     * 日付Fromを＋１日します。
     */
    nextFromDate(param) {
      switch (param) {
        case "order":
          this.orderDateFromCal = commonFunction.addDate(this.orderDateFromCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = commonFunction.addDate(
            this.pickupScheduleDateFromCal,
            "add"
          );
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = commonFunction.addDate(
            this.deliveryScheduleDateFromCal,
            "add"
          );
          break;
      }
    },
    /**
     * 日付Toを－１日します。
     */
    prevToDate(param) {
      switch (param) {
        case "order":
          this.orderDateToCal = commonFunction.addDate(this.orderDateToCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateToCal = commonFunction.addDate(this.pickupScheduleDateToCal, "");
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = commonFunction.addDate(
            this.deliveryScheduleDateToCal,
            ""
          );
          break;
      }
    },

    /**
     * 日付Toを＋１日します。
     */
    nextToDate(param) {
      switch (param) {
        case "order":
          this.orderDateToCal = commonFunction.addDate(this.orderDateToCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateToCal = commonFunction.addDate(
            this.pickupScheduleDateToCal,
            "add"
          );
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = commonFunction.addDate(
            this.deliveryScheduleDateToCal,
            "add"
          );
          break;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateFromCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = formatedDt;
          break;
      }
    },

    changecreateDateFrom(val) {
      this.createDateFromCal = commonFunction.directInput(val);
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateToCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateToCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = formatedDt;
          break;
      }
    },

    /**
     * 受注一覧画面：取引先コードリストボックス取得処理
     */
    setSrhShipperClient() {
      return commonFunction.getListSetName(this.shipperClientList, this.shipperClientSelected);
    },

    /**
     * 元請営業所コードリストボックス取得処理
     */
    setSrhOffice() {
      return commonFunction.getListSetName(this.orgOfficeList, this.orgOfficeSelected);
    },
    changeShipperClient(value) {
      let val = this.shipperClientList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.shipperClientHint = val.text;
      }
      this.shipperClientList = this.shipperClientList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.shipperClientHint = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 元請営業所 プルダウンチェンジイベント
     */
    changeOrgOffice(value) {
      // 元請営業所選択時
      let val = this.orgOfficeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.orgOfficeHint = val.text;
        this.orgOfficeInputSync = val.text;
      }
      this.orgOfficeList = this.orgOfficeList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.orgOfficeHint = "";
      } else {
        // 処理なし
      }
    },

    /**
     * お届け先 プルダウンチェンジイベント
     */
    changeDelivery(value) {
      // お届け先を選択時
      let val = this.deliveryPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintDelivery = val.text;
        this.getDeliverySelected = val.text;
        this.deliveryPointSid = val.value;
      }
      this.deliveryPointList = this.deliveryPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintDelivery = "";
        this.deliveryPointSid = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 受注一覧画面：出荷元コードリストボックス取得処理
     */
    setSrhPickup() {
      return commonFunction.getListSetName(this.pickupPointList, this.pickupPointSelected);
    },

    /**
     * 受注一覧画面：お届け先コードリストボックス取得処理
     */
    setSrhDelivery() {
      return commonFunction.getListSetName(this.deliveryPointList, this.deliveryPointSelected);
    },

    /**
     * 集荷先 プルダウンチェンジイベント
     */
    changePickup(value) {
      // 集荷先を選択時
      let val = this.pickupPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintPickup = val.text;
        this.getPickupSelected = val.text;
        this.pickupPointSid = val.value;
      }
      this.pickupPointList = this.pickupPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintPickup = "";
        this.pickupPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 段組み表項目出力
     */
    showValue(value) {
      return value == "" || value == null ? "&nbsp;" : this.escapeHtml(value);
    },

    /**
     * htmlをエスケープする
     */
    escapeHtml(string) {
      if (typeof string !== "string") {
        return string;
      }
      return string.replace(/[&'`"<>]/g, function (match) {
        return {
          "&": "&amp;",
          "'": "&#x27;",
          "`": "&#x60;",
          '"': "&quot;",
          "<": "&lt;",
          ">": "&gt;",
        }[match];
      });
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.shipperClientSelected = "";
        this.shipperClientList = [];
        this.orgOfficeSelected = "";
        this.orgOfficeList = [];
        this.pickupPointSelected = "";
        this.pickupPointList = [];
        this.deliveryPointSelected = "";
        this.deliveryPointList = [];
        this.inquiryNumber = "";
        this.freightOrderSid = "";

        // 初期化状態の設定
        this.orderDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orderDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

        // 遷移フラグ
        this.transitionFlg = false;
      }

      // 初期化
      this.init();
      // 付けられているチェックを初期状態にする。
      this.listCheckboxBtn = [];
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";
    },

    // initDummyData: function () {
    //   this.initDummySession();
    //   this.initApiStub(myOfficeSid);
    // },

    // initDummySession() {
    //   // セッション情報にダミーデータを設定
    //   // sessionStorage.setItem("comp_sid", "1000000100");
    //   // sessionStorage.setItem("usercode", "999");
    //   // sessionStorage.setItem("userInfo.userName", "藤沢 所長");
    //   // sessionStorage.setItem("sales_office_sid", "2100000093");
    //   // sessionStorage.setItem("office_cd", "???");

    //   sessionStorage.setItem("comp_sid", "2400000004");
    //   sessionStorage.setItem("usercode", "093");
    //   sessionStorage.setItem("userInfo.userName", "岡山 所長");
    //   sessionStorage.setItem("sales_office_sid", "2100000093");
    //   sessionStorage.setItem("office_cd", "093");

    //   sessionStorage.setItem("lang", "ja");

    //   // reqComCompanySid: 2400000004
    //   // reqComOfficeSid: 2100000093
    //   // reqComExecUser: 093
    //   // reqComCompanySid: sessionStorage.getItem("comp_sid"),
    //   // reqComOfficeSid: sessionStorage.getItem("sales_office_sid"),
    //   // reqComExecUser: sessionStorage.getItem("usercode"),
    //   // reqComLanguageCd: sessionStorage.getItem("lang"),
    //   // kvp.reqComCompanySid = sessionStorage.getItem("comp_sid");
    //   // kvp.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
    //   // kvp.reqComExecUser = sessionStorage.getItem("usercode");
    //   // kvp.reqComLanguageCd = sessionStorage.getItem("lang");
    // },

    // initApiStub() {
    //   let myOfficeSid = sessionStorage.getItem("sales_office_sid");
    //   let myOfficeName = "自営業所999";
    //   // ダミー受注データ設定
    //   let itemName = "";
    //   let dummyDataList = [];
    //   let dummyDataMap = {};
    //   for (let i = 1; i <= 600; i++) {
    //     let detailNo1 = ("000" + i).slice(-3);
    //     let detailNo2 = ("000" + (((i - 1) % 3) + 1)).slice(-3);
    //     let routeDefinedStatus = "" + (i % 4 < 2 ? "1" : "0");
    //     let freightOrderSid = "1234567" + detailNo1;
    //     let privateFlg = "" + (i % 2);
    //     let privateName = privateFlg == "1" ? "公開" : "非公開";
    //     let orderAcceptStatus = privateFlg == "1" ? "1" : i % 2 == 0 ? "2" : "0";

    //     let orgOfficeSid = "oo" + detailNo1;
    //     let orgOfficeName = "元請営業所" + detailNo1;
    //     let deliveryOfficeSid = "do" + detailNo2;
    //     let deliveryOfficeName = "担当営業所" + detailNo2;

    //     if (orderAcceptStatus == "0") {
    //       orgOfficeSid = myOfficeSid;
    //       orgOfficeName = myOfficeName;
    //       deliveryOfficeSid = myOfficeSid;
    //       deliveryOfficeName = myOfficeName;
    //     }
    //     if (orderAcceptStatus == "1") {
    //       deliveryOfficeSid = orgOfficeSid;
    //       deliveryOfficeName = orgOfficeName;
    //     }
    //     if (orderAcceptStatus == "2") {
    //       deliveryOfficeSid = myOfficeSid;
    //       deliveryOfficeName = myOfficeName;
    //     }

    //     if (i % 19 == 0) {
    //       itemName = "";
    //     }
    //     itemName = "品名" + detailNo1 + (itemName != "" ? "," : "") + itemName;
    //     const dummyData = {
    //       orderDate: "2024/07/12 00:00:00.000",
    //       freightOrderSid: freightOrderSid,
    //       deliveryAreaName: "エリア名称" + detailNo2,
    //       shipperClientCd: "sc" + detailNo1,
    //       shipperClientName: "取引先名テスト" + detailNo1,
    //       pickupPointSid: "pp" + detailNo2,
    //       pickupPointName: "出荷元名テスト" + detailNo2,
    //       deliveryPointSid: "dp" + detailNo2,
    //       deliveryPointName: "お届け先テスト" + detailNo2,
    //       pickupScheduleDate: "2024/07/10 00:00:00.000",
    //       deliveryScheduleDate: "2024/07/11 00:00:00.000",
    //       pickupScheduleTime: "01:00 - 02:00",
    //       deliveryScheduleTime: "10:00 - 13:00",
    //       routeDefinedStatus: routeDefinedStatus,
    //       privateFlg: privateFlg,
    //       privateName: privateName,
    //       orderAcceptStatus: orderAcceptStatus,
    //       orgOfficeSid: orgOfficeSid,
    //       orgOfficeName: orgOfficeName,
    //       deliveryOfficeSid: deliveryOfficeSid,
    //       deliveryOfficeName: deliveryOfficeName,
    //       itemName: itemName,
    //       smallLotsQuantity: i,
    //       totalWeight: i * 100,
    //       inquiryNum: "9876543210",
    //       typicalItemName: "品名" + detailNo1,
    //       typicalPackingNacName: "箱",
    //       updateDatetime: "2024/07/01 00:00:00.000",
    //       freightOrderUpdateDatetime: "2024/07/01 00:00:00.000",
    //       privateInfSid: "xxxx",
    //       invNumSid: "in" + detailNo1,
    //     };
    //     dummyDataMap[freightOrderSid] = dummyData;
    //     dummyDataList.push(dummyData);
    //   }

    //   // APIのスタブ
    //   this.$httpClient.secureGet = function (url, config) {
    //     let params = config.params;
    //     // 受注一覧API
    //     if (url == appConfig.API_URL.BIZ_CUSTOMER_ORDER_SEARCH) {
    //       if (params.shipperClientCd == "4490500") {
    //         return new Promise((resolve, reject) => {
    //           reject("テスト用エラーメッセージ１");
    //         });
    //       }
    //       if (params.shipperClientCd == "4485600") {
    //         return new Promise((resolve) => {
    //           resolve({
    //             data: {
    //               resCom: {
    //                 resComStatus: "Success",
    //                 resComCode: "999",
    //                 resComCount: 8,
    //                 resComMessage: "テスト用エラーメッセージ２",
    //               },
    //             },
    //           });
    //         });
    //       }
    //       let pageStart = params.reqComPageLimit * (params.reqComPageIndex - 1);
    //       let pageEnd = pageStart + params.reqComPageLimit;
    //       if (pageEnd >= dummyDataList.length) {
    //         pageEnd = dummyDataList.length;
    //       }
    //       let pageCount = Math.ceil(dummyDataList.length / params.reqComPageLimit);
    //       const result = dummyDataList.slice(pageStart, pageEnd);
    //       return new Promise((resolve) => {
    //         resolve({
    //           data: {
    //             resCom: {
    //               resComStatus: "Success",
    //               resComCode: "000",
    //               resComCount: result.length,
    //               resComPagination: {
    //                 pageLimit: params.reqComPageLimit,
    //                 pageIndex: params.reqComPageIndex,
    //                 totalRecord: dummyDataList.length,
    //                 totalPage: pageCount,
    //               },
    //             },
    //             resIdv: {
    //               orders: result,
    //             },
    //           },
    //         });
    //       });
    //     }
    //     // 取引先マスタ参照API
    //     if (url == appConfig.API_URL.MST_CUSTOMERS_SEARCH) {
    //       if (params.supplierCode == "99") {
    //         return new Promise((resolve, reject) => {
    //           reject("テスト用エラーメッセージ１");
    //         });
    //       }
    //       if (params.supplierCode == "98") {
    //         return new Promise((resolve) => {
    //           resolve({
    //             data: {
    //               resCom: {
    //                 resComStatus: "Success",
    //                 resComCode: "999",
    //                 resComCount: 8,
    //                 resComMessage: "テスト用エラーメッセージ２",
    //               },
    //             },
    //           });
    //         });
    //       }
    //       let result = [
    //         {
    //           customerCode: "4400000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テクノウッドワークス株式会社",
    //           supplierShortName: "テクノウッドワークス",
    //           supplierNameKana: "テクノウッドワークス",
    //           supplierShortNameKana: "テクノウッドワークス",
    //           postCode: "3220014",
    //           address1: "栃木県鹿沼市 さつき町",
    //           address2: "１６－１",
    //           telephoneNumber: "0289-72-0107",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＰＲ株式会社",
    //           supplierShortName: "ティーピーアール",
    //           supplierNameKana: "ティーピーアール",
    //           supplierShortNameKana: "ティーピーアール",
    //           postCode: "1000005",
    //           address1: "東京都千代田区丸の内１丁目",
    //           address2: "６番２号 新丸の内センタービル１０Ｆ",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デンカ㈱",
    //           supplierShortName: "デンカ",
    //           supplierNameKana: "デンカ",
    //           supplierShortNameKana: "デンカ",
    //           postCode: "1038338",
    //           address1: "東京都中央区日本橋",
    //           address2: "室町２丁目１番１号日本橋三井タワー９Ｆ",
    //           telephoneNumber: "03-5290-5546",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400201",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デンカ株式会社 花巻工場",
    //           supplierShortName: "デンカ ハナマキコウジョウ",
    //           supplierNameKana: "デンカ ハナマキコウジョウ",
    //           supplierShortNameKana: "デンカ ハナマキコウジョウ",
    //           postCode: "0250312",
    //           address1: "岩手県花巻市 二枚橋 ５－１１８",
    //           address2: "",
    //           telephoneNumber: "0198-26-2131",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400202",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デンカ株式会社 千葉工場 事務部業務課製品グループ",
    //           supplierShortName: "デンキカガクコウギョウ チバコウ",
    //           supplierNameKana: "デンキカガクコウギョウ チバコウジョウギョウムカセイヒングル",
    //           supplierShortNameKana: "デンキカガクコウギョウ チバコウ",
    //           postCode: "2900045",
    //           address1: "千葉県市原市",
    //           address2: "五井南海岸６番地",
    //           telephoneNumber: "0436-26-3205",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デュコル",
    //           supplierShortName: "デュコル",
    //           supplierNameKana: "デュコル",
    //           supplierShortNameKana: "デュコル",
    //           postCode: "1140024",
    //           address1: "東京都北区西ヶ原２－３４－１４",
    //           address2: "エクセラ王子２０１号",
    //           telephoneNumber: "03-3918-3933",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社メッツ",
    //           supplierShortName: "メツツ",
    //           supplierNameKana: "メツツ",
    //           supplierShortNameKana: "メツツ",
    //           postCode: "3501101",
    //           address1: "埼玉県川越市",
    //           address2: "大字的場１６２０",
    //           telephoneNumber: "049-237-5901",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400403",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社メッツ 身延工場",
    //           supplierShortName: "メツツ",
    //           supplierNameKana: "メツツ",
    //           supplierShortNameKana: "メツツ",
    //           postCode: "4092522",
    //           address1: "山梨県南巨摩郡",
    //           address2: "身延町下山１０３５０－１",
    //           telephoneNumber: "05566-2-5911",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400404",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社メッツ 狭山工場",
    //           supplierShortName: "メツツ",
    //           supplierNameKana: "メツツ",
    //           supplierShortNameKana: "メツツ",
    //           postCode: "3501301",
    //           address1: "埼玉県狭山市",
    //           address2: "青柳１１４－１",
    //           telephoneNumber: "042-955-6255",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テイケー工業株式会社",
    //           supplierShortName: "テイケーコウギョウ",
    //           supplierNameKana: "テイケーコウギョウ",
    //           supplierShortNameKana: "テイケーコウギョウ",
    //           postCode: "3340056",
    //           address1: "埼玉県川口市",
    //           address2: "峰５７５",
    //           telephoneNumber: "0482-96-4111",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティー・プロ・イノベーション",
    //           supplierShortName: "ティープロイノベーション",
    //           supplierNameKana: "ティープロイノベーション",
    //           supplierShortNameKana: "ティープロイノベーション",
    //           postCode: "3310062",
    //           address1: "埼玉県さいたま市西区 土屋",
    //           address2: "519-1-101",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400701",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ネットワークビジネス開発部",
    //           supplierShortName: "ボルボカージャパン ネットワーク",
    //           supplierNameKana: "ボルボカージャパン ネットワークビジネスカイハツブ",
    //           supplierShortNameKana: "ボルボカージャパン ネットワーク",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8694",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400702",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ＶＯＬＶＯ ＳＥＬＥＫＴ 尾山台",
    //           supplierShortName: "ボルボカージャパン ボルボセ",
    //           supplierNameKana: "ボルボカージャパン ボルボセレクトオヤマダイアプルーブト",
    //           supplierShortNameKana: "ボルボカージャパン ボルボセ",
    //           postCode: "1580086",
    //           address1: "東京都世田谷区 尾山台",
    //           address2: "３－４－３",
    //           telephoneNumber: "03-5752-1651",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400703",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ボルボ・カー練馬",
    //           supplierShortName: "ボルボカージャパン ボルボカ",
    //           supplierNameKana: "ボルボカージャパン ボルボカーネリマ",
    //           supplierShortNameKana: "ボルボカージャパン ボルボカ",
    //           postCode: "1770032",
    //           address1: "東京都練馬区",
    //           address2: "谷原１－２－８",
    //           telephoneNumber: "03-5910-3100",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400704",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ディーラービジネス開発部能力開発グループ",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8698",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400705",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社 ボルボ・カー目黒",
    //           supplierShortName: "ボルボカージャパン ボルボカ",
    //           supplierNameKana: "ボルボカージャパン ボルボカーメグロ",
    //           supplierShortNameKana: "ボルボカージャパン ボルボカ",
    //           postCode: "1520003",
    //           address1: "東京都目黒区",
    //           address2: "碑文谷５－１－５",
    //           telephoneNumber: "03-3714-3261",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400706",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ボルボ・カーズ東名横浜",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1940005",
    //           address1: "東京都町田市南町田５－９－１８",
    //           address2: "",
    //           telephoneNumber: "042-796-1171",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400707",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ボルボ スタジオ青山",
    //           supplierShortName: "ボルボカージャパン ボルボ",
    //           supplierNameKana: "ボルボカージャパン ボルボ スタジオアオヤマ",
    //           supplierShortNameKana: "ボルボカージャパン ボルボ",
    //           postCode: "1070061",
    //           address1: "東京都港区 北青山",
    //           address2: "３－３－１１ １Ｆ",
    //           telephoneNumber: "03-6896-1141",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400709",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ディーラービジネス戦略開発部",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8698",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400710",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ 埼玉サービスセンター",
    //           supplierShortName: "ボルボカージャパン サイタマサー",
    //           supplierNameKana: "ボルボカージャパン サイタマサービスセンター",
    //           supplierShortNameKana: "ボルボカージャパン サイタマサー",
    //           postCode: "3500151",
    //           address1: "埼玉県比企郡川島町 八幡",
    //           address2: "６－２",
    //           telephoneNumber: "049-299-7770",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400711",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ＶＯＬＶＯ ＳＥＬＥＫＴ東名横浜",
    //           supplierShortName: "ボルボカージャパン ボルボセ",
    //           supplierNameKana: "ボルボカージャパン ボルボセレクトトウメイヨコハマ",
    //           supplierShortNameKana: "ボルボカージャパン ボルボセ",
    //           postCode: "1940012",
    //           address1: "東京都町田市金森",
    //           address2: "３－２０－１０",
    //           telephoneNumber: "042-799-1436",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400713",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ 広報部",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400715",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社ボルボ・カーズ三鷹",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1820012",
    //           address1: "東京都調布市",
    //           address2: "深大寺東町８－２９－２",
    //           telephoneNumber: "042-443-3511",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400717",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400718",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社 ボルボ・カー横浜港北",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "2240025",
    //           address1: "神奈川県横浜市",
    //           address2: "都筑区早渕３－２１－２１",
    //           telephoneNumber: "045-593-1185",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400729",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ マーケティング本部",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400731",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社豊橋ＶＤＣ",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "4418134",
    //           address1: "愛知県豊橋市",
    //           address2: "神野西町１－１－４",
    //           telephoneNumber: "0532-33-2222",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400735",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社世田谷支店",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1580097",
    //           address1: "東京都世田谷区",
    //           address2: "用賀１－１０－１５",
    //           telephoneNumber: "03-5491-1611",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400740",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社ボルボ・カー虎ノ門",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050003",
    //           address1: "東京都港区",
    //           address2: "西新橋３－１６－１１ 愛宕イーストビル",
    //           telephoneNumber: "03-6721-2012",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400744",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン株式会社ボルボ・カー小平",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1870002",
    //           address1: "東京都小平市",
    //           address2: "花小金井３－３０－２７",
    //           telephoneNumber: "0424-73-7788",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400745",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050011",
    //           address1: "東京都港区芝公園",
    //           address2: "１－１－１",
    //           telephoneNumber: "03-5404-8700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400750",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "使用不可",
    //           supplierShortName: "ボルボ",
    //           supplierNameKana: "",
    //           supplierShortNameKana: "ボルボ",
    //           postCode: "9999999",
    //           address1: "未設定",
    //           address2: "",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400751",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ボルボ・カー杉並",
    //           supplierShortName: "ボルボカージャパン ボルボカ",
    //           supplierNameKana: "ボルボカージャパン ボルボカースギナミ",
    //           supplierShortNameKana: "ボルボカージャパン ボルボカ",
    //           postCode: "1680081",
    //           address1: "東京都杉並区 宮前",
    //           address2: "５－１４－１２",
    //           telephoneNumber: "03-5336-7321",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400752",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ボルボ・カー・ジャパン㈱ ボルボ・カー・東京",
    //           supplierShortName: "ボルボカージャパン",
    //           supplierNameKana: "ボルボカージャパン",
    //           supplierShortNameKana: "ボルボカージャパン",
    //           postCode: "1050003",
    //           address1: "東京都港区 西新橋",
    //           address2: "３－１６－１１",
    //           telephoneNumber: "03-6721-2015",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "Ｔ－ＷＯＲＫ㈱",
    //           supplierShortName: "ティーワーク",
    //           supplierNameKana: "ティーワーク",
    //           supplierShortNameKana: "ティーワーク",
    //           postCode: "4928144",
    //           address1: "愛知県稲沢市 小池",
    //           address2: "2-25-15",
    //           telephoneNumber: "0587-81-3478",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ホンダカーズ新潟県央",
    //           supplierShortName: "ホンダカーズニイガタケンオウ",
    //           supplierNameKana: "ホンダカーズニイガタケンオウ",
    //           supplierShortNameKana: "ホンダカーズニイガタケンオウ",
    //           postCode: "9550055",
    //           address1: "新潟県三条市",
    //           address2: "塚ノ目２－９－１",
    //           telephoneNumber: "0256-34-7575",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4400901",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ホンダカーズ新潟県央 Ｕ－ＳＥＬＥＣＴ燕三条店",
    //           supplierShortName: "ホンダカーズニイガタケンオウ ユー",
    //           supplierNameKana: "ホンダカーズニイガタケンオウ ユーセレクトツバメサンジョウテン",
    //           supplierShortNameKana: "ホンダカーズニイガタケンオウ ユー",
    //           postCode: "9591232",
    //           address1: "新潟県燕市 井土巻",
    //           address2: "５－１７６",
    //           telephoneNumber: "0256-47-0655",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "寺田産業株式会社",
    //           supplierShortName: "テラダサンギョウ",
    //           supplierNameKana: "テラダサンギョウ",
    //           supplierShortNameKana: "テラダサンギョウ",
    //           postCode: "6780255",
    //           address1: "兵庫県赤穂市 新田",
    //           address2: "９１５番地１",
    //           telephoneNumber: "0791-43-2285",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱定政オート販売 ホンダカーズ鯖江東",
    //           supplierShortName: "テイセイオートハンバイホンダカーズ",
    //           supplierNameKana: "テイセイオートハンバイホンダカーズサバエヒガシ",
    //           supplierShortNameKana: "テイセイオートハンバイホンダカーズ",
    //           postCode: "9161113",
    //           address1: "福井県鯖江市 戸口町",
    //           address2: "17-10",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティティ・アオイ",
    //           supplierShortName: "ティティアオイ",
    //           supplierNameKana: "ティティアオイ",
    //           supplierShortNameKana: "ティティアオイ",
    //           postCode: "9811523",
    //           address1: "宮城県角田市",
    //           address2: "梶賀字高畑北２８７－１",
    //           telephoneNumber: "0224-61-2260",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "Ｔ・Ｓインターナショナル株式会社",
    //           supplierShortName: "テイエスインターナショナル",
    //           supplierNameKana: "テイエスインターナショナル",
    //           supplierShortNameKana: "テイエスインターナショナル",
    //           postCode: "4610049",
    //           address1: "愛知県名古屋市",
    //           address2: "東区古出来１－２－２２はなみビル２Ｂ",
    //           telephoneNumber: "052-711-1119",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱テクニックサービス",
    //           supplierShortName: "テクニック サービス",
    //           supplierNameKana: "テクニック サービス",
    //           supplierShortNameKana: "テクニック サービス",
    //           postCode: "5130835",
    //           address1: "三重県鈴鹿市",
    //           address2: "平野町７６８７－１",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401401",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社テクニックサービス 熊本営業所",
    //           supplierShortName: "テクニックサービス",
    //           supplierNameKana: "カ)テクニックサービス",
    //           supplierShortNameKana: "テクニックサービス",
    //           postCode: "8691236",
    //           address1: "熊本県菊池郡",
    //           address2: "大津町杉水３４１０－７",
    //           telephoneNumber: "096-293-0459",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401450",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社テクニックサービス 預託金専用",
    //           supplierShortName: "テクニックサービス",
    //           supplierNameKana: "",
    //           supplierShortNameKana: "テクニックサービス",
    //           postCode: "1040044",
    //           address1: "東京都中央区",
    //           address2: "明石町６－１７",
    //           telephoneNumber: "03-3546-3605",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ティディエス株式会社",
    //           supplierShortName: "ティディエス",
    //           supplierNameKana: "ティディエス",
    //           supplierShortNameKana: "ティディエス",
    //           postCode: "3940004",
    //           address1: "長野県岡谷市 神明町",
    //           address2: "２－５－５",
    //           telephoneNumber: "0266-23-0577",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱電気硝子物流サービス",
    //           supplierShortName: "デンキガラスブツリュウサービス",
    //           supplierNameKana: "デンキガラスブツリュウサービス",
    //           supplierShortNameKana: "デンキガラスブツリュウサービス",
    //           postCode: "2510021",
    //           address1: "神奈川県藤沢市",
    //           address2: "鵠沼神明３丁目７－６",
    //           telephoneNumber: "0466-24-3064",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401601",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱電気硝子物流サービス 高月事業所",
    //           supplierShortName: "デンキガラスブツリュウサービス",
    //           supplierNameKana: "デンキガラスブツリュウサービス タカツキジギョウショ",
    //           supplierShortNameKana: "デンキガラスブツリュウサービス",
    //           postCode: "5290241",
    //           address1: "滋賀県長浜市 高月町高月",
    //           address2: "１９７９",
    //           telephoneNumber: "0749-85-5903",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401602",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱電気硝子物流サービス 大津事業所",
    //           supplierShortName: "デンキガラスブツリュウサービス",
    //           supplierNameKana: "デンキガラスブツリュウサービス オオツジギョウショ",
    //           supplierShortNameKana: "デンキガラスブツリュウサービス",
    //           postCode: "5200833",
    //           address1: "滋賀県大津市 晴嵐",
    //           address2: "二丁目７番１号",
    //           telephoneNumber: "077-537-1737",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401603",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱電気硝子物流サービス 貿易部",
    //           supplierShortName: "カ)デンキガラスブツリュウサービ",
    //           supplierNameKana: "カ)デンキガラスブツリュウサービス ボウエキブ",
    //           supplierShortNameKana: "カ)デンキガラスブツリュウサービ",
    //           postCode: "5211211",
    //           address1: "滋賀県東近江市 今町",
    //           address2: "934-1",
    //           telephoneNumber: "077-537-1700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401604",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱電気硝子物流サービス 能登川事業所",
    //           supplierShortName: "デンキガラスブツリュウサービス",
    //           supplierNameKana: "デンキガラスブツリュウサービス",
    //           supplierShortNameKana: "デンキガラスブツリュウサービス",
    //           postCode: "5211211",
    //           address1: "滋賀県東近江市今町",
    //           address2: "１０４０番地",
    //           telephoneNumber: "0748-42-0481",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4401900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＴＥＴＯＮ ＢＲＯＳ．",
    //           supplierShortName: "ティートンブロス",
    //           supplierNameKana: "ティートンブロス",
    //           supplierShortNameKana: "ティートンブロス",
    //           postCode: "1040033",
    //           address1: "東京都中央区 新川",
    //           address2: "１丁目－２０－１－１階",
    //           telephoneNumber: "03-6222-9580",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "有限会社天孝産業 本社 営業所",
    //           supplierShortName: "テンコウサンギョウ ホンシャ エイギ",
    //           supplierNameKana: "テンコウサンギョウ ホンシャ エイギョウショ",
    //           supplierShortNameKana: "テンコウサンギョウ ホンシャ エイギ",
    //           postCode: "2520245",
    //           address1: "神奈川県相模原市中央区 田名塩田",
    //           address2: "３－２－５",
    //           telephoneNumber: "042-777-3730",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティーシーライン使用停止 使用コード→４４２８２００",
    //           supplierShortName: "ティーシーライン",
    //           supplierNameKana: "ティーシーライン",
    //           supplierShortNameKana: "ティーシーライン",
    //           postCode: "3440042",
    //           address1: "埼玉県春日部市 増戸",
    //           address2: "２３９－１",
    //           telephoneNumber: "0487-55-7115",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "帝通エンヂニヤリング株式会社",
    //           supplierShortName: "テイツウエンヂニヤリング",
    //           supplierNameKana: "テイツウエンヂニヤリング",
    //           supplierShortNameKana: "テイツウエンヂニヤリング",
    //           postCode: "2118530",
    //           address1: "神奈川県川崎市",
    //           address2: "中原区苅宿４５番１号",
    //           telephoneNumber: "044-411-4454",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デンカポリマー株式会社",
    //           supplierShortName: "デンカポリマー",
    //           supplierNameKana: "デンカポリマー",
    //           supplierShortNameKana: "デンカポリマー",
    //           postCode: "1350042",
    //           address1: "東京都江東区木場１－５－２５",
    //           address2: "深川ギャザリア タワーＳ棟９階",
    //           telephoneNumber: "03-5245-3641",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402301",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デンカポリマー株式会社 香取配送所",
    //           supplierShortName: "デンカポリマー",
    //           supplierNameKana: "デンカポリマー",
    //           supplierShortNameKana: "デンカポリマー",
    //           postCode: "2892247",
    //           address1: "千葉県香取郡",
    //           address2: "多古町水戸字水戸台１－２６",
    //           telephoneNumber: "0479-76-5581",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱天神モータース",
    //           supplierShortName: "テンジン モータース",
    //           supplierNameKana: "テンジン モータース",
    //           supplierShortNameKana: "テンジン モータース",
    //           postCode: "1360071",
    //           address1: "東京都江東区",
    //           address2: "亀戸２－８－３",
    //           telephoneNumber: "03-684-0361",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ティー・エム・エス株式会社",
    //           supplierShortName: "ティーエムエス",
    //           supplierNameKana: "ティーエムエス",
    //           supplierShortNameKana: "ティーエムエス",
    //           postCode: "3700852",
    //           address1: "群馬県高崎市 中居町",
    //           address2: "４ー２ー２１",
    //           telephoneNumber: "027-350-7472",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402501",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ティー・エム・エス株式会社 鶴ヶ島インター支店",
    //           supplierShortName: "ティーエムエス ツルガシマインターシテ",
    //           supplierNameKana: "ティーエムエス ツルガシマインターシテン",
    //           supplierShortNameKana: "ティーエムエス ツルガシマインターシテ",
    //           postCode: "3500027",
    //           address1: "埼玉県坂戸市",
    //           address2: "仲町９－１０",
    //           telephoneNumber: "049-289-4521",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402502",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ティー・エム・エス株式会社 深谷支店",
    //           supplierShortName: "ティーエムエス(カ フカヤシテン",
    //           supplierNameKana: "ティーエムエス(カ フカヤシテン",
    //           supplierShortNameKana: "ティーエムエス(カ フカヤシテン",
    //           postCode: "3660052",
    //           address1: "埼玉県深谷市 上柴町西",
    //           address2: "１－１８－３",
    //           telephoneNumber: "048-551-8511",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクサーブ",
    //           supplierShortName: "テクサーブ",
    //           supplierNameKana: "テクサーブ",
    //           supplierShortNameKana: "テクサーブ",
    //           postCode: "8618046",
    //           address1: "熊本県熊本市",
    //           address2: "石原１丁目２番１号",
    //           telephoneNumber: "096-388-0500",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社Ｔブロード",
    //           supplierShortName: "カブシキガイシャティブロード",
    //           supplierNameKana: "カブシキガイシャティブロード",
    //           supplierShortNameKana: "カブシキガイシャティブロード",
    //           postCode: "5760014",
    //           address1: "大阪府交野市 星田山手",
    //           address2: "４ー９ー２７",
    //           telephoneNumber: "072-891-8798",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "天龍産業㈱",
    //           supplierShortName: "テンリユウサンギヨウ",
    //           supplierNameKana: "テンリユウサンギヨウ",
    //           supplierShortNameKana: "テンリユウサンギヨウ",
    //           postCode: "4380202",
    //           address1: "静岡県磐田市 高木１５５０番地１",
    //           address2: "",
    //           telephoneNumber: "053-421-1431",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テージーケー",
    //           supplierShortName: "テージーケー",
    //           supplierNameKana: "テージーケー",
    //           supplierShortNameKana: "テージーケー",
    //           postCode: "1930942",
    //           address1: "東京都八王子市",
    //           address2: "椚田町１２１１－４",
    //           telephoneNumber: "042-664-1341",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4402901",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テージーケー 城山工場購買部部品管理課",
    //           supplierShortName: "テージーケー",
    //           supplierNameKana: "テージーケー",
    //           supplierShortNameKana: "テージーケー",
    //           postCode: "2520101",
    //           address1: "神奈川県相模原市",
    //           address2: "緑区町屋１－４－３３",
    //           telephoneNumber: "042-782-1246",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＴＳオートサービス",
    //           supplierShortName: "テイエスオートサービス",
    //           supplierNameKana: "テイエスオートサービス",
    //           supplierShortNameKana: "テイエスオートサービス",
    //           postCode: "5150331",
    //           address1: "三重県多気郡明和町 佐田",
    //           address2: "946-1",
    //           telephoneNumber: "0596-65-6855",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＴＮ－ＣＯＮＣＥＰＴ",
    //           supplierShortName: "ティーエヌコンセプト",
    //           supplierNameKana: "ティーエヌコンセプト",
    //           supplierShortNameKana: "ティーエヌコンセプト",
    //           postCode: "5770057",
    //           address1: "大阪府東大阪市 足代新町１１－５",
    //           address2: "",
    //           telephoneNumber: "06-6732-4110",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デイリーホンダ株式会社",
    //           supplierShortName: "デイリーホンダ",
    //           supplierNameKana: "デイリーホンダ",
    //           supplierShortNameKana: "デイリーホンダ",
    //           postCode: "2440842",
    //           address1: "横浜市",
    //           address2: "栄区飯島町１５２１",
    //           telephoneNumber: "045-891-2941",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "寺清自動車株式会社",
    //           supplierShortName: "テラセイジドウシャ",
    //           supplierNameKana: "テラセイジドウシャ",
    //           supplierShortNameKana: "テラセイジドウシャ",
    //           postCode: "3580012",
    //           address1: "埼玉県入間市",
    //           address2: "東藤沢３－６－１１",
    //           telephoneNumber: "04-2963-1348",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ティーエムシー株式会社",
    //           supplierShortName: "ティーエムシー",
    //           supplierNameKana: "ティーエムシー",
    //           supplierShortNameKana: "ティーエムシー",
    //           postCode: "5780982",
    //           address1: "大阪府東大阪市",
    //           address2: "吉田本町３－５－５３",
    //           telephoneNumber: "072-966-0400",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクニカル オーディオ デバイセズ ラボラトリーズ",
    //           supplierShortName: "テクニカル",
    //           supplierNameKana: "テクニカル",
    //           supplierShortNameKana: "テクニカル",
    //           postCode: "3508555",
    //           address1: "埼玉県川越市",
    //           address2: "山田字西町２５－１",
    //           telephoneNumber: "049-228-8235",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティムス",
    //           supplierShortName: "ティムス",
    //           supplierNameKana: "ティムス",
    //           supplierShortNameKana: "ティムス",
    //           postCode: "4700441",
    //           address1: "愛知県豊田市",
    //           address2: "深見町岩花１０６７－１７",
    //           telephoneNumber: "0565-75-3200",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403701",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティムス 計画管理部",
    //           supplierShortName: "ティムス",
    //           supplierNameKana: "ティムス",
    //           supplierShortNameKana: "ティムス",
    //           postCode: "4700441",
    //           address1: "愛知県豊田市",
    //           address2: "深見町岩花１０６７－１７",
    //           telephoneNumber: "0565-75-3206",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＳＰ太陽株式会社",
    //           supplierShortName: "ティーエスピータイヨウ",
    //           supplierNameKana: "ティーエスピータイヨウ",
    //           supplierShortNameKana: "ティーエスピータイヨウ",
    //           postCode: "1530043",
    //           address1: "東京都目黒区",
    //           address2: "東山１－１７－１６",
    //           telephoneNumber: "03-3719-3721",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "天昇電気工業株式会社",
    //           supplierShortName: "テンショウデンキコウギョウ",
    //           supplierNameKana: "テンショウデンキコウギョウ",
    //           supplierShortNameKana: "テンショウデンキコウギョウ",
    //           postCode: "1940004",
    //           address1: "東京都町田市",
    //           address2: "鶴間６８７番１号天幸ビル１７",
    //           telephoneNumber: "042-778-1555",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4403901",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "天昇電気工業株式会社 埼玉工場",
    //           supplierShortName: "テンショウデンキコウギョウ",
    //           supplierNameKana: "テンショウデンキコウギョウ",
    //           supplierShortNameKana: "テンショウデンキコウギョウ",
    //           postCode: "3500142",
    //           address1: "埼玉県比企郡",
    //           address2: "川島町上大屋敷３１５番地",
    //           telephoneNumber: "049-299-3335",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ウイルプラス帝欧オート株式会社",
    //           supplierShortName: "ウイルプラステイオウオート",
    //           supplierNameKana: "ウイルプラステイオウオート",
    //           supplierShortNameKana: "ウイルプラステイオウオート",
    //           postCode: "8100011",
    //           address1: "福岡県福岡市",
    //           address2: "中央区高砂２－７－２ 高砂ビル ２Ｆ",
    //           telephoneNumber: "092-531-7136",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404001",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ウイルプラス帝欧オート株式会社 ボルボ・カーズ福岡南",
    //           supplierShortName: "ウイルプラステイオウオート フクオカミ",
    //           supplierNameKana: "ウイルプラステイオウオート フクオカミナミ",
    //           supplierShortNameKana: "ウイルプラステイオウオート フクオカミ",
    //           postCode: "8160912",
    //           address1: "福岡県大野城市 御笠川４丁目１２－１",
    //           address2: "",
    //           telephoneNumber: "092-504-8800",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ディ・シー・ティ",
    //           supplierShortName: "ディシーティ",
    //           supplierNameKana: "ディシーティ",
    //           supplierShortNameKana: "ディシーティ",
    //           postCode: "1560054",
    //           address1: "東京都世田谷区",
    //           address2: "桜丘５－４８－１１ ２０１",
    //           telephoneNumber: "03-5426-0402",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＩ Ａｕｔｏｍｏｔｉｖｅ Ｊａｐａｎ㈱",
    //           supplierShortName: "ティーアイオートモーティブジャパン",
    //           supplierNameKana: "ティーアイオートモーティブジャパン",
    //           supplierShortNameKana: "ティーアイオートモーティブジャパン",
    //           postCode: "2210835",
    //           address1: "神奈川県横浜市神奈川区",
    //           address2: "鶴屋町３－２９－１ 第６安田ビル",
    //           telephoneNumber: "045-317-8110",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404201",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＩ Ａｕｔｏｍｏｔｉｖｅ Ｊａｐａｎ㈱配管システム事業部",
    //           supplierShortName: "ティーアイオートモーティブジャパン",
    //           supplierNameKana: "ティーアイオートモーティブジャパン",
    //           supplierShortNameKana: "ティーアイオートモーティブジャパン",
    //           postCode: "4600012",
    //           address1: "愛知県名古屋市中区",
    //           address2: "千代田４－２３－２ 第５富士ビル１Ｆ",
    //           telephoneNumber: "052-339-0763",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404202",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＩ Ａｕｔｏｍｏｔｉｖｅ Ｊａｐａｎ㈱ 群馬工場",
    //           supplierShortName: "ティーアイオートモーティブジャパン",
    //           supplierNameKana: "ティーアイオートモーティブジャパン グンマコウジョウ",
    //           supplierShortNameKana: "ティーアイオートモーティブジャパン",
    //           postCode: "3792221",
    //           address1: "群馬県伊勢崎市 国定町",
    //           address2: "１丁目２３－１",
    //           telephoneNumber: "0270-63-8966",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ティティアイ・ジャパン株式会社ジャガー・ランドローバー・アカデミー",
    //           supplierShortName: "ティティアイジャパン",
    //           supplierNameKana: "ティティアイジャパン",
    //           supplierShortNameKana: "ティティアイジャパン",
    //           postCode: "1360082",
    //           address1: "東京都江東区",
    //           address2: "新木場２－１－６",
    //           telephoneNumber: "03-6457-0215",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＭホンダ株式会社",
    //           supplierShortName: "テーエムホンダ",
    //           supplierNameKana: "テーエムホンダ",
    //           supplierShortNameKana: "テーエムホンダ",
    //           postCode: "2290006",
    //           address1: "神奈川県相模原市",
    //           address2: "渕野辺４－３５－１６",
    //           telephoneNumber: "0427-56-2600",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テラダイン株式会社",
    //           supplierShortName: "テラダイン",
    //           supplierNameKana: "テラダイン",
    //           supplierShortNameKana: "テラダイン",
    //           postCode: "2200012",
    //           address1: "神奈川県横浜市西区みなとみらい",
    //           address2: "３－６－３ ＭＭパークビル７階",
    //           telephoneNumber: "045-414-3630",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ディー・アール・シー",
    //           supplierShortName: "ディー・アール・シー",
    //           supplierNameKana: "ディー・アール・シー",
    //           supplierShortNameKana: "ディー・アール・シー",
    //           postCode: "1000005",
    //           address1: "東京都台東区 上野",
    //           address2: "７ー９ー１５",
    //           telephoneNumber: "03-5806-8270",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノメタル 本社",
    //           supplierShortName: "カブシキカイシャテクノメタル ホンシャ",
    //           supplierNameKana: "カブシキカイシャテクノメタル ホンシャ",
    //           supplierShortNameKana: "カブシキカイシャテクノメタル ホンシャ",
    //           postCode: "5710073",
    //           address1: "大阪府門真市 北巣本町",
    //           address2: "２３－３",
    //           telephoneNumber: "072-865-1766",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4404800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＡＫＥ－Ｇ",
    //           supplierShortName: "テイクジー",
    //           supplierNameKana: "テイクジー",
    //           supplierShortNameKana: "テイクジー",
    //           postCode: "1770042",
    //           address1: "東京都練馬区",
    //           address2: "下石神井２－３３－３９",
    //           telephoneNumber: "03-5923-9852",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱テクノトランス",
    //           supplierShortName: "テクノトランス",
    //           supplierNameKana: "テクノトランス",
    //           supplierShortNameKana: "テクノトランス",
    //           postCode: "2891113",
    //           address1: "千葉県八街市 八街へ",
    //           address2: "１９９‐１１８４",
    //           telephoneNumber: "043-440-3710",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＵサービス",
    //           supplierShortName: "ティーユーサービス",
    //           supplierNameKana: "ティーユーサービス",
    //           supplierShortNameKana: "ティーユーサービス",
    //           postCode: "9850044",
    //           address1: "宮城県塩竈市 母子沢町",
    //           address2: "１６‐２",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社ティーケイラインサービス",
    //           supplierShortName: "ティーケイラインサービス",
    //           supplierNameKana: "ティーケイラインサービス",
    //           supplierShortNameKana: "ティーケイラインサービス",
    //           postCode: "2330013",
    //           address1: "神奈川県横浜市港南区 丸山台",
    //           address2: "４ー２ー１１",
    //           telephoneNumber: "045-349-8651",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405605",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デポックス 本社",
    //           supplierShortName: "デポックス",
    //           supplierNameKana: "デポックス",
    //           supplierShortNameKana: "デポックス",
    //           postCode: "3540044",
    //           address1: "埼玉県入間郡",
    //           address2: "三芳町北永井３３－６",
    //           telephoneNumber: "049-259-6012",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "有限会社天領エキスプレス",
    //           supplierShortName: "テンリョウエキスプレス",
    //           supplierNameKana: "テンリョウエキスプレス",
    //           supplierShortNameKana: "テンリョウエキスプレス",
    //           postCode: "8771232",
    //           address1: "大分県日田市大字三和６８７番地",
    //           address2: "",
    //           telephoneNumber: "0973-28-7230",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テイケイワークス株式会社 町田支店",
    //           supplierShortName: "テイケイワークス マチダシテン",
    //           supplierNameKana: "テイケイワークス マチダシテン",
    //           supplierShortNameKana: "テイケイワークス マチダシテン",
    //           postCode: "1940013",
    //           address1: "東京都町田市 原町田６－１８－１３",
    //           address2: "サニーサイドビル３Ｆ",
    //           telephoneNumber: "042-722-0350",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405801",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テイケイワークス株式会社 本社",
    //           supplierShortName: "テイケイワークス",
    //           supplierNameKana: "テイケイワークス",
    //           supplierShortNameKana: "テイケイワークス",
    //           postCode: "1600022",
    //           address1: "東京都新宿区 新宿三丁目１番２４号",
    //           address2: "",
    //           telephoneNumber: "03-3350-7821",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4405900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "有限会社ティ・エス・アール運輸倉庫",
    //           supplierShortName: "ユウゲンガイシャティ・エス・アールウ",
    //           supplierNameKana: "ユウゲンガイシャティ・エス・アールウンユソウコ",
    //           supplierShortNameKana: "ユウゲンガイシャティ・エス・アールウ",
    //           postCode: "3501205",
    //           address1: "埼玉県日高市大字原宿４９１番地２",
    //           address2: "",
    //           telephoneNumber: "042-985-3569",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "天竜自動車運送㈱",
    //           supplierShortName: "テンリュウジドウシャウンソウ",
    //           supplierNameKana: "テンリュウジドウシャウンソウ",
    //           supplierShortNameKana: "テンリュウジドウシャウンソウ",
    //           postCode: "4340012",
    //           address1: "静岡県浜松市浜名区 中瀬",
    //           address2: "７１３５－６４",
    //           telephoneNumber: "053-580-3070",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406101",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "天竜自動車運送㈱ 浜松営業所",
    //           supplierShortName: "テンリュウジドウシャウンソウ ハママ",
    //           supplierNameKana: "テンリュウジドウシャウンソウ ハママツエイギョウショ",
    //           supplierShortNameKana: "テンリュウジドウシャウンソウ ハママ",
    //           postCode: "4338117",
    //           address1: "静岡県浜松市中央区 高丘東",
    //           address2: "２－２０－５７",
    //           telephoneNumber: "053-420-3830",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＤＥＳＴＩＮＹ",
    //           supplierShortName: "ディスティニー",
    //           supplierNameKana: "ディスティニー",
    //           supplierShortNameKana: "ディスティニー",
    //           postCode: "1910002",
    //           address1: "東京都日野市 新町",
    //           address2: "１－１７－５６",
    //           telephoneNumber: "090-2400-8998",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社 ティスコ運輸",
    //           supplierShortName: "カブシキガイシャ ティスコウンユ",
    //           supplierNameKana: "カブシキガイシャ ティスコウンユ",
    //           supplierShortNameKana: "カブシキガイシャ ティスコウンユ",
    //           postCode: "9902161",
    //           address1: "山形県山形市 漆山",
    //           address2: "字大段１８６５－５",
    //           telephoneNumber: "023-686-9860",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406301",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社 ティスコ運輸 岩手営業所",
    //           supplierShortName: "カブシキガイシャ ティスコウンユ イ",
    //           supplierNameKana: "カブシキガイシャ ティスコウンユ イワテエイギョウショ",
    //           supplierShortNameKana: "カブシキガイシャ ティスコウンユ イ",
    //           postCode: "0250004",
    //           address1: "岩手県花巻市 葛",
    //           address2: "",
    //           telephoneNumber: "0198-29-4592",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＴＨＣ",
    //           supplierShortName: "ティーエイチシー",
    //           supplierNameKana: "ティーエイチシー",
    //           supplierShortNameKana: "ティーエイチシー",
    //           postCode: "1100005",
    //           address1: "東京都台東区 上野",
    //           address2: "５－１４－９",
    //           telephoneNumber: "03-3831-5055",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱手原産業倉庫",
    //           supplierShortName: "テハラサンギョウソウコ",
    //           supplierNameKana: "テハラサンギョウソウコ",
    //           supplierShortNameKana: "テハラサンギョウソウコ",
    //           postCode: "5202342",
    //           address1: "滋賀県野洲市",
    //           address2: "野洲１５４８",
    //           telephoneNumber: "077-587-0554",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＢロジスティクス株式会社 業務部 営業・調達グループ",
    //           supplierShortName: "ティービーロジスティクス(カ",
    //           supplierNameKana: "ティービーロジスティクス(カ",
    //           supplierShortNameKana: "ティービーロジスティクス(カ",
    //           postCode: "4730928",
    //           address1: "愛知県豊田市生駒町切戸１７番地の１",
    //           address2: "",
    //           telephoneNumber: "0565-59-3135",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テイケイワークス東京㈱",
    //           supplierShortName: "テイケイワークストウキョウ",
    //           supplierNameKana: "テイケイワークストウキョウ",
    //           supplierShortNameKana: "テイケイワークストウキョウ",
    //           postCode: "1600022",
    //           address1: "東京都新宿区 新宿",
    //           address2: "三丁目１番２４号",
    //           telephoneNumber: "03-3350-8201",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4406800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "寺田倉庫㈱ トランクルームグループ",
    //           supplierShortName: "テラダソウコ トランクルームグルーフ",
    //           supplierNameKana: "テラダソウコ トランクルームグループ",
    //           supplierShortNameKana: "テラダソウコ トランクルームグルーフ",
    //           postCode: "3350025",
    //           address1: "埼玉県戸田市",
    //           address2: "南町６－１３",
    //           telephoneNumber: "048-445-1212",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノＯＮＥ",
    //           supplierShortName: "テクノワン",
    //           supplierNameKana: "テクノワン",
    //           supplierShortNameKana: "テクノワン",
    //           postCode: "3214346",
    //           address1: "栃木県真岡市 松山町",
    //           address2: "２６－４",
    //           telephoneNumber: "0285-81-1030",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＤＩＣインテリア株式会社 本社",
    //           supplierShortName: "ディアイシーインテリア",
    //           supplierNameKana: "ディアイシーインテリア",
    //           supplierShortNameKana: "ディアイシーインテリア",
    //           postCode: "9788113",
    //           address1: "福島県石川郡",
    //           address2: "平田村大字駒形字小舘",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407101",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＤＩＣインテリア㈱ 福島工場",
    //           supplierShortName: "ディアイシーインテリア ソウサイセンヨ",
    //           supplierNameKana: "ディアイシーインテリア ソウサイセンヨウ",
    //           supplierShortNameKana: "ディアイシーインテリア ソウサイセンヨ",
    //           postCode: "9788113",
    //           address1: "福島県石川郡",
    //           address2: "平田村大字駒形字小舘",
    //           telephoneNumber: "0247-54-2990",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407103",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＤＩＣインテリア株式会社 路線課",
    //           supplierShortName: "ディアイシーインテリア",
    //           supplierNameKana: "ディアイシーインテリア",
    //           supplierShortNameKana: "ディアイシーインテリア",
    //           postCode: "9788113",
    //           address1: "福島県石川郡",
    //           address2: "平田村大字駒形字小舘",
    //           telephoneNumber: "0247-54-2990",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ケンコー・トキナー",
    //           supplierShortName: "ケンコートキナー",
    //           supplierNameKana: "ケンコートキナー",
    //           supplierShortNameKana: "ケンコートキナー",
    //           postCode: "1648686",
    //           address1: "東京都中野区 中野５－６８－１０",
    //           address2: "ＫＴ中野ビル",
    //           telephoneNumber: "03-6840-1105",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407201",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ケンコー・トキナー 大阪営業所",
    //           supplierShortName: "ケンコートキナー オオサカエイギョウシ",
    //           supplierNameKana: "ケンコートキナー オオサカエイギョウショ",
    //           supplierShortNameKana: "ケンコートキナー オオサカエイギョウシ",
    //           postCode: "5400005",
    //           address1: "大阪府大阪市中央区 上町１－２－１３",
    //           address2: "",
    //           telephoneNumber: "06-6767-2640",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407202",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ケンコー・トキナー 名古屋出張所",
    //           supplierShortName: "ケンコートキナー ナゴヤシュッチョウシ",
    //           supplierNameKana: "ケンコートキナー ナゴヤシュッチョウジョ",
    //           supplierShortNameKana: "ケンコートキナー ナゴヤシュッチョウシ",
    //           postCode: "4600008",
    //           address1: "愛知県名古屋市中区 栄１－１５－６",
    //           address2: "サカエミヤシタビル１Ｆ",
    //           telephoneNumber: "052-232-3331",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＤＮＰアカウンティング",
    //           supplierShortName: "カブシキガイシャDNPアカウンティン",
    //           supplierNameKana: "カブシキガイシャDNPアカウンティング",
    //           supplierShortNameKana: "カブシキガイシャDNPアカウンティン",
    //           postCode: "1628001",
    //           address1: "東京都新宿区市谷",
    //           address2: "加賀町１－１－１",
    //           telephoneNumber: "050-3753-2502",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デルセーアジアリミテッド日本支店",
    //           supplierShortName: "デルセーアジアリミテッドニホンシテ",
    //           supplierNameKana: "デルセーアジアリミテッドニホンシテン",
    //           supplierShortNameKana: "デルセーアジアリミテッドニホンシテ",
    //           postCode: "2200012",
    //           address1: "神奈川県横浜市西区 みなとみらい",
    //           address2: "３－７－１ ＯＣＥＡＮ ＧＡＴＥ",
    //           telephoneNumber: "045-628-9384",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱デザインアーク",
    //           supplierShortName: "カ)デザインアーク",
    //           supplierNameKana: "カ)デザインアーク",
    //           supplierShortNameKana: "カ)デザインアーク",
    //           postCode: "3080111",
    //           address1: "茨城県筑西市 舟生１５３０",
    //           address2: "",
    //           telephoneNumber: "0296-37-6441",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テクノＵＭＧ株式会社",
    //           supplierShortName: "テクノユーエムジーカブシキカイシャ",
    //           supplierNameKana: "テクノユーエムジーカブシキカイシャ",
    //           supplierShortNameKana: "テクノユーエムジーカブシキカイシャ",
    //           postCode: "5100883",
    //           address1: "三重県四日市市 泊小柳町 ４－６",
    //           address2: "",
    //           telephoneNumber: "059-349-4645",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4407800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テクノデザイン株式会社 加工部",
    //           supplierShortName: "テクノデザイン カコウブ",
    //           supplierNameKana: "テクノデザイン カコウブ",
    //           supplierShortNameKana: "テクノデザイン カコウブ",
    //           postCode: "8611312",
    //           address1: "熊本県菊池市 森北仁田畑",
    //           address2: "１８１２－２６",
    //           telephoneNumber: "0968-25-0758",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＫモータース",
    //           supplierShortName: "ティーケーモータース",
    //           supplierNameKana: "ティーケーモータース",
    //           supplierShortNameKana: "ティーケーモータース",
    //           postCode: "5090206",
    //           address1: "岐阜県可児市 土田",
    //           address2: "5177-4",
    //           telephoneNumber: "0574-66-5460",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＤＩＣデコール㈱",
    //           supplierShortName: "ディーアイシーデコール",
    //           supplierNameKana: "ディーアイシーデコール",
    //           supplierShortNameKana: "ディーアイシーデコール",
    //           postCode: "1038233",
    //           address1: "東京都中央区日本橋三丁目",
    //           address2: "７番２０号",
    //           telephoneNumber: "03-6733-6175",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "有限会社ティーエムオートサービス",
    //           supplierShortName: "ティーエムオートサービス",
    //           supplierNameKana: "ティーエムオートサービス",
    //           supplierShortNameKana: "ティーエムオートサービス",
    //           postCode: "4418077",
    //           address1: "愛知県豊橋市 神野新田町",
    //           address2: "字ヘノ割４２番１号（４０５）",
    //           telephoneNumber: "0532-32-1080",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "ディー・エイチ・エル・ジャパン㈱",
    //           supplierShortName: "ディー・エイチ・エル・ジャパン",
    //           supplierNameKana: "ディー・エイチ・エル・ジャパン",
    //           supplierShortNameKana: "ディー・エイチ・エル・ジャパン",
    //           postCode: "1400002",
    //           address1: "東京都品川区",
    //           address2: "東品川１－３７－８",
    //           telephoneNumber: "03-5479-2580",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "勅使川原産業㈱",
    //           supplierShortName: "テシガワラサンギョウ",
    //           supplierNameKana: "テシガワラサンギョウ",
    //           supplierShortNameKana: "テシガワラサンギョウ",
    //           postCode: "4901105",
    //           address1: "愛知県あま市 新居屋山",
    //           address2: "１３１番地１",
    //           telephoneNumber: "052-442-8817",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408901",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "勅使川原産業㈱ 盛岡営業所",
    //           supplierShortName: "テシガワラサンギョウ モリオカエイキ",
    //           supplierNameKana: "テシガワラサンギョウ モリオカエイギョウショ",
    //           supplierShortNameKana: "テシガワラサンギョウ モリオカエイキ",
    //           postCode: "0283621",
    //           address1: "岩手県紫波郡",
    //           address2: "矢巾町広宮沢第１１地割字北川５０４－５",
    //           telephoneNumber: "019-637-3903",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4408902",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "勅使川原産業㈱ 青森営業所",
    //           supplierShortName: "テシガワラサンギョウ アオモリ",
    //           supplierNameKana: "テシガワラサンギョウ アオモリ",
    //           supplierShortNameKana: "テシガワラサンギョウ アオモリ",
    //           postCode: "0370011",
    //           address1: "青森県五所川原市 金山",
    //           address2: "亀ヶ岡４６－１９",
    //           telephoneNumber: "0173-39-2231",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ディアイ商事",
    //           supplierShortName: "カブシキガイシャディアイ",
    //           supplierNameKana: "カブシキガイシャディアイ",
    //           supplierShortNameKana: "カブシキガイシャディアイ",
    //           postCode: "8120002",
    //           address1: "福岡県福岡市博多区空港前５－５５－１２",
    //           address2: "",
    //           telephoneNumber: "092-624-7200",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社Ｔ・Ｄ・Ｓ",
    //           supplierShortName: "ティーディーエス",
    //           supplierNameKana: "ティーディーエス",
    //           supplierShortNameKana: "ティーディーエス",
    //           postCode: "4640094",
    //           address1: "愛知県名古屋市千種区 赤坂町",
    //           address2: "4-12",
    //           telephoneNumber: "",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デリカ",
    //           supplierShortName: "デリカ",
    //           supplierNameKana: "デリカ",
    //           supplierShortNameKana: "デリカ",
    //           postCode: "3901242",
    //           address1: "長野県松本市",
    //           address2: "大字和田５５１１番１１",
    //           telephoneNumber: "0263-48-1180",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "【使用停止】デンカ株式会社",
    //           supplierShortName: "デンカ",
    //           supplierNameKana: "デンカ",
    //           supplierShortNameKana: "デンカ",
    //           postCode: "1038338",
    //           address1: "東京都中央区日本橋室町二丁目１番１号",
    //           address2: "",
    //           telephoneNumber: "03-5290-5055",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409301",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "【使用停止】デンカ株式会社 花巻工場",
    //           supplierShortName: "デンカ ハナマキコウジョウ",
    //           supplierNameKana: "デンカ ハナマキコウジョウ",
    //           supplierShortNameKana: "デンカ ハナマキコウジョウ",
    //           postCode: "0250312",
    //           address1: "岩手県花巻市 二枚橋 ５－１１８",
    //           address2: "",
    //           telephoneNumber: "0198-26-2131",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティーオーサービス",
    //           supplierShortName: "カ)ティーオーサービス",
    //           supplierNameKana: "カ)ティーオーサービス",
    //           supplierShortNameKana: "カ)ティーオーサービス",
    //           postCode: "2620012",
    //           address1: "千葉県千葉市花見川区 千種町",
    //           address2: "１６８－１４－Ｂ",
    //           telephoneNumber: "043-286-6644",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "寺口運送株式会社",
    //           supplierShortName: "テラグチウンソウ(カ",
    //           supplierNameKana: "テラグチウンソウ(カ",
    //           supplierShortNameKana: "テラグチウンソウ(カ",
    //           postCode: "5900106",
    //           address1: "大阪府堺市南区 豊田",
    //           address2: "５８０",
    //           telephoneNumber: "072-284-1122",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409501",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "寺口運送㈱ 堺営業所",
    //           supplierShortName: "テラグチウンソウ(カ サカイエイギョ",
    //           supplierNameKana: "テラグチウンソウ(カ サカイエイギョウショ",
    //           supplierShortNameKana: "テラグチウンソウ(カ サカイエイギョ",
    //           postCode: "5900106",
    //           address1: "大阪府堺市南区 豊田５８０番地",
    //           address2: "",
    //           telephoneNumber: "072-284-1177",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＬＳ株式会社",
    //           supplierShortName: "ティーエルエスカブシキカイシャ",
    //           supplierNameKana: "ティーエルエスカブシキカイシャ",
    //           supplierShortNameKana: "ティーエルエスカブシキカイシャ",
    //           postCode: "5330033",
    //           address1: "大阪府大阪市東淀川区 東中島",
    //           address2: "１－２１－２",
    //           telephoneNumber: "06-6225-8118",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テック・エンジニアリング",
    //           supplierShortName: "テックエンジニアリング",
    //           supplierNameKana: "テックエンジニアリング",
    //           supplierShortNameKana: "テックエンジニアリング",
    //           postCode: "8700316",
    //           address1: "大分県大分市大字一木字",
    //           address2: "１２１２－１５０",
    //           telephoneNumber: "097-593-4777",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "帝人フロンティア株式会社",
    //           supplierShortName: "テイジンフロンティア",
    //           supplierNameKana: "テイジンフロンティア",
    //           supplierShortNameKana: "テイジンフロンティア",
    //           postCode: "1050021",
    //           address1: "東京都港区 東新橋",
    //           address2: "２－１４－１",
    //           telephoneNumber: "03-6402-7283",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409901",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "帝人フロンティア株式会社 東京工繊部 東京工繊課",
    //           supplierShortName: "テイジンフロンティア トウキョウコウセ",
    //           supplierNameKana: "テイジンフロンティア トウキョウコウセンブトウキョウコウセンカ",
    //           supplierShortNameKana: "テイジンフロンティア トウキョウコウセ",
    //           postCode: "1050021",
    //           address1: "東京都港区 東新橋２丁目１４番１号",
    //           address2: "ＮＢＦコモディオ汐留",
    //           telephoneNumber: "03-6402-7682",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4409902",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "帝人フロンティア株式会社 産業資材販売部 産業資材販売課",
    //           supplierShortName: "テイジンフロンティア サンギョウシサ",
    //           supplierNameKana: "テイジンフロンティア サンギョウシザイハンバイブ サンギョウシ",
    //           supplierShortNameKana: "テイジンフロンティア サンギョウシサ",
    //           postCode: "5308605",
    //           address1: "大阪市北区中之島３丁目２番４号",
    //           address2: "中之島フェスティバルタワー・ウエスト",
    //           telephoneNumber: "06-6233-3196",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノフレックス",
    //           supplierShortName: "テクノフレツクス",
    //           supplierNameKana: "テクノフレツクス",
    //           supplierShortNameKana: "テクノフレツクス",
    //           postCode: "1040042",
    //           address1: "東京都中央区",
    //           address2: "入船１丁目８－３",
    //           telephoneNumber: "03-5117-3611",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410001",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノフレックス 熊本工場",
    //           supplierShortName: "テクノフレツクス",
    //           supplierNameKana: "テクノフレツクス",
    //           supplierShortNameKana: "テクノフレツクス",
    //           postCode: "8691232",
    //           address1: "熊本県菊池郡",
    //           address2: "大津町高尾野２７２－１１",
    //           telephoneNumber: "096-293-3000",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ホンダカーズ富士吉田",
    //           supplierShortName: "ホンダカーズフジヨシダ",
    //           supplierNameKana: "ホンダカーズフジヨシダ",
    //           supplierShortNameKana: "ホンダカーズフジヨシダ",
    //           postCode: "4030004",
    //           address1: "山梨県富士吉田市",
    //           address2: "下吉田２５５０",
    //           telephoneNumber: "0555-22-2238",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＤＩＹセンチュリー",
    //           supplierShortName: "デイーアイワイセンチユリー",
    //           supplierNameKana: "デイー アイ ワイ センチユリー",
    //           supplierShortNameKana: "デイーアイワイセンチユリー",
    //           postCode: "3290206",
    //           address1: "栃木県小山市",
    //           address2: "東間々田２－１７－３４",
    //           telephoneNumber: "0285-45-5582",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社天成運輸",
    //           supplierShortName: "カブシキガイシャテンセイウンユ",
    //           supplierNameKana: "カブシキガイシャテンセイウンユ",
    //           supplierShortNameKana: "カブシキガイシャテンセイウンユ",
    //           postCode: "5980024",
    //           address1: "大阪府泉佐野市 上之郷",
    //           address2: "１９０３",
    //           telephoneNumber: "072-447-8056",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テクダイヤ株式会社",
    //           supplierShortName: "テクダイヤ",
    //           supplierNameKana: "テクダイヤ",
    //           supplierShortNameKana: "テクダイヤ",
    //           postCode: "1706020",
    //           address1: "東京都豊島区東池袋",
    //           address2: "３丁目１番１号 サンシャイン６０・２０階",
    //           telephoneNumber: "03-3988-3500",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ディエスロジコ",
    //           supplierShortName: "ディエスロジコ",
    //           supplierNameKana: "ディエスロジコ",
    //           supplierShortNameKana: "ディエスロジコ",
    //           postCode: "8130062",
    //           address1: "福岡県福岡市",
    //           address2: "東区松島５－４－２６",
    //           telephoneNumber: "092-612-1700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4410900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "有限会社テイカー",
    //           supplierShortName: "テイカー",
    //           supplierNameKana: "テイカー",
    //           supplierShortNameKana: "テイカー",
    //           postCode: "1540004",
    //           address1: "東京都世田谷区太子堂",
    //           address2: "５－２８－９ ジェイアインビル１０２",
    //           telephoneNumber: "03-5431-7232",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テクノグリーンインターナショナル株式会社",
    //           supplierShortName: "テクノグリーンインターナショナル",
    //           supplierNameKana: "テクノグリーンインターナショナル",
    //           supplierShortNameKana: "テクノグリーンインターナショナル",
    //           postCode: "5300015",
    //           address1: "大阪府大阪市",
    //           address2: "北区中崎西１丁目４番２２号",
    //           telephoneNumber: "06-6371-0104",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクニカルニッポン",
    //           supplierShortName: "テクニカル ニツポン",
    //           supplierNameKana: "テクニカル ニツポン",
    //           supplierShortNameKana: "テクニカル ニツポン",
    //           postCode: "5130836",
    //           address1: "三重県鈴鹿市",
    //           address2: "国府町７６５１番地２４",
    //           telephoneNumber: "0593-75-6611",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デジタルハリウッド株式会社",
    //           supplierShortName: "デジタルハリウッド",
    //           supplierNameKana: "デジタルハリウッド",
    //           supplierShortNameKana: "デジタルハリウッド",
    //           postCode: "1010062",
    //           address1: "東京都千代田区 神田駿河台",
    //           address2: "４－６",
    //           telephoneNumber: "03-3526-5785",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社ＴＧＬ",
    //           supplierShortName: "ティージーエル",
    //           supplierNameKana: "ティージーエル",
    //           supplierShortNameKana: "ティージーエル",
    //           postCode: "3306010",
    //           address1: "埼玉県さいたま市中央区",
    //           address2: "新都心１１－２",
    //           telephoneNumber: "048-601-0829",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411401",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＴＧＬ 関西サービスセンター",
    //           supplierShortName: "ティージーエル カンサイサービスセン",
    //           supplierNameKana: "ティージーエル カンサイサービスセンター",
    //           supplierShortNameKana: "ティージーエル カンサイサービスセン",
    //           postCode: "6320098",
    //           address1: "奈良県天理市 南六条町",
    //           address2: "22-1",
    //           telephoneNumber: "0743-87-9421",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＹＳエンタープライズ",
    //           supplierShortName: "ティーワイエスエンタープライズ",
    //           supplierNameKana: "ティーワイエスエンタープライズ",
    //           supplierShortNameKana: "ティーワイエスエンタープライズ",
    //           postCode: "1510071",
    //           address1: "東京都渋谷区 本町",
    //           address2: "１－５４－５",
    //           telephoneNumber: "03-6276-4052",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社住理工ロジテック",
    //           supplierShortName: "スミリコウロジテツク",
    //           supplierNameKana: "スミリコウロジテツク",
    //           supplierShortNameKana: "スミリコウロジテツク",
    //           postCode: "4850831",
    //           address1: "愛知県小牧市",
    //           address2: "東２丁目３２２番地",
    //           telephoneNumber: "0568-77-2986",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テーケィアールマニュファクチャリングジャパン",
    //           supplierShortName: "テーケィアールマニュファクチャリングシ",
    //           supplierNameKana: "テーケィアールマニュファクチャリングジャパン",
    //           supplierShortNameKana: "テーケィアールマニュファクチャリングシ",
    //           postCode: "3113155",
    //           address1: "茨城県東茨城郡茨城町 下土師",
    //           address2: "１８２７－１",
    //           telephoneNumber: "029-292-5115",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4411900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デドン北海道販売㈱",
    //           supplierShortName: "デドンホツカイドウハンバイ",
    //           supplierNameKana: "デドンホツカイドウハンバイ",
    //           supplierShortNameKana: "デドンホツカイドウハンバイ",
    //           postCode: "0992354",
    //           address1: "北海道網走郡大空町 女満別眺湖台",
    //           address2: "１丁目６９－１２",
    //           telephoneNumber: "0152-77-3939",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デンヒチ 本社",
    //           supplierShortName: "デンヒチ",
    //           supplierNameKana: "デンヒチ",
    //           supplierShortNameKana: "デンヒチ",
    //           postCode: "8060001",
    //           address1: "福岡県北九州市",
    //           address2: "八幡西区築地町１９－１５",
    //           telephoneNumber: "093-645-2000",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ディ・エス・シィ株式会社",
    //           supplierShortName: "デイエスシイ",
    //           supplierNameKana: "デイエスシイ",
    //           supplierShortNameKana: "デイエスシイ",
    //           postCode: "5580054",
    //           address1: "大阪府大阪市",
    //           address2: "住吉区帝塚山東１丁目７番２０号",
    //           telephoneNumber: "06-6115-8560",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱デンソーソリューション 北海道支社",
    //           supplierShortName: "デンソーソリューション",
    //           supplierNameKana: "デンソーソリューション",
    //           supplierShortNameKana: "デンソーソリューション",
    //           postCode: "0030002",
    //           address1: "北海道札幌市",
    //           address2: "白石区南郷通２１丁目南４－１５",
    //           telephoneNumber: "011-558-7837",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "帝人エンジニアリング株式会社 複合材料容器課",
    //           supplierShortName: "テイジンエンジニアリング(カ フク",
    //           supplierNameKana: "テイジンエンジニアリング(カ フクゴウザイリョウヨウキカ",
    //           supplierShortNameKana: "テイジンエンジニアリング(カ フク",
    //           postCode: "1080073",
    //           address1: "東京都港区 三田",
    //           address2: "３－１－１７",
    //           telephoneNumber: "03-3506-4064",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＭ ＳＡＩＴＯ合同会社",
    //           supplierShortName: "ティエムサイトウ(ドウ",
    //           supplierNameKana: "ティエムサイトウ(ドウ",
    //           supplierShortNameKana: "ティエムサイトウ(ドウ",
    //           postCode: "4980814",
    //           address1: "三重県桑名郡木曽岬町 三崎",
    //           address2: "３０４－２",
    //           telephoneNumber: "059-484-6828",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社デル・スタッフ",
    //           supplierShortName: "デル・スタッフ",
    //           supplierNameKana: "デル・スタッフ",
    //           supplierShortNameKana: "デル・スタッフ",
    //           postCode: "5230891",
    //           address1: "滋賀県近江八幡市 鷹飼町",
    //           address2: "１４９６－１",
    //           telephoneNumber: "0748-32-5771",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ウエインズインポートカーズ株式会社",
    //           supplierShortName: "ウエインズインポートカーズ",
    //           supplierNameKana: "ウエインズインポートカーズ",
    //           supplierShortNameKana: "ウエインズインポートカーズ",
    //           postCode: "2350005",
    //           address1: "神奈川県横浜市",
    //           address2: "磯子区東町１４",
    //           telephoneNumber: "045-754-5532",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412701",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ウエインズインポートカーズ株式会社ボルボカーズ戸塚",
    //           supplierShortName: "ウエインズインポートカーズ",
    //           supplierNameKana: "ウエインズインポートカーズ",
    //           supplierShortNameKana: "ウエインズインポートカーズ",
    //           postCode: "2450062",
    //           address1: "神奈川県横浜市",
    //           address2: "戸塚区汲沢町１２０９－１",
    //           telephoneNumber: "045-860-5050",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社ティー・シー・シー 宇都宮営業所",
    //           supplierShortName: "カ)ティー・シー・シー ウツノミヤエイキ",
    //           supplierNameKana: "カ)ティー・シー・シー ウツノミヤエイギョウショ",
    //           supplierShortNameKana: "カ)ティー・シー・シー ウツノミヤエイキ",
    //           postCode: "3200051",
    //           address1: "栃木県宇都宮市 上戸祭町",
    //           address2: "９４－２１",
    //           telephoneNumber: "028-600-6630",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4412901",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティー・シー・シー 船橋営業所",
    //           supplierShortName: "カ)ティー・シー・シー フナバシエイキ",
    //           supplierNameKana: "カ)ティー・シー・シー フナバシエイギョウショ",
    //           supplierShortNameKana: "カ)ティー・シー・シー フナバシエイキ",
    //           postCode: "2730005",
    //           address1: "千葉県船橋市 本町７－８－４",
    //           address2: "いつきビル４０１",
    //           telephoneNumber: "047-406-5202",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ＴＨビジネスサポート",
    //           supplierShortName: "ティーエイチビジネスサポート",
    //           supplierNameKana: "ティーエイチビジネスサポート",
    //           supplierShortNameKana: "ティーエイチビジネスサポート",
    //           postCode: "2860035",
    //           address1: "千葉県成田市 囲護台",
    //           address2: "２－１－６",
    //           telephoneNumber: "0476-22-8930",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社 ＴＢＭ",
    //           supplierShortName: "ティービーエム",
    //           supplierNameKana: "ティービーエム",
    //           supplierShortNameKana: "ティービーエム",
    //           postCode: "1040061",
    //           address1: "東京都中央区 銀座",
    //           address2: "２－７－１７－６Ｆ",
    //           telephoneNumber: "03-3538-6777",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テイエス・コーポレーション ホンダドリーム東熊本",
    //           supplierShortName: "テイエスコーポレーション ホンダド",
    //           supplierNameKana: "テイエスコーポレーション ホンダドリームヒガシクマモト",
    //           supplierShortNameKana: "テイエスコーポレーション ホンダド",
    //           postCode: "8691101",
    //           address1: "熊本県菊池郡菊陽町",
    //           address2: "大字津久礼平ノ上１１番地８",
    //           telephoneNumber: "096-232-7321",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413301",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱テイエス・コーポレーション",
    //           supplierShortName: "テイエスコーポレーション",
    //           supplierNameKana: "テイエスコーポレーション",
    //           supplierShortNameKana: "テイエスコーポレーション",
    //           postCode: "8691101",
    //           address1: "熊本県菊池郡",
    //           address2: "菊陽町大字津久礼４３‐２",
    //           telephoneNumber: "096-232-7333",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413302",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "㈱テイエス・コーポレーション 運送部門",
    //           supplierShortName: "テイエスコーポレーション ウンソウブ",
    //           supplierNameKana: "テイエスコーポレーション ウンソウブモン",
    //           supplierShortNameKana: "テイエスコーポレーション ウンソウブ",
    //           postCode: "8611311",
    //           address1: "熊本県菊池市 赤星",
    //           address2: "１８９０",
    //           telephoneNumber: "0968-24-3904",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デリバリーエース株式会社",
    //           supplierShortName: "デリバリーエース",
    //           supplierNameKana: "デリバリーエース",
    //           supplierShortNameKana: "デリバリーエース",
    //           postCode: "4170001",
    //           address1: "静岡県富士市 今泉 ４４１－１",
    //           address2: "",
    //           telephoneNumber: "0545-53-7744",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社寺嶋製作所",
    //           supplierShortName: "テラシマセイサクショ",
    //           supplierNameKana: "テラシマセイサクショ",
    //           supplierShortNameKana: "テラシマセイサクショ",
    //           postCode: "5291551",
    //           address1: "滋賀県東近江市 宮川町",
    //           address2: "３３９－２",
    //           telephoneNumber: "0748-55-0191",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティー・シー・エス",
    //           supplierShortName: "ティー・シー・エス",
    //           supplierNameKana: "ティー・シー・エス",
    //           supplierShortNameKana: "ティー・シー・エス",
    //           postCode: "1100015",
    //           address1: "東京都台東区 東上野",
    //           address2: "３－１６－１－３Ｆ",
    //           telephoneNumber: "03-5812-7601",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4413900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノアソシエ エリア営業本部西日本営業部 九州営",
    //           supplierShortName: "テクノアソシエ エリアエイギョウブホ",
    //           supplierNameKana: "テクノアソシエ エリアエイギョウブホンブニシニホンエイギョウブキ",
    //           supplierShortNameKana: "テクノアソシエ エリアエイギョウブホ",
    //           postCode: "8000314",
    //           address1: "福岡県京都郡苅田町 幸町",
    //           address2: "６番１号",
    //           telephoneNumber: "093-435-0333",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ＴＥＣ群馬",
    //           supplierShortName: "カ)テックグンマ",
    //           supplierNameKana: "カ)テックグンマ",
    //           supplierShortNameKana: "カ)テックグンマ",
    //           postCode: "3720031",
    //           address1: "群馬県伊勢崎市 今泉町",
    //           address2: "２丁目９４４ー８",
    //           telephoneNumber: "0270-75-5777",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社 テクノコシダ",
    //           supplierShortName: "テクノコシダ",
    //           supplierNameKana: "テクノコシダ",
    //           supplierShortNameKana: "テクノコシダ",
    //           postCode: "2700144",
    //           address1: "千葉県流山市 前ケ崎",
    //           address2: "６４７ー１",
    //           telephoneNumber: "04-7170-3777",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "ティーロジエクスプレス㈱",
    //           supplierShortName: "ティーロジエクスプレス",
    //           supplierNameKana: "ティーロジエクスプレス",
    //           supplierShortNameKana: "ティーロジエクスプレス",
    //           postCode: "3720011",
    //           address1: "群馬県伊勢崎市 三和町",
    //           address2: "２７３９ー２",
    //           telephoneNumber: "0270-30-3551",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＰＲ熱学株式会社",
    //           supplierShortName: "ティーピーアールネツガク",
    //           supplierNameKana: "ティーピーアールネツガク",
    //           supplierShortNameKana: "ティーピーアールネツガク",
    //           postCode: "5730065",
    //           address1: "大阪府枚方市 出口",
    //           address2: "１－２－１７",
    //           telephoneNumber: "072-841-0881",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ディエスヴィ・ソリューションズ株式会社",
    //           supplierShortName: "ディエスヴィソリューションズ",
    //           supplierNameKana: "ディエスヴィソリューションズ",
    //           supplierShortNameKana: "ディエスヴィソリューションズ",
    //           postCode: "1350032",
    //           address1: "東京都江東区 福住",
    //           address2: "２－５－４",
    //           telephoneNumber: "03-4510-3489",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティーシーエス",
    //           supplierShortName: "カ)ティーシーエス",
    //           supplierNameKana: "カ)ティーシーエス",
    //           supplierShortNameKana: "カ)ティーシーエス",
    //           postCode: "1100015",
    //           address1: "東京都台東区 東上野",
    //           address2: "３－１８－７－４Ｆ",
    //           telephoneNumber: "03-5812-7601",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社電通 アカウントマネジメント局",
    //           supplierShortName: "デンツウ アカウントマネジメントキョ",
    //           supplierNameKana: "デンツウ アカウントマネジメントキョク",
    //           supplierShortNameKana: "デンツウ アカウントマネジメントキョ",
    //           postCode: "1057001",
    //           address1: "東京都港区",
    //           address2: "東新橋１－８－１",
    //           telephoneNumber: "03-6216-9790",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4414900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノプロ テクノプロ・デザイン社 宇都宮支店",
    //           supplierShortName: "カブシキガイシャテクノプロ テクノ",
    //           supplierNameKana: "カブシキガイシャテクノプロ テクノプロ・デザインシャ ウツノミ",
    //           supplierShortNameKana: "カブシキガイシャテクノプロ テクノ",
    //           postCode: "3210953",
    //           address1: "栃木県宇都宮市東宿郷",
    //           address2: "３－１－７",
    //           telephoneNumber: "028-638-6935",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デンケン",
    //           supplierShortName: "カブシキガイシャデンケン",
    //           supplierNameKana: "カブシキガイシャデンケン",
    //           supplierShortNameKana: "カブシキガイシャデンケン",
    //           postCode: "1100003",
    //           address1: "東京都台東区根岸",
    //           address2: "5-11-25",
    //           telephoneNumber: "03-3871-5251",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "有限会社テクノ物流",
    //           supplierShortName: "テクノブツリュウ",
    //           supplierNameKana: "テクノブツリュウ",
    //           supplierShortNameKana: "テクノブツリュウ",
    //           postCode: "9591513",
    //           address1: "新潟県南蒲原郡田上町 大字川船河",
    //           address2: "１６９１番地",
    //           telephoneNumber: "0256-52-2081",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "有限会社根本",
    //           supplierShortName: "ユウゲンガイシャネモト",
    //           supplierNameKana: "ユウゲンガイシャネモト",
    //           supplierShortNameKana: "ユウゲンガイシャネモト",
    //           postCode: "5100872",
    //           address1: "三重県四日市市 内堀町１８０番地",
    //           address2: "",
    //           telephoneNumber: "059-349-2255",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "天龍工業株式会社",
    //           supplierShortName: "テンリユウコウギヨウ",
    //           supplierNameKana: "テンリユウコウギヨウ",
    //           supplierShortNameKana: "テンリユウコウギヨウ",
    //           postCode: "5040814",
    //           address1: "岐阜県各務原市",
    //           address2: "蘇原興亜町４－１",
    //           telephoneNumber: "0583-82-4111",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415502",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "天龍工業㈱",
    //           supplierShortName: "テンリユウコウギヨウ",
    //           supplierNameKana: "テンリユウコウギヨウ",
    //           supplierShortNameKana: "テンリユウコウギヨウ",
    //           postCode: "9392757",
    //           address1: "富山県富山市",
    //           address2: "婦中町道場１番地３",
    //           telephoneNumber: "076-466-3111",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デンソーパワトレインテクノロジーズ",
    //           supplierShortName: "カブシキガイシャデンソーパワトレ",
    //           supplierNameKana: "カブシキガイシャデンソーパワトレインテクノロジーズ",
    //           supplierShortNameKana: "カブシキガイシャデンソーパワトレ",
    //           postCode: "5110275",
    //           address1: "三重県いなべ市 大安町",
    //           address2: "鍋坂２４３０－１",
    //           telephoneNumber: "0594-87-1056",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "デリシャス㈱",
    //           supplierShortName: "デリシヤス",
    //           supplierNameKana: "デリシヤス",
    //           supplierShortNameKana: "デリシヤス",
    //           postCode: "0611433",
    //           address1: "北海道恵庭市 北柏木町",
    //           address2: "３丁目１５７番地",
    //           telephoneNumber: "0123-34-8172",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティエヌエス 横須賀営業所",
    //           supplierShortName: "カブシキガイシャティエヌエス ヨコス",
    //           supplierNameKana: "カブシキガイシャティエヌエス ヨコスカエイギョウショ",
    //           supplierShortNameKana: "カブシキガイシャティエヌエス ヨコス",
    //           postCode: "2370064",
    //           address1: "神奈川県横須賀市 追浜町",
    //           address2: "３－１９",
    //           telephoneNumber: "046-845-9937",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4415900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デルセージャパン",
    //           supplierShortName: "カブシキガイシャデルセージャパ",
    //           supplierNameKana: "カブシキガイシャデルセージャパン",
    //           supplierShortNameKana: "カブシキガイシャデルセージャパ",
    //           postCode: "2200012",
    //           address1: "神奈川県横浜市西区 みなとみらい",
    //           address2: "３－７－１ ＯＣＥＡＮ ＧＡＴＥ",
    //           telephoneNumber: "045-628-9384",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ＴＳネットワーク",
    //           supplierShortName: "カ)ティーエスネットワーク",
    //           supplierNameKana: "カ)ティーエスネットワーク",
    //           supplierShortNameKana: "カ)ティーエスネットワーク",
    //           postCode: "5110863",
    //           address1: "三重県桑名市 新西方",
    //           address2: "４丁目１０５番地",
    //           telephoneNumber: "0594-24-8207",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テンポスバスターズ 埼玉三芳店",
    //           supplierShortName: "カ)テンポスバスターズ サイタマミ",
    //           supplierNameKana: "カ)テンポスバスターズ サイタマミヨシテン",
    //           supplierShortNameKana: "カ)テンポスバスターズ サイタマミ",
    //           postCode: "3540041",
    //           address1: "埼玉県入間郡三芳町",
    //           address2: "藤久保８５２－３",
    //           telephoneNumber: "049-274-1601",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416201",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テンポスバスターズ 鈴鹿店",
    //           supplierShortName: "カ)テンポスバスターズ スズカテ",
    //           supplierNameKana: "カ)テンポスバスターズ スズカテン",
    //           supplierShortNameKana: "カ)テンポスバスターズ スズカテ",
    //           postCode: "5130802",
    //           address1: "三重県鈴鹿市 飯野寺家町３１１－１",
    //           address2: "",
    //           telephoneNumber: "059-389-5757",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＨＫリズム株式会社 浜松工場 物流課",
    //           supplierShortName: "THKリズム(カ ハママツコウジョウ",
    //           supplierNameKana: "THKリズム(カ ハママツコウジョウ ブツリュウカ",
    //           supplierShortNameKana: "THKリズム(カ ハママツコウジョウ",
    //           postCode: "4300831",
    //           address1: "静岡県浜松市中央区 御給町",
    //           address2: "２８３－３",
    //           telephoneNumber: "053-462-2183",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デンソートリム",
    //           supplierShortName: "デンソートリム",
    //           supplierNameKana: "デンソートリム",
    //           supplierShortNameKana: "デンソートリム",
    //           postCode: "5101222",
    //           address1: "三重県三重郡菰野町 大強原",
    //           address2: "赤坂２４６０",
    //           telephoneNumber: "059-391-0029",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社ティーエヌ運輸倉庫",
    //           supplierShortName: "ティーエヌウンユソウコ",
    //           supplierNameKana: "ティーエヌウンユソウコ",
    //           supplierShortNameKana: "ティーエヌウンユソウコ",
    //           postCode: "8610821",
    //           address1: "熊本県玉名郡南関町 下坂下１９００－２",
    //           address2: "",
    //           telephoneNumber: "0968-66-7770",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416501",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティーエヌ運輸倉庫 大阪支店",
    //           supplierShortName: "ティーエヌウンユソウコ オオサカシテン",
    //           supplierNameKana: "ティーエヌウンユソウコ オオサカシテン",
    //           supplierShortNameKana: "ティーエヌウンユソウコ オオサカシテン",
    //           postCode: "5590025",
    //           address1: "大阪府大阪市住之江区 平林南",
    //           address2: "2-11-88",
    //           telephoneNumber: "06-6654-7312",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416502",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティーエヌ運輸倉庫 福岡営業所",
    //           supplierShortName: "ティーエヌウンユソウコ フクオカエイギ",
    //           supplierNameKana: "ティーエヌウンユソウコ フクオカエイギョウショ",
    //           supplierShortNameKana: "ティーエヌウンユソウコ フクオカエイギ",
    //           postCode: "8113121",
    //           address1: "福岡県古賀市筵内１０３４－４",
    //           address2: "",
    //           telephoneNumber: "092-692-1304",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社滋賀中央物流 岐阜営業所",
    //           supplierShortName: "シガチュウオウブツリュウ ギフエイ",
    //           supplierNameKana: "シガチュウオウブツリュウ ギフエイギョウショ",
    //           supplierShortNameKana: "シガチュウオウブツリュウ ギフエイ",
    //           postCode: "5070061",
    //           address1: "岐阜県多治見市 姫町 ３－１０",
    //           address2: "",
    //           telephoneNumber: "0572-56-7772",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社典勝 経理部",
    //           supplierShortName: "テンショウ ケイリブ",
    //           supplierNameKana: "テンショウ ケイリブ",
    //           supplierShortNameKana: "テンショウ ケイリブ",
    //           postCode: "3060054",
    //           address1: "茨城県古河市 中田新田３４－１０６",
    //           address2: "",
    //           telephoneNumber: "0280-47-4150",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4416900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テイエルブイ 株式会社テイエルブイ",
    //           supplierShortName: "カブシキガイシャテイエルブイ カフ",
    //           supplierNameKana: "カブシキガイシャテイエルブイ カブシキガイシャテイエルブイ",
    //           supplierShortNameKana: "カブシキガイシャテイエルブイ カフ",
    //           postCode: "6758511",
    //           address1: "兵庫県加古川市野口町長砂",
    //           address2: "881",
    //           telephoneNumber: "079-422-1122",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4417100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デンソー三共",
    //           supplierShortName: "デンソーサンキョウ",
    //           supplierNameKana: "デンソーサンキョウ",
    //           supplierShortNameKana: "デンソーサンキョウ",
    //           postCode: "3501335",
    //           address1: "埼玉県狭山市 柏原",
    //           address2: "5000",
    //           telephoneNumber: "04-2900-1400",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4417200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "有限会社ティー・エム・レーザー",
    //           supplierShortName: "ティーエムレーザー",
    //           supplierNameKana: "ティーエムレーザー",
    //           supplierShortNameKana: "ティーエムレーザー",
    //           postCode: "3640014",
    //           address1: "埼玉県北本市 二ツ家３丁目３５番地",
    //           address2: "",
    //           telephoneNumber: "048-577-6438",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4417300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ゼット・エフ・パッシブ・セーフティ・システムズ・ジャパン㈱",
    //           supplierShortName: "ゼット・エフ・パッシブ・セーフティ",
    //           supplierNameKana: "ゼット・エフ・パッシブ・セーフティ・システムズ・ジャパンカブ",
    //           supplierShortNameKana: "ゼット・エフ・パッシブ・セーフティ",
    //           postCode: "2310801",
    //           address1: "神奈川県横浜市中区 新山下",
    //           address2: "１－１６－５",
    //           telephoneNumber: "045-628-8500",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4417400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "出羽弥兵衛株式会社",
    //           supplierShortName: "デワヤヘイ(カ",
    //           supplierNameKana: "デワヤヘイ(カ",
    //           supplierShortNameKana: "デワヤヘイ(カ",
    //           postCode: "9970164",
    //           address1: "山形県鶴岡市 羽黒町昼田",
    //           address2: "字南田５０",
    //           telephoneNumber: "0235-78-0835",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4419800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱ティーエルジャパン",
    //           supplierShortName: "カ)ティーエルジャパン",
    //           supplierNameKana: "カ)ティーエルジャパン",
    //           supplierShortNameKana: "カ)ティーエルジャパン",
    //           postCode: "3430844",
    //           address1: "埼玉県越谷市 大間野町",
    //           address2: "４丁目３２６－１",
    //           telephoneNumber: "048-961-5700",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4420000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノクリエイティブ",
    //           supplierShortName: "カ)テクノクリエイティブ",
    //           supplierNameKana: "カ)テクノクリエイティブ",
    //           supplierShortNameKana: "カ)テクノクリエイティブ",
    //           postCode: "8620954",
    //           address1: "熊本県",
    //           address2: "熊本市中央区神水１丁目３８‐１０",
    //           telephoneNumber: "096-386-2360",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4426400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱ティーエーシー",
    //           supplierShortName: "テイーエーシー",
    //           supplierNameKana: "テイーエーシー",
    //           supplierShortNameKana: "テイーエーシー",
    //           postCode: "2540012",
    //           address1: "神奈川県平塚市 大神",
    //           address2: "２７３９－２",
    //           telephoneNumber: "0463-31-5702",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4426401",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社ティーエーシー",
    //           supplierShortName: "テイーエーシー",
    //           supplierNameKana: "テイーエーシー",
    //           supplierShortNameKana: "テイーエーシー",
    //           postCode: "2591219",
    //           address1: "神奈川県平塚市",
    //           address2: "広川６２ー１",
    //           telephoneNumber: "0463-50-3711",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4426600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "（有）ティービーティー",
    //           supplierShortName: "テイービーテイー",
    //           supplierNameKana: "テイービーテイー",
    //           supplierShortNameKana: "テイービーテイー",
    //           postCode: "2990251",
    //           address1: "千葉県袖ケ浦市 のぞみ野",
    //           address2: "５３ー６",
    //           telephoneNumber: "0438-62-1913",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノ・サービス",
    //           supplierShortName: "テクノサービス",
    //           supplierNameKana: "テクノサービス",
    //           supplierShortNameKana: "テクノサービス",
    //           postCode: "1010022",
    //           address1: "東京都千代田区 神田練塀町",
    //           address2: "八十五番地",
    //           telephoneNumber: "03-5209-7600",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427201",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノサービス使用不可大津営業所",
    //           supplierShortName: "テクノサービスカンサイ",
    //           supplierNameKana: "テクノサービスカンサイ",
    //           supplierShortNameKana: "テクノサービスカンサイ",
    //           postCode: "5250032",
    //           address1: "滋賀県草津市",
    //           address2: "大路一丁目１番１号",
    //           telephoneNumber: "077-564-4550",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427202",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社テクノ・サービス 事務センター",
    //           supplierShortName: "テクノサービス",
    //           supplierNameKana: "テクノサービス",
    //           supplierShortNameKana: "テクノサービス",
    //           postCode: "1710021",
    //           address1: "東京都豊島区",
    //           address2: "西池袋１－７－７ 東京西池袋ビルディング",
    //           telephoneNumber: "03-5958-7520",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427205",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノ・サービス 川越営業所",
    //           supplierShortName: "テクノサービス カワゴエエイギョウ",
    //           supplierNameKana: "テクノサービス カワゴエエイギョウショ",
    //           supplierShortNameKana: "テクノサービス カワゴエエイギョウ",
    //           postCode: "3500046",
    //           address1: "埼玉県川越市",
    //           address2: "菅原町１１番地２白川ビル",
    //           telephoneNumber: "049-225-9280",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427207",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノ・サービス 古河営業所",
    //           supplierShortName: "テクノサービス コガエイギョウショ",
    //           supplierNameKana: "テクノサービス コガエイギョウショ",
    //           supplierShortNameKana: "テクノサービス コガエイギョウショ",
    //           postCode: "3060023",
    //           address1: "茨城県古河市 本町",
    //           address2: "４－１－９",
    //           telephoneNumber: "0120-046-707",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427210",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "㈱テクノ・サービス 佐賀営業所",
    //           supplierShortName: "テクノサービス サガエイギョウショ",
    //           supplierNameKana: "テクノサービス サガエイギョウショ",
    //           supplierShortNameKana: "テクノサービス サガエイギョウショ",
    //           postCode: "8400801",
    //           address1: "佐賀県佐賀市 駅前中央１－１０－３７",
    //           address2: "佐賀駅前センタービル",
    //           telephoneNumber: "0952-28-0910",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "株式会社アグレックス",
    //           supplierShortName: "カブシキガイシャアグレックス",
    //           supplierNameKana: "カブシキガイシャアグレックス",
    //           supplierShortNameKana: "カブシキガイシャアグレックス",
    //           postCode: "1631438",
    //           address1: "東京都新宿区 西新宿三丁目２０番地２号",
    //           address2: "東京オペラシティビル ３８Ｆ",
    //           telephoneNumber: "03-5371-1500",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱ＴＢテクノグリーン",
    //           supplierShortName: "カ)ティービーテクノグリーン",
    //           supplierNameKana: "カ)ティービーテクノグリーン",
    //           supplierShortNameKana: "カ)ティービーテクノグリーン",
    //           postCode: "4730902",
    //           address1: "愛知県豊田市",
    //           address2: "大林町８－５４",
    //           telephoneNumber: "0565-29-1381",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノ・サービス",
    //           supplierShortName: "カ)テクノ・サービス",
    //           supplierNameKana: "カ)テクノ・サービス",
    //           supplierShortNameKana: "カ)テクノ・サービス",
    //           postCode: "1010022",
    //           address1: "東京都千代田区 神田練塀町８５",
    //           address2: "ＪＥＢＬ秋葉原スクエア",
    //           telephoneNumber: "03-5224-1600",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427501",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱テクノ・サービス 高岡営業所",
    //           supplierShortName: "カ)テクノ・サービス タカオカエイギ",
    //           supplierNameKana: "カ)テクノ・サービス タカオカエイギョウショ",
    //           supplierShortNameKana: "カ)テクノ・サービス タカオカエイギ",
    //           postCode: "9398215",
    //           address1: "富山県富山市",
    //           address2: "掛尾栄町４－６０",
    //           telephoneNumber: "076-491-0900",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4427502",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テクノ・サービス 熊本営業所",
    //           supplierShortName: "カ)テクノ・サービス クマモトエイギ",
    //           supplierNameKana: "カ)テクノ・サービス クマモトエイギョウショ",
    //           supplierShortNameKana: "カ)テクノ・サービス クマモトエイギ",
    //           postCode: "1710021",
    //           address1: "東京都豊島区 西池袋",
    //           address2: "１－７－７東京西池袋ビルディング",
    //           telephoneNumber: "03-5950-2810",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428200",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱ティーシーライン",
    //           supplierShortName: "カ)ティーシーライン",
    //           supplierNameKana: "カ)ティーシーライン",
    //           supplierShortNameKana: "カ)ティーシーライン",
    //           postCode: "3440042",
    //           address1: "埼玉県春日部市",
    //           address2: "増戸２３９－１",
    //           telephoneNumber: "048-755-7115",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "ディエスヴィ・エアーシー㈱",
    //           supplierShortName: "ディエスヴィ・エアーシー(カ",
    //           supplierNameKana: "ディエスヴィ・エアーシー(カ",
    //           supplierShortNameKana: "ディエスヴィ・エアーシー(カ",
    //           postCode: "5500013",
    //           address1: "大阪府大阪市西区",
    //           address2: "新町１－１０－２ 大阪産業ビル２Ｆ",
    //           telephoneNumber: "06-4390-4321",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428401",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "ディエスヴィ・エアーシー㈱ 東京支店",
    //           supplierShortName: "ディエスヴィ・エアーシー(カ トウキ",
    //           supplierNameKana: "ディエスヴィ・エアーシー(カ トウキョウシテン",
    //           supplierShortNameKana: "ディエスヴィ・エアーシー(カ トウキ",
    //           postCode: "1350032",
    //           address1: "東京都江東区 福住２丁目５番４号",
    //           address2: "ＩＸＩＮＡＬ門前仲町３階",
    //           telephoneNumber: "03-4510-3480",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428402",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "ディエスヴィ・エアーシー株式会社",
    //           supplierShortName: "ディエスヴィ・エアーシー(カ",
    //           supplierNameKana: "ディエスヴィ・エアーシー(カ",
    //           supplierShortNameKana: "ディエスヴィ・エアーシー(カ",
    //           postCode: "1350032",
    //           address1: "東京都江東区福住",
    //           address2: "２－５－４",
    //           telephoneNumber: "03-4510-3480",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱テラモト",
    //           supplierShortName: "カ)テラモト",
    //           supplierNameKana: "カ)テラモト",
    //           supplierShortNameKana: "カ)テラモト",
    //           postCode: "2100858",
    //           address1: "神奈川県川崎市",
    //           address2: "川崎区大川町１２番８号",
    //           telephoneNumber: "044-355-2640",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428801",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "ディーゼル精機㈱ 固定資産専用",
    //           supplierShortName: "ディーゼルセイキ(カ コテイシサンセ",
    //           supplierNameKana: "ディーゼルセイキ(カ コテイシサンセンヨウ",
    //           supplierShortNameKana: "ディーゼルセイキ(カ コテイシサンセ",
    //           postCode: "5530003",
    //           address1: "大阪府大阪市",
    //           address2: "福島区福島５‐３‐７",
    //           telephoneNumber: "06-6452-0966",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4428900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社デジタルフォトム",
    //           supplierShortName: "デジタルフォトム",
    //           supplierNameKana: "デジタルフォトム",
    //           supplierShortNameKana: "デジタルフォトム",
    //           postCode: "1060031",
    //           address1: "東京都港区",
    //           address2: "西麻布１丁目３番１７号 岡村ビル",
    //           telephoneNumber: "03-3405-7332",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429000",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱テレワークサービス",
    //           supplierShortName: "カ)テレワークサービス",
    //           supplierNameKana: "カ)テレワークサービス",
    //           supplierShortNameKana: "カ)テレワークサービス",
    //           postCode: "3310812",
    //           address1: "埼玉県さいたま市北区 宮原町",
    //           address2: "四丁目６５番地１４",
    //           telephoneNumber: "048-665-0011",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "株式会社ティーエス自動車",
    //           supplierShortName: "カ)ティーエスジドウシャ",
    //           supplierNameKana: "カ)ティーエスジドウシャ",
    //           supplierShortNameKana: "カ)ティーエスジドウシャ",
    //           postCode: "7038212",
    //           address1: "岡山県岡山市",
    //           address2: "東区古都宿２７１－３",
    //           telephoneNumber: "086-278-0055",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "テクニックジャパン株式会社",
    //           supplierShortName: "テクニックジャパン",
    //           supplierNameKana: "テクニックジャパン",
    //           supplierShortNameKana: "テクニックジャパン",
    //           postCode: "1600022",
    //           address1: "東京都新宿区",
    //           address2: "新宿二丁目６番４号ＫＮ新宿ビル８階",
    //           telephoneNumber: "03-5360-8175",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱帝国データバンク",
    //           supplierShortName: "カ)テイコクデータバンク",
    //           supplierNameKana: "カ)テイコクデータバンク",
    //           supplierShortNameKana: "カ)テイコクデータバンク",
    //           postCode: "1600003",
    //           address1: "東京都新宿区 四谷本塩町",
    //           address2: "１４－３",
    //           telephoneNumber: "03-5919-9331",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429601",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱電友社",
    //           supplierShortName: "カ)デンユウシヤ",
    //           supplierNameKana: "カ)デンユウシヤ",
    //           supplierShortNameKana: "カ)デンユウシヤ",
    //           postCode: "0591304",
    //           address1: "北海道苫小牧市",
    //           address2: "北栄町３－２０－１８",
    //           telephoneNumber: "0144-55-3810",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱ティーユーサービス",
    //           supplierShortName: "カ)ティーユーサービス",
    //           supplierNameKana: "カ)ティーユーサービス",
    //           supplierShortNameKana: "カ)ティーユーサービス",
    //           postCode: "5190137",
    //           address1: "三重県亀山市",
    //           address2: "阿野田町９６６－１",
    //           telephoneNumber: "0595-83-4344",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4429800",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱テラシステム",
    //           supplierShortName: "カ)テラシステム",
    //           supplierNameKana: "カ)テラシステム",
    //           supplierShortNameKana: "カ)テラシステム",
    //           postCode: "8691222",
    //           address1: "熊本県菊池郡",
    //           address2: "大津町岩坂塚ノ西３３０１",
    //           telephoneNumber: "096-279-1166",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4430100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティアールジー",
    //           supplierShortName: "ティアールジー",
    //           supplierNameKana: "ティアールジー",
    //           supplierShortNameKana: "ティアールジー",
    //           postCode: "6530033",
    //           address1: "兵庫県神戸市長田区苅藻島町",
    //           address2: "２－１－２１",
    //           telephoneNumber: "078-306-6033",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4431600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱ディールトランスポート",
    //           supplierShortName: "カ)デイールトランスポート",
    //           supplierNameKana: "カ)デイールトランスポート",
    //           supplierShortNameKana: "カ)デイールトランスポート",
    //           postCode: "0030835",
    //           address1: "北海道札幌市白石区 北郷五条",
    //           address2: "７丁目２番１０号",
    //           telephoneNumber: "011-807-4818",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4431700",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "㈱ティエムネットワーク",
    //           supplierShortName: "カ)ティエムネットワーク",
    //           supplierNameKana: "カ)ティエムネットワーク",
    //           supplierShortNameKana: "カ)ティエムネットワーク",
    //           postCode: "2210834",
    //           address1: "神奈川県横浜市神奈川区",
    //           address2: "台町１３番２０号カーサブランカビル５０１",
    //           telephoneNumber: "045-328-1116",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4431701",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "㈱ティエムネットワーク 福島営業所",
    //           supplierShortName: "カ)ティエムネットワーク フクシマエイキ",
    //           supplierNameKana: "カ)ティエムネットワーク フクシマエイギョウショ",
    //           supplierShortNameKana: "カ)ティエムネットワーク フクシマエイキ",
    //           postCode: "9701375",
    //           address1: "福島県いわき市",
    //           address2: "三和町中三坂湯の向１０５",
    //           telephoneNumber: "0246-97-1072",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4432100",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "株式会社ティーテック",
    //           supplierShortName: "テイーテツク",
    //           supplierNameKana: "カ)テイーテツク",
    //           supplierShortNameKana: "テイーテツク",
    //           postCode: "6920023",
    //           address1: "島根県安来市",
    //           address2: "黒井田町 １７８９－１",
    //           telephoneNumber: "0854-27-7233",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4438900",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "天馬運輸㈱",
    //           supplierShortName: "テンマウンユ(カ",
    //           supplierNameKana: "テンマウンユ(カ",
    //           supplierShortNameKana: "テンマウンユ(カ",
    //           postCode: "6332114",
    //           address1: "奈良県宇陀市 大宇陀春日",
    //           address2: "５１４番地",
    //           telephoneNumber: "0743-83-0724",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4485300",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "（有）天申運輸",
    //           supplierShortName: "ユ)テンシンウンユ",
    //           supplierNameKana: "ユ)テンシンウンユ",
    //           supplierShortNameKana: "ユ)テンシンウンユ",
    //           postCode: "7210971",
    //           address1: "広島県福山市",
    //           address2: "蔵王町 ３０５２－１",
    //           telephoneNumber: "084-971-8040",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4485400",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＰＲエンプラ株式会社",
    //           supplierShortName: "ティーピーアールエンプラ",
    //           supplierNameKana: "ティーピーアールエンプラ",
    //           supplierShortNameKana: "ティーピーアールエンプラ",
    //           postCode: "2530101",
    //           address1: "神奈川県高座郡",
    //           address2: "寒川町倉見１８１８番地",
    //           telephoneNumber: "0467-74-8800",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4485500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: true,
    //           customerName: "デュプロ販売㈱ 固定資産専用",
    //           supplierShortName: "デユプロハンバイ(カ",
    //           supplierNameKana: "デユプロハンバイ(カ",
    //           supplierShortNameKana: "デユプロハンバイ(カ",
    //           postCode: "4350046",
    //           address1: "静岡県浜松市中央区 丸塚町",
    //           address2: "５７‐３",
    //           telephoneNumber: "053-466-5131",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4485600",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: false,
    //           isPayable: true,
    //           customerName: "有限会社ディステム",
    //           supplierShortName: "ディステム",
    //           supplierNameKana: "ユ)ディステム",
    //           supplierShortNameKana: "ディステム",
    //           postCode: "0110945",
    //           address1: "秋田県秋田市",
    //           address2: "工崎港西三丁目１４‐２５",
    //           telephoneNumber: "018-893-3543",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //         {
    //           customerCode: "4490500",
    //           customerType: "0",
    //           isDeliveryCompany: false,
    //           isReceivable: true,
    //           isPayable: false,
    //           customerName: "ＴＳＹ ＡＭＥＲＩＣＡ株式会社",
    //           supplierShortName: "ティエスワイアメリカ(カ",
    //           supplierNameKana: "ティエスワイアメリカ(カ",
    //           supplierShortNameKana: "ティエスワイアメリカ(カ",
    //           postCode: "5500014",
    //           address1: "大阪府大阪市西区 北堀江北堀江１丁目５番",
    //           address2: "四ツ橋新興産ビル１２Ｆ",
    //           telephoneNumber: "06-6531-2222",
    //           telephoneNumber2: null,
    //           updateDatetime: "2024-11-29 06:36:11",
    //         },
    //       ];
    //       return new Promise((resolve) => {
    //         resolve({
    //           data: {
    //             resCom: {
    //               resComStatus: "Success",
    //               resComCode: "000",
    //               resComCount: result.length,
    //             },
    //             resIdv: {
    //               supplierInfoList: result,
    //             },
    //           },
    //         });
    //       });
    //     }
    //     // 拠点マスタ参照API
    //     if (url == appConfig.API_URL.MST_BIZ_BASE_SEARCH) {
    //       if (params.baseCode == "99") {
    //         return new Promise((resolve, reject) => {
    //           reject("テスト用エラーメッセージ１");
    //         });
    //       }
    //       if (params.baseCode == "98") {
    //         return new Promise((resolve) => {
    //           resolve({
    //             data: {
    //               resCom: {
    //                 resComStatus: "Success",
    //                 resComCode: "999",
    //                 resComCount: 8,
    //                 resComMessage: "テスト用エラーメッセージ２",
    //               },
    //             },
    //           });
    //         });
    //       }
    //       let result = [];
    //       result = [
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2100000050",
    //           baseCode: "001",
    //           baseOwnerType: "2",
    //           baseName: "日高営業所（テスト用）",
    //           baseNameKana: "日高営業所（テスト用）",
    //           baseShortName: "日高営業所（テスト用）",
    //           baseShortNameKana: null,
    //           postCode: "3501213",
    //           address1: "埼玉県",
    //           address2: "日高市高萩995-1",
    //           telephoneNumber: "042-985-1972",
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-07-23 14:18:00",
    //         },
    //         {
    //           customerCode: "4400710",
    //           baseSid: "2100000999",
    //           baseCode: "001",
    //           baseOwnerType: "2",
    //           baseName: "日高営業所999用",
    //           baseNameKana: "日高営業所",
    //           baseShortName: "日高営業所",
    //           baseShortNameKana: null,
    //           postCode: "3501213",
    //           address1: "埼玉県",
    //           address2: "日高市高萩995-1",
    //           telephoneNumber: "999-9991",
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-07-16 00:00:00",
    //         },
    //         {
    //           customerCode: "4400710",
    //           baseSid: "2400000001",
    //           baseCode: "001",
    //           baseOwnerType: "2",
    //           baseName: "テスト営業所（表示確認用）",
    //           baseNameKana: "テスト営業所（表示確認用）",
    //           baseShortName: "テスト営業所（表示確認用）",
    //           baseShortNameKana: null,
    //           postCode: "3501213",
    //           address1: "埼玉県",
    //           address2: "日高市高萩995-1",
    //           telephoneNumber: "042-999-8745",
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-07-16 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000001",
    //           baseCode: "001",
    //           baseOwnerType: "2",
    //           baseName: "大河原営業所",
    //           baseNameKana: null,
    //           baseShortName: "大河原",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000002",
    //           baseCode: "002",
    //           baseOwnerType: "2",
    //           baseName: "柏原営業所",
    //           baseNameKana: null,
    //           baseShortName: "柏原",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000003",
    //           baseCode: "003",
    //           baseOwnerType: "2",
    //           baseName: "鈴鹿営業所",
    //           baseNameKana: null,
    //           baseShortName: "鈴鹿C",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000004",
    //           baseCode: "004",
    //           baseOwnerType: "2",
    //           baseName: "大津営業所",
    //           baseNameKana: null,
    //           baseShortName: "大津",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000005",
    //           baseCode: "005",
    //           baseOwnerType: "2",
    //           baseName: "真岡営業所",
    //           baseNameKana: null,
    //           baseShortName: "真岡",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000007",
    //           baseCode: "007",
    //           baseOwnerType: "2",
    //           baseName: "広島営業所",
    //           baseNameKana: null,
    //           baseShortName: "広島",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000008",
    //           baseCode: "008",
    //           baseOwnerType: "2",
    //           baseName: "北郡山営業所",
    //           baseNameKana: null,
    //           baseShortName: "北郡山",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000009",
    //           baseCode: "009",
    //           baseOwnerType: "2",
    //           baseName: "太田営業所",
    //           baseNameKana: null,
    //           baseShortName: "太田",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000010",
    //           baseCode: "010",
    //           baseOwnerType: "2",
    //           baseName: "浜松営業所",
    //           baseNameKana: null,
    //           baseShortName: "浜松",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000011",
    //           baseCode: "011",
    //           baseOwnerType: "2",
    //           baseName: "神戸営業所",
    //           baseNameKana: null,
    //           baseShortName: "神戸",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000012",
    //           baseCode: "012",
    //           baseOwnerType: "2",
    //           baseName: "岡山営業所",
    //           baseNameKana: null,
    //           baseShortName: "岡山",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000013",
    //           baseCode: "013",
    //           baseOwnerType: "2",
    //           baseName: "門司営業所",
    //           baseNameKana: null,
    //           baseShortName: "門司",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "2410000014",
    //           baseCode: "014",
    //           baseOwnerType: "2",
    //           baseName: "角田営業所",
    //           baseNameKana: null,
    //           baseShortName: "角田",
    //           baseShortNameKana: null,
    //           postCode: "0000000",
    //           address1: "0",
    //           address2: "0",
    //           telephoneNumber: null,
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-11-20 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "9999999995",
    //           baseCode: "999",
    //           baseOwnerType: "2",
    //           baseName: "999営業所",
    //           baseNameKana: "999営業所",
    //           baseShortName: "999営業所",
    //           baseShortNameKana: null,
    //           postCode: "9999993",
    //           address1: "テスト県",
    //           address2: "テスト市テスト1-3",
    //           telephoneNumber: "999-9993",
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-07-16 00:00:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "8800000001",
    //           baseCode: "A1234567891234567890123456789012345678901234567890",
    //           baseOwnerType: "2",
    //           baseName:
    //             "日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日",
    //           baseNameKana: "日高営業所（テスト用）",
    //           baseShortName: "日高営業所（テスト用）",
    //           baseShortNameKana: null,
    //           postCode: "3501213",
    //           address1: "埼玉県",
    //           address2: "日高市高萩995-1",
    //           telephoneNumber: "0420010-98533-197244",
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-07-23 14:18:00",
    //         },
    //         {
    //           customerCode: "1000000",
    //           baseSid: "8800000002",
    //           baseCode: "A1234567891234567890123456789012345678901234567890",
    //           baseOwnerType: "2",
    //           baseName:
    //             "VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名",
    //           baseNameKana: "VC東名横浜",
    //           baseShortName: "VC東名横浜",
    //           baseShortNameKana: null,
    //           postCode: "1940005",
    //           address1:
    //             "神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川県",
    //           address2:
    //             "日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高市高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩995-1",
    //           telephoneNumber: "0423310-97733-166244",
    //           telephoneNumber2: null,
    //           faxNumber: null,
    //           updateDatetime: "2024-07-23 14:18:00",
    //         },
    //       ];
    //       return new Promise((resolve) => {
    //         resolve({
    //           data: {
    //             resCom: {
    //               resComStatus: "Success",
    //               resComCode: "000",
    //               resComCount: result.length,
    //             },
    //             resIdv: {
    //               baseInfoList: result,
    //             },
    //           },
    //         });
    //       });
    //     }
    //   };
    //   this.$httpClient.securePost = function (url, body) {
    //     let reqIdv = body.reqIdv;
    //     // 受注受託/解除API
    //     if (url == appConfig.API_URL.BIZ_ACCEPT_ORDER) {
    //       if (reqIdv.freightOrderSid == "1234567010") {
    //         return new Promise((resolve, reject) => {
    //           reject("テスト用エラーメッセージ１");
    //         });
    //       }
    //       if (reqIdv.freightOrderSid == "1234567011") {
    //         return new Promise((resolve) => {
    //           resolve({
    //             data: {
    //               resCom: {
    //                 resComStatus: "Success",
    //                 resComCode: "999",
    //                 resComCount: 8,
    //                 resComMessage: "テスト用エラーメッセージ２",
    //               },
    //             },
    //           });
    //         });
    //       }

    //       let dummyData = dummyDataMap[reqIdv.freightOrderSid];
    //       let orderAcceptStatus = dummyData.orderAcceptStatus;
    //       let privateFlg = dummyData.privateFlg;
    //       let orgOfficeSid = dummyData.orgOfficeSid;
    //       let orgOfficeName = dummyData.orgOfficeName;
    //       let deliveryOfficeSid = dummyData.deliveryOfficeSid;
    //       let deliveryOfficeName = dummyData.deliveryOfficeName;
    //       if (reqIdv.orderAcceptStatus == "1") {
    //         orderAcceptStatus = "2";
    //         privateFlg = "0";
    //       } else {
    //         orderAcceptStatus = "1";
    //         privateFlg = "1";
    //       }
    //       if (orderAcceptStatus == "1") {
    //         deliveryOfficeSid = orgOfficeSid;
    //         deliveryOfficeName = orgOfficeName;
    //       }
    //       if (orderAcceptStatus == "2") {
    //         deliveryOfficeSid = myOfficeSid;
    //         deliveryOfficeName = myOfficeName;
    //       }
    //       let privateName = privateFlg == "1" ? "公開" : "非公開";
    //       dummyData.orderAcceptStatus = orderAcceptStatus;
    //       dummyData.privateFlg = privateFlg;
    //       dummyData.privateName = privateName;
    //       dummyData.deliveryOfficeSid = deliveryOfficeSid;
    //       dummyData.deliveryOfficeName = deliveryOfficeName;
    //       return new Promise((resolve) => {
    //         resolve({
    //           data: {
    //             resCom: {
    //               resComStatus: "Success",
    //               resComCode: "000",
    //             },
    //             resIdv: {},
    //           },
    //         });
    //       });
    //     }
    //     if (url == appConfig.API_URL.BIZ_TRANSPORT_ORDERS_CSV) {
    //       return new Promise((resolve) => {
    //         resolve({
    //           data: {
    //             resCom: {
    //               resComStatus: "Success",
    //               resComCode: "000",
    //             },
    //             resIdv: {
    //               filePath: "/files/orders.csv",
    //               // src/pc/public/files/oorders.csv を配置しておく
    //             },
    //           },
    //         });
    //       });
    //     }
    //   };
    // },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    orderDateFromCal: {
      handler(val) {
        this.orderDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    orderDateToCal: {
      handler(val) {
        this.orderDateTo = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateFromCal: {
      handler(val) {
        this.pickupScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateToCal: {
      handler(val) {
        this.pickupScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateFromCal: {
      handler(val) {
        this.deliveryScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateToCal: {
      handler(val) {
        this.deliveryScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },
    sortOptions: {
      handler(items) {
        // console.log("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_TOD_002_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false, false);
        }
      },
      deep: true,
    },

    /**
     * 元請営業所取得
     */
    orgOfficeInputSync(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.orgOfficeHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 事業部・営業所検索キーワード
          params.officeSearchKeyword = val;

          const officeListTmp = this.getMstOffice(params);
          Promise.all([officeListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.orgOfficeList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.orgOfficeList = [];
        }
      }
    },

    /**
     * 出荷元取得
     */
    getPickupSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 取引先コード
          // params.customerCode = shipperClientCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点検索キーワード
          params.baseSearchKeyword = val;

          const pickupListTmp = getParameter.getTmsBizBaseMst(params);
          // const pickupListTmp = [
          //   {
          //     text: "002（VCJ埼玉SC）",
          //     value: "2400000002",
          //     customerCode: "4400710",
          //     name: "VCJ埼玉SC",
          //   },
          //   {
          //     text: "003（VC東名横浜）",
          //     value: "2400000003",
          //     customerCode: "4400711",
          //     name: "VC東名横浜",
          //   },
          //   {
          //     text: "004（VC港北NT）",
          //     value: "2400000004",
          //     customerCode: "4400712",
          //     name: "VC港北NT",
          //   },
          //   {
          //     text: "005（VC目黒）",
          //     value: "2400000005",
          //     customerCode: "4400713",
          //     name: "VC目黒",
          //   },
          //   {
          //     text: "006（VC練馬）",
          //     value: "2400000006",
          //     customerCode: "4400714",
          //     name: "VC練馬",
          //   },
          //   {
          //     text: "007（VC杉並）",
          //     value: "2400000007",
          //     customerCode: "4400715",
          //     name: "VC杉並",
          //   },
          //   {
          //     text: "008（VC世田谷）",
          //     value: "2400000008",
          //     customerCode: "4400716",
          //     name: "VC世田谷",
          //   },
          // ];
          Promise.all([pickupListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.pickupPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },

    /**
     * お届け先取得
     */
    getDeliverySelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 取引先コード
          // params.customerCode = shipperClientCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点検索キーワード
          params.baseSearchKeyword = val;

          const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
          // const deliveryListTmp = [
          //   {
          //     text: "002（VCJ埼玉SC）",
          //     value: "2400000002",
          //     customerCode: "4400710",
          //     name: "VCJ埼玉SC",
          //   },
          //   {
          //     text: "003（VC東名横浜）",
          //     value: "2400000003",
          //     customerCode: "4400711",
          //     name: "VC東名横浜",
          //   },
          //   {
          //     text: "004（VC港北NT）",
          //     value: "2400000004",
          //     customerCode: "4400712",
          //     name: "VC港北NT",
          //   },
          //   {
          //     text: "005（VC目黒）",
          //     value: "2400000005",
          //     customerCode: "4400713",
          //     name: "VC目黒",
          //   },
          //   {
          //     text: "006（VC練馬）",
          //     value: "2400000006",
          //     customerCode: "4400714",
          //     name: "VC練馬",
          //   },
          //   {
          //     text: "007（VC杉並）",
          //     value: "2400000007",
          //     customerCode: "4400715",
          //     name: "VC杉並",
          //   },
          //   {
          //     text: "008（VC世田谷）",
          //     value: "2400000008",
          //     customerCode: "4400716",
          //     name: "VC世田谷",
          //   },
          // ];
          Promise.all([deliveryListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.deliveryPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
        }
      }
    },
    shipperClientInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.shipperClientHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getCustomInfo(val);
        }
      }
    },
  },
  mounted() {
    // this.initDummyData();
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}

/* 一括ボタン */
#batchBtn {
  width: 20px;
  margin: 0px;
}

/* 検索結果表示部 */
#listData.v-data-table--fixed-header > .v-data-table__wrapper {
  > table {
    width: auto;
    table-layout: auto;
    > thead tr {
      height: 72px;
      /* 横スクロール 列固定 */
      th:nth-child(1) {
        position: sticky !important;
        left: 0 !important;
        z-index: 6 !important;
      }
      th:nth-child(2) {
        position: sticky !important;
        left: 50px !important;
        z-index: 6 !important;
      }
    }
    > tbody {
      > tr {
        > td {
          background-color: #ffffff;

          /* 行選択 */
          div.check {
            /* trやtdでのmin-heightの指定が効かないため、ここで最小限の行高さを確保する */
            /* 行の高さを可変にする必要がなければヘッダと同様の指定方法で意図通りに動作する */
            height: 83px;
            /* あとは中のチェックボックスを上下左右中央寄せするための指定 */
            display: flex;
            align-items: center;
            justify-content: center;
          }
          /* 受託/受託解除ボタン */
          div.orderAcceptStatus {
            .v-btn {
              width: 80px;
              margin: 0px;
            }
            .visible {
              visibility: visible;
            }
            .hidden {
              visibility: hidden;
            }
          }
        }
        /* 横スクロール 列固定 */
        td:nth-child(1) {
          position: sticky !important;
          left: 0 !important;
          z-index: 5 !important;
        }
        td:nth-child(2) {
          position: sticky !important;
          left: 50px !important;
          z-index: 5 !important;
        }
      }
      tr:nth-child(odd) td {
        background-color: #dde5f0;
      }
    }
  }
}

.add-textbox-remark {
  width: 40rem;
}

div.container {
  padding-top: 11px;
  padding-bottom: 11px;
}
</style>
<style lang="scss">
// 確認ダイアログの改行設定
div.v-card__text p {
  white-space: pre-wrap;
}
</style>
