<template>
  <v-container class="pa-0 ma-0">
    <v-row dense>
      <v-col cols="12">
        <v-card outlined width="auto" class="pa-1" :class="vehicleBackgroundColor">
          <v-row no-gutters align="end">
            <v-col cols="12" class="d-flex justify-start flex-wrap">
              <div class="text-no-wrap">{{ dispatchVehicle.carShape }}</div>
              <div class="text-no-wrap">{{ `(${dispatchVehicle.carShago})` }}</div>
            </v-col>
          </v-row>
          <v-row no-gutters align="end">
            <v-col cols="12">
              <div>{{ `積載量: ${dispatchVehicle.carPayload}kg` }}</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                depressed
                small
                dark
                max-width="100%"
                color="light-blue darken-1"
                class="px-2 mr-2"
                @click="$emit('add-bin', dispatchVehicle.carSid)"
              >
                <v-icon> mdi-plus </v-icon>
                追加
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-2 mx-1" v-show="showBinList"></v-divider>
          <div class="d-flex flex-column align-end mx-1" v-show="showBinList">
            <v-card
              v-for="(bin, index) in dispatchVehicle.dispatchBinList"
              :key="bin.transportSid"
              outlined
              dense
              width="70%"
              :style="{ display: 'inline-flex' }"
              elevation="1"
              class="mb-1 transport"
            >
              <v-card-text class="pa-1 bin-card">
                <v-icon> mdi-truck-outline </v-icon>
                <span class="ml-3">{{ `第${index + 1}便` }}</span>
              </v-card-text>
              <div class="eye-toggle">
                <v-btn
                  icon
                  small
                  elevation="2"
                  @click="$emit('visiable-bin', dispatchVehicle.carSid, bin.transportSid)"
                >
                  <v-icon>{{ bin.visible ? "mdi-eye" : "mdi-eye-off" }} </v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "VehicleItem",
  props: {
    // 車両情報
    dispatchVehicle: { type: Object, required: true },
  },
  computed: {
    showBinList() {
      return this.dispatchVehicle.dispatchBinList?.length ?? false;
    },
    // 社有か
    isOwnVehicle() {
      return this.dispatchVehicle.carTransportCompDiv === "01";
    },
    vehicleBackgroundColor() {
      return this.isOwnVehicle ? "" : "chartered-vehicle";
    },
  },
  methods: {},
};
</script>

<style scoped>
.bin-card {
  margin: auto 0px;
}

.eye-toggle {
  padding: 4px;
}

.chartered-vehicle {
  background-color: rgb(from #c5cae9 r g b / 60%) !important;
  border: 1px solid #e0e0e0 !important;
}

.chartered-vehicle .transport {
  border: 1px solid #bdbdbd !important;
}
</style>
