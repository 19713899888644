<template>
  <v-card outlined class="lot-item">
    <div class="d-flex justify-space-between">
      <v-card-text class="flex-grow-1 d-flex flex-column py-1 pl-2 pr-0 overflow-hidden">
        <div>
          <span class="mr-1 font-weight-bold">{{ `${$t("label.lbl_lotNumber")}:` }}</span>
          <span class="font-size">{{ lotItem.lotSid }}</span>
        </div>
        <div>
          <div class="d-flex justify-start">
            <div class="mr-4">
              <span class="mr-1 font-weight-bold">{{ `${$t("label.lbl_totalLotWeight")}:` }}</span>
              <span class="font-size"
                >{{ commonFunction.getDelimiterFew(lotItem.totalWeight) }}kg</span
              >
            </div>
            <div>
              <span class="mr-1 font-weight-bold">{{ `${$t("label.lbl_packingNumInLot")}:` }}</span>
              <span class="font-size" v-if="!inTrunkPointLoadingPacking">{{
                lotItem.smallLotsQuantity
              }}</span>
              <span class="font-size" v-else>{{ lotItem.itemCount }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
      <div class="flex-shrink-0 d-flex align-center" v-if="!inTrunkPointLoadingPacking">
        <!-- 詳細を表示するためにアコーディオンメニュー開閉ボタンを押したときにマルチドラッグの選択状態にならないようにするため、
             mousedownとpointerdownのイベントが伝搬しないようにする -->
        <v-btn icon @click="showFreights = !showFreights" @mousedown.stop @pointerdown.stop>
          <v-icon>{{ showFreights ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <!-- ロット内の配達貨物の表示エリア -->
      <div v-show="showFreights" class="px-2">
        <v-divider class="my-1"></v-divider>
        <SortableWrapper
          :option="lotDragOptions"
          v-model="lotFreights"
          style="max-height: calc(5rem * 4)"
          class="overflow-y-auto"
        >
          <FreightItem
            v-for="freight in lotFreights"
            :key="`${freight.pickupDeliveryDiv}_${freight.packingSid}`"
            :freight="freight"
            :undispatchFreightFlg="true"
            :inputDisableFlg="disabledDeliveryDateTimeInput"
            @change:deliveryDateTime="updateDeliveryDateTime(freight.packingSid, $event)"
            class="mb-1"
          ></FreightItem>
        </SortableWrapper>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import SortableWrapper from "./SortableWrapper.vue";
import FreightItem from "./FreightItem.vue";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import DispatchMixin from "./DispatchMixin";
import Sortable from "sortablejs";

export default {
  name: "TrunkLotItem",
  components: {
    SortableWrapper,
    FreightItem,
  },
  mixins: [DispatchMixin],
  props: {
    lotItem: { type: Object, required: true },
    transportCompDivs: { type: Array, required: false, default: () => [] },
    vehicleShapeMajors: { type: Array, required: false, default: () => [] },
    dispatched: { type: Boolean, required: true },
    transportSid: { type: String, required: false },
    showFreightList: { type: Boolean, required: false, default: false },
    disabledDraggable: { type: Boolean, required: false, default: false },
    disabledDeliveryDateTimeInput: { type: Boolean, required: false, default: true },
    inTrunkPointLoadingPacking: { type: Boolean, required: false, default: false },
  },
  watch: {
    showFreightList(newValue) {
      this.show = newValue;
    },
    transportCompDivs(newValue) {
      this.transportCompDivList = newValue;
    },
    vehicleShapeMajors(newValue) {
      this.vehicleShapeMajorList = newValue;
    },
  },
  created() {
    this.transportCompDivList = this.transportCompDivs;
    this.vehicleShapeMajorList = this.vehicleShapeMajors;
  },
  data() {
    return {
      show: this.showFreightList,
      commonFunction: commonFunction,
      /** ロットから貨物のみ移動した場合にロットごと移動するためのハンドラー */
      moveFreightHandler: null,
    };
  },
  computed: {
    showFreights: {
      get() {
        return this.showFreightList || this.show;
      },
      set(newValue) {
        this.show = newValue;
        this.$emit("update:showFreightList", newValue);
      },
    },
    lotFreights: {
      get() {
        const freights = [...(this.lotItem.freightList ?? [])];
        return freights;
      },
      set(newFreights) {
        const newLot = { ...this.lotItem, freightList: newFreights };
        this.$emit("update:lotItem", newLot);
        this.$nextTick(() => {
          // ロット内の貨物が便/未配車貨物のエリアに移動して lotFreights が更新された場合は、
          // ロットの貨物情報や移動元/移動先の情報を伝えるイベントを発火する
          if (this.moveFreightHandler !== null) {
            this.moveFreightHandler();
            this.moveFreightHandler = null;
          }
        });
      },
    },
    lotDragOptions() {
      const name = `lot_${this.lotItem.lotSid}`;
      const group = {
        name,
        put: name,
        pull: (to, from) => {
          const toName = to.options.group.name;
          const fromName = from.options.group.name;
          return this.canMoveFreight(toName, fromName);
        },
      };
      return {
        ...this.defaultDragOptions,
        group,
        disabled: this.disabledDraggable,
        onMove: (evt) => {
          const toName = Sortable.get(evt.to).options.group.name;
          const fromName = Sortable.get(evt.from).options.group.name;
          if (this.canMoveFreight(toName, fromName)) {
            // 移動先がドラッグ可能なエリアの場合
            const obj = {
              from: {
                area: this.dispatched ? this.dragArea.transport : this.dragArea.undistapch,
                transportSid: this.dispatched ? this.transportSid : null,
              },
              to: {
                area:
                  toName === this.undispatchGroupName
                    ? this.dragArea.undistapch
                    : this.dragArea.transport,
                transportSid: toName.startsWith(this.dispatchGroupNamePrefix)
                  ? toName.substring(this.dispatchGroupNamePrefix.length)
                  : null,
              },
              lotSid: this.lotItem.lotSid,
              freightList: [...this.lotFreights],
            };
            this.moveFreightHandler = () => {
              this.$emit("move:freight", obj);
            };
            return true;
          }
          // 移動先がドラッグ不可のエリアの場合
          this.moveFreightHandler = null;
          return false;
        },
      };
    },
  },
  methods: {
    canMoveFreight(toGroupName, ownGroupName) {
      if (toGroupName === ownGroupName) {
        // 移動先が自身のロットの場合
        return true;
      }
      if (toGroupName.startsWith("lot")) {
        // 別のロットの場合
        return false;
      }
      if (this.dispatched) {
        // 配車済みの場合、ロットが配車されている便以外に移動が可能
        return toGroupName !== `${this.dispatchGroupNamePrefix}${this.transportSid}`;
      } else {
        // 未配車の場合、未配車貨物エリア以外に移動が可能
        return toGroupName !== this.undispatchGroupName;
      }
    },
    updateDeliveryDateTime(packingSid, { deliveryDate, deliveryTime }) {
      const index = this.lotFreights.findIndex((x) => x.packingSid === packingSid);
      if (index === -1) {
        return;
      }

      const newItem = {
        ...this.lotFreights[index],
        deliveryDate,
        deliveryTime,
      };
      this.lotFreights = this.lotFreights.toSpliced(index, 1, newItem);
    },
  },
};
</script>
<style lang="scss" scoped>
.lot-item {
  --border-color: 0 0 139;
  border: 2px solid rgb(var(--border-color) / 70%) !important;
}

.font-size {
  font-size: 0.75rem;
}
</style>
