<template>
  <v-sheet color="white" height="100vh" class="overflow-hidden">
    <v-sheet class="overflow-hidden" height="100%">
      <NavBar :tittle="titleLabel" />
      <Loading :loadingCounter="loadingCounter" />
      <v-sheet class="overflow-hidden d-flex flex-column flex-start" height="calc(100% - 48px)">
        <div class="px-4 my-8 flex-shrink-1 flex-grow-0">
          <!-- 受注情報一覧 -->
          <OfficeLotRouteTransportTable :transportList="transportOrderList" />
        </div>
        <div class="d-flex justify-start align-center px-4 mb-1">
          <div class="font-weight-bold" style="font-size: large">
            {{ $t("label.lbl_routeDetail") }}
          </div>
          <template v-if="hasDispatchedOrDeliveriedDelievryStatusDiv">
            <!-- 配車済みまたは配達済みの貨物がある場合はルート確定・削除ボタンは表示せずワーニングメッセージを表示する -->
            <v-alert dense text type="warning" class="mb-0 ml-4">{{
              $t("label.lbl_cannotMakeLotMessage")
            }}</v-alert>
          </template>
          <template v-else>
            <!-- ルート削除・確定ボタン -->
            <v-spacer></v-spacer>
            <v-btn v-if="!isRegister" color="primary" class="mr-4" @click="clickDelete()">{{
              $t("btn.btn_routeDelete")
            }}</v-btn>
            <v-btn color="primary" class="mr-4" @click="clickRegister()">{{
              $t("btn.btn_routeConfirm")
            }}</v-btn>
          </template>
        </div>
        <div class="px-4 flex-shrink-1 flex-grow-0">
          <!-- ロット別ルート -->
          <v-form ref="routeForm" lazy-validation v-model="valid">
            <OfficeLotRouteTable
              :lotRoutes="officeLotRouteList"
              :transportCompDivList="transportCompDivList"
              :pointList="points"
              :vehicleShapeMajorCodeList="vehicleShapeMajorCodes"
              :transportCompDiv.sync="selectedTransportCompDiv"
              :vehicleShapeMajor.sync="selectedVehicleShapeMajor"
              :disabled="hasDispatchedOrDeliveriedDelievryStatusDiv"
              @update:lotRoutes="updateLotRoutes($event)"
            ></OfficeLotRouteTable>
          </v-form>
        </div>
        <div class="d-flex px-4 flex-shrink-1 flex-grow-0 my-2">
          <!-- 営業所別ロット組（荷繰り）登録へ戻るボタン -->
          <v-btn color="primary" @click="clickBack()" style="left: 81vw">{{
            $t("btn.btn_returnMakeLotByOfficeList")
          }}</v-btn>
          <v-spacer></v-spacer>
        </div>
      </v-sheet>
    </v-sheet>
    <SimpleDialog
      :isShow.sync="showInfoDialog"
      :title="infoDialogOption.title"
      :message="infoDialogOption.message"
      :outsideClickNotCloseFlg="infoDialogOption.outsideClickNotCloseFlg"
      :firstPageFlag="infoDialogOption.firstPageFlag"
      :closeFlag="infoDialogOption.closeFlag"
      :todAddFlg="infoDialogOption.todAddFlg"
      :todAddSearchParams="infoDialogOption.todAddSearchParams"
      :isRequireOkActionWhenpressOkBtn="infoDialogOption.isRequireOkActionWhenpressOkBtn"
      :okAction="infoDialogOption.okAction"
    />
    <ConfirmDialog
      :isShow.sync="showConfirmDialog"
      :title="confirmDialogOption.title"
      :message="confirmDialogOption.message"
      :redMessage="confirmDialogOption.redMessage"
      :okAction="confirmDialogOption.okAction"
      :screenFlag="confirmDialogOption.screenFlag"
      :changeFlag="confirmDialogOption.changeFlag"
      :cancelAction="confirmDialogOption.cancelAction"
      :okBtnTxt="confirmDialogOption.okBtnTxt"
      :cancelBtnTxt="confirmDialogOption.cancelBtnTxt"
      :cancelBtnFlg="confirmDialogOption.cancelBtnFlg"
      :okBtnColor="confirmDialogOption.okBtnColor"
      :isAlert="confirmDialogOption.isAlert"
      :outsideClickNotCloseFlg="confirmDialogOption.outsideClickNotCloseFlg"
    />
  </v-sheet>
</template>
<script>
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "../../components/SimpleDialog.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import NavBar from "../../components/NavBar.vue";
import Loading from "../../components/loading.vue";
import OfficeLotRouteTransportTable from "./OfficeLotRouteTransportTable.vue";
import OfficeLotRouteTable from "./OfficeLotRouteTable.vue";

/** 個人情報・拠点区分 */
const PointDiv = {
  /** 個人情報 */
  PRIVATE_INF: 0,
  /** 拠点 */
  POINT: 1,
};

/** 個人用の拠点コード */
const POINT_CODE_FOR_PRIVATE = "900";

export default {
  name: "OfficeLotRouteAdd",
  components: {
    SimpleDialog,
    ConfirmDialog,
    NavBar,
    Loading,
    OfficeLotRouteTransportTable,
    OfficeLotRouteTable,
  },
  data() {
    return {
      loadingCounter: 0,
      transportInfoList: [],
      valid: true,
      lotSid: null,
      invNumSid: null,
      transportCompDivList: [],
      vehicleShapeMajorCodes: [],
      transportOrderList: [],
      packingList: [],
      lotInfo: null,
      officeLotRouteList: [],
      showConfirmDialog: false,
      modifiedConfirmDialogOption: {},
      showInfoDialog: false,
      modifiedInfoDialogOption: {},
      infoDialog: {
        isShow: false,
        infoDialog: appConfig.DIALOG.title,
        message: "",
      },
      selectedTransportCompDiv: "",
      selectedVehicleShapeMajor: "",
      routeLastUpdateDateTime: null,
      searchParam: null, // 前画面（営業所別ロット組登録画面）の検索条件
    };
  },
  computed: {
    /** 配車済み・配達済みの貨物があるか */
    hasDispatchedOrDeliveriedDelievryStatusDiv() {
      return this.packingList
        ?.map((x) => x.deliveryStatusDiv)
        .some(
          (div) =>
            div === appConfig.DELIVERY_STATUS_DIV.DISPATCHED ||
            div === appConfig.DELIVERY_STATUS_DIV.DELIVERED
        );
    },
    isRegister() {
      // ロットSIDがなければ登録
      return !this.lotInfo?.lotSid;
    },
    titleLabel() {
      return this.isRegister
        ? "label.lbl_officeRouteRegister"
        : "label.lbl_officeRouteUpdateAndDelete";
    },
    infoDialogOption() {
      return {
        title: appConfig.DIALOG.title,
        message: "",
        firstPageFlag: true,
        closeFlag: false,
        todAddFlg: false,
        todAddSearchParams: null,
        outsideClickNotCloseFlg: false,
        isRequireOkActionWhenpressOkBtn: true,
        okAction: () => {},
        ...this.modifiedInfoDialogOption,
      };
    },
    confirmDialogOption() {
      return {
        title: "確認",
        message: "",
        redMessage: "",
        okAction: () => {},
        screenFlag: false,
        changeFlag: false,
        cancelAction: () => {},
        okBtnTxt: "OK",
        cancelBtnTxt: "キャンセル",
        cancelBtnFlg: true,
        okBtnColor: "",
        isAlert: false,
        outsideClickNotCloseFlg: false,
        ...this.modifiedConfirmDialogOption,
      };
    },
    // ルートで選択可能な拠点
    // 受注情報の集荷元・お届け先のみをまとめる
    points() {
      const pointMap = new Map();
      const privateInfMap = new Map();
      for (const t of this.transportOrderList ?? []) {
        // 出荷元
        if (t.pickupPointCd === POINT_CODE_FOR_PRIVATE) {
          // 個人の場合
          if (!privateInfMap.has(t.pickupPrivateInfSid)) {
            const val = {
              div: PointDiv.PRIVATE_INF,
              value: t.pickupPrivateInfSid,
              text: t.pickupPointName,
            };
            privateInfMap.set(t.pickupPrivateInfSid, val);
          }
        } else {
          // 拠点の場合
          if (!pointMap.has(t.pickupPointSid)) {
            const val = { div: PointDiv.POINT, value: t.pickupPointSid, text: t.pickupPointName };
            pointMap.set(t.pickupPointSid, val);
          }
        }

        // お届け先
        if (t.deliveryPointCd === POINT_CODE_FOR_PRIVATE) {
          // 個人の場合
          if (!privateInfMap.has(t.deliveryPrivateInfSid)) {
            const val = {
              div: PointDiv.PRIVATE_INF,
              value: t.deliveryPrivateInfSid,
              text: t.deliveryPointName,
            };
            privateInfMap.set(t.deliveryPrivateInfSid, val);
          }
        } else {
          // 拠点の場合
          if (!pointMap.has(t.deliveryPointSid)) {
            const val = {
              div: PointDiv.POINT,
              value: t.deliveryPointSid,
              text: t.deliveryPointName,
            };
            pointMap.set(t.deliveryPointSid, val);
          }
        }
      }

      // 拠点、個人の順で配列にまとめる
      return [...pointMap.values(), ...privateInfMap.values()];
    },
  },
  created() {
    // パラメータから lotSid と invNumSid のリストを取得する
    this.transportInfoList = this.$route?.params?.transportInfoList ?? [];
    // 遷移元の検索条件を保存する
    this.searchParam = this.$route?.params?.searchParam;
    // ローディングON
    this.loadingCounter = 1;
    this.init().finally(() => {
      // ローディングOFF
      this.loadingCounter = 0;
    });
  },
  methods: {
    async init() {
      return Promise.all([
        getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.VEHICLE_SHAPE_MAJOR_DIV }),
        getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.TRANSPORT_COMP_DIV }),
        this.fetchTransportOrders(this.transportInfoList),
      ])
        .then(([shapeMajorDivCodeList, transportCompDivCodeList, transportOrders]) => {
          this.vehicleShapeMajorCodes = shapeMajorDivCodeList;
          this.transportCompDivList = transportCompDivCodeList.filter((x) => x.value !== "04"); // 路線(04)を除外
          this.packingList = transportOrders.packingList;
          this.transportOrderList = transportOrders.transportOrderList;
          this.lotInfo = transportOrders.lotInfo;
          return;
        })
        .then(() => {
          // 受注情報取得後、取得したロット情報を元にロット別ルートを取得する
          return this.fetchOfficeLotRoutes(this.lotInfo?.lotSid);
        })
        .then((officeLotRoutes) => {
          this.selectedTransportCompDiv = officeLotRoutes.transportCompDiv;
          this.routeLastUpdateDateTime = officeLotRoutes.routeLastUpdateDateTime;
          this.selectedVehicleShapeMajor = officeLotRoutes.vehicleShapeMajor;
          this.officeLotRouteList = officeLotRoutes.lotRouteList ?? [];
        })
        .catch((e) => {
          this.modifiedInfoDialogOption = {
            message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
            firstPageFlag: false,
            todAddFlg: true,
            todAddSearchParams: { searchParam: this.searchParam },
          };
          this.showInfoDialog = true;
        });
    },
    // 営業所別ルート取得
    async fetchOfficeLotRoutes(lotSid) {
      if (!lotSid) {
        // ロットSIDがない場合
        return {};
      }

      // GetAPIリクエスト用のConfigを生成
      const config = this.$httpClient.createGetApiRequestConfig();

      // パラメータ設定
      config.params.lotSid = lotSid;

      let response;
      try {
        response = await this.$httpClient.secureGet(appConfig.API_URL.BIZ_LOT_ROUTE, config);
      } catch (e) {
        throw new Error(messsageUtil.getMessage("P-999-999_999_E"));
      }

      const { resCom, resIdv } = response.data;

      if (resCom.resComCode !== appConfig.RESCOMCODE_SUCCESS) {
        throw new Error(resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E"));
      }

      // 初期表示での輸送手段と車両形状の「入力してください」メッセージを抑止する
      if (resIdv.transportCompDiv === null) {
        resIdv.transportCompDiv = undefined;
      }
      if (resIdv.vehicleShapeMajor === null) {
        resIdv.vehicleShapeMajor = undefined;
      }

      return resIdv;
    },
    async fetchTransportOrders(transportInfoList) {
      if (!this.transportInfoList?.length) {
        return [];
      }

      let response;
      try {
        const config = this.$httpClient.createGetApiRequestConfig();
        config.params.transportOrderList = transportInfoList;
        response = await this.$httpClient.secureGet(
          appConfig.API_URL.BIZ_LOT_ROUTE_TRANSPORT_ORDER_SEARCH,
          config
        );
      } catch (e) {
        throw new Error(messsageUtil.getMessage("P-999-999_999_E"));
      }

      // resIdv の中身 => { packingList, transportOrderList, lotList }
      const { resCom, resIdv } = response.data;
      if (resCom.resComCode !== appConfig.RESCOMCODE_SUCCESS) {
        throw new Error(resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E"));
      }

      return resIdv;
    },
    clickRegister() {
      this.validateMessage = "";
      this.$refs.routeForm.validate();
      if (this.checkFields(this.officeLotRouteList)) {
        // バリデーションエラーがある場合はエラーのダイアログを表示
        this.modifiedInfoDialogOption = {
          message: this.validateMessage,
        };
        this.showInfoDialog = true;
        return;
      }

      // 選択可能な拠点のSet
      const pointSet = this.points.reduce(
        (set, point) => set.add(`${point.div}_${point.value}`),
        new Set()
      );

      // ロット別ルートで選択されている拠点のSet
      const selectedPointSet = this.officeLotRouteList.reduce((set, item) => {
        if (item.fromPrivateInfSid) {
          set.add(`${PointDiv.PRIVATE_INF}_${item.fromPrivateInfSid}`);
        } else if (item.fromPointSid) {
          set.add(`${PointDiv.POINT}_${item.fromPointSid}`);
        }
        if (item.toPrivateInfSid) {
          set.add(`${PointDiv.PRIVATE_INF}_${item.toPrivateInfSid}`);
        } else if (item.toPointSid) {
          set.add(`${PointDiv.POINT}_${item.toPointSid}`);
        }
        return set;
      }, new Set());

      // 全ての拠点をルートに設定しているか
      if (!pointSet.isSubsetOf(selectedPointSet) || !selectedPointSet.isSubsetOf(pointSet)) {
        // 全ての拠点をルートに設定していない場合
        this.modifiedInfoDialogOption = {
          message: messsageUtil.getMessage("P-TOD-007_001_E"),
        };
        this.showInfoDialog = true;
        return;
      }

      // ルート確定確認ダイアログを表示
      this.modifiedConfirmDialogOption = {
        message: messsageUtil.getMessage("P-TOD-007_002_C"),
        title: appConfig.DIALOG.confirm,
        screenFlag: true,
        cancelBtnFlg: true,
        okAction: () => {
          // OKボタンが押されたらルート登録を実行
          this.loadingCounter = 1;
          this.registerRoutes()
            .catch((e) => {
              this.modifiedInfoDialogOption = {
                message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
              };
              this.showInfoDialog = true;
            })
            .finally(() => {
              this.loadingCounter = 0;
            });
        },
      };
      this.showConfirmDialog = true;
    },
    clickDelete() {
      const okAction = () => {
        this.loadingCounter = 1;
        this.deleteLotRoutes()
          .catch((e) => {
            this.modifiedInfoDialogOption = {
              message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
            };
            this.showInfoDialog = true;
          })
          .finally(() => {
            this.loadingCounter = 0;
          });
      };
      this.modifiedConfirmDialogOption = {
        message: messsageUtil.getMessage("P-TOD-007_001_C"),
        title: appConfig.DIALOG.confirm,
        screenFlag: true,
        cancelBtnFlg: true,
        okAction: okAction,
      };
      this.showConfirmDialog = true;
    },
    clickBack() {
      // 営業所別ロット組登録へ戻るボタンを押したとき、確認ダイアログを表示し、OKが押されたら戻る
      const okAction = () => {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_TOD_002,
          params: { searchParam: this.searchParam },
        });
      };
      this.modifiedConfirmDialogOption = {
        message: messsageUtil.getMessage("P-TOD-007_001_W"),
        title: appConfig.DIALOG.confirm,
        screenFlag: true,
        cancelBtnFlg: true,
        okAction: okAction,
      };
      this.showConfirmDialog = true;
      return;
    },

    // ロット別ルート登録実行
    async registerRoutes() {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_007; // 画面ID

      const packingList = this.packingList.map((p) => {
        return {
          packingSid: p.packingSid,
          packingLastUpdateDateTime: p.packingLastUpdateDateTime,
        };
      });

      const lotRouteList = this.officeLotRouteList.map((route, index) => {
        return {
          ...route,
          routeOrder: index + 1,
        };
      });

      body.reqIdv = {
        packingList,
        lotRouteList,
        lotSid: this.lotInfo?.lotSid,
        lotLastUpdateDateTime:
          this.lotInfo?.lotLastUpdateDateTime ?? body.reqCom.reqComExecTimestamp,
        routeLastUpdateDateTime: this.routeLastUpdateDateTime ?? body.reqCom.reqComExecTimestamp,
        transportCompDiv: this.selectedTransportCompDiv,
        vehicleShapeMajor: this.selectedVehicleShapeMajor,
      };

      let response;
      try {
        response = await this.$httpClient.securePost(
          appConfig.API_URL.BIZ_LOT_ROUTE,
          body,
          appConfig.APP_CONFIG
        );
      } catch (e) {
        throw new Error(messsageUtil.getMessage("P-999-999_999_E"));
      }

      const { resCom, resIdv } = response.data;
      if (resCom.resComCode !== appConfig.RESCOMCODE_SUCCESS) {
        throw new Error(resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E"));
      }

      this.modifiedInfoDialogOption = {
        firstPageFlag: true,
        todAddFlg: true, // 営業所別ロット組登録へ戻るボタン表示
        todAddSearchParams: { searchParam: this.searchParam }, // 営業所別ロット組登録の検索条件
        message: messsageUtil.getMessage("P-TOD-007_002_S"),
        okAction: () => {
          // 受注・ルート情報を再読み込みする
          this.transportInfoList = this.transportInfoList.map((transportInfo) => {
            return { ...transportInfo, lotSid: resIdv.lotSid };
          });
          this.loadingCounter = 1;
          this.init().finally(() => {
            this.loadingCounter = 0;
          });
        },
      };
      this.showInfoDialog = true;
    },
    async deleteLotRoutes() {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_007; // 画面ID
      body.reqIdv = {
        lotSid: this.lotInfo?.lotSid,
        routeLastUpdateDateTime: this.routeLastUpdateDateTime,
        packingList: this.packingList.map((p) => {
          return {
            packingSid: p.packingSid,
            packingLastUpdateDateTime: p.packingLastUpdateDateTime,
          };
        }),
      };

      let response;
      try {
        response = await this.$httpClient.securePost(
          appConfig.API_URL.BIZ_LOT_ROUTE_DELETE,
          body,
          appConfig.APP_CONFIG
        );
      } catch (e) {
        throw new Error(messsageUtil.getMessage("P-999-999_999_E"));
      }

      const { resCom } = response.data;
      if (resCom.resComCode !== appConfig.RESCOMCODE_SUCCESS) {
        throw new Error(resCom.resComMessage);
      }

      this.modifiedInfoDialogOption = {
        firstPageFlag: false,
        todAddFlg: true,
        todAddSearchParams: { searchParam: this.searchParam },
        outsideClickNotCloseFlg: true,
        message: messsageUtil.getMessage("P-TOD-007_001_S"),
      };
      this.showInfoDialog = true;
    },

    updateLotRoutes(newRoutes) {
      this.officeLotRouteList = newRoutes;
      this.$nextTick(() => this.$refs.routeForm.validate());
    },

    checkFields(officeLotRouteList) {
      // 入力必須チェック
      if (
        this.isEmpty(this.selectedTransportCompDiv) ||
        this.isEmpty(this.selectedVehicleShapeMajor)
      ) {
        this.validateMessage = this.$t("check.chk_is_required");
        return true;
      }
      if (
        officeLotRouteList.length === 0 ||
        this.isEmpty(this.isEmpty(officeLotRouteList[0].fromPointSid))
      ) {
        this.validateMessage = this.$t("check.chk_is_required");
        return true;
      }
      for (let route of officeLotRouteList) {
        if (
          this.isEmpty(route.toPointSid) ||
          this.isEmpty(route.departureScheduleDate) ||
          this.isEmpty(route.arrivalScheduleDate)
        ) {
          this.validateMessage = this.$t("check.chk_is_required");
          return true;
        }
      }

      // 日付項目、時間項目の相関チェック
      let preDeliveryScheduleDate = null;
      let prePickupDatetimeFrom = null;
      let preDeliveryDatetimeFrom = null;
      for (let route of officeLotRouteList) {
        if (
          // 出発日 と 到着日
          this.afterThan(route.departureScheduleDate, route.arrivalScheduleDate, true) ||
          // 出発日時from と 出発日時from
          this.afterThan(route.departureScheduleTimeFrom, route.departureScheduleTimeTo, false) ||
          // 出発日時from と 到着日時from
          this.afterThan(route.departureScheduleTimeFrom, route.arrivalScheduleTimeFrom, false) ||
          // 出発日時from と 到着日時to
          this.afterThan(route.departureScheduleTimeFrom, route.arrivalScheduleTimeTo, false) ||
          // 到着日時from と 到着日時to
          this.afterThan(route.arrivalScheduleTimeFrom, route.arrivalScheduleTimeTo, false)
        ) {
          this.validateMessage = this.$t("check.chk_inputPickupDeliveryDateTime");
          return true;
        }
        if (
          // 前行の到着日と現在行の出発日
          this.afterThan(preDeliveryScheduleDate, route.departureScheduleDate, true) ||
          // 前行の出発日時fromと現在行の出発日時from
          this.afterThan(prePickupDatetimeFrom, route.departureScheduleTimeFrom, false) ||
          // 前行の出発日時fromと現在行の到着日時from
          this.afterThan(prePickupDatetimeFrom, route.arrivalScheduleTimeFrom, false) ||
          // 前行の到着日時fromと現在行の出発日時from
          this.afterThan(preDeliveryDatetimeFrom, route.departureScheduleTimeFrom, false) ||
          // 前行の到着日時fromと現在行の到着日時from
          this.afterThan(preDeliveryDatetimeFrom, route.arrivalScheduleTimeFrom, false)
        ) {
          this.validateMessage = this.$t("check.chk_inputDeliveryPickupDateTime");
          return true;
        }

        // 前行の日時を記憶
        preDeliveryScheduleDate = route.arrivalScheduleDate;
        if (route.departureScheduleTimeFrom != null) {
          prePickupDatetimeFrom = route.departureScheduleTimeFrom;
        }
        if (route.arrivalScheduleTimeFrom != null) {
          preDeliveryDatetimeFrom = route.arrivalScheduleTimeFrom;
        }
      }
      return false;
    },

    // 日時比較チェック
    afterThan(datetime1, datetime2, allowEquals) {
      return (
        !this.isEmpty(datetime1) &&
        !this.isEmpty(datetime2) &&
        (allowEquals ? datetime1 > datetime2 : datetime1 >= datetime2)
      );
    },

    // 未設定チェック
    isEmpty(value) {
      return value === undefined || value === null || value === "";
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss"></style>
