<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_salesClaimList" />
      <!-- 売上請求一覧画面 -->
      <v-form ref="form">
        <!-- 検索項目 -->
        <v-container fluid>
          <!-- 1行目 -->
          <v-row class="search-row">
            <!-- 元請営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeOrg"
                :items="officeOrgList"
                :label="$t('label.lbl_officeOrg')"
                class="txt-single"
                :hint="getOfficeOrg"
                @change="(event) => changeOfficeOrg(event)"
                :rules="[rules.required]"
                :error-messages="alertMessageOfficeOrg"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="supplier"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="getSupplier"
                @change="(event) => changeSupplier(event)"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 業務No -->
            <div class="search-autocomplete first-search-item" style="width: 400px">
              <v-autocomplete
                dense
                v-model="businessNo"
                :items="businessNoList"
                :label="$t('label.lbl_bussinessNo')"
                class="txt-single"
                :hint="getBusinessNo"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 集計月 -->
            <div class="date-style" style="margin-left: 12px">
              <v-text-field
                outlined
                dense
                v-model="month"
                :label="$t('label.lbl_Month')"
                @change="changeMonth"
                :error-messages="alertMessageMonth"
                :rules="[rules.yyyymm, rules.required]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="monthMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="monthCal"
                  type="month"
                  @input="monthMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-lastMonth"
                class="day-ctrl-btn"
                @click="preMonthOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-nextMonth"
                class="day-ctrl-btn"
                @click="nextMonthOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 2段目 -->
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="other-btn"
                  @click="getSalesClaimList()"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <!--ボタンエリア-->
              <div class="btn-send-area" style="float: right">
                <!--送信ボタン-->
                <v-btn
                  v-bind:disabled="true"
                  color="primary"
                  id="btn-send"
                  class="other-btn"
                  @click="getSalesClaimList()"
                  >{{ $t("btn.btn_send") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            :hide-default-footer="true"
            :items-per-page="50"
            height="720px"
          >
            <!-- 受注日 -->
            <template v-slot:[`item.orderDate`]="{ item }">
              <div class="listNo-style">{{ item.orderDate }}</div>
            </template>
            <!-- 貨物受注No -->
            <template v-slot:[`item.freightOrderNo`]="{ item }">
              <div class="listNo-style">{{ item.freightOrderNo }}</div>
            </template>
            <!-- 取引先 -->
            <template v-slot:[`item.supplier`]="{ item }">
              <div class="listNo-style">{{ item.supplier }}</div>
            </template>
            <!-- 業務No -->
            <template v-slot:[`item.businessNo`]="{ item }">
              <div class="listNo-style">{{ item.businessNo }}</div>
              <div class="listNo-style">{{ item.businessName }}</div>
            </template>
            <!-- 出荷元 -->
            <template v-slot:[`item.pickupPoint`]="{ item }">
              <div class="listNo-style">{{ item.pickupPoint }}</div>
            </template>
            <!-- お届け先 -->
            <template v-slot:[`item.deliveryPoint`]="{ item }">
              <div class="listNo-style">{{ item.deliveryPoint }}</div>
            </template>
            <!-- 締め日 -->
            <template v-slot:[`item.cutOffDate`]="{ item }">
              <div class="listNo-style">{{ item.cutOffDate }}</div>
              <div class="listNo-style">{{ item.cutOffBaseDate }}</div>
            </template>
            <!-- 請求金額 -->
            <template v-slot:[`item.billingAmount`]="{ item }">
              <div class="align-right">{{ item.billingAmount }}</div>
            </template>
            <!-- 支払金額 -->
            <template v-slot:[`item.paymentAmount`]="{ item }">
              <div class="align-right">{{ item.paymentAmount }}</div>
            </template>
            <!-- 詳細ボタン -->
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
        <v-dialog v-model="alertDialog" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ message }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="alertCancel"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil.js";
import { appConfig } from "../../assets/scripts/js/AppConfig.js";
import { getParameter } from "../../assets/scripts/js/GetParameter.js";
//import { messsageUtil } from "../../assets/scripts/js/MesssageUtil.js";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
export default {
  name: appConfig.SCREEN_ID.P_CLA_004,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 元請営業所
    officeOrg: "",
    // 元請営業所リスト
    officeOrgList: [],
    // 取引先
    supplier: "",
    // 取引先リスト
    supplierList: [],
    // 取引先ヒント
    supplierHint: "",
    // 業務No
    businessNo: "",
    // 業務Noリスト
    businessNoList: [],
    // 集計月
    month: "",
    monthMenu: false,
    monthCal: "",
    inputList: [],
    // 戻る遷移時
    backflg: false,
    // 警告ダイアログ
    alertDialog: false,
    // 元請営業所アラートメッセージ
    alertMessageOfficeOrg: "",
    // 集計月アラートメッセージ
    alertMessageMonth: "",
    message: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
      okAction: () => {},
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーション
    rules: {
      // YYYY/MMチェック
      yyyymm: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            value.length == 7 &&
            new Date(value.split("/")[0] + "-" + value.split("/")[1]) != "Invalid Date"
          )
        ) {
          return i18n.tc("check.chk_billingCutOffMonth");
        }
        return true;
      },
      // 必須チェック
      required: (value) => !!value || i18n.tc("check.chk_input"),
    },
    // ヘッダ
    headerItems: [
      // 受注日
      {
        text: i18n.tc("label.lbl_orderDate"),
        value: "orderDate",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 貨物受注No
      {
        text: i18n.tc("label.lbl_freightOrdersNo"),
        value: "freightOrderNo",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplier",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 業務No
      {
        text: i18n.tc("label.lbl_bussinessNo"),
        value: "businessNo",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 出荷元
      {
        text: i18n.tc("label.lbl_pickupPoint"),
        value: "pickupPoint",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // お届け先
      {
        text: i18n.tc("label.lbl_deliveryPoint"),
        value: "deliveryPoint",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 締め日
      {
        text: i18n.tc("label.lbl_cutOffDate"),
        value: "cutOffDate",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 請求金額
      {
        text: i18n.tc("label.lbl_billingAmount"),
        value: "billingAmount",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 支払金額
      {
        text: i18n.tc("label.lbl_paymentAmount"),
        value: "paymentAmount",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  methods: {
    /**
     * 初期値
     */
    async init() {
      // 月度
      this.month = dateTimeHelper.convertJST();
      this.monthCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      // 元請営業所情報取得
      await this.getOfficeOrgInfo();
      // 戻る遷移時の場合
      if (this.$route.params.officeOrg) {
        this.officeOrg = this.$route.params.officeOrg;
      }
      // 取引先情報取得
      await this.getSupplierInfo(this.officeOrg.value);
      // 戻る遷移時の場合
      if (this.$route.params.supplier) {
        this.supplier = this.$route.params.supplier;
        const params = {};
        // 事業部・営業所コード
        params.officeCode = this.officeOrg.substring(this.officeOrg.length - 3);
        // 取引先コード
        const tmpSupplier = this.supplierList.find((v) => v.value == this.supplier);
        params.requesterCustomerCode = tmpSupplier.clientCd;
        // 業務マスタ取得処理
        await this.getBusinessInfo(params);
      }
      if (this.$route.params.businessNo) {
        this.businessNo = this.$route.params.businessNo;
      }
      if (this.$route.params.month) {
        this.month = this.$route.params.month;
      }
      // 戻る遷移時の場合検索
      if (this.$route.params.officeOrg) {
        this.getSalesClaimList();
      }
    },

    /**
     * 元請営業所情報取得
     */
    async getOfficeOrgInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const officeList = getParameter.getJurisdictionOffice();
      await Promise.all([officeList])
        .then((result) => {
          this.officeOrg = "";
          // 画面の初期値を設定します。
          this.officeOrgList = result[0];
          const tmpOfficeOrg = this.officeOrgList.find(
            (v) => v.value == sessionStorage.getItem("sales_office_sid")
          );
          if (tmpOfficeOrg) {
            this.officeOrg = tmpOfficeOrg.value;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 元請営業所変更
     */
    changeOfficeOrg(val) {
      // 取引先リストを空にする。
      this.businessNoList = [];
      this.businessNo = "";
      // 業務Noリストを空にする。
      this.businessNoList = [];
      this.businessNo = "";
      if (this.isNullOrBlank(val)) {
        // 取引先リストを取得する。
        this.getSupplierInfo(this.officeOrg);
      }
    },

    /**
     * 取引先情報取得
     */
    async getSupplierInfo(params) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      let supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertJST(),
        false,
        null,
        params
      );
      await Promise.all([supplierList])
        .then((result) => {
          this.supplier = "";
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 取引先変更
     */
    changeSupplier(val) {
      const params = {};
      if (this.isNullOrBlank(val)) {
        // 事業部・営業所コード
        params.officeCode = this.officeOrg.substring(this.officeOrg.length - 3);
        // 取引先コード
        const tmpSupplier = this.supplierList.find((v) => v.value == val);
        params.requesterCustomerCode = tmpSupplier.clientCd;
        this.getBusinessInfo(params);
      }
    },

    /**
     * 業務マスタ取得処理
     */
    async getBusinessInfo(params) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 業務マスタ取得
      const businessNoList = getParameter.getOperationMst(params);

      await Promise.all([businessNoList])
        .then((result) => {
          this.jurisdictionOffice = "";
          // 画面の初期値を設定します。
          this.businessNoList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 売上請求一覧取得： 検索ボタン押下処理
     */
    getSalesClaimList() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON;
        this.loadingCounter = 1;

        this.backflg = false;
        const config = this.$httpClient.createGetApiRequestConfig();
        // 元請営業所
        config.params.officeSidOrg = this.officeOrg;
        // 取引先
        if (this.supplier) {
          const tmpSupplier = this.supplierList.find((v) => v.value == this.supplier);
          config.params.clientSid = tmpSupplier.clientCd;
        }
        // 業務No
        if (this.businessNo) {
          config.params.businessNo = this.businessNo;
        }
        // 月度
        config.params.month = this.month;
        // console.debug("executeCostMstDetailApi() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_SALES_CLAIM_SEARCH, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                jsonData.resIdv.salesClaimList.forEach((row) => {
                  list.push({
                    orderDate:
                      row.orderDate == ""
                        ? ""
                        : dateTimeHelper
                            .convertUTC2JST(row.orderDate)
                            .replaceAll("-", "/")
                            .substring(0, 10),
                    freightOrderNo: row.freightOrderNo,
                    shipperCd: row.shipperCd,
                    supplier: row.shipperName,
                    businessNo: row.businessNo,
                    businessName: row.businessName,
                    pickupPoint: row.pickupPointName,
                    deliveryPoint: row.deliveryPointName,
                    cutOffDate: !this.isNullOrBlank(row.billClosingDay)
                      ? ""
                      : row.billClosingDay + "日",
                    cutOffBaseDate: row.billingClosingDivName,
                    billingAmount: !this.isNullOrBlank(Math.trunc(row.totalBillAmount))
                      ? "¥0"
                      : "¥" + commonFunction.getDelimiter(Math.trunc(row.totalBillAmount), 9),
                    paymentAmount: !this.isNullOrBlank(Math.trunc(row.totalPayAmount))
                      ? "¥0"
                      : "¥" + commonFunction.getDelimiter(Math.trunc(row.totalPayAmount), 9),
                  });
                });
                this.inputList = list;
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    // 詳細画面へ
    jumpTo(item) {
      if (this.$refs.form.validate()) {
        // 元請営業所
        const tmpOfficeOrg = this.officeOrgList.find((v) => v.value == this.officeOrg);
        // 取引先
        const tmpSupplier = this.supplierList.find((v) => v.supplierCd == item.supplierCd);
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CLA_005,
          params: {
            officeOrgName: tmpOfficeOrg.text,
            officeOrg: tmpOfficeOrg.value,
            supplierName: tmpSupplier.text,
            supplier: tmpSupplier.value,
            businessName: `${item.businessNo}` + `（${item.businessName.trim()}）`,
            businessNo: item.businessNo,
            month: this.month,
            freightOrderNo: item.freightOrderNo,
            listofficeOrg: this.officeOrg,
            listSupplier: this.supplier,
            listBusinessNo: this.businessNo,
          },
        });
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /** 集計月を-１日 */
    preMonthOfAchieveTo() {
      if (this.monthCal == null) {
        return;
      }
      let date = new Date(this.monthCal);
      this.monthCal = dateTimeHelper.dateCalc(date.setMonth(date.getMonth() - 1));
    },

    /** 集計月を+１日 */
    nextMonthOfAchieveTo() {
      if (this.monthCal == null) {
        return;
      }
      let date = new Date(this.monthCal);
      this.monthCal = dateTimeHelper.dateCalc(date.setMonth(date.getMonth() + 1));
    },

    /** 集計月入力 */
    changeMonth(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.monthCal = dateTimeHelper.dateCalc(strDt);
        this.month = strDt;
      } else {
        this.monthCal = null;
      }
    },

    /** カレンダコンポーネントの日付はyyyy-MMのためyyyy/MMに変換する。*/
    formatMonth(date) {
      if (!date) return null;
      date = commonUtil.zen2han(date);
      // 月の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])$/;
      date = date.replaceAll("／", "/");
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month] = date.split("-");
        return `${year}/${month}`;
      }
    },

    /**
     * アラートメッセージクリア処理
     */
    clearAlertMessage() {
      // アラートメッセージを非表示にする。
      this.alertMessageMonth = "";
    },

    /**
     * 存在チェック
     */
    isNullOrBlank(val) {
      if ((val ?? false) && val != "") {
        return true;
      }
      return false;
    },
  },

  computed: {
    // 元請営業所ヒント取得
    getOfficeOrg() {
      let tmpOfficeOrg = this.officeOrgList.find((v) => v.value == this.officeOrg);
      if (tmpOfficeOrg) {
        return tmpOfficeOrg.name;
      }
      return "";
    },
    // 取引先ヒント取得
    getSupplier() {
      let tmpSupplier = this.supplierList.find((v) => v.value == this.supplier);
      if (tmpSupplier) {
        return tmpSupplier.name;
      }
      return "";
    },
    // 業務Noヒント取得
    getBusinessNo() {
      let tmpBusinessNo = this.businessNoList.find((v) => v.value == this.businessNo);
      if (tmpBusinessNo) {
        return tmpBusinessNo.name;
      }
      return "";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    month(val) {
      if (this.isNullOrBlank(val)) {
        // アラートメッセージを非表示にする。
        this.clearAlertMessage();
      }
    },
    monthCal: {
      handler(val) {
        this.month = this.formatMonth(val);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.v-data-table {
  white-space: pre-line;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-left-red {
  color: red;
}
</style>
