<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_cargoStatusPage" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 1行目 -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item" v-if="isClientLogin()">
              <v-autocomplete
                dense
                v-model="selectedClient"
                :items="clientList"
                :label="$t('label.lbl_supplier')"
                :hint="setClient()"
                :search-input.sync="clientInput"
                @change="clientChange"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 出荷元 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="selectedDepartureBase"
                :items="departureBaseList"
                :label="$t('label.lbl_shipFrom')"
                :hint="setDepartureBase()"
                :search-input.sync="departureBaseInput"
                persistent-hint
                class="txt-single"
                margin-bottom="0"
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- お届け先 -->
            <div class="search-autocomplete status-search-item" v-if="isClientLogin()">
              <v-autocomplete
                dense
                v-model="selectedDeliveryBase"
                :items="deliveryBaseList"
                :search-input.sync="deliveryBaseInput"
                :hint="setDeliveryBase()"
                :label="$t('label.lbl_addressee')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <div class="search-autocomplete status-search-item" v-if="!isClientLogin()">
              <v-autocomplete
                dense
                v-model="selectedDeliveryBase"
                :items="deliveryBaseList"
                :hint="setDeliveryBase()"
                :disabled="isNotShipper()"
                :label="$t('label.lbl_addressee')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- ステータス -->
            <div class="search-autocomplete status-search-item">
              <v-autocomplete
                dense
                v-model="selectedStatus"
                :items="statusList"
                :hint="setStatus()"
                :label="$t('label.lbl_status')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- お客様管理番号 -->
            <div class="inquiry-number-search-item">
              <v-text-field
                outlined
                dense
                v-model="individualNo"
                :label="$t('label.lbl_clientControlNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="individualNoMessage"
              ></v-text-field>
            </div>
          </v-row>
          <v-row><div class="blank-row2"></div></v-row>
          <!-- 3行目 -->
          <v-row class="search-row">
            <!-- 現状到着予定日(From)-->
            <div class="date-style first-search-item">
              <v-text-field
                outlined
                dense
                v-model="changeArrivalScheduleDateFrom"
                :label="$t('label.lbl_changeArrivalScheduleDateFrom')"
                :rules="[
                  rules.inputRequiredCreate(
                    changeArrivalScheduleDateFrom,
                    changeArrivalScheduleDateTo
                  ),
                  rules.yyyymmdd_create,
                ]"
                @change="
                  changeDate('changeArrivalScheduleDateFrom', 'changeArrivalScheduleDateFromCal')
                "
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="changeArrivalScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="changeArrivalScheduleDateFromCal"
                  @input="changeArrivalScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                text
                x-small
                @click="prevDate('changeArrivalScheduleDateFromCal')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                text
                x-small
                @click="nextDate('changeArrivalScheduleDateFromCal')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 現状到着予定日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="changeArrivalScheduleDateTo"
                :label="$t('label.lbl_changeArrivalScheduleDateTo')"
                :rules="[
                  rules.inputRequiredCreate(
                    changeArrivalScheduleDateTo,
                    changeArrivalScheduleDateFrom
                  ),
                  rules.yyyymmdd_create,
                ]"
                @change="
                  changeDate('changeArrivalScheduleDateTo', 'changeArrivalScheduleDateToCal')
                "
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="changeArrivalScheduleDateToMessage"
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="changeArrivalScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="changeArrivalScheduleDateToCal"
                  @input="changeArrivalScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                text
                x-small
                @click="prevDate('changeArrivalScheduleDateToCal')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                text
                x-small
                @click="nextDate('changeArrivalScheduleDateToCal')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;&nbsp;</span>
          </v-row>
          <v-row><div class="blank-row2"></div></v-row>
          <!-- 4行目 -->
          <v-row class="search-row">
            <!-- 出荷実績日(From)-->
            <div class="date-style first-search-item">
              <v-text-field
                outlined
                dense
                v-model="shippedDateFrom"
                :label="$t('label.lbl_shippedDateFrom')"
                :rules="[
                  rules.inputRequiredCreate(shippedDateFrom, shippedDateTo),
                  rules.yyyymmdd_create,
                ]"
                @change="changeDate('shippedDateFrom', 'shippedDateFromCal')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="shippedDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippedDateFromCal"
                  @input="shippedDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                text
                x-small
                @click="prevDate('shippedDateFromCal')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                text
                x-small
                @click="nextDate('shippedDateFromCal')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 出荷実績日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippedDateTo"
                :label="$t('label.lbl_shippedDateTo')"
                :rules="[
                  rules.inputRequiredCreate(shippedDateTo, shippedDateFrom),
                  rules.yyyymmdd_create,
                ]"
                @change="changeDate('shippedDateTo', 'shippedDateToCal')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="shippedDateToMessage"
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="shippedDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippedDateToCal"
                  @input="shippedDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                text
                x-small
                @click="prevDate('shippedDateToCal')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                text
                x-small
                @click="nextDate('shippedDateToCal')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;&nbsp;</span>
            <!-- 到着実績日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrivalDateFrom"
                :label="$t('label.lbl_arrivalDateFrom')"
                :rules="[
                  rules.inputRequiredCreate(arrivalDateFrom, arrivalDateTo),
                  rules.yyyymmdd_create,
                ]"
                @change="changeDate('arrivalDateFrom', 'arrivalDateFromCal')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="arrivalDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrivalDateFromCal"
                  @input="arrivalDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                text
                x-small
                @click="prevDate('arrivalDateFromCal')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                text
                x-small
                @click="nextDate('arrivalDateFromCal')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 到着実績日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrivalDateTo"
                :label="$t('label.lbl_arrivalDateTo')"
                :rules="[
                  rules.inputRequiredCreate(arrivalDateTo, arrivalDateFrom),
                  rules.yyyymmdd_create,
                ]"
                @change="changeDate('arrivalDateTo', 'arrivalDateToCal')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="arrivalDateToMessage"
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="arrivalDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="arrivalDateToCal"
                  @input="arrivalDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                text
                x-small
                @click="prevDate('arrivalDateToCal')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                text
                x-small
                @click="nextDate('arrivalDateToCal')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;&nbsp;&nbsp;</span>
          </v-row>

          <!--5行目-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="search()">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: right">
                <!-- CSV出力ボタン -->
                <v-btn color="primary" class="api-btn" @click="csvOutput()">{{
                  $t("btn.btn_outputcsv")
                }}</v-btn>
              </div>
            </div>
          </v-row>

          <!--検索結果画面-->
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ this.totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="pageNumReset(100)" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="pageNumReset(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="pageNumReset(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :ripple="false"
            :server-items-length="itemsPerPage"
            :hide-default-footer="true"
            :options.sync="sortOptions"
            disable-filtering
            height="510px"
          >
            <!-- ヘッダ項目 -->
            <!--お客様管理番号-->
            <template v-slot:[`header.autoNo`]="{ header }">
              <div>{{ header.text }}</div>
            </template>
            <!--お客様管理番号-->
            <template v-slot:[`header.individualNo`]="{ header }">
              <div class="table_th_bottom_line">&nbsp;</div>
              <span>{{ header.text }}</span>
            </template>
            <!--出荷元-->
            <template v-slot:[`header.departureBase`]="{ header }">
              <div class="table_th_bottom_line text-center">輸送依頼情報</div>
              <span style="padding-left: 6px">{{ header.text }}</span>
            </template>
            <!--お届け先-->
            <template v-slot:[`header.deliveryBase`]="{ header }">
              <div class="table_th_bottom_line">&nbsp;</div>
              <span style="padding-left: 6px; padding-right: 5px">{{ header.text }}</span>
            </template>

            <!--当初到着予定-->
            <template v-slot:[`header.arrivalScheduleDate`]="{ header }">
              <div class="table_th_bottom_line text-right">計</div>
              <span>{{ header.text }}</span>
            </template>
            <!--現状到着予定日-->
            <template v-slot:[`header.changeArrivalScheduledDate`]="{ header }">
              <div class="table_th_bottom_line text-left">画</div>
              <span>{{ header.text }}</span>
            </template>

            <!--出荷実績-->
            <template v-slot:[`header.shippedDate`]="{ header }">
              <div class="table_th_bottom_line text-right">実</div>
              <span style="padding-right: 5px">{{ header.text }}</span>
            </template>
            <!--到着実績-->
            <template v-slot:[`header.arrivalDate`]="{ header }">
              <div class="table_th_bottom_line text-left">績</div>
              <span style="padding-right: 5px">{{ header.text }}</span>
            </template>

            <!--計画状況-->
            <template v-slot:[`header.planStatusName`]="{ header }">
              <div class="table_th_bottom_line text-right">到着</div>
              <span>{{ header.text }}</span>
            </template>
            <!--到着状況-->
            <template v-slot:[`header.arrivalStatusName`]="{ header }">
              <div class="table_th_bottom_line text-left">状況</div>
              <span>{{ header.text }}</span>
            </template>

            <!--ルート詳細-->
            <template v-slot:[`header.cfm`]="{ header }">
              <div>ルート</div>
              <span>{{ header.text }}</span>
            </template>

            <!--出荷元-->
            <template v-slot:[`item.departureBase`]="{ item }">
              <div style="text-align: left">
                {{ item.departureBase }}
              </div>
            </template>
            <!--お届け先-->
            <template v-slot:[`item.deliveryBase`]="{ item }">
              <div style="text-align: left">
                {{ item.deliveryBase }}
              </div>
            </template>

            <!--当初到着予定-->
            <template v-slot:[`item.arrivalScheduleDate`]="{ item }">
              <div
                :class="getChangeClass(item.arrivalScheduleDate, item.changeArrivalScheduledDate)"
              >
                {{ item.arrivalScheduleDate }}
              </div>
            </template>
            <!--現状到着予定日-->
            <template v-slot:[`item.changeArrivalScheduledDate`]="{ item }">
              <div
                :class="getChangeClass(item.arrivalScheduleDate, item.changeArrivalScheduledDate)"
              >
                {{ item.changeArrivalScheduledDate }}
              </div>
            </template>
            <!-- 計画状況 -->
            <template v-slot:[`item.planStatusName`]="{ item }">
              <div :class="getStatusClass(item.planStatus)">
                {{ item.planStatusName }}
              </div>
            </template>
            <!-- 到着状況 -->
            <template v-slot:[`item.arrivalStatusName`]="{ item }">
              <div :class="getStatusClass(item.arrivalStatus)">
                {{ item.arrivalStatusName }}
              </div>
            </template>
            <!-- ルート詳細ボタン -->
            <template v-slot:[`item.cfm`]="{ item }">
              <v-btn
                v-if="
                  item.changeArrivalScheduledDate != null && item.changeArrivalScheduledDate != ''
                "
                small
                @click="openCargoStatusDetail(item)"
                text
              >
                <v-icon>mdi-truck</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
      />
    </v-sheet>
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";

import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import NavBar from "../../components/NavBar.vue";

export default {
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 出荷実績日(From)
    shippedDateFrom: "",
    shippedDateFromMenu: false,
    shippedDateFromCal: "",
    // 出荷実績日(To)
    shippedDateTo: "",
    shippedDateToMenu: false,
    shippedDateToCal: "",

    // 現状到着予定日(From)
    changeArrivalScheduleDateFrom: "",
    changeArrivalScheduleDateFromMenu: false,
    changeArrivalScheduleDateFromCal: "",
    // 現状到着予定日(To)
    changeArrivalScheduleDateTo: "",
    changeArrivalScheduleDateToMenu: false,
    changeArrivalScheduleDateToCal: "",
    // 到着実績日(From)
    arrivalDateFrom: "",
    arrivalDateFromMenu: false,
    arrivalDateFromCal: "",
    // 到着実績日(To)
    arrivalDateTo: "",
    arrivalDateToMenu: false,
    arrivalDateToCal: "",

    // 取引先
    clientList: [],
    selectedClient: "",
    selectedClientText: "",
    clientInput: "",
    // 出荷元
    departureBaseList: [],
    selectedDepartureBase: "",
    selectedDepartureBaseText: "",
    departureBaseInput: "",
    // お届け先
    deliveryBaseList: [],
    selectedDeliveryBase: "",
    selectedDeliveryBaseText: "",
    deliveryBaseInput: "",
    // 貨物状況ステータス
    statusList: [],
    selectedStatus: "",
    // お客様管理番号
    individualNo: "",
    // 検索後のパラメータ
    searchParam: {
      // 出荷実績日(From)
      shippedDateFrom: "",
      // 出荷実績日(To)
      shippedDateTo: "",

      // 現状到着予定日(From)
      changeArrivalScheduleDateFrom: "",
      // 現状到着予定日(To)
      changeArrivalScheduleDateTo: "",
      // 到着実績日(From)
      arrivalDateFrom: "",
      // 到着実績日(To)
      arrivalDateTo: "",

      // 取引先
      selectedClient: "",
      // 出荷元
      selectedDepartureBase: "",
      // お届け先
      selectedDeliveryBase: "",
      // 貨物状況ステータス
      selectedStatus: "",
      // お客様管理番号
      individualNo: "",
    },
    // 明細一覧
    headerItems: [
      {
        text: i18n.tc("label.lbl_No"),
        value: "autoNo",
        width: "70px",
        sortable: false,
        align: "center",
        rowspan: "2",
      },
      // お客様管理番号
      {
        text: i18n.tc("label.lbl_clientControlNumber"),
        value: "individualNo",
        sortable: false,
        width: "280px",
        align: "center",
      },
      // 出荷元
      {
        text: i18n.tc("label.lbl_shipFrom"),
        value: "departureBase",
        sortable: false,
        width: "220px",
        align: "left",
      },
      // お届け先
      {
        text: i18n.tc("label.lbl_addressee"),
        value: "deliveryBase",
        sortable: false,
        width: "340px",
        align: "left",
      },
      // 当初到着予定
      {
        text: i18n.tc("label.lbl_initArrivalSchDate"),
        value: "arrivalScheduleDate",
        sortable: false,
        width: "150px",
        align: "center",
      },
      // 現状到着予定日
      {
        text: i18n.tc("label.lbl_changeArrivalSchDate"),
        value: "changeArrivalScheduledDate",
        sortable: false,
        width: "150px",
        align: "center",
      },
      // 出荷実績
      {
        text: i18n.tc("label.lbl_departureActDate"),
        value: "shippedDate",
        sortable: false,
        width: "150px",
        align: "center",
      },
      // 到着実績
      {
        text: i18n.tc("label.lbl_arrivalActDate"),
        value: "arrivalDate",
        sortable: false,
        width: "150px",
        align: "center",
      },
      // 計画状況
      {
        text: i18n.tc("label.lbl_planStatusName"),
        value: "planStatusName",
        sortable: false,
        width: "125px",
        align: "center",
      },
      // 到着状況
      {
        text: i18n.tc("label.lbl_arrivalStatusName"),
        value: "arrivalStatusName",
        sortable: false,
        width: "125px",
        align: "center",
      },
      // ルート詳細
      {
        text: i18n.tc("label.lbl_routeDetail"),
        value: "cfm",
        width: "100px",
        align: "center",
        sortable: false,
      },
    ],
    inputList: [],
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    totalCount: 0,
    // ソート
    sortItem: "",
    sortOptions: {},
    // 出荷実績日(To)メッセージ
    shippedDateToMessage: "",
    // 現状到着予定日(To)メッセージ
    changeArrivalScheduleDateToMessage: "",
    // 到着実績日(To)メッセージ
    arrivalDateToMessage: "",
    // お客様管理番号メッセージ
    individualNoMessage: "",
    // エラーメッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // 確認メッセージダイアログ
    confirmDialog: {
      isOpen: false,
      message: "",
      okAction: () => {},
    },
    defaultData: {},
  }),
  created() {},
  methods: {
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      // 現状到着予定日(From)
      this.changeArrivalScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
      this.changeArrivalScheduleDateFromCal = dateTimeHelper.dateCalc(
        this.changeArrivalScheduleDateFrom
      );
      this.changeArrivalScheduleDateFromMenu = false;
      // 現状到着予定日(To)
      this.changeArrivalScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
      this.changeArrivalScheduleDateToCal = dateTimeHelper.dateCalc(
        this.changeArrivalScheduleDateTo
      );
      this.changeArrivalScheduleDateToMenu = false;

      // その他画面から戻る場合、検索条件の復元
      if (this.$route.params.searchParam) {
        this.searchParam = this.$route.params.searchParam;
        // 取引先リスト
        this.clientList = this.searchParam.clientList;
        // 出荷元リスト
        this.departureBaseList = this.searchParam.departureBaseList;
        // お届け先リスト
        this.deliveryBaseList = this.searchParam.deliveryBaseList;
        // ステータスリスト
        this.statusList = this.searchParam.statusList;

        // 出荷実績日(From)
        this.shippedDateFrom = this.searchParam.shippedDateFrom;
        this.shippedDateFromCal = this.dateCalc(this.searchParam.shippedDateFrom);
        // 出荷実績日(To)
        this.shippedDateTo = this.searchParam.shippedDateTo;
        this.shippedDateToCal = this.dateCalc(this.searchParam.shippedDateTo);

        // 現状到着予定日(From)
        this.changeArrivalScheduleDateFrom = this.searchParam.changeArrivalScheduleDateFrom;
        this.changeArrivalScheduleDateFromCal = this.dateCalc(
          this.searchParam.changeArrivalScheduleDateFrom
        );
        // 現状到着予定日(To)
        this.changeArrivalScheduleDateTo = this.searchParam.changeArrivalScheduleDateTo;
        this.changeArrivalScheduleDateToCal = this.dateCalc(
          this.searchParam.changeArrivalScheduleDateTo
        );
        // 到着実績日(From)
        this.arrivalDateFrom = this.searchParam.arrivalDateFrom;
        this.arrivalDateFromCal = this.dateCalc(this.searchParam.arrivalDateFrom);
        // 到着実績日(To)
        this.arrivalDateTo = this.searchParam.arrivalDateTo;
        this.arrivalDateToCal = this.dateCalc(this.searchParam.arrivalDateTo);

        // 取引先
        this.selectedClient = this.searchParam.selectedClient;
        // 出荷元
        this.selectedDepartureBase = this.searchParam.selectedDepartureBase;
        // お届け先
        this.selectedDeliveryBase = this.searchParam.selectedDeliveryBase;
        // 貨物状況ステータス
        this.selectedStatus = this.searchParam.selectedStatus;
        // お客様管理番号
        this.individualNo = this.searchParam.individualNo;

        this.page = this.searchParam.page;
        this.itemsPerPage = this.searchParam.itemsPerPage;
        this.itemsPerPageBtn = this.searchParam.itemsPerPage.toString();
        this.getApiList();
      } else {
        // お届け先リストの取得
        this.getDeliveryBaseList();
        // 貨物状況ステータスの取得
        this.getStatusList();
      }
      this.$refs.form.validate();
    },
    pageNumReset(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.search();
    },

    /**
     * 検索ボタンを押下
     */
    search() {
      // 単項目チェック
      let inputCheckResult = this.$refs.form.validate();
      if (!inputCheckResult) {
        return;
      } else {
        this.shippedDateToMessage = "";
        this.changeArrivalScheduleDateToMessage = "";
        this.arrivalDateToMessage = "";
        this.individualNoMessage = "";
        let isError = false;

        // 到出荷実績日(From > To)
        if (
          this.shippedDateTo &&
          new Date(this.shippedDateFrom).getTime() > new Date(this.shippedDateTo).getTime()
        ) {
          this.shippedDateToMessage = i18n.tc("check.chk_inputFromVali");
          isError = true;
        }
        // 現状到着予定日(From > To)
        if (
          this.changeArrivalScheduleDateTo &&
          new Date(this.changeArrivalScheduleDateFrom).getTime() >
            new Date(this.changeArrivalScheduleDateTo).getTime()
        ) {
          this.changeArrivalScheduleDateToMessage = i18n.tc("check.chk_inputFromVali");
          isError = true;
        }
        // 到着実績日(From > To)
        if (
          this.arrivalDateTo &&
          new Date(this.arrivalDateFrom).getTime() > new Date(this.arrivalDateTo).getTime()
        ) {
          this.arrivalDateToMessage = i18n.tc("check.chk_inputFromVali");
          isError = true;
        }

        // お客様管理番号(桁数を超えている)
        var individualNoLen = 100;
        if (this.individualNo && this.individualNo.length > individualNoLen) {
          this.individualNoMessage = i18n.tc("check.chk_isStrLen", 0, [individualNoLen]);
          isError = true;
        }

        if (isError) {
          return;
        }
      }

      // 出荷実績日(From)
      this.searchParam.shippedDateFrom = this.shippedDateFrom;
      // 出荷実績日(To)
      this.searchParam.shippedDateTo = this.shippedDateTo;

      // 現状到着予定日(From)
      this.searchParam.changeArrivalScheduleDateFrom = this.changeArrivalScheduleDateFrom;
      // 現状到着予定日(To)
      this.searchParam.changeArrivalScheduleDateTo = this.changeArrivalScheduleDateTo;
      // 到着実績日(From)
      this.searchParam.arrivalDateFrom = this.arrivalDateFrom;
      // 到着実績日(To)
      this.searchParam.arrivalDateTo = this.arrivalDateTo;

      // 取引先
      this.searchParam.selectedClient = this.selectedClient;
      // 出荷元
      this.searchParam.selectedDepartureBase = this.selectedDepartureBase;
      // お届け先
      this.searchParam.selectedDeliveryBase = this.selectedDeliveryBase;
      // 貨物状況ステータス
      this.searchParam.selectedStatus = this.selectedStatus;
      // お客様管理番号
      this.searchParam.individualNo = this.individualNo;

      this.page = 1;
      this.getApiList();
    },

    /**
     * 明細データ取得を実行
     */
    getApiList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 取引先CD
      if (this.searchParam.selectedClient) {
        config.params.shipperClientCd = this.searchParam.selectedClient;
      }
      // 出荷元
      if (this.searchParam.selectedDepartureBase) {
        config.params.departureBaseSid = this.searchParam.selectedDepartureBase;
      }
      // お届け先
      if (this.searchParam.selectedDeliveryBase) {
        config.params.deliveryBaseSid = this.searchParam.selectedDeliveryBase;
      }
      // 貨物状況ステータス
      if (this.searchParam.selectedStatus) {
        config.params.deliveryStatus = this.searchParam.selectedStatus;
      }
      // お客様管理番号
      if (this.searchParam.individualNo) {
        config.params.individualNo = this.searchParam.individualNo;
      }

      // 出荷実績日(From)
      if (this.searchParam.shippedDateFrom) {
        config.params.shippedDateFrom = dateTimeHelper.convertUTC(this.searchParam.shippedDateFrom);
      }
      // 出荷実績日(To)
      if (this.searchParam.shippedDateTo) {
        config.params.shippedDateTo = dateTimeHelper.convertUTC(
          this.searchParam.shippedDateTo + " 23:59:59.999"
        );
      }

      // 現状到着予定日(From)
      if (this.searchParam.changeArrivalScheduleDateFrom) {
        config.params.changeArrivalScheduleDateFrom = dateTimeHelper.convertUTC(
          this.searchParam.changeArrivalScheduleDateFrom
        );
      }
      // 現状到着予定日(To)
      if (this.searchParam.changeArrivalScheduleDateTo) {
        config.params.changeArrivalScheduleDateTo = dateTimeHelper.convertUTC(
          this.searchParam.changeArrivalScheduleDateTo + " 23:59:59.999"
        );
      }
      // 到着実績日(From)
      if (this.searchParam.arrivalDateFrom) {
        config.params.arrivalDateFrom = dateTimeHelper.convertUTC(this.searchParam.arrivalDateFrom);
      }
      // 到着実績日(To)
      if (this.searchParam.arrivalDateTo) {
        config.params.arrivalDateTo = dateTimeHelper.convertUTC(
          this.searchParam.arrivalDateTo + " 23:59:59.999"
        );
      }

      // ページャー処理
      config.params.reqComPageIndex = this.page;
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;

      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }

      this.searchParam.page = this.page;
      this.searchParam.itemsPerPage = this.itemsPerPage;
      this.searchParam.sortItem = this.sortItem;

      this.inputList = [];
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_CARGO_STATUS_CONFIRMATION_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              this.pageCount = jsonData.resCom.resComPagination.totalPage;

              jsonData.resIdv.deliveryList.forEach((item) => {
                if (item.planStatus == "02") {
                  item.planStatusName = "遅延";
                }
                if (item.arrivalStatus == "04") {
                  item.arrivalStatusName = "遅延";
                }
              });
              this.inputList = jsonData.resIdv.deliveryList;
            } else {
              // エラー時
              this.pageCount = 0;
              this.totalCount = 0;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
            resolve(response);
          })
          .catch((ex) => {
            this.pageCount = 0;
            this.totalCount = 0;
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリアの実施
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);

      this.$route.params.searchParam = null;

      this.init();

      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * 貨物状況CSV出力
     */
    csvOutput() {
      this.confirmDialog.message = messsageUtil.getMessage("P-TRN-003_001_C");
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.okAction = this.csvOutputOkClick;
    },
    csvOutputOkClick() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      let csvInputInfo = {};
      // 取引先CD
      if (this.selectedClient) {
        csvInputInfo.shipperClientCd = this.selectedClient;
      }

      // 出荷元
      if (this.selectedDepartureBase) {
        csvInputInfo.departureBaseSid = this.selectedDepartureBase;
      }

      // お届け先
      if (this.selectedDeliveryBase) {
        csvInputInfo.deliveryBaseSid = this.selectedDeliveryBase;
      }

      // 貨物状況ステータス
      if (this.selectedStatus) {
        csvInputInfo.deliveryStatus = this.selectedStatus;
      }

      // お客様管理番号
      if (this.individualNo) {
        csvInputInfo.individualNo = this.individualNo;
      }

      // 出荷実績日(From)
      if (this.shippedDateFrom) {
        csvInputInfo.shippedDateFrom = dateTimeHelper.convertUTC(this.shippedDateFrom);
      }
      // 出荷実績日(To)
      if (this.shippedDateTo) {
        csvInputInfo.shippedDateTo = dateTimeHelper.convertUTC(
          this.shippedDateTo + " 23:59:59.999"
        );
      }

      // 現状到着予定日(From)
      if (this.changeArrivalScheduleDateFrom) {
        csvInputInfo.changeArrivalScheduleDateFrom = dateTimeHelper.convertUTC(
          this.changeArrivalScheduleDateFrom
        );
      }
      // 現状到着予定日(To)
      if (this.changeArrivalScheduleDateTo) {
        csvInputInfo.changeArrivalScheduleDateTo = dateTimeHelper.convertUTC(
          this.changeArrivalScheduleDateTo + " 23:59:59.999"
        );
      }
      // 到着実績日(From)
      if (this.arrivalDateFrom) {
        csvInputInfo.arrivalDateFrom = dateTimeHelper.convertUTC(this.arrivalDateFrom);
      }
      // 到着実績日(To)
      if (this.arrivalDateTo) {
        csvInputInfo.arrivalDateTo = dateTimeHelper.convertUTC(
          this.arrivalDateTo + " 23:59:59.999"
        );
      }

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TRN_003;

      body.reqIdv = csvInputInfo;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(貨物状況実績登録)
          .securePost(appConfig.API_URL.BIZ_CARGO_STATUS_CSV, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.filePath);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.dataOutPutDialog = false;
          });
      });
    },

    /**
     * 貨物状況詳細画面に遷移する
     */
    openCargoStatusDetail(item) {
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].autoNo == item.autoNo) {
          // 選択行情報
          let selectedRowInfo = this.inputList[i];
          // 取引先リスト
          this.searchParam.clientList = this.clientList;
          // 出荷元リスト
          this.searchParam.departureBaseList = this.departureBaseList;
          // お届け先リスト
          this.searchParam.deliveryBaseList = this.deliveryBaseList;
          // ステータスリスト
          this.searchParam.statusList = this.statusList;
          this.$router.push({
            name: appConfig.SCREEN_ID.P_TRN_004,
            params: {
              // お客様管理番号
              individualNo: selectedRowInfo.individualNo,
              // 荷主取引先CD
              shipperClientCd: selectedRowInfo.shipperClientCd,
              // 現状到着予定日
              changeArrivalScheduledDate: selectedRowInfo.changeArrivalScheduledDate,
              // 戻る時の画面復元パラメータ
              searchParam: this.searchParam,
            },
          });
          return;
        }
      }
    },

    /**
     * 日付変更
     */
    changeDate(key1, key2) {
      if (this[key1] && dateTimeHelper.validDate(this[key1], "/")) {
        this[key2] = dateTimeHelper.dateCalc(this[key1]);
      } else {
        this[key2] = null;
      }
    },
    /**
     * 日付を－１日します。
     */
    prevDate(param) {
      this[param] = commonFunction.addDate(this[param], "");
    },
    /**
     * 日付を＋１日します。
     */
    nextDate(param) {
      this[param] = commonFunction.addDate(this[param], "add");
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 取引先リストボックスの選択
     */
    setClient() {
      for (var i = 0; i < this.clientList.length; i++) {
        if (this.clientList[i].value == this.selectedClient) {
          this.selectedClientText = this.clientList[i].text;
          return this.clientList[i].name;
        }
      }
    },
    /**
     * 取引先データ取得
     */
    getClientList(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const params = {};
      if (isNaN(val)) {
        // 取引先名称
        params.supplierName = val;
      } else {
        // 取引先コード（前方一致）
        params.supplierCode = val;
      }

      const clientList = getParameter.getTmsBizCustomerMst(params);
      Promise.all([clientList])
        .then((result) => {
          // リスト情報の設定
          this.clientList = result[0];
          this.selectedClientText = "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 出荷元リストボックスの選択
     */
    setDepartureBase() {
      for (var i = 0; i < this.departureBaseList.length; i++) {
        if (this.departureBaseList[i].value == this.selectedDepartureBase) {
          this.selectedDepartureBaseText = this.departureBaseList[i].text;
          return this.departureBaseList[i].name;
        }
      }
    },
    /**
     * 出荷元データ取得
     */
    getDepartureBaseList(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const params = {};
      params.baseSearchKeyword = val;

      // 有効期限区分（1=すべて取得）
      params.validDateKbn = "1";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      const clientList = getParameter.getTmsBizBaseMst(params);
      Promise.all([clientList])
        .then((result) => {
          // リスト情報の設定
          this.departureBaseList = result[0];
          this.selectedDepartureBaseText = "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * お届け先リストボックスの選択
     */
    setDeliveryBase() {
      for (var i = 0; i < this.deliveryBaseList.length; i++) {
        if (this.deliveryBaseList[i].value == this.selectedDeliveryBase) {
          this.selectedDeliveryBaseText = this.deliveryBaseList[i].text;
          return this.deliveryBaseList[i].name;
        }
      }
    },
    /**
     * ニッコンユーザの場合、お届け先データ取得
     */
    getDeliveryBaseListClientLogin(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const params = {};
      params.baseSearchKeyword = val;
      // 有効期限区分（1=すべて取得）
      params.validDateKbn = "1";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      const clientList = getParameter.getTmsBizBaseMst(params);
      Promise.all([clientList])
        .then((result) => {
          // リスト情報の設定
          let list = [{ value: "", text: "" }];
          this.deliveryBaseList = list.concat(result[0]);
          this.selectedDeliveryBaseText = "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * お届け先データ取得
     */
    getDeliveryBaseList() {
      if (this.isClientLogin()) {
        return;
      }

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.userSid = sessionStorage.getItem("userInfo.userSid");
      new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_SHIPPER_DEPARTMENT_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // お届け先リスト
              let deliveryList = [{ value: "", text: "" }];
              if (jsonData.resIdv.baseInfo.deliveryBaseList) {
                jsonData.resIdv.baseInfo.deliveryBaseList.forEach((row) => {
                  deliveryList.push({
                    text: `${row.baseCode}` + `（${row.baseName}）`,
                    value: row.baseSid,
                    name: row.baseName,
                    customerCode: row.customerCode,
                  });
                });
              }
              this.deliveryBaseList = deliveryList;
              // 荷主(一般ユーザ)の場合
              if (!this.isShipper()) {
                this.selectedDeliveryBase = deliveryList[deliveryList.length - 1].value;
              }

              // 出荷元リスト
              let departureList = [{ value: "", text: "" }];
              if (jsonData.resIdv.baseInfo.departureBaseList) {
                jsonData.resIdv.baseInfo.departureBaseList.forEach((row) => {
                  departureList.push({
                    text: `${row.baseCode}` + `（${row.baseName}）`,
                    value: row.baseSid,
                    name: row.baseName,
                    customerCode: row.customerCode,
                  });
                });
              }
              this.departureBaseList = departureList;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },

    /**
     * ニッコンユーザーの判定
     */
    isClientLogin() {
      if (
        // 「貨物状況詳細の営業担当」のロールがある場合
        sessionStorage.getItem(appConfig.BTN_AUTHORITY_ID.P_TRN_CARGO_STATUS_DETAIL_SALES) == "true"
      ) {
        return true;
      }
      return false;
    },

    /**
     * 荷主(管理者)の判定
     */
    isShipper() {
      // 「貨物状況一覧の荷主管理者」のロールがある場合
      if (sessionStorage.getItem(appConfig.BTN_AUTHORITY_ID.P_TRN_CARGO_STATUS_SHIPPER) == "true") {
        return true;
      }
      return false;
    },

    /**
     * 荷主(管理者以外)の判定
     */
    isNotShipper() {
      // 荷主(管理者以外)の場合
      if (!this.isClientLogin() && !this.isShipper()) {
        return true;
      }
      return false;
    },

    /**
     * 取引先の選択値を変更されている場合
     */
    clientChange(item) {
      if (item) {
        // お届け先リスト再設定
        this.getDeliveryBaseList();
      } else {
        this.deliveryBaseList = [];
        this.selectedDeliveryBase = "";
      }
    },

    /**
     * ステータスリストボックスの選択
     */
    setStatus() {
      for (var i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].value == this.selectedStatus) {
          return this.statusList[i].name;
        }
      }
    },
    /**
     * ステータスデータ取得
     */
    getStatusList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const statusListRequest = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.CARGI_STATUS,
      });

      Promise.all([statusListRequest])
        .then((result) => {
          // リスト情報の設定
          let list = [{ text: "", name: "", value: "" }];
          this.statusList = list.concat(result[0]);
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 日付の比較
     */
    getChangeClass(date1, date2) {
      if (date1 && date2 && date1 != date2) {
        return "status1";
      }
      return "";
    },

    /**
     * 状況の判定
     */
    getStatusClass(status) {
      if (status == "02" || status == "04") {
        return "status1";
      }
      return "";
    },

    /**
     * 日付の変換
     */
    dateCalc(val) {
      if (val == "" || val == null) {
        return null;
      }
      if (dateTimeHelper.validDate(val, "/")) {
        return dateTimeHelper.dateCalc(val);
      }
      return null;
    },
  },
  watch: {
    shippedDateFromCal: {
      handler(val) {
        this.shippedDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    shippedDateToCal: {
      handler(val) {
        this.shippedDateTo = this.formatDate(val);
      },
      deep: true,
    },
    changeArrivalScheduleDateFromCal: {
      handler(val) {
        this.changeArrivalScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    changeArrivalScheduleDateToCal: {
      handler(val) {
        this.changeArrivalScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    arrivalDateFromCal: {
      handler(val) {
        this.arrivalDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    arrivalDateToCal: {
      handler(val) {
        this.arrivalDateTo = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      if (this.inputList.length > 0) {
        this.page = newValue;
        this.getApiList();
      }
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          if (this.inputList.length == 0) {
            items.sortBy = [];
            items.sortDesc = [];
            return;
          }
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_TRN_003_SORT[sortBy].replaceAll("{0}", ascdesc);
          this.getApiList();
        }
      },
      deep: true,
    },

    // 取引先の入力
    clientInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (this.selectedClientText && val.indexOf(this.selectedClientText) == 0) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getClientList(val);
        }
      }
    },
    // 出荷元の入力
    departureBaseInput(val) {
      if (!this.isClientLogin()) {
        return;
      }
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (this.selectedDepartureBaseText && val.indexOf(this.selectedDepartureBaseText) == 0) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 出荷元取得
          this.getDepartureBaseList(val);
        }
      }
    },
    // お届け先の入力
    deliveryBaseInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (this.selectedDeliveryBaseText && val.indexOf(this.selectedDeliveryBaseText) == 0) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // お届け先取得
          this.getDeliveryBaseListClientLogin(val);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.status-search-item {
  margin-left: 30px;
  width: 16rem;
}

.inquiry-number-search-item {
  margin-left: 30px;
  width: 300px;
}

.client-item {
  width: 250px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.client {
  margin-left: 15px;
}

.blank-row1 {
  height: 25px;
}

.blank-row2 {
  height: 5px;
}

.status1 {
  margin: 0 auto;
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #f8cbad;
  border-radius: 35% / 100%;
}

.status2 {
  margin: 0 auto;
  width: 80%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #8faadc;
  border-radius: 35% / 100%;
}

//データテーブル
#listData {
  overflow-x: scroll;
  &.v-data-table--fixed-header ::v-deep {
    th {
      padding-left: 0px;
      padding-right: 0px;
      border-right: 0px lightgrey;
      border-right-style: solid;
      border-left: 2px lightgrey;
      border-left-style: solid;
      border-top: 2px lightgrey;
      border-top-style: solid;
      border-bottom: 2px lightgrey !important;
      border-bottom-style: solid !important;
    }
    th:nth-child(2) {
      border-right: 0px;
    }
    th:nth-child(3) {
      border-left: 0px;
      border-right: 0px;
    }
    th:nth-child(4) {
      border-left: 0px;
    }
    th:nth-child(5) {
      border-right: 0px;
    }
    th:nth-child(6) {
      border-left: 0px;
    }
    th:nth-child(7) {
      border-right: 0px;
    }
    th:nth-child(8) {
      border-left: 0px;
    }
    th:nth-child(9) {
      border-right: 0px;
    }
    th:nth-child(10) {
      border-left: 0px;
    }
    th:nth-child(11) {
      border-right: 2px;
    }
    td:nth-child(3) {
      padding-left: 0px;
      text-align: left;
    }
    td:nth-child(4) {
      padding-left: 0px;
      text-align: left;
    }
  }
}

.table_th_bottom_line {
  border-bottom: 2px lightgrey !important;
  border-bottom-style: solid !important;
}
</style>
