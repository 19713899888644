<template>
  <v-sheet color="white" height="100vh" class="overflow-hidden">
    <v-sheet class="overflow-hidden" height="100%">
      <NavBar tittle="label.lbl_trunkVehicleDispatch" />
      <Loading :loadingCounter="loadingCounter" />
      <v-sheet class="overflow-hidden" height="calc(100% - 48px)">
        <v-container fluid class="pa-1 grey lighten-3" style="height: 100%">
          <v-row no-gutters class="fill-height">
            <v-col cols="12" class="d-flex justify-space-between fill-height">
              <!-- 幹線パターン一覧 -->
              <v-sheet
                width="250"
                elevation="5"
                rounded="lg"
                height="100%"
                class="pa-1 flex-grow-0 flex-shrink-1"
              >
                <v-row dense class="fill-height">
                  <v-col cols="12" class="d-flex flex-column fill-height">
                    <v-row dense class="flex-grow-0 flex-shrink-1">
                      <v-col cols="12">
                        <DatePicker
                          :key="'DatePicker' + datePickerKey"
                          :value="operationDate"
                          @input="changeOperationDate"
                          :label="$t('label.lbl_operationDate')"
                          :required="true"
                          :clearable="false"
                        />
                        <!-- 運行日変更時のダイアログ -->
                        <ConfirmDialog
                          :isShow.sync="showOperationDateChangedDialog"
                          :screenFlag="true"
                          :okBtnTxt="$t('btn.btn_change')"
                          :message="operationDateChangedDialogMessage"
                          :okAction="operationDateChangedDialogOkAction"
                          :cancelAction="operationDateChangedDialogCancelAction"
                        ></ConfirmDialog>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-1 mb-2"></v-divider>
                    <v-row dense class="flex-grow-1" style="max-height: calc(100% - 140px)">
                      <v-col
                        cols="12"
                        class="d-flex flex-column flex-nowrap overflow-y-auto fill-height"
                      >
                        <TrunkPatternItem
                          v-for="vehicle in dispatchVehicles"
                          :key="vehicle.trunkPatternSid"
                          :trunkPattern="vehicle"
                          class="mb-1"
                          :trunkFlg="true"
                          @visible-bin="visibleBin"
                        ></TrunkPatternItem>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
              <!-- 配車エリア -->
              <v-sheet
                outlined
                height="100%"
                max-width="calc(100% - 250px - 330px)"
                rounded="md"
                color="transparent"
                class="mx-1 flex-grow-1 flex-shrink-0 d-flex flex-column"
              >
                <v-row
                  no-gutters
                  style="height: calc(100% - 50px); width: 100%"
                  class="flex-grow-1 flex-shrink-0 overflow-x-auto overflow-y-hidden"
                >
                  <v-col
                    cols="12"
                    style="height: 100%; width: 100%"
                    class="d-flex flex-nowrap pb-1"
                  >
                    <template v-for="(vehicle, vehicleIndex) in dispatchVehicles">
                      <TrunkBinItem
                        width="calc(100% / 4 - 16px)"
                        v-for="(binItem, binIndex) in vehicle.trunkDiagramDispatchBinList ?? []"
                        :key="'bi' + binItem.trunkDiagramName"
                        :officeAddress="officeAddress"
                        :operationDate="operationDate"
                        :transportCompDivs="[...transportCompDivList]"
                        :vehicleShapeMajors="[...vehicleShapeMajorList]"
                        :binItem="{
                          trunkPatternSid: vehicle.trunkPatternSid,
                          directionFlg: vehicle.directionFlg,
                          firstOfficeSid: vehicle.firstOfficeSid,
                          ...binItem,
                        }"
                        class="ma-1 flex-shrink-0"
                        @change-car1="changeCar1"
                        @change-car2="changeCar2"
                        @change-driver="changeDriver"
                        @update:freightList="
                          $set(
                            dispatchVehicles[vehicleIndex].trunkDiagramDispatchBinList[binIndex],
                            'dispatchedFreightDtl',
                            $event
                          )
                        "
                        @move:freight="moveLotFreight($event)"
                        @change:deliveryDateTime="changeDeliveryDateTime($event)"
                        v-show="binItem.visible"
                      >
                      </TrunkBinItem>
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="flex-shrink-1 flex-grow-0">
                  <v-col cols="12" class="text-right">
                    <v-btn :disabled="isBinChange" color="primary" elevation="2" @click="register">
                      仮組み登録
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
              <!-- 未配車貨物 -->
              <v-sheet
                width="320"
                elevation="5"
                rounded="lg"
                class="pa-1 flex-grow-0 flex-shrink-1 undispatch"
              >
                <v-row dense class="pt-4">
                  <v-col cols="12" class="text-caption filter-text">
                    <span class="font-weight-bold mr-1">並べ替え</span>
                    {{ sortText }}
                  </v-col>
                  <v-col cols="12" class="text-caption filter-text">
                    <span class="font-weight-bold mr-1">絞り込み</span>
                    {{ filterText }}
                  </v-col>
                </v-row>
                <v-row dense class="justify-center">
                  <SearchCondition
                    :openLabel="'検索条件を設定する'"
                    :dialogTitle="`検索条件の追加・変更`"
                    :filters="filters"
                    :sortItems="freightSortItems"
                    @change:condition="filterItems = $event"
                    @change:sort="sortItems = $event"
                    @click="filterUndispatchFreights"
                  >
                  </SearchCondition>
                </v-row>
                <v-row dense>
                  <v-col cols="12" class="text-center text-caption">
                    <span> 未配車貨物:</span> {{ undispatchFreightCount }} <span>件</span>
                  </v-col>
                </v-row>
                <v-divider class="mt-0 mb-1"></v-divider>
                <v-row dense style="height: calc(100% - 160px)">
                  <v-col
                    cols="12"
                    class="pa-1 fill-height d-flex flex-column flex-nowrap overflow-y-auto"
                  >
                    <SortableWrapper
                      :option="undispatchDragOptions"
                      :label="'貨物リスト'"
                      v-model="undispatchedList"
                    >
                      <template v-for="item in undispatchedList">
                        <template v-if="item.isLot">
                          <TrunkLotItem
                            :lotItem="item"
                            :transportCompDivs="transportCompDivList"
                            :vehicleShapeMajors="vehicleShapeMajorList"
                            :dispatched="false"
                            :key="item.key"
                            class="mb-1"
                            :class="showUndispatchItem(item) ? '' : 'd-none'"
                            @update:lotItem="updateLotItem(item.lotSid, $event)"
                            @move:freight="moveLotFreight($event)"
                          ></TrunkLotItem>
                        </template>
                        <template v-else>
                          <FreightItem
                            :key="item.key"
                            :freight="item"
                            :undispatchFreightFlg="true"
                            class="mb-1"
                            :class="showUndispatchItem(item) ? '' : 'd-none'"
                            @input="deliveryDateTime"
                          ></FreightItem>
                        </template>
                      </template>
                    </SortableWrapper>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="showConfirmDialog"
      :title="confirmDialogOption.title"
      :message="confirmDialogOption.message"
      :redMessage="confirmDialogOption.redMessage"
      :okAction="confirmDialogOption.okAction"
      :screenFlag="confirmDialogOption.screenFlag"
      :changeFlag="confirmDialogOption.changeFlag"
      :cancelAction="confirmDialogOption.cancelAction"
      :okBtnTxt="confirmDialogOption.okBtnTxt"
      :cancelBtnTxt="confirmDialogOption.cancelBtnTxt"
      :cancelBtnFlg="confirmDialogOption.cancelBtnFlg"
      :okBtnColor="confirmDialogOption.okBtnColor"
      :isAlert="confirmDialogOption.isAlert"
      :outsideClickNotCloseFlg="confirmDialogOption.outsideClickNotCloseFlg"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isShow"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="true"
      :closeFlag="false"
    />
  </v-sheet>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import DatePicker from "../../components/DatePicker.vue";
import NavBar from "../../components/NavBar.vue";
import TrunkPatternItem from "./TrunkPatternItem.vue";
import FreightItem from "./FreightItem.vue";
import TrunkLotItem from "./TrunkLotItem.vue";
import TrunkBinItem from "./TrunkBinItem.vue";
import SortableWrapper from "./SortableWrapper.vue";
import Loading from "../../components/loading.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonFunction } from "../../assets/scripts/js/CommonFunction.js";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import SimpleDialog from "../../components/SimpleDialog.vue";
import SearchCondition from "./SearchCondition.vue";
import DispatchMixin from "./DispatchMixin";
import { getParameter } from "../../assets/scripts/js/GetParameter";

const UndispatchFreightTabKey = Object.freeze({
  LOT: "LOT",
  PICKUP: "PICKUP",
});

/** 未配車貨物のタブの情報 */
const UndispatchFreightTabValue = Object.freeze({
  LOT: Object.freeze({
    key: UndispatchFreightTabKey.LOT,
    label: "label.lbl_lot",
    order: 1,
  }),
  PICKUP: Object.freeze({
    key: UndispatchFreightTabKey.PICKUP,
    label: "label.lbl_tab_item_pickup",
    order: 2,
  }),
});

export default {
  name: "TrunkVehicleDispatchRegister",
  components: {
    NavBar,
    DatePicker,
    TrunkPatternItem,
    FreightItem,
    TrunkBinItem,
    TrunkLotItem,
    SortableWrapper,
    Loading,
    SimpleDialog,
    ConfirmDialog,
    SearchCondition,
  },
  mixins: [DispatchMixin],
  mounted() {
    const from = this.operationDateUTC;
    const fromDate = dateTimeHelper.convertJstDateTime(from);
    fromDate.setDate(fromDate.getDate() + 1); // 初期表示時の予定日の絞り込みは運行日とその次の日までに設定
    const to = dateTimeHelper.convertJST(fromDate);
    this.filterItems = {
      "scheduleDate.from": from,
      "scheduleDate.to": to,
    };
    this.init().finally(() => {
      this.loadingCounter = 0;
    });
  },
  data() {
    return {
      loadingCounter: 1,
      operationDate:
        this.$route.query?.operationDate ?? dateTimeHelper.toStringNowDate().split(" ")[0],
      datePickerKey: 1,
      showOperationDateChangedDialog: false,
      operationDateChangedDialogMessage: messsageUtil.getMessage("P-DVP-001_005_W"),
      operationDateChangedDialogOkAction: () => {},
      operationDateChangedDialogCancelAction: () => {},
      vehicleSort: {
        vehicleSort1: null,
        vehicleSortOrder1: "ASC",
        vehicleSort2: null,
        vehicleSortOrder2: "ASC",
      },

      vehicleSortItems: [
        { text: "形状", value: "carShape" },
        { text: "車号", value: "carShago" },
        { text: "積載量", value: "carPayload" },
      ],

      freightSort: {
        vehicleSort1: null,
        vehicleSortOrder1: "ASC",
        vehicleSort2: null,
        vehicleSortOrder2: "ASC",
        vehicleSort3: null,
        vehicleSortOrder3: "ASC",
        vehicleSort4: null,
        vehicleSortOrder4: "ASC",
      },
      freightSortItems: [
        { text: "方面", value: "area" },
        { text: "集配区分", value: "pickupDeliveryDiv" },
        { text: "重量", value: "totalWeight" },
        { text: "予定日時", value: "scheduleFromDate" },
      ],
      officeAddress: "",
      cars: [],
      car2s: [],
      drivers: [],
      originalDispatchVehicles: [],
      dispatchVehicles: [],
      undispatchedFreights: [],
      totalUndispatchFreightCount: 0,
      originalUndispatchFreightCount: 0,
      newTransportSidPrefix: "new_",
      newTransportSidNumber: 0,
      showConfirmDialog: false,
      confirmDialogDefaultOption: {
        title: "確認",
        message: "",
        redMessage: "",
        okAction: () => {},
        screenFlag: false,
        changeFlag: false,
        cancelAction: () => {},
        okBtnTxt: "OK",
        cancelBtnTxt: "キャンセル",
        cancelBtnFlg: true,
        okBtnColor: "",
        isAlert: false,
        outsideClickNotCloseFlg: false,
      },
      modifiedConfirmDialogOption: {},
      infoDialog: {
        isShow: false,
        message: "",
        title: "",
      },
      filter: {
        required: false,
        label: "",
        type: "",
        multi: false,
        group: false,
        values: "",
        texts: "",
        groupValues: [],
      },
      groupValue: {
        label: "",
        values: [],
        texts: "",
      },
      filters: [],
      filterItems: [],
      sortItems: [],
      isBinChange: false,
      selectedUndispatchTabIndex: 0, // タブの初期値: ロット
      undispatchFreightTabs: Object.values(UndispatchFreightTabValue).sort(
        (value1, value2) => value1.order - value2.order
      ),
      pagingMax: 9999,
    };
  },
  computed: {
    confirmDialogOption() {
      return {
        ...this.confirmDialogDefaultOption,
        ...this.modifiedConfirmDialogOption,
      };
    },
    binItems: {
      get() {
        const bins = this.dispatchVehicles
          .filter(
            ({ trunkDiagramDispatchBinList }) => trunkDiagramDispatchBinList?.length > 0 ?? false
          )
          .flatMap((vehicle) => {
            return vehicle.trunkDiagramDispatchBinList.map((bin) => {
              return {
                carSid: vehicle.carSid,
                carSid2: vehicle.carSid2,
                carShape: vehicle.carShape,
                carShago: vehicle.carShago,
                carPayload: vehicle.carPayload,
                ...bin,
              };
            });
          });
        return bins;
      },
      set(value) {
        this.dispatchVehicles = value;
      },
    },
    operationDateUTC() {
      return dateTimeHelper.convertUTC(this.operationDate + " 00:00:00");
    },
    undispatchFreightCount() {
      const currentCount = this.undispatchedFreights?.length ?? 0;
      const currentStr = currentCount === 0 ? "0" : commonFunction.getDelimiter(currentCount);
      const total =
        this.undispatchFreightTotalCount - (this.originalUndispatchFreightCount - currentCount);
      const totalStr = total === 0 ? "0" : commonFunction.getDelimiter(total);
      return `${currentStr}/${totalStr}`;
    },
    undispatchedList: {
      get() {
        return this.toLotFreightList(this.undispatchedFreights);
      },
      set(newUndispatchedList) {
        this.undispatchedFreights = newUndispatchedList.flatMap((x) => x.freightList ?? x);
      },
    },
    sortText() {
      return this.sortItems
        .map(({ property, order }) => {
          const propName = this.freightSortItems.find((item) => item.value === property)?.text;
          const orderText =
            order === "asc"
              ? this.$t("label.lbl_ascending_order")
              : this.$t("label.lbl_descending_order");
          return `${propName}(${orderText})`;
        })
        .join(", ");
    },
    filterText() {
      const arr = [];
      for (const filter of this.filters) {
        if (filter.range === "true" || filter.range === "timeonly") {
          const from = this.filterItems[filter.name + ".from"];
          const to = this.filterItems[filter.name + ".to"];
          if (!from && !to) {
            continue;
          }
          arr.push(`${filter.label}:[${from ?? ""} ~ ${to ?? ""}]`);
          continue;
        }
        const value = this.filterItems[filter.name];
        if (!value || !value.length) {
          continue;
        }
        let values = Array.isArray(value) ? value : [value];
        if (["radio", "select", "autocomplete"].includes(filter.type)) {
          const optionValue = filter.values ?? filter.groupValues.flatMap((v) => v.values);
          values = values.map((v) => optionValue.find((ov) => ov.value === v).text);
        }
        const filterText = values.join(", ");
        arr.push(`${filter.label}:[${filterText}]`);
      }
      return arr.join(" ");
    },
  },
  watch: {
    vehicleSort: async function (newValue) {
      this.vehicleSort = newValue;

      this.loadingCounter = 1;
      await this.modifyVehicleDispatch();
      this.loadingCounter = 0;
    },
    freightSort: async function (newValue) {
      this.freightSort = newValue;

      this.loadingCounter = 1;
      await this.modifyVehicleDispatch();
      this.loadingCounter = 0;
    },
    dispatchVehicles: {
      handler: function () {
        const changeDispatchList = this.getRegisterDispatchVehicle();
        // 便に変更あれば活性、なければ非活性
        this.isBinChange = !changeDispatchList.length;
      },
      // 子要素の変更まで検知
      deep: true,
    },
  },
  methods: {
    async init() {
      try {
        const [
          [totalCount, filters, undispatchedFreights],
          address,
          transportCompDivs,
          vehicleShapeMajors,
        ] = await Promise.all([
          this.fetchUndispatchedFreightList(this.filterItems, this.sortItems),
          this.getOfficeAddress(),
          getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.TRANSPORT_COMP_DIV }),
          getParameter.getTmsCodeMst({ codeType: appConfig.CODETYPE.VEHICLE_SHAPE_MAJOR_DIV }),
        ]);
        this.undispatchFreightTotalCount = totalCount;
        this.originalUndispatchFreightCount = undispatchedFreights?.length ?? 0;
        this.filters = this.mergeFilters(this.filters, filters);
        this.undispatchedFreights = [...undispatchedFreights];
        this.officeAddress = address;
        this.transportCompDivList = transportCompDivs;
        this.vehicleShapeMajorList = vehicleShapeMajors;

        this.fetchDispatchVehicleSearch(
          this.operationDateUTC,
          this.vehicleSort,
          this.$route.query?.visibleBinList
        )
          .then((dispatchVehicles) => {
            this.dispatchVehicles = [...dispatchVehicles];
            // 更新時に配車情報の変更があったか判定できるように画面表示時点での配車情報を保持する
            this.originalDispatchVehicles = JSON.parse(JSON.stringify(dispatchVehicles));
          })
          .catch((e) => {
            this.dispatchVehicles = [];
            this.originalDispatchVehicles = [];
            // ここでthrowしてもcatchブロックまで飛ばせないため直接実行
            this.infoDialog = {
              isShow: true,
              title: appConfig.DIALOG.title,
              message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
            };
          });
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },
    async modifyVehicleDispatch() {
      try {
        const [[totalCount, filters, newUndispatchedFreights], newDispatchVehicles] =
          await Promise.all([
            this.fetchUndispatchedFreightList(this.filterItems, this.sortItems),
            this.fetchDispatchVehicleSearch(this.operationDateUTC, this.vehicleSort),
          ]);

        const [dispatchVehicles, undispatchedFreights] = this.mergeDispatchFreights(
          newDispatchVehicles,
          newUndispatchedFreights,
          this.dispatchVehicles,
          this.undispatchedFreights,
          this.originalDispatchVehicles
        );

        this.undispatchFreightTotalCount = totalCount;
        this.originalUndispatchFreightCount = undispatchedFreights?.length ?? 0;
        this.filters = this.mergeFilters(this.filters, filters);
        this.dispatchVehicles = dispatchVehicles;
        this.undispatchedFreights = undispatchedFreights;
        this.originalDispatchVehicles = newDispatchVehicles;
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },

    changeOperationDate(newValue) {
      if (this.operationDate === newValue) {
        return;
      }

      if (!dateTimeHelper.isDisplayDateFormat(newValue.replaceAll("-", "/"))) {
        // 運行日の入力欄に表示される日付を戻すため、keyを更新する
        this.datePickerKey += 1;
        return;
      }

      if (this.binChanged()) {
        // 運行日を変更すると、編集中の配車情報がなくなるため、確認のダイアログを表示する
        this.operationDateChangedDialogOkAction = () => {
          this.operationDate = newValue;
          this.loadingCounter = 1;
          // 一覧画面から遷移した際のパラメータを初期化
          if (this.$route.query && this.$route.query.visibleBinList) {
            var query = Object.assign({}, this.$route.query);
            delete query.visibleBinList;
            this.$router.push({ query: query });
          }
          this.init().finally(() => {
            this.loadingCounter = 0;
          });
        };
        this.operationDateChangedDialogCancelAction = () => {
          // 運行日の入力欄に表示される日付を戻すため、keyを更新する
          this.datePickerKey += 1;
        };
        this.showOperationDateChangedDialog = true;
      } else {
        this.operationDate = newValue;
        this.loadingCounter = 1;
        // 一覧画面から遷移した際のパラメータを初期化
        if (this.$route.query && this.$route.query.visibleBinList) {
          var query = Object.assign({}, this.$route.query);
          delete query.visibleBinList;
          this.$router.push({ query: query });
        }
        this.init().finally(() => {
          this.loadingCounter = 0;
        });
      }
    },

    showUndispatchItem(item) {
      const selectedTabKey = this.undispatchFreightTabs[this.selectedUndispatchTabIndex].key;
      if (item.isLot) {
        return selectedTabKey === UndispatchFreightTabKey.LOT;
      } else {
        return selectedTabKey === UndispatchFreightTabKey.PICKUP;
      }
    },
    binChanged() {
      const originalBinList = this.originalDispatchVehicles.flatMap(
        ({ trunkDiagramDispatchBinList }) => trunkDiagramDispatchBinList ?? []
      );
      const editedBinList = this.dispatchVehicles.flatMap(
        ({ trunkDiagramDispatchBinList }) => trunkDiagramDispatchBinList ?? []
      );

      // 便の数が違う場合
      if (originalBinList.length !== editedBinList.length) {
        return true;
      }

      for (let i = 0; i < originalBinList.length; i++) {
        const originalBin = originalBinList[i];
        const editBin = editedBinList[i];

        // 便の車両1が異なる場合
        if (originalBin.carSid !== editBin.carSid) {
          return true;
        }

        // 便の車両2が異なる場合
        if (originalBin.carSid2 !== editBin.carSid2) {
          return true;
        }

        // 便のドライバーが異なる場合
        if (originalBin.driverUserSid !== editBin.driverUserSid) {
          return true;
        }

        const originalFreights = originalBin.dispatchedFreightDtl ?? [];
        const editedFreights = editBin.dispatchedFreightDtl ?? [];

        /// 便に配車されている貨物の数が違う場合
        if (originalFreights.length !== editedFreights.length) {
          return true;
        }

        // 配車されている貨物の順番が違う場合
        for (let j = 0; j < originalFreights.length; j++) {
          const originalFreight = originalFreights[j];
          const editedFreight = editedFreights[j];
          if (originalFreight.packingSid !== editedFreight.packingSid) {
            return true;
          }
        }
      }
      return false;
    },

    // 貨物の納品日時を変更
    changeDeliveryDateTime({
      carSid,
      transportSid,
      packingSid,
      pickupDeliveryDiv,
      deliveryDate,
      deliveryTime,
    }) {
      const vehicleIndex = this.dispatchVehicles.findIndex((x) => x.carSid === carSid);
      if (vehicleIndex === 1) {
        return;
      }
      const vehicle = this.dispatchVehicles[vehicleIndex];
      const binIndex = vehicle.dispatchBinList.findIndex((x) => x.transportSid === transportSid);
      if (binIndex === -1) {
        return;
      }
      const bin = vehicle.dispatchBinList[binIndex];
      const packingIndex = bin.dispatchedFreightDtl.findIndex(
        (x) => x.packingSid === packingSid && x.pickupDeliveryDiv === pickupDeliveryDiv
      );
      if (packingIndex === -1) {
        return;
      }
      const newPacking = {
        ...bin.dispatchedFreightDtl[packingIndex],
        deliveryDate,
        deliveryTime,
      };

      this.$set(
        this.dispatchVehicles[vehicleIndex].dispatchBinList[binIndex].dispatchedFreightDtl,
        packingIndex,
        newPacking
      );
    },

    deliveryDateTime(deliveryDateTime) {
      const opeDate = new Date(this.operationDateUTC);

      if (deliveryDateTime && deliveryDateTime.length == 16) {
        let deliveryDateStr = deliveryDateTime.substr(0, 10);
        let deliveryDateUTC = dateTimeHelper.convertUTC(deliveryDateStr);
        // 運行日より過去日の場合、ダイアログを表示
        if (opeDate.getTime() > new Date(deliveryDateUTC).getTime()) {
          this.modifiedConfirmDialogOption = {
            message: messsageUtil.getMessage("P-DVP-001_003_W"),
            title: appConfig.DIALOG.confirm,
            screenFlag: true,
            cancelBtnFlg: false,
          };
          this.showConfirmDialog = true;
        }
      }
    },
    changeCar1(trunkPatternSid, transportSid, cars, carSid) {
      this.cars = cars;
      const car = this.cars.find(({ vehicleSid }) => vehicleSid === carSid);

      const trunkPatternIndex = this.dispatchVehicles.findIndex(
        (trunkPattern) => trunkPattern.trunkPatternSid === trunkPatternSid
      );
      const oldTrunkPattern = this.dispatchVehicles[trunkPatternIndex];
      const binIndex = oldTrunkPattern.trunkDiagramDispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );

      const oldBinItem = oldTrunkPattern.trunkDiagramDispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        carSid: car?.vehicleSid ?? "",
        carShago: car?.carShago ?? "",
      };

      const newTrunkPattern = {
        ...oldTrunkPattern,
        trunkDiagramDispatchBinList: oldTrunkPattern.trunkDiagramDispatchBinList.toSpliced(
          binIndex,
          1,
          newBinItem
        ),
      };

      this.dispatchVehicles.splice(trunkPatternIndex, 1, newTrunkPattern);
    },
    changeCar2(trunkPatternSid, transportSid, car2s, carSid) {
      this.car2s = car2s;
      const car = this.car2s.find(({ vehicleSid }) => vehicleSid === carSid);

      const trunkPatternIndex = this.dispatchVehicles.findIndex(
        (trunkPattern) => trunkPattern.trunkPatternSid === trunkPatternSid
      );
      const oldTrunkPattern = this.dispatchVehicles[trunkPatternIndex];
      const binIndex = oldTrunkPattern.trunkDiagramDispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );

      const oldBinItem = oldTrunkPattern.trunkDiagramDispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        carSid2: car?.vehicleSid ?? "",
        carShago: car?.carShago ?? "",
      };

      const newTrunkPattern = {
        ...oldTrunkPattern,
        trunkDiagramDispatchBinList: oldTrunkPattern.trunkDiagramDispatchBinList.toSpliced(
          binIndex,
          1,
          newBinItem
        ),
      };

      this.dispatchVehicles.splice(trunkPatternIndex, 1, newTrunkPattern);
    },
    changeDriver(trunkPatternSid, transportSid, drivers, driverSid) {
      this.drivers = drivers;
      const driver = this.drivers.find(({ driverUserSid }) => driverUserSid === driverSid);

      const trunkPatternIndex = this.dispatchVehicles.findIndex(
        (trunkPattern) => trunkPattern.trunkPatternSid === trunkPatternSid
      );
      const oldTrunkPattern = this.dispatchVehicles[trunkPatternIndex];
      const binIndex = oldTrunkPattern.trunkDiagramDispatchBinList.findIndex(
        (bin) => bin.transportSid === transportSid
      );

      const oldBinItem = oldTrunkPattern.trunkDiagramDispatchBinList[binIndex];
      const newBinItem = {
        ...oldBinItem,
        driverUserSid: driver?.driverUserSid ?? "",
        driverUserName: driver?.driverUserName ?? "",
      };

      const newTrunkPattern = {
        ...oldTrunkPattern,
        trunkDiagramDispatchBinList: oldTrunkPattern.trunkDiagramDispatchBinList.toSpliced(
          binIndex,
          1,
          newBinItem
        ),
      };

      this.dispatchVehicles.splice(trunkPatternIndex, 1, newTrunkPattern);
    },
    updateLotItem(lotSid, lotItem) {
      const lotIndex = this.undispatchedList.findIndex((x) => x.lotSid === lotSid);
      if (lotIndex === -1) {
        return;
      }

      this.undispatchedList = this.undispatchedList.toSpliced(lotIndex, 1, lotItem);
    },

    /**
     * ロット内から貨物が個別で便/未配車貨物エリアに移動されたとき、移動した貨物があったロット自体も配車/未配車エリアに移動させる
     * @param {Object} param 移動した貨物の情報
     * @param {Object} param.from 貨物の移動元の情報
     * @param {"transport"|"undispatch"} param.from.area 貨物があった場所
     * @param {String} param.from.transportSid 移動元が便の場合、その便の便SID
     * @param {Object} param.to 貨物の移動先の情報
     * @param {"transport"|"undispatch"} param.to.area 貨物が移動した場所
     * @param {String} param.to.transportSid 移動先が便の場合、その便の便SID
     * @param {String} param.lotSid 移動した貨物のロットSID
     * @param {Object[]} param.freightList ロットにある全貨物のリスト
     */
    moveLotFreight({ from, to, lotSid, freightList }) {
      // 便SIDから便がある車両の配列とその便の配列のインデックスを返す関数
      const findVehicleAndBinIndex = (transportSid) => {
        const vehicleLength = this.dispatchVehicles?.length ?? 0;
        for (let vehicleIndex = 0; vehicleIndex < vehicleLength; vehicleIndex++) {
          const vehicle = this.dispatchVehicles[vehicleIndex];
          const binIndex =
            vehicle.trunkDiagramDispatchBinList?.findIndex(
              (x) => x.transportSid === transportSid
            ) ?? -1;
          if (binIndex !== -1) {
            return { vehicleIndex, binIndex };
          }
        }
        return { vehicleIndex: -1, binIndex: -1 };
      };

      let removeHandler; // 移動元の便/未配車エリアから移動した貨物のロットを削除する関数
      let moveHandler; // 移動先の便/未配車エリアに移動した貨物のロットを追加する関数

      // 移動元
      if (from.area === this.dragArea.undistapch) {
        // 移動元が未配車貨物エリアの場合
        // 未配車エリアに移動した貨物と同じロットに組まれていた貨物があるか
        const hasFreight = this.undispatchedFreights?.some((x) => x.lotSid === lotSid) ?? false;
        if (!hasFreight) {
          // ない場合、ロットの全ての貨物が配車済みでありロットの貨物を未配車から便に移動する必要がないため終了
          return;
        }

        removeHandler = () => {
          // 未配車貨物から移動した貨物と同じロットに組まれていた貨物を削除する
          this.undispatchedFreights = this.undispatchedFreights.filter((x) => x.lotSid !== lotSid);
          return true;
        };
      } else {
        // 移動元が便の場合
        const { vehicleIndex, binIndex } = findVehicleAndBinIndex(from.transportSid);
        if (vehicleIndex === -1 || binIndex === -1) {
          return;
        }
        removeHandler = () => {
          // 便から移動された貨物があったロットの全貨物を便から削除する
          const bin = this.dispatchVehicles[vehicleIndex].trunkDiagramDispatchBinList[binIndex];
          this.$set(
            this.dispatchVehicles[vehicleIndex].trunkDiagramDispatchBinList[binIndex],
            "dispatchedFreightDtl",
            bin.dispatchedFreightDtl?.filter((x) => x.lotSid !== lotSid) ?? []
          );
          return true;
        };
      }

      // 移動先
      if (to.area === this.dragArea.undistapch) {
        // 移動先が未配車貨物エリアの場合
        // 移動先の未配車貨物エリアにロットの全貨物を移動するハンドラを設定
        moveHandler = () => {
          // 未配車貨物にロットの貨物を追加
          const index = this.undispatchedFreights.findIndex((x) => x.lotSid === lotSid);
          // 移動された貨物を未配車貨物から削除し、ロットの全貨物改めて追加する
          this.undispatchedFreights = this.undispatchedFreights
            .filter((x) => x.lotSid !== lotSid)
            .toSpliced(index, 0, ...freightList);
        };
      } else {
        // 移動先が便の場合
        // 貨物移動先の車両と便のインデックスを取得
        const { vehicleIndex, binIndex } = findVehicleAndBinIndex(to.transportSid);
        if (vehicleIndex === -1 || binIndex === -1) {
          return;
        }
        // 移動先の便にロットの全貨物を移動するハンドラを設定
        moveHandler = () => {
          const dispatchFreights =
            this.dispatchVehicles[vehicleIndex].trunkDiagramDispatchBinList[binIndex]
              .dispatchedFreightDtl ?? [];
          const freightIndex = dispatchFreights.findIndex((x) => x.lotSid === lotSid);
          if (freightIndex === -1) {
            return;
          }

          // 移動された貨物を便から削除し、ロットの全貨物を対象の便に移動する
          const dipstachFreights =
            dispatchFreights
              .filter((x) => x.lotSid !== lotSid)
              .toSpliced(freightIndex, 0, ...freightList) ?? [];
          this.$set(
            this.dispatchVehicles[vehicleIndex].trunkDiagramDispatchBinList[binIndex],
            "dispatchedFreightDtl",
            dipstachFreights
          );
        };
      }
      // 移動元からロットの貨物を削除し、成功したら移動先にロットの貨物を移動する
      if (removeHandler()) {
        moveHandler();
      }
    },

    visibleBin(trunkPatternSid, trunkDiagramSid) {
      // 幹線パターン順取得
      const trunkPatternIndex = this.dispatchVehicles.findIndex(
        (vehicle) => vehicle.trunkPatternSid === trunkPatternSid
      );

      // ダイヤ順取得
      const binIndex = this.dispatchVehicles[
        trunkPatternIndex
      ].trunkDiagramDispatchBinList.findIndex((bin) => bin.trunkDiagramSid === trunkDiagramSid);
      // 表示を反転させる
      const binData =
        this.dispatchVehicles[trunkPatternIndex].trunkDiagramDispatchBinList[binIndex];
      if (binData) {
        binData.visible = !binData.visible;
      }
    },

    register() {
      const dispatchVehicle = this.getRegisterDispatchVehicle();
      if (dispatchVehicle.some(({ driverUserSid }) => !driverUserSid)) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: messsageUtil.getMessage("P-DVP-001_002_E"),
        };
        return;
      }
      const dispatchData = dispatchVehicle.map(
        ({
          carSid,
          carSid2,
          driverUserSid,
          transportSid,
          trunkDiagramSid,
          transportUpdateDatetime,
          fromDate,
          toDate,
          remarks,
          dispatchedFreightDtl,
        }) => {
          return {
            carSid,
            carSid2: carSid2 == "" ? null : carSid2,
            instDate: this.operationDateUTC,
            driverUserSid,
            transportSid: transportSid.startsWith(this.newTransportSidPrefix) ? null : transportSid,
            trunkDiagramSid,
            transportUpdateDatetime: transportUpdateDatetime,
            fromDate: fromDate ? fromDate : "",
            toDate: toDate ? toDate : "",
            remarks,
            dispatchedFreightDtl:
              dispatchedFreightDtl?.map(
                (
                  {
                    officeSid,
                    pickupDeliveryDiv,
                    shipperClientCd,
                    packingSid,
                    pickupDeliveryPointSid,
                    pickupDeliveryPointCd,
                    packingUpdateDatetime,
                    deliveryDate,
                    deliveryTime,
                    pickupWayDiv,
                    packingSidOrg,
                  },
                  index
                ) => {
                  return {
                    autoNo2: (index + 1).toString(),
                    officeSid,
                    pickupDeliveryDiv,
                    shipperClientCd,
                    moduleSid: null, // TODO 幹線対応後
                    packingSid,
                    pickupDeliveryPointSid,
                    pickupDeliveryPointCd,
                    packingUpdateDatetime,
                    deliveryDate,
                    deliveryTime,
                    pickupWayDiv,
                    packingSidOrg,
                  };
                }
              ) ?? [],
          };
        }
      );
      // 便が日を跨ぐ際の便開始終了日時計算
      for (let i = 0; i < dispatchData.length; i++) {
        if (dispatchData[i].fromDate != "" && dispatchData[i].toDate != "") {
          // 便開始日時を日付型で取得
          const fromDateInDt = new Date(dispatchData[i].fromDate);
          // 便終了日時を日付型で取得
          const toDateInDt = new Date(dispatchData[i].toDate);
          if (fromDateInDt > toDateInDt) {
            // 開始日時が終了日時よりも後であった場合
            // 終了日時を1日進める
            toDateInDt.setDate(toDateInDt.getDate() + 1);
            dispatchData[i].toDate = dateTimeHelper.toStringDate(
              "YYYY/MM/DD hh:mm:ss.SSS",
              toDateInDt
            );
          }
        }
      }
      let dataSendFlag = false;
      let schedulePastFlag = false;
      const opeDate = new Date(this.operationDateUTC);
      for (const vi of dispatchVehicle) {
        if (vi.planOnFlg === "1") {
          dataSendFlag = true;
        }
        for (const freight of vi.dispatchedFreightDtl ?? []) {
          if (freight.scheduleDate) {
            if (opeDate.getTime() > new Date(freight.scheduleDate).getTime()) {
              schedulePastFlag = true;
            }
          }
        }
        if (dataSendFlag && schedulePastFlag) break;
      }

      // 配車登録完了時のダイアログを表示する関数
      const openCompletedDialog = () => {
        const closeAction = () => {
          this.loadingCounter = 1;
          this.init().finally(() => {
            this.loadingCounter = 0;
          });
        };
        this.modifiedConfirmDialogOption = {
          title: this.$t("label.lbl_completed"),
          message: messsageUtil.getMessage("P-DVP-001_002_C"),
          screenFlag: true,
          cancelBtnFlg: false,
          okAction: closeAction,
          cancelAction: closeAction,
        };
        this.showConfirmDialog = true;
      };

      // 配車登録のエラーダイアログを表示する関数
      const openErrorDialog = (e) => {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      };

      // 配車登録を実行する関数
      const registerAction = () => {
        this.loadingCounter = 1;
        this.registerDispatchVehicle(dispatchData)
          .then(() => {
            this.loadingCounter = 0;
            // 正常終了時、完了ダイアログを表示する
            openCompletedDialog();
          })
          .catch((e) => {
            this.loadingCounter = 0;
            openErrorDialog(e);
          });
      };

      // データ送信フラグが「確定（1）」の貨物がある場合、確認ダイアログを表示する関数
      const openDataSendedConfirmDialog = () => {
        this.modifiedConfirmDialogOption = {
          message: messsageUtil.getMessage("P-DVP-001_001_W"),
          screenFlag: true,
          okAction: registerAction,
        };
        this.showConfirmDialog = true;
      };

      if (schedulePastFlag) {
        // 予定日(期限)が運行日よりも過去日の場合、確認ダイアログを表示
        this.modifiedConfirmDialogOption = {
          message: messsageUtil.getMessage("P-DVP-001_004_W"),
          title: appConfig.DIALOG.confirm,
          screenFlag: true,
          cancelBtnFlg: true,
          okAction: dataSendFlag ? openDataSendedConfirmDialog : registerAction,
        };
        this.showConfirmDialog = true;
      } else if (dataSendFlag) {
        openDataSendedConfirmDialog();
      } else {
        registerAction();
      }
    },

    /**
     * 幹線未配車貨物取得APIテスト用
     * @param undispatchedFreightFilters
     * @param undispatchedFreightSorts
     */
    fetchUndispatchedFreightListTest(undispatchedFreightFilters, undispatchedFreightSorts) {
      const config = this.$httpClient.createGetApiRequestConfig();

      const filter = { lotMatchFlg: 1 };
      for (const [name, value] of Object.entries(undispatchedFreightFilters)) {
        if (!!value || !!value.length) {
          if (name.startsWith("scheduleDate") && value.match(/^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/g)) {
            filter[name] = dateTimeHelper.convertUTC(value + " 00:00:00");
          } else {
            filter[name] = value;
          }
        }
      }

      config.params.filter = { ...filter };
      config.params.sorts = [...undispatchedFreightSorts];
      const undispatchedFreightTotalCount = 10;
      const undispatchFreightFilter = [
        {
          required: true,
          label: "予定日",
          name: "scheduleDate",
          type: "date",
          multi: false,
          range: "true",
          unit: null,
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: ["2025/03/12 15:00:00.000", "2025/03/13 15:00:00.000"],
        },
        {
          required: false,
          label: "品名",
          name: "itemName",
          type: "autocomplete",
          multi: false,
          range: "false",
          unit: null,
          group: false,
          values: [],
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "荷姿名",
          name: "packingNacCds",
          type: "autocomplete",
          multi: true,
          range: "false",
          unit: null,
          group: false,
          values: [
            {
              value: "BA",
              text: "洋樽",
            },
            {
              value: "BE",
              text: "束",
            },
            {
              value: "BG",
              text: "袋",
            },
            {
              value: "BK",
              text: "篭",
            },
            {
              value: "BL",
              text: "俵梱（圧縮）",
            },
            {
              value: "BN",
              text: "俵梱（無圧縮）",
            },
            {
              value: "BR",
              text: "棒",
            },
            {
              value: "BX",
              text: "箱",
            },
            {
              value: "CA",
              text: "（四角の）缶",
            },
            {
              value: "CG",
              text: "篭",
            },
            {
              value: "CH",
              text: "箱",
            },
            {
              value: "CK",
              text: "洋樽",
            },
            {
              value: "CL",
              text: "線",
            },
            {
              value: "CN",
              text: "コンテナー",
            },
            {
              value: "CO",
              text: "大型ガラス瓶（無保護）",
            },
            {
              value: "CP",
              text: "篭（木箱）入りの大型ガラス瓶",
            },
            {
              value: "CR",
              text: "クレート、すかし箱",
            },
            {
              value: "CS",
              text: "箱",
            },
            {
              value: "CT",
              text: "段ボール",
            },
            {
              value: "CX",
              text: "缶",
            },
            {
              value: "CY",
              text: "鉄製円筒形容器等",
            },
            {
              value: "DJ",
              text: "瓶（無保護）",
            },
            {
              value: "DP",
              text: "篭で保護した瓶",
            },
            {
              value: "DR",
              text: "ドラム缶",
            },
            {
              value: "FB",
              text: "ファイバードラム",
            },
            {
              value: "FL",
              text: "フラスコ",
            },
            {
              value: "FR",
              text: "フレーム",
            },
            {
              value: "FT",
              text: "bag",
            },
            {
              value: "HD",
              text: "頭",
            },
            {
              value: "HG",
              text: "大樽",
            },
            {
              value: "IN",
              text: "インゴット（塊）",
            },
            {
              value: "JR",
              text: "ジャー、壷、瓶等",
            },
            {
              value: "JG",
              text: "ジョッキ",
            },
            {
              value: "KG",
              text: "小缶又は小樽",
            },
            {
              value: "LG",
              text: "丸太",
            },
            {
              value: "LZ",
              text: "丸太(束ねたもの）",
            },
            {
              value: "MT",
              text: "敷物又は包",
            },
            {
              value: "NE",
              text: "裸（包装をしていないもの）",
            },
            {
              value: "NT",
              text: "網（ネット）",
            },
            {
              value: "PA",
              text: "束、小包",
            },
            {
              value: "PC",
              text: "小包",
            },
            {
              value: "PE",
              text: "檻、囲い",
            },
            {
              value: "PG",
              text: "板",
            },
            {
              value: "PI",
              text: "大樽",
            },
            {
              value: "PK",
              text: "包",
            },
            {
              value: "PL",
              text: "手桶、バケツ",
            },
            {
              value: "PP",
              text: "パレット",
            },
            {
              value: "PS",
              text: "個、板、反",
            },
            {
              value: "PU",
              text: "仕切をした篭、箱",
            },
            {
              value: "PY",
              text: "板（束ねたもの）",
            },
            {
              value: "PZ",
              text: "厚板（束ねたもの）パイプ（束ねたもの）",
            },
            {
              value: "RL",
              text: "枠、巻",
            },
            {
              value: "RO",
              text: "巻",
            },
            {
              value: "SA",
              text: "布袋、鞘",
            },
            {
              value: "SI",
              text: "滑材、枕木",
            },
            {
              value: "SF",
              text: "組、セット",
            },
            {
              value: "SK",
              text: "組箱",
            },
            {
              value: "SB",
              text: "片",
            },
            {
              value: "SS",
              text: "スチールケース",
            },
            {
              value: "ST",
              text: "板、枚",
            },
            {
              value: "SV",
              text: "スチールエンビロープ",
            },
            {
              value: "SZ",
              text: "板、枚（束ねたもの）",
            },
            {
              value: "TB",
              text: "桶",
            },
            {
              value: "TI",
              text: "中樽",
            },
            {
              value: "TN",
              text: "小缶",
            },
            {
              value: "VA",
              text: "大桶、大樽",
            },
            {
              value: "VG",
              text: "バラ（ガス状）",
            },
            {
              value: "VL",
              text: "バラ（液状）",
            },
            {
              value: "VO",
              text: "バラ（固形、固体（小塊））",
            },
            {
              value: "VK",
              text: "バンパック",
            },
            {
              value: "VQ",
              text: "バラ（ガス）（特殊温度、圧力）",
            },
            {
              value: "VR",
              text: "バラ（固形）（穀類）",
            },
            {
              value: "VY",
              text: "バラ（固形）（粉類）",
            },
            {
              value: "WD",
              text: "ウッデンドラム",
            },
            {
              value: "ZZ",
              text: "その他",
            },
          ],
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "都道府県",
          name: "prefectures",
          type: "autocomplete",
          multi: true,
          range: "false",
          unit: null,
          group: false,
          values: [],
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "市区町村",
          name: "areas",
          type: "autocomplete",
          multi: true,
          range: "false",
          unit: null,
          group: true,
          values: null,
          groupValues: [],
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "住所",
          name: "address",
          type: "text",
          multi: false,
          range: "false",
          unit: null,
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "重量",
          name: "totalWeight",
          type: "number",
          multi: false,
          range: "false",
          unit: "kg",
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "体積",
          name: "totalVolume",
          type: "number",
          multi: false,
          range: "false",
          unit: "㎥",
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "サイズL長",
          name: "freightSizeL",
          type: "number",
          multi: false,
          range: "false",
          unit: "cm",
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "サイズW幅",
          name: "freightSizeW",
          type: "number",
          multi: false,
          range: "false",
          unit: "cm",
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "サイズH高",
          name: "freightSizeH",
          type: "number",
          multi: false,
          range: "false",
          unit: "cm",
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
        {
          required: false,
          label: "輸送業者区分",
          name: "transportCompDiv",
          type: "text",
          multi: false,
          range: "false",
          unit: null,
          group: false,
          values: null,
          groupValues: null,
          initialValue: null,
          initialValues: null,
        },
      ];
      const undispatchFreight = [
        {
          packingSid: "25000000000000002831",
          invNumSid: "25000000000000000146",
          officeSid: "2100000H80",
          officeName: "岡山営業所",
          area: "岡山県岡山市東区",
          scheduleDate: "2025/03/14 15:00:00.000",
          scheduleFromDate: "2025/03/15 15:00:00.000",
          scheduleToDate: "2025/03/16 15:00:00.000",
          pickupDeliveryDiv: "02",
          itemName: "品名08",
          totalWeight: 800.0,
          pickupDeliveryPoint: null,
          pickupDeliveryPointSid: "2100000093",
          pickupDeliveryPointCd: "5000000",
          address: "",
          packingNacName: "洋樽",
          totalVolume: 512.0,
          items40Name: null,
          transCharge: "0",
          businessNo: "204600",
          shipperClientCd: "8102000",
          shipperClientName: null,
          jigName: null,
          packingRemarks: "",
          freightSizeL: 800.0,
          freightSizeW: 800.0,
          freightSizeH: 800.0,
          packingUpdateDatetime: "2025/02/07 07:58:28.350",
          sendFlg: null,
          deliveryDate: null,
          deliveryTime: null,
          pickupWayDiv: "03",
          transportRequirementList: null,
          inquiryNumber: "yoneda04",
          packingSidOrg: "25000000000000002804",
          lotSid: "25000000000000000586",
          transportCompDiv: "01",
          vehicleShapeMajor: null,
        },
        {
          packingSid: "25000000000000002832",
          invNumSid: "25000000000000000146",
          officeSid: "2100000H80",
          officeName: "岡山営業所",
          area: "岡山県岡山市東区",
          scheduleDate: "2025/03/14 15:00:00.000",
          scheduleFromDate: "2025/03/15 15:00:00.000",
          scheduleToDate: "2025/03/16 15:00:00.000",
          pickupDeliveryDiv: "02",
          itemName: "品名08",
          totalWeight: 800.0,
          pickupDeliveryPoint: null,
          pickupDeliveryPointSid: "2100000093",
          pickupDeliveryPointCd: "5000000",
          address: "",
          packingNacName: "洋樽",
          totalVolume: 512.0,
          items40Name: null,
          transCharge: "0",
          businessNo: "204600",
          shipperClientCd: "8102000",
          shipperClientName: null,
          jigName: null,
          packingRemarks: "",
          freightSizeL: 800.0,
          freightSizeW: 800.0,
          freightSizeH: 800.0,
          packingUpdateDatetime: "2025/02/07 07:58:28.350",
          sendFlg: null,
          deliveryDate: null,
          deliveryTime: null,
          pickupWayDiv: "03",
          transportRequirementList: null,
          inquiryNumber: "yoneda04",
          packingSidOrg: "25000000000000002805",
          lotSid: "25000000000000000587",
          transportCompDiv: "01",
          vehicleShapeMajor: null,
        },
      ];

      return [undispatchedFreightTotalCount, undispatchFreightFilter, undispatchFreight];
    },

    /**
     * 幹線未配車貨物取得API
     * @param undispatchedFreightFilters
     * @param undispatchedFreightSorts
     */
    fetchUndispatchedFreightList(undispatchedFreightFilters, undispatchedFreightSorts) {
      const config = this.$httpClient.createGetApiRequestConfig();

      const filter = { lotMatchFlg: 1 };
      for (const [name, value] of Object.entries(undispatchedFreightFilters)) {
        if (!!value || !!value.length) {
          if (name.startsWith("scheduleDate") && value.match(/^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/g)) {
            filter[name] = dateTimeHelper.convertUTC(value + " 00:00:00");
          } else {
            filter[name] = value;
          }
        }
      }

      config.params.filter = { ...filter };
      config.params.sorts = [...undispatchedFreightSorts];

      // 接続先のAPI_URLを入力
      return this.$httpClient
        .secureGet(appConfig.API_URL.BIZ_TRUNK_UNDISPATCHED_FREIGHT_SEARCH, config)
        .then(
          (response) => {
            const data = response.data;
            if (data.resCom.resComCode !== "000") {
              const message =
                data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }

            return [
              data.resIdv.undispatchFreightTotalCount,
              data.resIdv.undispatchFreightFilter,
              data.resIdv.undispatchFreight,
            ];
          },
          (error) => {
            const message =
              error?.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },

    /**
     * 幹線配車一覧取得APIテスト用
     * @param operationDate
     * @param vehicleSort
     * @param visibleBinList
     */
    async fetchDispatchVehicleSearchTest(operationDate, vehicleSort, visibleBinList) {
      console.log("幹線配車一覧取得テスト");
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.operationDate = operationDate;
      config.params.vehicleSort1 = vehicleSort.vehicleSort1;
      config.params.vehicleSortOrder1 = vehicleSort.vehicleSortOrder1;
      config.params.vehicleSort2 = vehicleSort.vehicleSort2;
      config.params.vehicleSortOrder2 = vehicleSort.vehicleSortOrder2;
      config.params.visibleBinList = visibleBinList;
      // 営業所コードを設定
      config.params.officeSid = config.params.reqComOfficeSid;
      config.params.reqComPageLimit = this.pagingMax;

      // テスト用返却値の設定
      const trunkPatternList = [
        {
          trunkPatternSid: "25000000000000000010",
          trunkPatternName: "狭山→鈴鹿(中指示)",
          directionFlg: "1",
          firstOfficeSid: "2100000C18",
          trunkDiagramDispatchBinList: [
            {
              carSid: "V000002406",
              carSid2: "V000001732",
              carShape: "キャブオーバ",
              trunkDiagramSid: "25000000000000000005",
              trunkDiagramName: "柏原(狭山)→鈴鹿C昼便",
              carShago: ["L637A", "L637B"],
              carPayload: 10900,
              transportSid: "25000000000000001089",
              instDate: "2025-03-09 15:00:00",
              officeSid: "2100000093",
              officeName: "岡山営業所",
              planOnFlg: "0",
              driverUserSid: "21999001",
              driverUserName: "藤沢　所長",
              fromDate: "2025-03-09 23:00:00",
              toDate: "2025-03-10 06:00:00",
              transportDistance: null,
              remarks: "",
              visible: true,
              binStatusDiv: "0",
              carTransportCompDiv: null,
              dispatchedFreightDtl: [
                {
                  autoNo2: 1,
                  pickupDeliveryDiv: "02",
                  packingSid: "25000000000000006517",
                  officeSidOrg: "2100000093",
                  officeNameOrg: "岡山営業所",
                  shipperClientCd: "4200100",
                  shipperClientName: null,
                  invNumSid: "25000000000000002132",
                  jigCd: null,
                  jigName: null,
                  itemName: "item1",
                  packingNacCd: "BA",
                  packingNacName: "洋樽",
                  freightSizeL: 1200.0,
                  freightSizeW: 3400.0,
                  freightSizeH: 675.0,
                  totalWeight: 8000.0,
                  totalVolume: 2754.0,
                  officeSid: "2100000093",
                  officeName: "岡山営業所",
                  items40Sid: null,
                  items40Name: null,
                  packingUpdateDatetime: "2025/03/10 09:18:55.588",
                  sendFlg: null,
                  packingRemarks: "",
                  businessNo: "211100",
                  jisDistrictCode: null,
                  area: "神奈川県藤沢市",
                  scheduleDate: "2025/03/09 15:00:00.000",
                  scheduleFromDate: null,
                  scheduleToDate: null,
                  pickupDeliveryPointSid: "2100000050",
                  pickupDeliveryPointCd: "5000000",
                  pickupDeliveryPoint: "藤沢営業所",
                  postcode: "2520805",
                  address: "神奈川県藤沢市円行2-19-10",
                  applicableTaxRate: null,
                  transChargeNoTax: null,
                  deliveryDate: null,
                  deliveryTime: null,
                  packingSidOrg: "25000000000000006515",
                  routeCd: null,
                  routeOrder: 99999,
                  freightStatusDiv: "0",
                  pickupWayDiv: "01",
                  lotSid: "25000000000000000582",
                  transportCompDiv: "01",
                  transportRequirementList: null,
                  vehicleShapeMajor: "01",
                  transportRequirements: null,
                  inquiryNumber: "",
                },
              ],
            },
            {
              carSid: "V000002406",
              carSid2: "V000001732",
              carShape: "キャブオーバ",
              trunkDiagramSid: "25000000000000000006",
              trunkDiagramName: "柏原(狭山)→鈴鹿C夜便",
              carShago: ["L637A", "L637B"],
              carPayload: 10900,
              transportSid: "25000000000000001089",
              instDate: "2025-03-09 15:00:00",
              officeSid: "2100000093",
              officeName: "岡山営業所",
              planOnFlg: "0",
              driverUserSid: "21999001",
              driverUserName: "藤沢　所長",
              fromDate: "2025-03-10 11:00:00",
              toDate: "2025-03-10 13:00:00",
              transportDistance: null,
              remarks: "",
              visible: true,
              binStatusDiv: "0",
              carTransportCompDiv: null,
              dispatchedFreightDtl: [
                {
                  autoNo2: 1,
                  pickupDeliveryDiv: "02",
                  packingSid: "25000000000000006518",
                  officeSidOrg: "2100000093",
                  officeNameOrg: "岡山営業所",
                  shipperClientCd: "4200100",
                  shipperClientName: null,
                  invNumSid: "25000000000000002132",
                  jigCd: null,
                  jigName: null,
                  itemName: "item1",
                  packingNacCd: "BA",
                  packingNacName: "洋樽",
                  freightSizeL: 1200.0,
                  freightSizeW: 3400.0,
                  freightSizeH: 675.0,
                  totalWeight: 8000.0,
                  totalVolume: 2754.0,
                  officeSid: "2100000093",
                  officeName: "岡山営業所",
                  items40Sid: null,
                  items40Name: null,
                  packingUpdateDatetime: "2025/03/10 09:18:55.588",
                  sendFlg: null,
                  packingRemarks: "",
                  businessNo: "211100",
                  jisDistrictCode: null,
                  area: "神奈川県藤沢市",
                  scheduleDate: "2025/03/09 15:00:00.000",
                  scheduleFromDate: null,
                  scheduleToDate: null,
                  pickupDeliveryPointSid: "2100000050",
                  pickupDeliveryPointCd: "5000000",
                  pickupDeliveryPoint: "藤沢営業所",
                  postcode: "2520805",
                  address: "神奈川県藤沢市円行2-19-10",
                  applicableTaxRate: null,
                  transChargeNoTax: null,
                  deliveryDate: null,
                  deliveryTime: null,
                  packingSidOrg: "25000000000000006515",
                  routeCd: null,
                  routeOrder: 99999,
                  freightStatusDiv: "0",
                  pickupWayDiv: "01",
                  lotSid: "25000000000000000583",
                  transportCompDiv: "01",
                  transportRequirementList: null,
                  vehicleShapeMajor: "01",
                  transportRequirements: null,
                  inquiryNumber: "",
                },
              ],
            },
          ],
        },
        {
          trunkPatternSid: "25000000000000000011",
          trunkPatternName: "大津→角田(中指示)",
          directionFlg: "0",
          firstOfficeSid: "2100000C19",
          trunkDiagramDispatchBinList: [
            {
              carSid: "V000002406",
              carSid2: "V000001732",
              carShape: "キャブオーバ",
              trunkDiagramSid: "25000000000000000003",
              trunkDiagramName: "大津→角田昼便",
              carShago: ["L637A", "L637B"],
              carPayload: 10900,
              transportSid: "25000000000000001089",
              instDate: "2025-03-09 15:00:00",
              officeSid: "2100000093",
              officeName: "岡山営業所",
              planOnFlg: "0",
              driverUserSid: "21999002",
              driverUserName: "乗務員　AAA",
              fromDate: "2025-03-09 22:00:00",
              toDate: "2025-03-10 05:00:00",
              transportDistance: null,
              remarks: "",
              visible: true,
              binStatusDiv: "0",
              carTransportCompDiv: null,
              dispatchedFreightDtl: [
                {
                  autoNo2: 1,
                  pickupDeliveryDiv: "02",
                  packingSid: "25000000000000006519",
                  officeSidOrg: "2100000093",
                  officeNameOrg: "岡山営業所",
                  shipperClientCd: "4200100",
                  shipperClientName: null,
                  invNumSid: "25000000000000002132",
                  jigCd: null,
                  jigName: null,
                  itemName: "item1",
                  packingNacCd: "BA",
                  packingNacName: "洋樽",
                  freightSizeL: 1200.0,
                  freightSizeW: 3400.0,
                  freightSizeH: 675.0,
                  totalWeight: 8000.0,
                  totalVolume: 2754.0,
                  officeSid: "2100000093",
                  officeName: "岡山営業所",
                  items40Sid: null,
                  items40Name: null,
                  packingUpdateDatetime: "2025/03/10 09:18:55.588",
                  sendFlg: null,
                  packingRemarks: "",
                  businessNo: "211100",
                  jisDistrictCode: null,
                  area: "神奈川県藤沢市",
                  scheduleDate: "2025/03/09 15:00:00.000",
                  scheduleFromDate: null,
                  scheduleToDate: null,
                  pickupDeliveryPointSid: "2100000050",
                  pickupDeliveryPointCd: "5000000",
                  pickupDeliveryPoint: "藤沢営業所",
                  postcode: "2520805",
                  address: "神奈川県藤沢市円行2-19-10",
                  applicableTaxRate: null,
                  transChargeNoTax: null,
                  deliveryDate: null,
                  deliveryTime: null,
                  packingSidOrg: "25000000000000006515",
                  routeCd: null,
                  routeOrder: 99999,
                  freightStatusDiv: "0",
                  pickupWayDiv: "01",
                  lotSid: "25000000000000000584",
                  transportCompDiv: "01",
                  transportRequirementList: null,
                  vehicleShapeMajor: "01",
                  transportRequirements: null,
                  inquiryNumber: "",
                },
              ],
            },
            {
              carSid: "V000001732",
              carSid2: "V000002406",
              carShape: "キャブオーバ",
              trunkDiagramSid: "25000000000000000004",
              trunkDiagramName: "大津→角田夜便",
              carShago: ["L637A", "L637B"],
              carPayload: 10900,
              transportSid: "25000000000000001089",
              instDate: "2025-03-09 15:00:00",
              officeSid: "2100000093",
              officeName: "岡山営業所",
              planOnFlg: "0",
              driverUserSid: "21999002",
              driverUserName: "乗務員　AAA",
              fromDate: "2025-03-10 12:00:00",
              toDate: "2025-03-10 14:00:00",
              transportDistance: null,
              remarks: "",
              visible: true,
              binStatusDiv: "0",
              carTransportCompDiv: null,
              dispatchedFreightDtl: [
                {
                  autoNo2: 1,
                  pickupDeliveryDiv: "02",
                  packingSid: "25000000000000006520",
                  officeSidOrg: "2100000093",
                  officeNameOrg: "岡山営業所",
                  shipperClientCd: "4200100",
                  shipperClientName: null,
                  invNumSid: "25000000000000002132",
                  jigCd: null,
                  jigName: null,
                  itemName: "item1",
                  packingNacCd: "BA",
                  packingNacName: "洋樽",
                  freightSizeL: 1200.0,
                  freightSizeW: 3400.0,
                  freightSizeH: 675.0,
                  totalWeight: 8000.0,
                  totalVolume: 2754.0,
                  officeSid: "2100000093",
                  officeName: "岡山営業所",
                  items40Sid: null,
                  items40Name: null,
                  packingUpdateDatetime: "2025/03/10 09:18:55.588",
                  sendFlg: null,
                  packingRemarks: "",
                  businessNo: "211100",
                  jisDistrictCode: null,
                  area: "神奈川県藤沢市",
                  scheduleDate: "2025/03/09 15:00:00.000",
                  scheduleFromDate: null,
                  scheduleToDate: null,
                  pickupDeliveryPointSid: "2100000050",
                  pickupDeliveryPointCd: "5000000",
                  pickupDeliveryPoint: "藤沢営業所",
                  postcode: "2520805",
                  address: "神奈川県藤沢市円行2-19-10",
                  applicableTaxRate: null,
                  transChargeNoTax: null,
                  deliveryDate: null,
                  deliveryTime: null,
                  packingSidOrg: "25000000000000006515",
                  routeCd: null,
                  routeOrder: 99999,
                  freightStatusDiv: "0",
                  pickupWayDiv: "01",
                  lotSid: "25000000000000000585",
                  transportCompDiv: "01",
                  transportRequirementList: null,
                  vehicleShapeMajor: "01",
                  transportRequirements: null,
                  inquiryNumber: "",
                },
              ],
            },
          ],
        },
      ];
      return trunkPatternList;
    },

    /**
     * 幹線配車一覧取得API
     * @param operationDate
     * @param vehicleSort
     * @param visibleBinList
     */
    async fetchDispatchVehicleSearch(operationDate, vehicleSort, visibleBinList) {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.operationDate = operationDate;
      config.params.vehicleSort1 = vehicleSort.vehicleSort1;
      config.params.vehicleSortOrder1 = vehicleSort.vehicleSortOrder1;
      config.params.vehicleSort2 = vehicleSort.vehicleSort2;
      config.params.vehicleSortOrder2 = vehicleSort.vehicleSortOrder2;
      config.params.visibleBinList = visibleBinList;
      // 営業所コードを設定
      config.params.officeSid = config.params.reqComOfficeSid;
      config.params.reqComPageLimit = this.pagingMax;

      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.BIZ_TRUNK_DISPATCH_LIST, config).then(
        (response) => {
          const data = response.data;
          if (data.resCom.resComCode !== "000") {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }

          return data.resIdv.trunkPatternList;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    registerDispatchVehicle(dispatchDataList) {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_006; // 画面ID
      body.reqIdv.trunkDispatchPlanList = dispatchDataList;

      // 接続先のAPI_URLを入力
      return this.$httpClient
        .securePost(appConfig.API_URL.BIZ_TRUNK_DISPATCH_PLAN, body, appConfig.APP_CONFIG)
        .then(
          (response) => {
            // エラー時
            const jsonData = response.data;
            if (jsonData.resCom.resComCode !== "000") {
              const message =
                jsonData.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
            return;
          },
          (error) => {
            const message =
              error.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },
    getRegisterDispatchVehicle() {
      const registerDispatchVehicle = [];
      const dispatches = this.dispatchVehicles.flatMap(
        ({ trunkPatternSid, trunkDiagramDispatchBinList }) =>
          trunkDiagramDispatchBinList?.map((dispatch) => ({ trunkPatternSid, ...dispatch })) ?? []
      );
      const originals = this.originalDispatchVehicles.flatMap(
        ({ trunkPatternSid, trunkDiagramDispatchBinList }) =>
          trunkDiagramDispatchBinList?.map((dispatch) => ({ trunkPatternSid, ...dispatch })) ?? []
      );
      for (const original of originals) {
        const dispatch = dispatches.find(
          ({ transportSid }) => transportSid === original.transportSid
        );
        if (!dispatch) {
          continue;
        }

        // 車両1が異なる場合
        if ((original.carSid?.toString() ?? "") !== (dispatch.carSid?.toString() ?? "")) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 車両2が異なる場合
        if ((original.carSid2?.toString() ?? "") !== (dispatch.carSid2?.toString() ?? "")) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // ドライバーが異なる場合
        if (original.driverUserSid !== dispatch.driverUserSid) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 便の開始時刻が異なる場合
        if (original.fromDate !== dispatch.fromDate) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 便の終了時刻が異なる場合
        if (original.toDate !== dispatch.toDate) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        // 便の備考が異なる場合
        if ((original.remarks?.toString() ?? "") !== (dispatch.remarks?.toString() ?? "")) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        let originalFreights = [];
        if (original.dispatchedFreightDtl) {
          originalFreights = original.dispatchedFreightDtl;
        }
        let dispatchFreights = [];
        if (dispatch.dispatchedFreightDtl) {
          dispatchFreights = dispatch.dispatchedFreightDtl;
        }

        // 配車済み貨物の数が異なる場合
        if (originalFreights.length !== dispatchFreights.length) {
          registerDispatchVehicle.push(dispatch);
          continue;
        }

        for (let i = 0; i < originalFreights.length; i++) {
          const of = originalFreights[i];
          const df = dispatchFreights[i];

          // 配車済み貨物の順番が異なる場合
          if (of.packingSid !== df.packingSid || of.pickupDeliveryDiv != df.pickupDeliveryDiv) {
            registerDispatchVehicle.push(dispatch);
            break;
          }
          // 配車済み貨物の納品日時が異なる場合
          if (of.deliveryDate !== df.deliveryDate || of.deliveryTime !== df.deliveryTime) {
            registerDispatchVehicle.push(dispatch);
            break;
          }
        }
      }

      return registerDispatchVehicle;
    },
    getOfficeAddress() {
      const config = this.$httpClient.createGetApiRequestConfig();
      // 拠点SID
      config.params.baseSid = config.params.reqComOfficeSid;
      // 拠点管理種別（2=営業所を取得）
      config.params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      config.params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      config.params.selfEstablishedLocationsKbn = "1";

      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.MST_BIZ_BASE_SEARCH, config).then(
        (response) => {
          const data = JSON.parse(JSON.stringify(response.data));
          if (data.resCom.resComCode !== "000" || !data.resIdv.baseInfoList?.length) {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
          return data.resIdv.baseInfoList[0].address1 + data.resIdv.baseInfoList[0].address2;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    async filterUndispatchFreights() {
      try {
        const [totalCount, filters, undispatchedFreights] = await this.fetchUndispatchedFreightList(
          this.filterItems,
          this.sortItems
        );

        // 絞り込み直前の配車済みの貨物
        const dispatchFreightIds = new Set(
          this.dispatchVehicles
            ?.flatMap(({ dispatchBinList }) => dispatchBinList ?? [])
            ?.flatMap(({ dispatchedFreightDtl }) => dispatchedFreightDtl ?? [])
            .map(({ pickupDeliveryDiv, packingSid }) => `${pickupDeliveryDiv}_${packingSid}`)
        );

        // 前回表示時点での（未配車貨物がまだ配車されていない状態の）配車済みの貨物
        const originalDispatchFreightIds = new Set(
          this.originalDispatchVehicles
            ?.flatMap(({ dispatchBinList }) => dispatchBinList ?? [])
            ?.flatMap(({ dispatchedFreightDtl }) => dispatchedFreightDtl ?? [])
            .map(({ pickupDeliveryDiv, packingSid }) => `${pickupDeliveryDiv}_${packingSid}`)
        );

        // 配車済み貨物から未配車に移動された貨物
        const freights =
          this.undispatchedFreights?.filter(({ pickupDeliveryDiv, packingSid }) =>
            originalDispatchFreightIds.has(`${pickupDeliveryDiv}_${packingSid}`)
          ) ?? [];

        // 絞り込み直前に配車済みの未配車貨物を新たに取得した未配車貨物の一覧から除き、
        // 配車済みから未配車に移動された貨物を加える
        this.undispatchedFreights = [
          ...undispatchedFreights.filter(
            (freight) =>
              !dispatchFreightIds.has(`${freight.pickupDeliveryDiv}_${freight.packingSid}`)
          ),
          ...freights,
        ];

        // 未配車貨物が配車されていない状態の配車済みの貨物と絞り込み直前の配車済み貨物を比較して
        // 絞り込み直前の貨物のみに含まれるもの(未配車貨物から便に配車した貨物)を取得
        const unregisteredDispatchFreightIds = dispatchFreightIds.difference(
          originalDispatchFreightIds
        );

        // 総件数 = 再取得した総件数 + 配車済みから未配車に移動された貨物数 - 未配車貨物から便に配車した貨物数
        this.undispatchFreightTotalCount =
          totalCount + (freights?.length ?? 0) - unregisteredDispatchFreightIds.size;
        this.filters = this.mergeFilters(this.filters, filters);
        this.originalUndispatchFreightCount = this.undispatchedFreights?.length ?? 0;
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },
    /**
     * 現在の絞り込み条件と最新の絞り込み条件を組み合わせる
     *
     * @param {Array} currentFilters 現在の絞り込み条件
     * @param {Array} latestFilters 最新の絞り込み条件
     *
     * @returns 現在の絞り込み条件に最新の絞り込み条件を組み合わせた新しい絞り込み条件
     */
    mergeFilters(currentFilters, latestFilters) {
      currentFilters ??= [];
      latestFilters ??= [];
      if (!currentFilters.length) {
        return latestFilters;
      }
      if (!latestFilters.length) {
        return currentFilters;
      }
      const equalFilter = (f1, f2, equalProps = ["name", "type"]) => {
        return equalProps.every((prop) => f1[prop] === f2[prop]);
      };

      // 現在の絞り込み条件と最新の絞り込み条件の values を組み合わせる関数
      const mergeValues = (baseValues, otherValues) => {
        baseValues ??= [];
        otherValues ??= [];
        if (!otherValues.length) {
          return baseValues;
        }
        if (!baseValues.length) {
          return otherValues;
        }
        const valuesMap = [...baseValues, ...otherValues].reduce(
          (map, { value, text }) => map.set(value, text),
          new Map()
        );
        return Array.from(valuesMap.keys())
          .toSorted()
          .map((value) => ({ value, text: valuesMap.get(value) }));
      };

      // 現在の絞り込み条件と最新の絞り込み条件の groupValues を組み合わせる関数
      const mergeGroupValues = (baseGroupValues, otherGroupValues) => {
        baseGroupValues ??= [];
        otherGroupValues ??= [];
        if (!otherGroupValues.length) {
          return baseGroupValues;
        }
        if (!baseGroupValues.length) {
          return otherGroupValues;
        }

        const groupValuesMap = [...baseGroupValues, ...otherGroupValues].reduce(
          (map, { label, values }) => {
            if (map.has(label)) {
              return map.set(label, mergeValues(map.get(label), values));
            } else {
              return map.set(label, values);
            }
          },
          new Map()
        );
        return Array.from(groupValuesMap.keys())
          .toSorted()
          .map((label) => ({ label, values: groupValuesMap.get(label) }));
      };

      const currentFilterMap = currentFilters.reduce((map, f) => map.set(f.name, f), new Map());
      const latestFilterMap = latestFilters.reduce((map, f) => map.set(f.name, f), new Map());

      // valuesに値がある絞り込みの種類
      const valuesTypeSet = new Set(["radio", "select", "autocomplete"]);

      // 現在の絞り込みと最新の絞り込み条件を組み合わせる
      const filters = [];
      for (const [name, currentFilter] of currentFilterMap.entries()) {
        const latestFilter = latestFilterMap.get(name);
        if (equalFilter(currentFilter, latestFilter)) {
          // 最新の絞り込み条件を取得した際に、表示される絞り込み条件を維持するために、
          // 初期値(initalValueとinitialValues)は最新の絞り込み条件にあるものを利用する
          const filter = {
            ...currentFilter,
            initialValue: latestFilter.initialValue,
            initialValues: latestFilter.initialValues,
          };
          if (currentFilter.group) {
            filter.groupValues = mergeGroupValues(
              currentFilter.groupValues,
              latestFilter.groupValues
            );
          } else if (valuesTypeSet.has(currentFilter.type)) {
            filter.values = mergeValues(currentFilter.values, latestFilter.values);
          }
          filters.push(filter);
        } else {
          filters.push(currentFilter);
        }
      }

      return filters;
    },

    /**
     * 未保存の編集中の配車情報（便、未配車貨物）と新しく取得した配車情報を組み合わせる
     *
     * 以下のように組み合わせた配車車両情報が返却される
     * 1. 新しく追加された便、配車情報が更新された便
     * 2. 1以外の便で、1で配車済みの貨物を除いた編集中の便
     * 3. 1,2以外の新しく取得した便から、1, 2で配車済みの貨物を除いた便
     *
     * @param {Array} newDispatchVehicle 新しく取得した配車車両
     * @param {Array} newUndispatchFreight 新しく取得した未配車貨物
     * @param {Array} editingDispatchVehicle 編集中の配車車両
     * @param {Array} editingUndispatchFreight 編集中の未配車貨物
     * @param {Array} oldOriginalDispatchVehicle 前回取得時の配車車両
     *
     * @returns [配車車両情報, 未配車貨物]
     */
    mergeDispatchFreights(
      newDispatchVehicle,
      newUndispatchFreight,
      editingDispatchVehicle,
      editingUndispatchFreight,
      oldOriginalDispatchVehicle
    ) {
      const oldOriginalBinList = oldOriginalDispatchVehicle.flatMap(
        ({ dispatchBinList }) => dispatchBinList ?? []
      );

      const oldOriginalTransportSids = oldOriginalBinList.map(({ transportSid }) => transportSid);

      // 前回の画面表示時より後に新しく追加された便
      const newBins = new Map();
      // 前回の画面表示時より後に更新された便
      const updatedBins = new Map();
      // 前回の画面表示時から変わらない便
      const unupdatedBins = new Map();
      for (const { carSid, dispatchBinList } of newDispatchVehicle) {
        const newBinList = [];
        const updatedBinList = [];
        const unupdatedBinList = [];
        for (const newBin of dispatchBinList ?? []) {
          // 新規で追加された便かどうか
          const index = oldOriginalTransportSids.indexOf(newBin.transportSid);
          if (index === -1) {
            newBinList.push(newBin);
            continue;
          }

          const oldDispatchedFreights = oldOriginalBinList[index].dispatchedFreightDtl ?? [];
          const newDispatchedFreights = newBin.dispatchedFreightDtl ?? [];

          // 配車されている貨物の数に違いがあるか
          if (oldDispatchedFreights.length !== newDispatchedFreights.length) {
            updatedBinList.push(newBin);
            continue;
          }

          let unupdated = true;
          // 貨物の順番が異なるか
          for (let i = 0; i < newDispatchedFreights.length; i++) {
            const oldFreightId = `${oldDispatchedFreights[i].pickupDeliveryDiv}_${oldDispatchedFreights[i].packingSid}`;
            const newFreightId = `${newDispatchedFreights[i].pickupDeliveryDiv}_${newDispatchedFreights[i].packingSid}`;
            if (oldFreightId !== newFreightId) {
              updatedBinList.push(newBin);
              unupdated = false;
              break;
            }
          }

          // 更新されていない場合
          if (unupdated) {
            unupdatedBinList.push(newBin);
          }
        }
        if (newBinList.length !== 0) newBins.set(carSid, newBinList);
        if (updatedBinList.length !== 0) updatedBins.set(carSid, updatedBinList);
        if (unupdatedBinList.length !== 0) unupdatedBins.set(carSid, unupdatedBinList);
      }

      // 前回画面表示より後に削除された便
      const deletedBins = new Map();
      for (const { carSid, dispatchBinList } of oldOriginalDispatchVehicle) {
        const newBinList = newBins.get(carSid) ?? [];
        const updatedBinList = updatedBins.get(carSid) ?? [];
        const unupdatedBinList = unupdatedBins.get(carSid) ?? [];
        const binList = [...newBinList, ...updatedBinList, ...unupdatedBinList];
        const deletedBinList = (dispatchBinList ?? []).filter(({ transportSid }) =>
          binList.every((bin) => bin.transportSid !== transportSid)
        );

        if (deletedBinList.length !== 0) deletedBins.set(carSid, deletedBinList);
      }

      // 現在配車可能な全貨物（新しく取得した未配車貨物と配車済み貨物）の荷姿Sid
      const allFreightIds = new Set(
        newUndispatchFreight.map((f) => `${f.pickupDeliveryDiv}_${f.packingSid}`)
      );
      for (const { dispatchBinList } of newDispatchVehicle) {
        for (const { dispatchedFreightDtl } of dispatchBinList ?? []) {
          for (const { pickupDeliveryDiv, packingSid } of dispatchedFreightDtl ?? []) {
            allFreightIds.add(`${pickupDeliveryDiv}_${packingSid}`);
          }
        }
      }

      // 新しく追加された便に配車されている貨物の荷姿SID
      const newFreightIds = new Set(
        Array.from(newBins.values())
          .flat()
          .flatMap((bin) => bin.dispatchedFreightDtl ?? [])
          .map((f) => `${f.pickupDeliveryDiv}_${f.packingSid}`)
      );

      // 更新された便に配車されている貨物の荷姿SID
      const updatedDispatchedFreightIds = new Set(
        Array.from(updatedBins.values())
          .flat()
          .flatMap((bin) => bin.dispatchedFreightDtl ?? [])
          .map((f) => `${f.pickupDeliveryDiv}_${f.packingSid}`)
      );

      // 更新されていない便情報のうち、更新前も未編集だった便情報を取得
      const uneditedBins = new Map();
      for (const [carSid, unupdatedBinList] of unupdatedBins.entries()) {
        const editingVehicle = editingDispatchVehicle.find((vehicle) => vehicle.carSid === carSid);
        const binList = [];
        for (const bin of unupdatedBinList) {
          const editingBin = editingVehicle.dispatchBinList.find(
            ({ transportSid }) => transportSid === bin.transportSid
          );
          const editingFreights = editingBin?.dispatchedFreightDtl ?? [];
          const editingPakingSidList = editingFreights.map(
            (f) => `${f.pickupDeliveryDiv}_${f.packingSid}`
          );
          if (
            bin.dispatchedFreightDtl.length === editingFreights.length &&
            bin.dispatchedFreightDtl.every(({ pickupDeliveryDiv, packingSid }) =>
              editingPakingSidList.includes(`${pickupDeliveryDiv}_${packingSid}`)
            )
          ) {
            binList.push(bin);
          }
        }

        if (binList.length !== 0) uneditedBins.set(carSid, binList);
      }

      // 編集中の貨物のうち、更新済みの便に移動している貨物を取得
      const editUpdatedPackingSids = new Set();
      for (const [carSid, updatedBinList] of updatedBins.entries()) {
        const updatedTransportSids = new Set(updatedBinList.map((bin) => bin.transportSid));
        const editingVehicle = editingDispatchVehicle.find((vehicle) => vehicle.carSid === carSid);
        const editingBinMap = editingVehicle.dispatchBinList
          .filter(({ transportSid }) => updatedTransportSids.has(transportSid))
          .reduce((map, bin) => map.set(bin.transportSid, bin), new Map());

        for (const { transportSid, dispatchedFreightDtl } of updatedBinList) {
          const editingBin = editingBinMap.get(transportSid);
          for (const { pickupDeliveryDiv, packingSid } of editingBin?.dispatchedFreightDtl ?? []) {
            const editFreightId = `${pickupDeliveryDiv}_${packingSid}`;
            if (
              dispatchedFreightDtl.every(
                (freight) => `${freight.pickupDeliveryDiv}_${freight.packingSid}` !== editFreightId
              )
            ) {
              editUpdatedPackingSids.add(editFreightId);
            }
          }
        }
      }

      // 前回表示時の編集中の便で、画面更新後の配車済みの貨物を除いた、未更新な便を抽出
      const editingBins = new Map();
      const editingFreightIds = new Set();
      for (const { carSid, dispatchBinList } of editingDispatchVehicle) {
        const unupdatedBinList = unupdatedBins.get(carSid) ?? [];

        const editingBinList = [];
        for (const bin of dispatchBinList ?? []) {
          if (
            !bin.transportSid.startsWith(this.newTransportSidPrefix) &&
            unupdatedBinList.every(({ transportSid }) => transportSid !== bin.transportSid)
          ) {
            break;
          }

          const unupdatedBin = unupdatedBinList.find(
            ({ transportSid }) => transportSid === bin.transportSid
          );

          const editUpdatedFreights =
            unupdatedBin?.dispatchedFreightDtl.filter(({ pickupDeliveryDiv, packingSid }) =>
              editUpdatedPackingSids.has(`${pickupDeliveryDiv}_${packingSid}`)
            ) ?? [];

          // 更新または新規追加された便に配車済みの貨物を除く
          const dispatchedFreights = [...editUpdatedFreights];

          const uneditDispatchFreightBins = unupdatedBins.get(carSid) ?? [];
          const uneditDispatchFreightList = uneditDispatchFreightBins.flatMap(
            (bin) => bin.dispatchedFreightDtl ?? []
          );
          for (const freight of bin.dispatchedFreightDtl) {
            const freightId = `${freight.pickupDeliveryDiv}_${freight.packingSid}`;
            if (
              !newFreightIds.has(freightId) &&
              !updatedDispatchedFreightIds.has(freightId) &&
              allFreightIds.has(freightId)
            ) {
              // 更新日時を反映するため未配車貨物リスト内に荷姿Sidと集荷配達区分が一致するデータがある場合
              // freightではなく、未配車貨物リストから値を返す
              const editUndispatchFreight = newUndispatchFreight.find(
                (el) => freightId === `${el.pickupDeliveryDiv}_${el.packingSid}`
              );
              // 貨物情報を最新の情報に変更
              const uneditDispatchFreight = uneditDispatchFreightList.find(
                (el) => freightId === `${el.pickupDeliveryDiv}_${el.packingSid}`
              );
              const latestFreight = uneditDispatchFreight ? uneditDispatchFreight : freight;
              dispatchedFreights.push(
                editUndispatchFreight ? editUndispatchFreight : latestFreight
              );
              editingFreightIds.add(freightId);
            }
          }

          editingBinList.push({
            ...bin,
            dispatchedFreightDtl: dispatchedFreights,
          });
        }

        if (editingBinList.length !== 0) editingBins.set(carSid, editingBinList);
      }

      // 編集中の配車と新しく取得した配車の組み合わせ
      const dispatchedVehicle = [];
      for (const newVehicle of newDispatchVehicle) {
        const updatedBinList = updatedBins.get(newVehicle.carSid) ?? [];
        const newBinLst = newBins.get(newVehicle.carSid) ?? [];
        const editingBinList = editingBins.get(newVehicle.carSid) ?? [];
        const vehicle = {
          ...newVehicle,
          dispatchBinList: [...updatedBinList, ...newBinLst, ...editingBinList],
        };
        dispatchedVehicle.push(vehicle);
      }

      // 配車済みの貨物を除いた未配車貨物のリスト
      const undispatchVehicle = newUndispatchFreight.filter(
        ({ pickupDeliveryDiv, packingSid }) =>
          !editingFreightIds.has(`${pickupDeliveryDiv}_${packingSid}`)
      );

      // 画面更新前の編集中の未配車貨物から配車可能な貨物を追加する
      const undispatchPakcingSids = new Set(
        undispatchVehicle.map(
          ({ pickupDeliveryDiv, packingSid }) => `${pickupDeliveryDiv}_${packingSid}`
        )
      );
      for (const freight of editingUndispatchFreight) {
        const freightId = `${freight.pickupDeliveryDiv}_${freight.packingSid}`;
        if (allFreightIds.has(freightId) && !undispatchPakcingSids.has(freightId)) {
          undispatchVehicle.push(freight);
        }
      }

      return [dispatchedVehicle, undispatchVehicle];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
::v-deep .selected-freight {
  filter: brightness(85%);
  // position: relative;
  // &::after {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   display: inline-block;
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   background-color: rgb(140 140 140 / 20%);
  // }
}

::v-deep .freight-ghost {
  opacity: 0.5;
}

// 未配車エリアの納品日非表示
.undispatch ::v-deep {
  .freightDateTimePicker {
    display: none;
  }
}
</style>
