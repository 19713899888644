<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_transportRouteUpsert" />

      <v-container fluid class="main-deliveryArea">
        <v-row style="padding: 23px 0px 5px">
          <div class="route-detail-title">
            {{ $t("label.lbl_tansportOrderList") }}
          </div>
        </v-row>
        <v-data-table
          id="listData"
          fixed-header
          :headers="orderListHeaderItems"
          :items="transportOrderList"
          hide-default-footer
          height="250px"
        >
          <!-- 受注日 -->
          <template v-slot:[`item.orderDate`]="{ item }">{{
            dateTimeHelper.convertUTC2JST(item.orderDate).substring(0, 10)
          }}</template>
          <!-- 集荷予定日 -->
          <template v-slot:[`item.pickupScheduleDate`]="{ item }">{{
            dateTimeHelper.convertUTC2JST(item.pickupScheduleDate).substring(0, 10)
          }}</template>
          <!-- 到着日 -->
          <template v-slot:[`item.deliveryScheduleDate`]="{ item }">{{
            dateTimeHelper.convertUTC2JST(item.deliveryScheduleDate).substring(0, 10)
          }}</template>
        </v-data-table>
        <v-row>
          <div class="route-detail-title">
            {{ $t("label.lbl_routeDetail") }}
          </div>
          <template v-if="isDisabledList">
            <!-- ロットSIDがある又は配車情報がある場合はルート確定・削除ボタンは表示せずワーニングメッセージを表示する -->
            <v-alert dense text type="warning" class="mb-0 ml-4">{{
              $t("label.lbl_cannotUpdateLotMessage")
            }}</v-alert>
          </template>
          <!-- ページネーション -->
          <div class="pagination">
            <v-pagination
              v-if="routeDetailList.length > 1"
              v-model="page"
              :length="routeDetailList.length"
              :total-visible="3"
            ></v-pagination>
          </div>
          <div class="btn-area" style="padding-bottom: 18px">
            <!-- 「区間登録」ボタン -->
            <v-btn
              v-show="!isDisabledList"
              color="primary"
              class="api-btn"
              v-on:click="registerSection"
            >
              {{ $t("btn.btn_registerSection") }}
            </v-btn>
            <!-- 「履歴」ボタン -->
            <v-btn
              v-show="!isDisabledList"
              v-if="screenStateFlg == 0"
              color="primary"
              class="api-btn"
              v-on:click="setRouteRecordToDetail"
            >
              {{ $t("btn.btn_routeRecord") }}
            </v-btn>
            <!-- 「初期表示に戻る」ボタン -->
            <v-btn v-else color="primary" class="api-btn" v-on:click="setRouteMstToDetail">
              {{ $t("btn.btn_returnFirstScreen") }}
            </v-btn>
            <!-- 「ルート削除」ボタン -->
            <v-btn v-show="isRegister" color="primary" class="api-btn" v-on:click="clickDelete">{{
              $t("btn.btn_routeDelete")
            }}</v-btn>
            <!-- 「ルート確定」ボタン -->
            <v-btn
              v-show="!isDisabledList"
              color="primary"
              class="api-btn"
              v-on:click="routeConfirm"
            >
              {{ $t("btn.btn_routeRegister") }}
            </v-btn>
          </div>
        </v-row>
        <div v-for="(routeDetail, index) in routeDetailList" :key="index">
          <v-data-table
            v-if="index + 1 == page"
            id="listData-route"
            fixed-header
            :headers="routeDetailHeaderItems"
            :items="routeDetail"
            hide-default-footer
            no-data-text=""
            height="350px"
          >
            <!-- 追加 -->
            <template v-if="routeDetail != null" v-slot:[`item.add`]="{ item }">
              <div>
                <v-btn
                  v-show="!isDisabledList"
                  v-if="
                    item.transportComp != transportCompDiv.TRUNK ||
                    (item.transportComp == transportCompDiv.TRUNK &&
                      item.No ==
                        routeDetail.findLast((e) => e.transportComp == transportCompDiv.TRUNK).No)
                  "
                  icon
                  small
                  @click="addLine(index, item)"
                >
                  <v-icon> mdi-playlist-plus </v-icon>
                </v-btn>
              </div>
            </template>

            <!-- 担当営業所 -->
            <template v-if="routeDetail != null" v-slot:[`item.relayOffice`]="{ item }">
              <div v-if="item.transportComp == transportCompDiv.TRUNK">
                {{ officeList.find((e) => e.value == item.relayOffice).text }}
              </div>
              <v-autocomplete
                dense
                v-else
                ref="relayOffice"
                v-model="item.relayOffice"
                :items="officeList"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
                :error-messages="relayOfficeAlertMessage"
                margin-bottom="0"
                :disabled="isDisabledList"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </template>

            <!-- 輸送手段 -->
            <template v-if="routeDetail != null" v-slot:[`item.transportComp`]="{ item }">
              <div v-if="item.transportComp == transportCompDiv.TRUNK">
                {{ transportCompDefaultList.find((e) => e.value == item.transportComp).text }}
              </div>
              <v-autocomplete
                dense
                v-else
                v-model="item.transportComp"
                :items="transportCompDefaultList"
                persistent-hint
                :rules="[rules.inputRequired]"
                :error-messages="transportCompAlertMessage"
                margin-bottom="0"
                @focus="getPreviousTransportComp(item.transportComp)"
                @change="changeTransportComp(index, item, $event)"
                :disabled="isDisabledList"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </template>

            <!-- 発地 -->
            <template v-if="routeDetail != null" v-slot:[`item.pickupPointRelay`]="{ item }">
              <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                {{ setPickupPoint(index, item) }}
              </div>
              <div v-else>
                {{ setPickupPoint(index, item) }}
              </div>
            </template>

            <!-- 着地 -->
            <template v-if="routeDetail != null" v-slot:[`item.deliveryPointRelay`]="{ item }">
              <div
                v-if="item.No == routeDetail.length || item.transportComp == transportCompDiv.TRUNK"
              >
                <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                  {{ item.deliveryPointRelayName }}
                </div>
                <div v-else>
                  {{ item.deliveryPointRelayName }}
                </div>
              </div>
              <v-autocomplete
                v-else
                dense
                :value="item.deliveryPointRelaySid"
                :items="deliveryPointList[item.No]"
                class="txt-single"
                persistent-hint
                :rules="[
                  rules.inputRequired,
                  rules.pickupDeliveryPointRelation(
                    item.deliveryPointRelaySid,
                    routeDetail[item.No].pickupPointRelaySid
                  ),
                ]"
                :error-messages="deliveryPointAlertMessage"
                margin-bottom="0"
                @click="getDeliveryPointList(item.No, item.pickupPointRelaySid)"
                @change="changeDeliveryPointRelay(item, $event)"
                :disabled="isDisabledList"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </template>

            <template v-slot:[`item.distanceAndTime`]="{ item }">
              <div>
                <v-btn
                  text
                  :disabled="isDisabledLink"
                  @click="showDistanceAndTime(item)"
                  class="link-btn"
                >
                  <span>{{ item.distanceAndTime }}</span>
                </v-btn>
              </div>
            </template>

            <!-- 出発日 -->
            <template v-if="routeDetail != null" v-slot:[`item.pickupScheduleDateRelay`]="{ item }">
              <div v-if="item.No == '1'">
                <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                  {{ item.pickupScheduleDateRelay }}
                </div>
                <div v-else>
                  {{ item.pickupScheduleDateRelay }}
                </div>
              </div>
              <DatePicker
                v-else
                :value="item.pickupScheduleDateRelay"
                :rules="[
                  rules.inputRequired,
                  rules.yyyymmdd_create,
                  checkPickupScheduleDate(item, item.No == routeDetail.length),
                ]"
                @input="(newValue) => (item.pickupScheduleDateRelay = newValue)"
                :clearable="false"
                :separator="'/'"
                :disabled="isDisabledList"
              />
            </template>

            <!-- 出発時間 -->
            <template v-if="routeDetail != null" v-slot:[`item.pickupScheduleTimeRelay`]="{ item }">
              <div v-if="item.No == '1'">
                <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                  {{ item.pickupScheduleTimeRelayFrom }} ~ {{ item.pickupScheduleTimeRelayTo }}
                </div>
                <div v-else>
                  {{ item.pickupScheduleTimeRelayFrom }} ~ {{ item.pickupScheduleTimeRelayTo }}
                </div>
              </div>
              <v-row v-else>
                <v-col cols="5">
                  <v-text-field
                    v-model="item.pickupScheduleTimeRelayFrom"
                    type="time"
                    clearable
                    :disabled="isDisabledList"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center" cols="2">~</v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="item.pickupScheduleTimeRelayTo"
                    type="time"
                    clearable
                    :rules="[
                      checkTime(
                        item.pickupScheduleDateRelay,
                        item.pickupScheduleTimeRelayFrom,
                        item.pickupScheduleDateRelay,
                        item.pickupScheduleTimeRelayTo,
                        'check.chk_inputFromDate',
                        []
                      ),
                    ]"
                    :disabled="isDisabledList"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            <!-- 到着日 -->
            <template
              v-if="routeDetail != null"
              v-slot:[`item.deliveryScheduleDateRelay`]="{ item }"
            >
              <div v-if="item.No == routeDetail.length">
                <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                  {{ item.deliveryScheduleDateRelay }}
                </div>
                <div v-else>
                  {{ item.deliveryScheduleDateRelay }}
                </div>
              </div>
              <DatePicker
                v-else
                ref="deliveryScheduleDateRelay"
                :value="item.deliveryScheduleDateRelay"
                @input="(newValue) => (item.deliveryScheduleDateRelay = newValue)"
                :clearable="false"
                :separator="'/'"
                :rules="[
                  rules.inputRequired,
                  rules.yyyymmdd_create,
                  checkDeliveryScheduleDate(item),
                ]"
                :disabled="isDisabledList"
              />
            </template>

            <!-- 到着時間 -->
            <template
              v-if="routeDetail != null"
              v-slot:[`item.deliveryScheduleTimeRelay`]="{ item }"
            >
              <div v-if="item.No == routeDetail.length">
                <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                  {{ item.deliveryScheduleTimeRelayFrom }} ~ {{ item.deliveryScheduleTimeRelayTo }}
                </div>
                <div v-else>
                  {{ item.deliveryScheduleTimeRelayFrom }} ~ {{ item.deliveryScheduleTimeRelayTo }}
                </div>
              </div>
              <v-row v-else>
                <v-col cols="5">
                  <v-text-field
                    ref="deliveryScheduleTimeRelayFrom"
                    v-model="item.deliveryScheduleTimeRelayFrom"
                    type="time"
                    clearable
                    :rules="[
                      checkTime(
                        item.pickupScheduleDateRelay,
                        item.pickupScheduleTimeRelayFrom,
                        item.deliveryScheduleDateRelay,
                        item.deliveryScheduleTimeRelayFrom,
                        'check.chk_timeLaterThan',
                        [$t('label.lbl_pickupScheduleTimeRelay')]
                      ),
                    ]"
                    :disabled="isDisabledList"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center" cols="2">~</v-col>
                <v-col cols="5">
                  <v-text-field
                    ref="deliveryScheduleTimeRelayTo"
                    v-model="item.deliveryScheduleTimeRelayTo"
                    type="time"
                    clearable
                    :rules="[
                      checkTime(
                        item.deliveryScheduleDateRelay,
                        item.deliveryScheduleTimeRelayFrom,
                        item.deliveryScheduleDateRelay,
                        item.deliveryScheduleTimeRelayTo,
                        'check.chk_inputFromDate',
                        []
                      ),
                      checkTime(
                        item.pickupScheduleDateRelay,
                        item.pickupScheduleTimeRelayFrom,
                        item.deliveryScheduleDateRelay,
                        item.deliveryScheduleTimeRelayTo,
                        'check.chk_timeLaterThan',
                        [$t('label.lbl_pickupScheduleTimeRelay')]
                      ),
                    ]"
                    :disabled="isDisabledList"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            <!-- 元請営業所 -->
            <template v-if="routeDetail != null" v-slot:[`item.orderedOfficeName`]="{ item }">
              <div v-if="isDisabledList" style="color: rgba(8, 8, 8, 0.38)">
                {{ item.orderedOfficeName }}
              </div>
              <div v-else>
                {{ item.orderedOfficeName }}
              </div>
            </template>

            <!-- 削除 -->
            <template v-if="routeDetail != null" v-slot:[`item.delete`]="{ item }">
              <v-icon
                v-if="
                  (routeDetail.length > 2 && item.No > 1 && item.No < routeDetail.length) ||
                  (routeDetail.length <= 2 && item.No > 1)
                "
                @click="deleteLine(index, item)"
                large
                :disabled="isDisabledList"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
          <TrunkLineAddDialog
            :dialogTitle="'幹線ルート設定'"
            :isShow.sync="showTrunkLineAddDialog"
            :lineInfo.sync="lineInfo"
            :dialogTrunkBase="dialogTrunkBase"
            @setTrunkInfo="setTrunkLine"
            @cancelTransportCompChange="revertTransportComp"
          >
          </TrunkLineAddDialog>
          <!-- 参考距離・時間ダイアログ -->
          <v-dialog v-model="isShowDistanceAndTimeDialog" :max-width="500" persistent scrollable>
            <v-card>
              <v-card-title id="sub-bar">
                <span id="lbl-screen-name">{{ $t("label.lbl_distanceAndTime") }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- 発地 -->
                    <v-col>
                      <v-text-field
                        dense
                        v-model="dialogStartBase"
                        :label="$t('label.lbl_pickupPointRelay')"
                        class="txt-single"
                        persistent-hint
                        style="margin: 30px"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <!-- 着地 -->
                    <v-col>
                      <v-text-field
                        dense
                        v-model="dialogEndBase"
                        :label="$t('label.lbl_deliveryPointRelay')"
                        class="txt-single"
                        persistent-hint
                        style="margin: 30px"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid>
                  <v-data-table
                    id="distanceAndTimeDialog"
                    :fixed-header="true"
                    disable-filtering
                    disable-sort
                    :hide-default-footer="true"
                    :headers="distanceAndTimeDialogHeader"
                    :items="distanceAndTimeDialog"
                    disable-pagination
                    height="100px"
                    class="overflow-hidden"
                  >
                  </v-data-table>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- 閉じるボタン -->
                  <v-btn class="other-btn" @click="closeShowDistanceAndTimeDialog">
                    {{ $t("btn.btn_close") }}
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div class="btn-area">
          <!-- 「一覧へ戻る」ボタン -->
          <v-btn color="primary" class="api-btn" v-on:click="returnToTransportList">
            {{ $t("btn.btn_returnToTransportList") }}
          </v-btn>
        </div>
      </v-container>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="showConfirmDialog"
      :title="confirmDialogOption.title"
      :message="confirmDialogOption.message"
      :redMessage="confirmDialogOption.redMessage"
      :okAction="confirmDialogOption.okAction"
      :screenFlag="confirmDialogOption.screenFlag"
      :changeFlag="confirmDialogOption.changeFlag"
      :cancelAction="confirmDialogOption.cancelAction"
      :okBtnTxt="confirmDialogOption.okBtnTxt"
      :cancelBtnTxt="confirmDialogOption.cancelBtnTxt"
      :cancelBtnFlg="confirmDialogOption.cancelBtnFlg"
      :okBtnColor="confirmDialogOption.okBtnColor"
      :isAlert="confirmDialogOption.isAlert"
      :outsideClickNotCloseFlg="confirmDialogOption.outsideClickNotCloseFlg"
      :checkBox="confirmDialogOption.checkBox"
      :checkBoxTxt="confirmDialogOption.checkBoxTxt"
      :check="confirmDialogOption.check"
      @check="setDefault"
    />
    <SimpleDialog
      :isShow.sync="infoDialog.isShow"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="true"
      :closeFlag="false"
    />
    <RegisterSectionDialog :isShow.sync="showRegisterSectionDialog" :screenId="screenId" />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
// import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import TrunkLineAddDialog from "./TrunkLineAddDialog.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import DatePicker from "../../components/DatePicker.vue";
import RegisterSectionDialog from "./RegisterSectionDialog.vue";

export default {
  name: appConfig.MENU_ID.P_TOD_ROUTE,
  components: {
    Loading,
    SimpleDialog,
    NavBar,
    TrunkLineAddDialog,
    ConfirmDialog,
    DatePicker,
    RegisterSectionDialog,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    dateTimeHelper: dateTimeHelper,
    transportCompDiv: appConfig.CARRIERCOMPDIV,
    showTrunkLineAddDialog: false,
    showConfirmDialog: false,
    showRegisterSectionDialog: false,
    screenId: appConfig.SCREEN_ID.P_TOD_006,
    confirmDialogDefaultOption: {
      title: i18n.tc("btn.btn_confirm"),
      message: "",
      redMessage: "",
      okAction: () => {},
      screenFlag: false,
      changeFlag: false,
      cancelAction: () => {},
      okBtnTxt: i18n.tc("btn.btn_ok_en"),
      cancelBtnTxt: i18n.tc("btn.btn_cancel"),
      cancelBtnFlg: true,
      okBtnColor: "",
      isAlert: false,
      outsideClickNotCloseFlg: false,
    },
    modifiedConfirmDialogOption: {},
    infoDialog: {
      isShow: false,
      message: "",
      title: "",
    },
    lineInfo: {
      index: "",
      item: null,
    },
    dialogTrunkBase: {
      index: "",
      fromBase: null,
      toBase: null,
    },
    previousTransportComp: null,
    loadingCounter: 0,
    page: 1,
    orderListHeaderItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "no",
        width: "1%",
        align: "center",
        sortable: false,
      },
      // 受注日
      {
        text: i18n.tc("label.lbl_orderDate"),
        value: "orderDate",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 元請営業所
      {
        text: i18n.tc("label.lbl_orderedOffice"),
        value: "officeNameOrg",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplier",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 貨物受注SID
      {
        text: i18n.tc("label.lbl_freightOrderSid"),
        value: "freightOrderNumber",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // エリア
      {
        text: i18n.tc("label.lbl_area"),
        value: "area",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 出荷元
      {
        text: i18n.tc("label.lbl_startBase"),
        value: "pickupPoint",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // お届け先
      {
        text: i18n.tc("label.lbl_endBase"),
        value: "deliveryPoint",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 集荷予定日
      {
        text: i18n.tc("label.lbl_pickupScheduleDate"),
        value: "pickupScheduleDate",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // （集荷予定）時間
      {
        text: i18n.tc("label.lbl_scheduleTime"),
        value: "pickupScheduleTime",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 到着日
      {
        text: i18n.tc("label.lbl_deliveryScheduleDate"),
        value: "deliveryScheduleDate",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // （到着予定）時間
      {
        text: i18n.tc("label.lbl_scheduleTime"),
        value: "deliveryScheduleTime",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 代表品名
      {
        text: i18n.tc("label.lbl_typicalItemName"),
        value: "representativeProductsName",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 小口数
      {
        text: i18n.tc("label.lbl_smallLotsQuantity"),
        value: "smallLotsQuantity",
        width: "auto",
        align: "center",
        sortable: false,
      },
      // 重量計(kg)
      {
        text: i18n.tc("label.lbl_totalWeightKg"),
        value: "weight",
        width: "auto",
        align: "center",
        sortable: false,
      },
    ],
    routeDetailHeaderItems: [
      // 行追加
      {
        text: i18n.tc("btn.btn_add"),
        value: "add",
        width: "68px",
        align: "center",
        sortable: false,
      },
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "No",
        width: "55px",
        align: "center",
        sortable: false,
      },
      // 担当営業所
      {
        text: i18n.tc("label.lbl_relayOffice"),
        value: "relayOffice",
        width: "191px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 輸送手段
      {
        text: i18n.tc("label.lbl_transportComp"),
        value: "transportComp",
        width: "191px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 発地
      {
        text: i18n.tc("label.lbl_pickupPointRelay"),
        value: "pickupPointRelay",
        width: "191px",
        align: "center",
        sortable: false,
      },
      // 着地
      {
        text: i18n.tc("label.lbl_deliveryPointRelay"),
        value: "deliveryPointRelay",
        width: "191px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 参考距離・時間（メモ：在庫一覧照会を参考にする）
      {
        text: i18n.tc("label.lbl_distanceAndTime"),
        value: "distanceAndTime",
        width: "215px",
        align: "center",
        sortable: false,
      },
      // 出発日
      {
        text: i18n.tc("label.lbl_pickupScheduleDateRelay"),
        value: "pickupScheduleDateRelay",
        width: "250px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 出発時間
      {
        text: i18n.tc("label.lbl_pickupScheduleTimeRelay"),
        value: "pickupScheduleTimeRelay",
        width: "287px",
        align: "center",
        sortable: false,
      },
      // 到着日
      {
        text: i18n.tc("label.lbl_deliveryScheduleDateRelay"),
        value: "deliveryScheduleDateRelay",
        width: "250px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      // 到着時間
      {
        text: i18n.tc("label.lbl_deliveryScheduleTimeRelay"),
        value: "deliveryScheduleTimeRelay",
        width: "287px",
        align: "center",
        sortable: false,
      },
      // 元請営業所
      {
        text: i18n.tc("label.lbl_orderedOffice"),
        value: "orderedOfficeName",
        width: "191px",
        align: "center",
        sortable: false,
      },
      // 行削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "68px",
        align: "center",
        sortable: false,
      },
    ],
    // 受注（大指示）一覧
    transportOrderList: [],
    // 画面フラグ
    screenStateFlg: 0,
    // 区間リスト
    sectionList: [],
    // ルート履歴リスト
    routeRecordList: [],
    // ルート詳細リスト
    routeDetailList: [],
    // 担当営業所リスト
    officeList: [],
    // 輸送手段リスト
    transportCompDefaultList: [],
    // 着地リスト
    deliveryPointList: {},
    // 参考距離・時間モーダル表示フラグ
    isShowDistanceAndTimeDialog: false,
    // モーダル内発地
    dialogStartBase: "",
    // モーダル内着地
    dialogEndBase: "",
    // 参考距離・時間
    distanceAndTimeDialog: [],
    distanceAndTimeDialogHeader: [
      // 参考距離
      {
        text: i18n.tc("label.lbl_transDistance"),
        value: "dialogDistance",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 参考時間
      {
        text: i18n.tc("label.lbl_requiredTime"),
        value: "dialogTime",
        width: "20%",
        align: "left",
        sortable: false,
      },
    ],
    // 前行の到着時間(From)
    preDeliveryDatetimeFrom: null,
    // デフォルトフラグ
    defaultFlg: false,
    // エラーメッセージ
    relayOfficeAlertMessage: "",
    transportCompAlertMessage: "",
    deliveryPointAlertMessage: "",
    isDisabledLink: false,
    // ロットSIDがある又は配車情報がある場合ルート明細は編集できないようにするステータス
    isDisabledList: false,
    isRegister: false,
    // 貨物情報
    packingList: [],
    freightList: [],
    rules: {
      pickupDeliveryPointRelation: (value1, value2) => {
        if (value1 != value2) {
          return i18n.tc("check.chk_pickupDeliveryPointRelation");
        } else {
          return true;
        }
      },
    },
  }),

  computed: {
    confirmDialogOption() {
      return {
        ...this.confirmDialogDefaultOption,
        ...this.modifiedConfirmDialogOption,
      };
    },
  },

  methods: {
    /**
     * 初期値
     */
    async init() {
      // 受注一覧取得処理
      this.transportOrderList = [];
      await this.getFreightOrderList(); // 本番用
      // this.getFreightOrderListTest(); // テスト用

      for (const order of this.transportOrderList ?? []) {
        if (order.deliveryStatusDiv === "配車済" || order.lotSid != null) {
          this.isDisabledList = true;
        }
      }
      if (!this.isDisabledList) {
        if (this.$route.params.routeDefinedStatus == "1") {
          // ルート登録済みの場合
          this.isRegister = true;
        }
      }
      // ルートマスタ表示処理
      this.routeDetailList = [];
      this.setRouteMstToDetail();

      // 営業所リスト取得処理
      this.officeList = [];
      this.getOfficeList(); // 本番用
      // this.getOfficeListTest(); // テスト用

      // 輸送手段リスト取得処理
      this.transportCompDefaultList = [];
      this.getTransportCompList(); // 本番用
      // this.getTransportCompListTest(); // テスト用
    },

    /**
     * 受注一覧取得処理
     */
    getFreightOrderList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.transportInfoList = this.$route.params.transportInfoList.map(
        (e) => e.freightOrderSid
      );
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_TRANSPORT_ORDERS_FOR_ROUTE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // 区間リストを取得
              this.transportOrderList = jsonData.resIdv.transportOrderList;
              this.packingList = jsonData.resIdv.packingList;
              this.freightList = jsonData.resIdv.freightList;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 受注一覧取得処理
     */
    getFreightOrderListTest() {
      this.transportOrderList = [
        {
          no: 1,
          orderDate: "2024/11/01 15:00:00.000",
          officeSidOrg: "S000001631",
          officeNameOrg: "日高営業所",
          supplierCd: "",
          supplier: "ダイハツ",
          freightOrderNumber: "2400000001",
          area: "",
          pickupBaseSid: "2410000016",
          pickupPoint: "智光山倉庫",
          deliveryBaseSid: "2410000017",
          deliveryPoint: "販社1",
          pickupScheduleDate: "2024/11/03 15:00:00.000",
          pickupScheduleTime: "09:00 - 10:00",
          deliveryScheduleDate: "2024/11/04 15:00:00.000",
          deliveryScheduleTime: "19:00 - 20:00",
          representativeProductsName: "ｺﾍﾟﾝ",
          smallLotsQuantity: "4",
          weight: "",
          deliveryStatusDiv: "未配車",
          //deliveryStatusDiv: "配車済",
          lotSid: null, //25000000000000000580
        },
        {
          no: 2,
          orderDate: "2024/11/01 15:00:00.000",
          officeSidOrg: "S000001631",
          officeNameOrg: "日高営業所",
          supplierCd: "",
          supplier: "ダイハツ",
          freightOrderNumber: "2400000002",
          area: "",
          pickupBaseSid: "2410000016",
          pickupPoint: "智光山倉庫",
          deliveryBaseSid: "2410000017",
          deliveryPoint: "販社1",
          pickupScheduleDate: "2024/11/03 15:00:00.000",
          pickupScheduleTime: "09:00 - 10:00",
          deliveryScheduleDate: "2024/11/04 15:00:00.000",
          deliveryScheduleTime: "19:00 - 20:00",
          representativeProductsName: "ﾀﾝﾄ",
          smallLotsQuantity: "1",
          weight: "",
          deliveryStatusDiv: "未配車",
          //deliveryStatusDiv: "配車済",
          lotSid: null, //25000000000000000580
        },
      ];

      this.freightList = [
        {
          invNumSid: "25000000000000002536",
          dataNum: 1,
          freightLastUpdateDateTime: "2025/03/24 5:00:30.308",
        },
        // {
        //   invNumSid: "25000000000000002541",
        //   dataNum: 1,
        //   freightLastUpdateDateTime: null,
        // },
      ];

      this.packingList = [
        {
          packingSid: "25000000000000035384",
          packingLastUpdateDateTime: "2025/03/24 5:00:30.308",
        },
        // {
        //   packingSid: "25000000000000035397",
        //   packingLastUpdateDateTime: null,
        // },
        // {
        //   packingSid: "25000000000000035398",
        //   packingLastUpdateDateTime: null,
        // },
      ];
    },

    /**
     * ルートマスタ表示処理
     */
    async setRouteMstToDetail() {
      // マスタは必ず1件であるためページングを初期化
      this.page = 1;
      // ルート詳細リストを初期化
      this.routeDetailList = [];
      // 区間リストの取得
      await this.getRouteMst(this.transportOrderList[0]); // 本番用
      // this.getRouteMstTest(this.transportOrderList[0]); // テスト用
      // 区間リストからルート詳細を作成
      const routeDetail = await this.setRouteDetail(this.sectionList);
      // ルート詳細リストに作成したルート詳細を追加
      this.routeDetailList.push(routeDetail);
      // 画面フラグを0（＝マスタ表示状態）に変更
      this.screenStateFlg = 0;
      // バリデーションメッセージを表示できるようにする
      this.showValidateMessages();
    },

    /**
     * ルートマスタ参照API実行処理
     */
    getRouteMst(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.freightOrderSid = this.transportOrderList[0].freightOrderNumber;
      config.params.startBaseSid = item.pickupBaseSid;
      config.params.endBaseSid = item.deliveryBaseSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_ROUTE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // 区間リストを取得
              this.sectionList = jsonData.resIdv.sectionList;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ルートマスタ参照API実行処理（テスト用）
     */
    getRouteMstTest(item) {
      const startBaseSid = item.pickupBaseSid;
      const endBaseSid = item.deliveryBaseSid;
      console.log(startBaseSid);
      console.log(endBaseSid);
      // マスタありの表示テスト用
      this.sectionList = [
        {
          routeOrder: 1,
          officeSid: null,
          transportCompDiv: null,
          pickupScheduleDate: null,
          pickupScheduleTime: null,
          deliveryScheduleDate: null,
          deliveryScheduleTime: null,
          sectionSid: "24100000162410000002",
          fromPointSid: "2410000016",
          fromPointName: "智光山倉庫",
          toPointSid: "2410000002",
          toPointName: "柏原営業所",
          pickupDeliveryTrunkFlg: "0",
        },
        {
          routeOrder: 2,
          officeSid: null,
          transportCompDiv: null,
          pickupScheduleDate: null,
          pickupScheduleTime: null,
          deliveryScheduleDate: null,
          deliveryScheduleTime: null,
          sectionSid: "24100000022410000006",
          fromPointSid: "2410000002",
          fromPointName: "柏原営業所",
          toPointSid: "2410000006",
          toPointName: "焼津IC",
          pickupDeliveryTrunkFlg: "1",
        },
        {
          routeOrder: 3,
          officeSid: null,
          transportCompDiv: null,
          pickupScheduleDate: null,
          pickupScheduleTime: null,
          deliveryScheduleDate: null,
          deliveryScheduleTime: null,
          sectionSid: "24100000062410000010",
          fromPointSid: "2410000006",
          fromPointName: "焼津IC",
          toPointSid: "2410000010",
          toPointName: "浜松営業所",
          pickupDeliveryTrunkFlg: "1",
        },
        {
          routeOrder: 4,
          officeSid: null,
          transportCompDiv: null,
          pickupScheduleDate: null,
          pickupScheduleTime: null,
          deliveryScheduleDate: null,
          deliveryScheduleTime: null,
          sectionSid: "24100000102410000017",
          fromPointSid: "2410000010",
          fromPointName: "浜松営業所",
          toPointSid: "2410000017",
          toPointName: "販社1",
          pickupDeliveryTrunkFlg: "0",
        },
      ];
      // 登録済ルート表示テスト用
      // this.sectionList = [
      //   {
      //     routeOrder: 1,
      //     officeSid: "S000001631",
      //     transportCompDiv: "03",
      //     pickupScheduleDate: "2025/02/10 15:00:00.000",
      //     pickupScheduleTime: null,
      //     deliveryScheduleDate: "2025/02/11 15:00:00.000",
      //     deliveryScheduleTime: null,
      //     sectionSid: "24100000162410000017",
      //     fromPointSid: "2410000016",
      //     fromPointName: "智光山倉庫",
      //     toPointSid: "2410000017",
      //     toPointName: "販社1",
      //     pickupDeliveryTrunkFlg: "0",
      //   },
      // ];

      // マスタなしの表示テスト用
      // return [];
    },

    /**
     * ルート履歴表示処理
     */
    async setRouteRecordToDetail() {
      // ルート詳細リストを初期化
      this.routeDetailList = [];
      // ルート履歴を取得
      await this.getRouteRecord(this.transportOrderList[0]); // 本番用
      // this.getRouteRecordTest(this.transportOrderList[0]); // テスト用
      if (this.routeRecordList.length != 0) {
        // 取得したルート履歴の件数分処理を行う
        for (var i = 0; i < this.routeRecordList.length; i++) {
          // ルート履歴の区間リストからルート詳細を作成
          const routeDetailFromRecord = await this.setRouteDetail(
            this.routeRecordList[i].sectionList
          );
          // ルート詳細リストに作成したルート詳細を追加
          this.routeDetailList.push(routeDetailFromRecord);
          // バリデーションメッセージを表示できるようにする
          this.showValidateMessages();
        }
        // 画面フラグを1（＝履歴表示状態）に変更
        this.screenStateFlg = 1;
      } else {
        // 新規ルート作成確認モーダルを表示
        this.modifiedConfirmDialogOption = {
          message: messsageUtil.getMessage("P-TOD-006_003_C"),
          title: appConfig.DIALOG.confirm,
          screenFlag: true,
          cancelBtnFlg: true,
          okAction: this.makeNewRoute,
          cancelAction: this.setRouteMstToDetail,
        };
        this.showConfirmDialog = true;
      }
    },

    /**
     * ルート履歴参照API実行処理
     */
    getRouteRecord(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.startBaseSid = item.pickupBaseSid;
      config.params.endBaseSid = item.deliveryBaseSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_ROUTE_RECORD_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // 区間リストを取得
              const routeRecordList = jsonData.resIdv.routeRecordList;
              if (routeRecordList.length != 0) {
                // デフォルトのルートを先頭に持ってくる
                const defaultRoute = routeRecordList
                  .sort((e1, e2) => e1.defaultFlg - e2.defaultFlg)
                  .pop();
                routeRecordList.splice(0, 0, defaultRoute);
              }
              this.routeRecordList = routeRecordList;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ルート履歴参照API実行処理（テスト用）
     */
    getRouteRecordTest(item) {
      console.log(item);
      // const routeRecordList = [
      //   {
      //     routeRecordSid: "2000000000",
      //     defaultFlg: "0",
      //     sectionList: [
      //       {
      //         routeOrder: 1,
      //         sectionSid: "24100000162410000018",
      //         fromPointSid: "2410000016",
      //         fromPointName: "智光山倉庫",
      //         toPointSid: "2410000018",
      //         toPointName: "甲府営業所",
      //         pickupDeliveryTrunkFlg: "0",
      //       },
      //       {
      //         routeOrder: 2,
      //         sectionSid: "24100000182410000010",
      //         fromPointSid: "2410000018",
      //         fromPointName: "甲府営業所",
      //         toPointSid: "2410000010",
      //         toPointName: "浜松営業所",
      //         pickupDeliveryTrunkFlg: "0",
      //       },
      //       {
      //         routeOrder: 3,
      //         sectionSid: "24100000102410000017",
      //         fromPointSid: "2410000010",
      //         fromPointName: "浜松営業所",
      //         toPointSid: "2410000017",
      //         toPointName: "販社1",
      //         pickupDeliveryTrunkFlg: "0",
      //       },
      //     ],
      //   },
      //   {
      //     routeRecordSid: "2000000001",
      //     defaultFlg: "1",
      //     sectionList: [
      //       {
      //         routeOrder: 1,
      //         sectionSid: "24100000162410000018",
      //         fromPointSid: "2410000016",
      //         fromPointName: "智光山倉庫",
      //         toPointSid: "2410000018",
      //         toPointName: "甲府営業所",
      //         pickupDeliveryTrunkFlg: "0",
      //       },
      //       {
      //         routeOrder: 2,
      //         sectionSid: "24100000182410000017",
      //         fromPointSid: "2410000018",
      //         fromPointName: "甲府営業所",
      //         toPointSid: "2410000017",
      //         toPointName: "販社1",
      //         pickupDeliveryTrunkFlg: "0",
      //       },
      //     ],
      //   },
      //   {
      //     routeRecordSid: "2000000002",
      //     defaultFlg: "0",
      //     sectionList: [
      //       {
      //         routeOrder: 1,
      //         sectionSid: "24100000162410000017",
      //         fromPointSid: "2410000016",
      //         fromPointName: "智光山倉庫",
      //         toPointSid: "2410000017",
      //         toPointName: "販社1",
      //         pickupDeliveryTrunkFlg: "0",
      //       },
      //     ],
      //   },
      // ];
      // // デフォルトのルートを先頭に持ってくる
      // const defaultRoute = routeRecordList.sort((e1, e2) => e1.defaultFlg - e2.defaultFlg).pop();
      // routeRecordList.splice(0, 0, defaultRoute);
      const routeRecordList = [];
      this.routeRecordList = routeRecordList;
    },

    /**
     * 新規ルート作成処理
     */
    async makeNewRoute() {
      const sectionList = [];
      const sectionInfo = {
        routeOrder: 1,
        fromPointSid: this.transportOrderList[0].pickupBaseSid,
        fromPointName: this.transportOrderList[0].pickupPoint,
        toPointSid: this.transportOrderList[0].deliveryBaseSid,
        toPointName: this.transportOrderList[0].deliveryPoint,
        pickupDeliveryTrunkFlg: "0",
      };
      sectionList.push(sectionInfo);
      // 区間リストからルート詳細を作成
      const routeDetail = await this.setRouteDetail(sectionList);
      // ルート詳細リストに作成したルート詳細を追加
      this.routeDetailList.push(routeDetail);
      // バリデーションメッセージを表示できるようにする
      this.showValidateMessages();
      // 画面フラグを2（＝新規ルート表示状態）に変更
      this.screenStateFlg = 2;
    },

    /**
     * ルート詳細設定処理
     */
    async setRouteDetail(sectionList) {
      const routeDetail = [];
      if (sectionList.length != 0) {
        for (var i = 0; i < sectionList.length; i++) {
          await this.getDeliveryPointList(i + 1, sectionList[i].fromPointSid);
          //this.getDeliveryPointListTest(i + 1, sectionList[i].fromPointSid);
          // 区間情報
          const sectionInfo = {
            No: sectionList[i].routeOrder,
            relayOffice: sectionList[i].officeSid ?? "",
            transportComp:
              sectionList[i].transportCompDiv ??
              (sectionList[i].pickupDeliveryTrunkFlg == "1" ? appConfig.CARRIERCOMPDIV.TRUNK : ""),
            pickupPointRelaySid: sectionList[i].fromPointSid,
            pickupPointRelayName: sectionList[i].fromPointName,
            deliveryPointRelaySid: sectionList[i].toPointSid,
            deliveryPointRelayName: sectionList[i].toPointName,
            distanceAndTime: i18n.tc("label.lbl_distanceAndTime"),
            pickupScheduleDateRelay:
              sectionList[i].pickupScheduleDate != null
                ? dateTimeHelper.convertUTC2JST(sectionList[i].pickupScheduleDate).substring(0, 10)
                : i == 0
                ? dateTimeHelper
                    .convertUTC2JST(this.transportOrderList[0].pickupScheduleDate)
                    .substring(0, 10)
                : null,
            pickupScheduleTimeRelay:
              sectionList[i].pickupScheduleTime ??
              (i == 0 ? this.transportOrderList[0].pickupScheduleTime : null),
            pickupScheduleTimeRelayFrom:
              sectionList[i].pickupScheduleTime != null
                ? sectionList[i].pickupScheduleTime.substring(0, 5)
                : i == 0
                ? this.transportOrderList[0].pickupScheduleTime.substring(0, 5)
                : null,
            pickupScheduleTimeRelayTo:
              sectionList[i].pickupScheduleTime != null
                ? sectionList[i].pickupScheduleTime.substring(8)
                : i == 0
                ? this.transportOrderList[0].pickupScheduleTime.substring(8)
                : null,
            deliveryScheduleDateRelay:
              sectionList[i].deliveryScheduleDate != null
                ? dateTimeHelper
                    .convertUTC2JST(sectionList[i].deliveryScheduleDate)
                    .substring(0, 10)
                : i == sectionList.length - 1
                ? dateTimeHelper
                    .convertUTC2JST(this.transportOrderList[0].deliveryScheduleDate)
                    .substring(0, 10)
                : null,
            deliveryScheduleTimeRelay:
              sectionList[i].deliveryScheduleTime ??
              (i == sectionList.length - 1
                ? this.transportOrderList[0].deliveryScheduleTime
                : null),
            deliveryScheduleTimeRelayFrom:
              sectionList[i].deliveryScheduleTime != null
                ? sectionList[i].deliveryScheduleTime.substring(0, 5)
                : i == sectionList.length - 1
                ? this.transportOrderList[0].deliveryScheduleTime.substring(0, 5)
                : null,
            deliveryScheduleTimeRelayTo:
              sectionList[i].deliveryScheduleTime != null
                ? sectionList[i].deliveryScheduleTime.substring(8)
                : i == sectionList.length - 1
                ? this.transportOrderList[0].deliveryScheduleTime.substring(8)
                : null,
            orderedOfficeName: this.transportOrderList[0].officeNameOrg,
          };
          // 区間情報をルート詳細に追加
          routeDetail.push(sectionInfo);
        }
      }
      return routeDetail;
    },

    /**
     * 担当営業所リスト取得
     */
    getOfficeList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 有効期限区分（0=有効期限内データを取得）
      config.params.validDateKbn = "0";
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_OFFICE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.officeInfoList.forEach((row) => {
                list.push({
                  text: row.officeName ?? "",
                  value: row.officeSid ?? "",
                  name: row.officeName ?? "",
                });
              });
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 担当営業所リスト取得テスト用
     */
    getOfficeListTest() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const officeListTmp = [
        {
          text: "日高営業所",
          value: "S000001631",
          name: "日高営業所",
        },
        {
          text: "柏原営業所",
          value: "2410000002",
          name: "柏原営業所",
        },
        {
          text: "狭山営業所",
          value: "2410000015",
          name: "狭山営業所",
        },
        {
          text: "浜松営業所",
          value: "2410000010",
          name: "浜松営業所",
        },
      ];
      this.officeList = officeListTmp;
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 輸送手段リスト取得
     */
    getTransportCompList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 計画区分値取得
      getParameter
        .getTmsCodeMst({
          codeType: appConfig.CODETYPE.TRANSPORT_COMP_DIV,
        })
        .then((transportCompList) => {
          // 画面の初期値を設定します。
          this.transportCompDefaultList = transportCompList;

          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 輸送手段リスト取得テスト用
     */
    getTransportCompListTest() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const transportCompListTmp = [
        {
          text: "社有",
          value: appConfig.CARRIERCOMPDIV.OWNED,
          name: "社有",
        },
        {
          text: "グループ",
          value: appConfig.CARRIERCOMPDIV.GROUP,
          name: "グループ",
        },
        {
          text: "協力",
          value: appConfig.CARRIERCOMPDIV.COOP,
          name: "協力",
        },
        {
          text: "路線",
          value: appConfig.CARRIERCOMPDIV.DELIVERY,
          name: "路線",
        },
        {
          text: "幹線",
          value: appConfig.CARRIERCOMPDIV.TRUNK,
          name: "幹線",
        },
        {
          text: "フェリー",
          value: appConfig.CARRIERCOMPDIV.FERRY,
          name: "フェリー",
        },
        {
          text: "JR",
          value: appConfig.CARRIERCOMPDIV.JR,
          name: "JR",
        },
        {
          text: "航空",
          value: appConfig.CARRIERCOMPDIV.AIR,
          name: "航空",
        },
      ];

      this.transportCompDefaultList = transportCompListTmp;

      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 輸送手段リスト制御
     */
    updateTransportCompList() {
      // 1件でも幹線が設定されていた場合、輸送手段プルダウンの幹線を非活性にする
      if (
        this.routeDetailList[this.page - 1].some(
          (e) => e.transportComp == appConfig.CARRIERCOMPDIV.TRUNK
        )
      ) {
        return true;
      }
      return false;
    },

    /**
     * 輸送手段変更前の値を取得
     */
    getPreviousTransportComp(value) {
      this.previousTransportComp = value;
    },

    /**
     * 輸送手段の幹線への変更をキャンセルした場合の処理
     */
    revertTransportComp(index, item) {
      this.routeDetailList[index][item.No - 1].transportComp = this.previousTransportComp;
    },

    /**
     * 輸送手段変更時の処理
     */
    changeTransportComp(index, item, selectedValue) {
      if (selectedValue === appConfig.CARRIERCOMPDIV.TRUNK) {
        this.showTrunkLineAddDialog = true;
      }
      this.lineInfo.index = index;
      this.lineInfo.item = item;
      this.dialogTrunkBase.fromBase = item.pickupPointRelaySid;
      this.dialogTrunkBase.toBase = item.deliveryPointRelaySid;
    },

    /**
     * 輸送手段を幹線へ変更した場合の処理
     */
    setTrunkLine(fromTrunkBase, toTrunkBase, lineInfo, selectedRoute) {
      // 幹線情報を挿入する行の添字を取得
      const trunkStartIndex = this.routeDetailList[lineInfo.index].indexOf(lineInfo.item);

      const trunkInfoList = [];
      let currentTrunkInfo = null;
      let isTargetFlag = false;
      for (const currentBaseInfo of selectedRoute) {
        // 発地拠点のデータ取得
        if (currentBaseInfo.baseSid === fromTrunkBase) {
          currentTrunkInfo = {
            fromBaseSid: currentBaseInfo.baseSid,
            fromBaseName: currentBaseInfo.baseName,
            toBaseSid: currentBaseInfo.baseSid, // この時点では次の点までの情報がないので同じ
            toBaseName: currentBaseInfo.baseName, // これも同様
          };
          // 幹線ルートの拠点のうち、対象範囲になったため true
          isTargetFlag = true;
        }

        // 発地以前の拠点の場合スキップ
        if (!isTargetFlag) {
          continue;
        }

        // 拠点役割区分が積替拠点(04)、または最後の項目の場合、toBaseの値を更新する
        if (
          (currentBaseInfo.baseRoleDiv === "04" && currentBaseInfo.baseSid !== fromTrunkBase) ||
          currentBaseInfo.baseSid === toTrunkBase
        ) {
          currentTrunkInfo.toBaseSid = currentBaseInfo.baseSid;
          currentTrunkInfo.toBaseName = currentBaseInfo.baseName;
          trunkInfoList.push(currentTrunkInfo);

          // 着地拠点と一致した場合、これ以降対象範囲外になるので繰り返しを中断する
          if (currentBaseInfo.baseSid === toTrunkBase) {
            break;
          }

          // 拠点役割区分が積替拠点(04)の場合
          if (currentBaseInfo.baseRoleDiv === "04") {
            currentTrunkInfo = {
              fromBaseSid: currentBaseInfo.baseSid,
              fromBaseName: currentBaseInfo.baseName,
              toBaseSid: currentBaseInfo.baseSid, // この時点では次の点までの情報がないので同じ
              toBaseName: currentBaseInfo.baseName, // これも同様
            };
          }
        }
      }

      // 幹線ダイヤマスタ・幹線ダイヤ経由地マスタを検索
      // const trunkInfoList = [
      //   {
      //     fromBaseSid: "2410000003",
      //     fromBaseName: "鈴鹿センター",
      //     toBaseSid: "2410000004",
      //     toBaseName: "大津営業所",
      //   },
      //   {
      //     fromBaseSid: "2410000004",
      //     fromBaseName: "大津営業所",
      //     toBaseSid: "2410000005",
      //     toBaseName: "真岡営業所",
      //   },
      // ];

      let isChangedFlg = false;
      for (var i = 0; i < trunkInfoList.length; i++) {
        const newItem = {
          No: null,
          relayOffice: trunkInfoList[i].fromBaseSid,
          transportComp: appConfig.CARRIERCOMPDIV.TRUNK,
          pickupPointRelaySid: trunkInfoList[i].fromBaseSid,
          // 発地名を担当営業所の表示名に変更する
          pickupPointRelayName: this.officeList.find(
            (e) => e.value === trunkInfoList[i].fromBaseSid
          ).text,
          deliveryPointRelaySid: trunkInfoList[i].toBaseSid,
          // 着地名を担当営業所の表示名に変更する
          deliveryPointRelayName: this.officeList.find(
            (e) => e.value === trunkInfoList[i].toBaseSid
          ).text,
          distanceAndTime: i18n.tc("label.lbl_distanceAndTime"),
          pickupScheduleDateRelay: null,
          pickupScheduleTimeRelay: null,
          deliveryScheduleDateRelay: null,
          deliveryScheduleTimeRelay: null,
          orderedOfficeName: this.transportOrderList[0].officeNameOrg,
        };
        const newItem2 = {
          No: null,
          relayOffice: "",
          transportComp: "",
          pickupPointRelaySid: "",
          pickupPointRelayName: "",
          deliveryPointRelaySid: lineInfo.item.deliveryPointRelaySid,
          deliveryPointRelayName: lineInfo.item.deliveryPointRelayName,
          distanceAndTime: i18n.tc("label.lbl_distanceAndTime"),
          pickupScheduleDateRelay: null,
          pickupScheduleTimeRelay: null,
          deliveryScheduleDateRelay: lineInfo.item.deliveryScheduleDateRelay,
          deliveryScheduleTimeRelay: lineInfo.item.deliveryScheduleTimeRelay,
          orderedOfficeName: this.transportOrderList[0].officeNameOrg,
        };
        // 選択行が1行目であった場合、1行目の下に幹線行を追加していく
        if (trunkStartIndex == 0) {
          // 一回目のループの場合、着地点用の行を追加する
          if (i == 0) {
            this.routeDetailList[lineInfo.index].splice(trunkStartIndex + 1, 0, newItem2);
            // 選択行は幹線ではないため輸送手段を空白にする。また選択行の到着情報は追加行にコピーされているため、空白にする。
            lineInfo.item.transportComp = "";
            lineInfo.item.deliveryPointRelaySid = "";
            lineInfo.item.deliveryPointRelayName = "";
          }
          this.routeDetailList[lineInfo.index].splice(trunkStartIndex + i + 1, 0, newItem);
        } else {
          if (i == 0) {
            // お届け先のデータを消さないために最初の行を改行する
            this.routeDetailList[lineInfo.index].splice(trunkStartIndex + 1, 0, newItem2);
            if (
              lineInfo.item.pickupPointRelaySid == "" ||
              lineInfo.item.pickupPointRelaySid == fromTrunkBase
            ) {
              // 選択行の発地が空白、または選択した幹線の発地拠点の場合、置換する
              this.routeDetailList[lineInfo.index].splice(trunkStartIndex, 1, newItem);
              isChangedFlg = true;
              continue;
            }
          }
          // 幹線行の追加
          if (isChangedFlg) {
            // 置換した場合はその下に追加する
            this.routeDetailList[lineInfo.index].splice(trunkStartIndex + i, 0, newItem);
            lineInfo.item.transportComp = "";
          } else {
            this.routeDetailList[lineInfo.index].splice(trunkStartIndex + i + 1, 0, newItem);
            lineInfo.item.transportComp = "";
          }
        }
      }
      // 各行の整合化
      this.ensureLineConsistency(lineInfo.index);

      // バリデーションメッセージを表示できるようにする
      this.showValidateMessages();
    },

    /**
     * 着地リスト取得
     */
    getDeliveryPointList(index, fromPointSid) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 発地SID
      config.params.fromPointSid = fromPointSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_SECTION_TO_POINT_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.toPointList.forEach((row) => {
                list.push({
                  text: row.toPointName ?? "",
                  value: row.toPointSid ?? "",
                  name: row.toPointName ?? "",
                });
              });
              this.deliveryPointList[index] = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 着地リスト取得テスト用
     */
    getDeliveryPointListTest(index, item) {
      console.log(item);
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const deliveryPointListTmp = [
        {
          text: "日高営業所",
          value: "S000001631",
          name: "日高営業所",
        },
        {
          text: "柏原営業所",
          value: "2410000002",
          name: "柏原営業所",
        },
        {
          text: "焼津IC",
          value: "2410000006",
          name: "焼津IC",
        },
        {
          text: "浜松営業所",
          value: "2410000010",
          name: "浜松営業所",
        },
        {
          text: "狭山営業所",
          value: "2410000015",
          name: "狭山営業所",
        },
        {
          text: "智光山倉庫",
          value: "2410000016",
          name: "智光山倉庫",
        },
        {
          text: "販社1",
          value: "2410000017",
          name: "販社1",
        },
        {
          text: "甲府営業所",
          value: "2410000018",
          name: "甲府営業所",
        },
      ];
      this.deliveryPointList[index] = deliveryPointListTmp;
      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 着地プルダウン変更処理
     */
    changeDeliveryPointRelay(item, deliveryPointRelaySid) {
      // 変更対象行の着地にプルダウンで選択した項目の着地SIDおよび着地名をセットする
      item.deliveryPointRelaySid = deliveryPointRelaySid;
      if (deliveryPointRelaySid) {
        item.deliveryPointRelayName = this.deliveryPointList[item.No].find(
          (e) => e.value == deliveryPointRelaySid
        ).text;
        this.isDisabledLink = false;
      } else {
        item.deliveryPointRelayName = null;
        this.isDisabledLink = true;
      }
    },

    /**
     * 着地の変更を次行の発地に反映する
     */
    setPickupPoint(index, item) {
      if (item.No > 1) {
        // 2行目以降で前行の着地が入力されていた場合、その値を反映させる
        if (
          this.routeDetailList[index].find((e) => e.No == item.No - 1).deliveryPointRelaySid != null
        ) {
          if (item.transportComp != appConfig.CARRIERCOMPDIV.TRUNK) {
            item.pickupPointRelaySid = this.routeDetailList[index].find(
              (e) => e.No == item.No - 1
            ).deliveryPointRelaySid;
            item.pickupPointRelayName = this.routeDetailList[index].find(
              (e) => e.No == item.No - 1
            ).deliveryPointRelayName;
          }
        } else {
          if (item.transportComp != appConfig.CARRIERCOMPDIV.TRUNK) {
            item.pickupPointRelaySid = null;
            item.pickupPointRelayName = null;
          }
        }
      }
      return item.pickupPointRelayName;
    },

    /**
     * 参考距離・時間モーダル表示処理
     */
    showDistanceAndTime(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 参考距離時間APIを呼び出す
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.fromPointSid = item.pickupPointRelaySid;
      config.params.toPointSid = item.deliveryPointRelaySid;
      config.params.transportCompDiv = item.transportComp;

      // ========テスト用データ=========
      // const jsonData = {
      //   resCom: {
      //     resComPagination: {
      //       totalPage: 1,
      //       totalRecord: 1,
      //     },
      //     resComCode: "000",
      //     resComMessage: "Error",
      //   },
      //   resIdv: {
      //     fromPointSid: "2100000093",
      //     toPointSid: "2100000028",
      //     tranDistance: "20",
      //     requiredTime: "591.5",
      //     aquisitionDateTime: "2025/03/04 04:34:59.192",
      //   },
      // };
      // // 成功
      // if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
      //   // モーダルを表示するフラグをON
      //   this.isShowDistanceAndTimeDialog = true;
      //   this.distAndTime = jsonData.resIdv;

      //   // 参考距離・時間情報を初期化
      //   this.distanceAndTimeDialog = [];

      //   this.dialogStartBase = item.pickupPointRelayName;
      //   this.dialogEndBase = item.deliveryPointRelayName;
      //   const requiredTimeHr = Math.ceil(Number(this.distAndTime.requiredTime) / 60);
      //   const distanceAndTime = {
      //     dialogDistance: this.distAndTime.tranDistance,
      //     dialogTime: requiredTimeHr,
      //   };
      //   this.distanceAndTimeDialog.push(distanceAndTime);
      // } else {
      //   // エラー時
      //   this.infoDialog = {
      //     isShow: true,
      //     title: appConfig.DIALOG.title,
      //     message: jsonData.resCom.resComMessage,
      //   };
      // }
      // ========テスト用データ=========

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_REFERENCE_DISTANCE_TIME, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // モーダルを表示するフラグをON
              this.isShowDistanceAndTimeDialog = true;

              // 参考距離・時間情報を初期化
              this.distanceAndTimeDialog = [];
              this.dialogStartBase = item.pickupPointRelayName;
              this.dialogEndBase = item.deliveryPointRelayName;
              const requiredTimeHr = Math.ceil(Number(jsonData.resIdv.requiredTime) / 60);
              const distanceAndTime = {
                dialogDistance: jsonData.resIdv.tranDistance,
                dialogTime: requiredTimeHr,
              };
              this.distanceAndTimeDialog.push(distanceAndTime);
              resolve(response);
            } else {
              // エラー時
              this.infoDialog = {
                isShow: true,
                title: appConfig.DIALOG.title,
                message: jsonData.resCom.resComMessage,
              };
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 参考距離・時間モーダル：閉じるボタンを押下する
     */
    closeShowDistanceAndTimeDialog() {
      this.isShowDistanceAndTimeDialog = false;
    },

    /**
     * ルート詳細行追加処理
     */
    addLine(index, item) {
      const newItem = {
        No: null,
        relayOffice: "",
        transportComp: "",
        pickupPointRelaySid: "",
        pickupPointRelayName: "",
        deliveryPointRelaySid:
          item.transportComp != appConfig.CARRIERCOMPDIV.TRUNK ? item.deliveryPointRelaySid : "",
        deliveryPointRelayName:
          item.transportComp != appConfig.CARRIERCOMPDIV.TRUNK ? item.deliveryPointRelayName : "",
        distanceAndTime: i18n.tc("label.lbl_distanceAndTime"),
        pickupScheduleDateRelay: null,
        pickupScheduleTimeRelay: null,
        deliveryScheduleDateRelay: item.deliveryScheduleDateRelay,
        deliveryScheduleTimeRelay: item.deliveryScheduleTimeRelay,
        orderedOfficeName: this.transportOrderList[0].officeNameOrg,
      };
      // 選択行の一つ下に行追加する
      this.routeDetailList[index].splice(this.routeDetailList[index].indexOf(item) + 1, 0, newItem);
      // 選択行の到着情報は追加行にコピーされているため、空白にする
      if (item.transportComp != appConfig.CARRIERCOMPDIV.TRUNK) {
        // 選択行が幹線でない場合は着地のみ空白にする
        item.deliveryPointRelaySid = "";
        item.deliveryPointRelayName = "";
      }
      // 到着日・時間を空白にする
      item.deliveryScheduleDateRelay = null;
      item.deliveryScheduleTimeRelay = null;

      // 各行の整合化
      this.ensureLineConsistency(index);

      // バリデーションメッセージを表示できるようにする
      this.showValidateMessages();
      this.isDisabledLink = true;
    },

    /**
     * 入力時間の整合性チェック
     */
    checkTime(fromDate, fromTime, toDate, toTime, message, msgarg) {
      if (this.isEmpty(fromDate) && this.isEmpty(toDate)) {
        fromDate = toDate = "2000/01/01";
      }
      if (this.isEmpty(fromDate) || this.isEmpty(toDate)) {
        return true;
      }
      if (!this.isEmpty(fromTime) && !this.isEmpty(toTime)) {
        const date1 = new Date(`${fromDate} ${fromTime}`);
        const date2 = new Date(`${toDate} ${toTime}`);
        if (date1 >= date2) {
          const result = i18n.t(message, msgarg);
          return result;
        }
      }
      return true;
    },

    /**
     * ルート詳細行削除処理
     */
    deleteLine(index, item) {
      // 行削除
      if (item.transportComp == appConfig.CARRIERCOMPDIV.TRUNK) {
        const firstIndex = this.routeDetailList[index].findIndex(
          (e) => e.transportComp == appConfig.CARRIERCOMPDIV.TRUNK
        );
        const lastIndex = this.routeDetailList[index].findLastIndex(
          (e) => e.transportComp == appConfig.CARRIERCOMPDIV.TRUNK
        );
        for (var i = firstIndex; i <= lastIndex; i++) {
          this.routeDetailList[index].splice(firstIndex, 1);
        }
      } else {
        this.routeDetailList[index].splice(this.routeDetailList[index].indexOf(item), 1);
      }

      // ルート詳細が2行の状態で2行目を削除した場合
      if (this.routeDetailList[index].length == 1) {
        // 削除行の着地情報を残りの1行にコピー
        this.routeDetailList[index][0].deliveryPointRelaySid = item.deliveryPointRelaySid;
        this.routeDetailList[index][0].deliveryPointRelayName = item.deliveryPointRelayName;
        this.routeDetailList[index][0].deliveryScheduleDateRelay = item.deliveryScheduleDateRelay;
        this.routeDetailList[index][0].deliveryScheduleTimeRelay = item.deliveryScheduleTimeRelay;
        this.isDisabledLink = false;
      }

      // 各行の整合化
      this.ensureLineConsistency(index);

      // バリデーションメッセージを表示できるようにする
      this.showValidateMessages();
    },

    /**
     * 行追加削除後共通処理（各行の整合化）
     */
    ensureLineConsistency(index) {
      // 各行のNoを振りなおす
      for (var i = 0; i < this.routeDetailList[index].length; i++) {
        this.routeDetailList[index][i].No = i + 1;
      }
    },

    /**
     * 中指示登録API実行処理
     */
    routeRegister(params) {
      // パラメータ設定
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_006; // 画面ID
      body.reqIdv.freightOrderList = params.freightOrderList;
      body.reqIdv.relayRouteInfoList = params.relayRouteInfoList;
      // 接続先のAPI_URLを入力
      return this.$httpClient
        .securePost(appConfig.API_URL.BIZ_TRANSPORT_ORDER_ROUTE_UPSERT, body, appConfig.APP_CONFIG)
        .then(
          (response) => {
            const jsonData = response.data;
            // エラー時
            if (jsonData.resCom.resComCode !== "000") {
              const message =
                jsonData.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
          },
          (error) => {
            const message =
              error.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },

    /**
     * ルート履歴登録API実行処理
     */
    routeRecordRegister(params, defaultFlg) {
      // パラメータ設定
      const routeInfoList = [];
      for (const relayRouteInfo of params.relayRouteInfoList) {
        const routeInfo = {
          routeOrder: relayRouteInfo.transportOrderNo,
          fromPointSid: relayRouteInfo.pickupPointSid,
          toPointSid: relayRouteInfo.deliveryPointSid,
          pickupDeliveryTrunkFlg:
            relayRouteInfo.transportCompDiv == appConfig.CARRIERCOMPDIV.TRUNK ? "1" : "0",
        };
        routeInfoList.push(routeInfo);
      }

      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_006; // 画面ID
      body.reqIdv.routeInfoList = routeInfoList;
      body.reqIdv.freightOrderSidList = this.$route.params.transportInfoList.map(
        (e) => e.freightOrderSid
      );
      body.reqIdv.defaultFlg = defaultFlg == false ? "0" : "1";
      // 接続先のAPI_URLを入力
      return this.$httpClient
        .securePost(appConfig.API_URL.BIZ_ROUTE_RECORD_UPSERT, body, appConfig.APP_CONFIG)
        .then(
          (response) => {
            const jsonData = response.data;
            // エラー時
            if (jsonData.resCom.resComCode !== "000") {
              const message =
                jsonData.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
          },
          (error) => {
            const message =
              error.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        );
    },

    /**
     * ルート確定API実行確認
     */
    routeConfirm() {
      // 入力項目不足フラグ
      let contentIncompleteFlg = false;
      // 路線フラグ
      let deliveryFlg = false;
      // 発着日時不整合フラグ
      let pickupDeliveryDatetimeErrorFlg = false;
      // 前行着日時発日時不整合フラグ
      let preDeliveryPickupDatetimeErrorFlg = false;
      // 受注一覧設定
      const freightOrderList = [];
      for (const transportOrder of this.transportOrderList) {
        const freightOrder = {
          // 受注番号
          freightOrderSid: transportOrder.freightOrderNumber,
          // 元請営業所SID
          officeSidOrg: transportOrder.officeSidOrg,
        };
        freightOrderList.push(freightOrder);
      }

      // 受注中継リスト設定
      const relayRouteInfoList = [];
      let preDeliveryScheduleDate = null;
      let prePickupDatetimeFrom = null;
      let preDeliveryDatetimeFrom = null;
      for (const routeDetail of this.routeDetailList[this.page - 1]) {
        if (
          // 未入力の必須項目が存在する場合
          !routeDetail.relayOffice ||
          !routeDetail.transportComp ||
          !routeDetail.pickupPointRelaySid ||
          !routeDetail.pickupScheduleDateRelay ||
          !routeDetail.deliveryPointRelaySid ||
          !routeDetail.deliveryScheduleDateRelay
        ) {
          contentIncompleteFlg = true;
          break;
        }
        if (routeDetail.transportComp == appConfig.CARRIERCOMPDIV.DELIVERY) {
          // 輸送手段で「路線」を選択していた場合
          deliveryFlg = true;
        }

        // 出発日
        let pickupScheduleDate = new Date(routeDetail.pickupScheduleDateRelay);
        // 到着日
        let deliveryScheduleDate = new Date(routeDetail.deliveryScheduleDateRelay);

        // 出発時間FROM
        let pickupScheduleDatetimeFrom = null;
        if (!this.isEmpty(routeDetail.pickupScheduleTimeRelayFrom)) {
          pickupScheduleDatetimeFrom = new Date(
            routeDetail.pickupScheduleDateRelay + " " + routeDetail.pickupScheduleTimeRelayFrom
          );
        }
        // 出発時間TO
        let pickupScheduleDatetimeTo = null;
        if (!this.isEmpty(routeDetail.pickupScheduleTimeRelayTo)) {
          pickupScheduleDatetimeTo = new Date(
            routeDetail.pickupScheduleDateRelay + " " + routeDetail.pickupScheduleTimeRelayTo
          );
        }
        // 到着時間FROM
        let deliveryScheduleDatetimeFrom = null;
        if (!this.isEmpty(routeDetail.deliveryScheduleTimeRelayFrom)) {
          deliveryScheduleDatetimeFrom = new Date(
            routeDetail.deliveryScheduleDateRelay + " " + routeDetail.deliveryScheduleTimeRelayFrom
          );
        }
        // 到着時間TO
        let deliveryScheduleDatetimeTo = null;
        if (!this.isEmpty(routeDetail.deliveryScheduleTimeRelayTo)) {
          deliveryScheduleDatetimeTo = new Date(
            routeDetail.deliveryScheduleDateRelay + " " + routeDetail.deliveryScheduleTimeRelayTo
          );
        }
        // 出発日時と到着日時のチェック
        if (
          pickupScheduleDatetimeFrom != null &&
          deliveryScheduleDatetimeFrom != null &&
          pickupScheduleDatetimeFrom > deliveryScheduleDatetimeFrom
        ) {
          pickupDeliveryDatetimeErrorFlg = true;
          break;
        }

        // 出発日と到着日のチェック
        if (this.afterThan(pickupScheduleDate, deliveryScheduleDate, true)) {
          pickupDeliveryDatetimeErrorFlg = true;
          break;
        }

        // 出発日時fromと出発日時toのチェック
        if (this.afterThan(pickupScheduleDatetimeFrom, pickupScheduleDatetimeTo, false)) {
          pickupDeliveryDatetimeErrorFlg = true;
          break;
        }

        // 出発日時fromと到着日時fromのチェック
        if (this.afterThan(pickupScheduleDatetimeFrom, deliveryScheduleDatetimeFrom, false)) {
          pickupDeliveryDatetimeErrorFlg = true;
          break;
        }

        // 出発日時fromと到着日時toのチェック
        if (this.afterThan(pickupScheduleDatetimeFrom, deliveryScheduleDatetimeTo, false)) {
          pickupDeliveryDatetimeErrorFlg = true;
          break;
        }

        // 以降、前行との整合性チェック

        // 前行の到着日と現在行の出発日
        if (this.afterThan(preDeliveryScheduleDate, pickupScheduleDate, true)) {
          preDeliveryPickupDatetimeErrorFlg = true;
          break;
        }

        // 前行の出発日時と現在行の出発日時
        if (this.afterThan(prePickupDatetimeFrom, pickupScheduleDatetimeFrom, false)) {
          preDeliveryPickupDatetimeErrorFlg = true;
          break;
        }
        // 前行の出発日時と現在行の到着日時
        if (this.afterThan(prePickupDatetimeFrom, deliveryScheduleDatetimeFrom, false)) {
          preDeliveryPickupDatetimeErrorFlg = true;
          break;
        }
        // 前行の到着日時と現在行の出発日時
        if (this.afterThan(preDeliveryDatetimeFrom, pickupScheduleDatetimeFrom, false)) {
          preDeliveryPickupDatetimeErrorFlg = true;
          break;
        }
        // 前行の到着日時と現在行の到着日時
        if (this.afterThan(preDeliveryDatetimeFrom, deliveryScheduleDatetimeFrom, false)) {
          preDeliveryPickupDatetimeErrorFlg = true;
          break;
        }

        // 前行の日時を記憶
        preDeliveryScheduleDate = deliveryScheduleDate;
        if (pickupScheduleDatetimeFrom != null) {
          prePickupDatetimeFrom = pickupScheduleDatetimeFrom;
        }
        if (deliveryScheduleDatetimeFrom != null) {
          preDeliveryDatetimeFrom = deliveryScheduleDatetimeFrom;
        }

        const relayRouteInfo = {
          // ルート順番
          transportOrderNo: routeDetail.No,
          // 担当営業所SID
          officeSid: routeDetail.relayOffice,
          // 輸送手段
          transportCompDiv: routeDetail.transportComp,
          // 集荷方法区分
          pickupWayDiv: this.setPickupWayDiv(
            routeDetail.relayOffice,
            routeDetail.pickupPointRelaySid
          ),
          // 集荷拠点SID
          pickupPointSid: routeDetail.pickupPointRelaySid,
          // 集荷予定日
          pickupScheduleDate: dateTimeHelper.toStringUTCDate(
            "YYYY/MM/DD hh:mm:ss.SSS",
            new Date(routeDetail.pickupScheduleDateRelay)
          ),
          // 集荷予定日時FROM
          pickupScheduleFromDate: !this.isEmpty(routeDetail.pickupScheduleTimeRelayFrom)
            ? dateTimeHelper.toStringUTCDate(
                "YYYY/MM/DD hh:mm:ss.SSS",
                new Date(pickupScheduleDatetimeFrom)
              )
            : null,
          // 集荷予定日時TO
          pickupScheduleToDate: !this.isEmpty(routeDetail.pickupScheduleTimeRelayTo)
            ? dateTimeHelper.toStringUTCDate(
                "YYYY/MM/DD hh:mm:ss.SSS",
                new Date(pickupScheduleDatetimeTo)
              )
            : null,
          // 配達方法区分
          deliveryWayDiv: this.setDeliveryWayDiv(
            routeDetail.relayOffice,
            routeDetail.deliveryPointRelaySid
          ),
          // 配達拠点SID
          deliveryPointSid: routeDetail.deliveryPointRelaySid,
          // 配達予定日
          deliveryScheduleDate: dateTimeHelper.toStringUTCDate(
            "YYYY/MM/DD hh:mm:ss.SSS",
            new Date(routeDetail.deliveryScheduleDateRelay)
          ),
          // 配達予定日時FROM
          deliveryScheduleFromDate: !this.isEmpty(routeDetail.deliveryScheduleTimeRelayFrom)
            ? dateTimeHelper.toStringUTCDate(
                "YYYY/MM/DD hh:mm:ss.SSS",
                new Date(deliveryScheduleDatetimeFrom)
              )
            : null,
          // 配達予定日時TO
          deliveryScheduleToDate: !this.isEmpty(routeDetail.deliveryScheduleTimeRelayTo)
            ? dateTimeHelper.toStringUTCDate(
                "YYYY/MM/DD hh:mm:ss.SSS",
                new Date(deliveryScheduleDatetimeTo)
              )
            : null,
        };
        relayRouteInfoList.push(relayRouteInfo);
      }

      const params = {
        freightOrderList,
        relayRouteInfoList,
      };

      // ルート登録関連のエラーダイアログを表示する関数
      const openErrorDialog = (e) => {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      };

      // ルート登録を実行する関数
      const registerAction = () => {
        this.loadingCounter = 1;
        this.routeRecordRegister(params, this.defaultFlg)
          .then(() => {
            this.loadingCounter = 0;
            // 正常終了時、完了ダイアログを表示する
            this.routeRegister(params)
              .then(() => {
                // 正常終了時、完了ダイアログを表示する
                openCompletedDialog();
              })
              .catch((e) => {
                openErrorDialog(e);
              });
          })
          .catch((e) => {
            openErrorDialog(e);
          })
          .finally(() => {
            this.preDeliveryDatetimeFrom = null;
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      };
      // ルート登録完了時のダイアログを表示する関数
      const openCompletedDialog = () => {
        const closeAction = () => {
          this.preDeliveryDatetimeFrom = null;
          this.defaultFlg = false;
          return;
        };
        this.modifiedConfirmDialogOption = {
          title: this.$t("label.lbl_completed"),
          message: messsageUtil.getMessage("P-TOD-006_002_S"),
          screenFlag: true,
          okBtnTxt: "受注一覧画面",
          cancelBtnFlg: true,
          cancelBtnTxt: i18n.tc("btn.btn_close"),
          okAction: this.returnToTransportList,
          cancelAction: closeAction,
        };
        this.showConfirmDialog = true;
      };
      if (contentIncompleteFlg) {
        // 未入力の必須項目があった場合
        const closeAction = () => {
          this.preDeliveryDatetimeFrom = null;
          return;
        };
        this.modifiedConfirmDialogOption = {
          title: appConfig.DIALOG.confirm,
          message: i18n.tc("check.chk_is_required"),
          screenFlag: true,
          cancelBtnFlg: false,
          okBtnTxt: i18n.tc("btn.btn_close"),
          okAction: closeAction,
        };
      } else if (deliveryFlg && params.relayRouteInfoList.length > 1) {
        // 路線選択時に直通ルートが設定されていなかった場合
        const closeAction = () => {
          this.preDeliveryDatetimeFrom = null;
          return;
        };
        this.modifiedConfirmDialogOption = {
          title: appConfig.DIALOG.confirm,
          message: i18n.tc("check.chk_relayInfoListSizeInDelivery"),
          screenFlag: true,
          cancelBtnFlg: false,
          okBtnTxt: i18n.tc("btn.btn_close"),
          okAction: closeAction,
        };
      } else if (pickupDeliveryDatetimeErrorFlg) {
        // 出発予定日時と到着予定日時が整合していない場合
        const closeAction = () => {
          this.preDeliveryDatetimeFrom = null;
          return;
        };
        this.modifiedConfirmDialogOption = {
          title: appConfig.DIALOG.confirm,
          message: i18n.tc("check.chk_inputPickupDeliveryDateTime"),
          screenFlag: true,
          cancelBtnFlg: false,
          okBtnTxt: i18n.tc("btn.btn_close"),
          okAction: closeAction,
        };
      } else if (preDeliveryPickupDatetimeErrorFlg) {
        // 前行の到着予定日時と出発予定日時が整合していない場合
        const closeAction = () => {
          this.preDeliveryDatetimeFrom = null;
          return;
        };
        this.modifiedConfirmDialogOption = {
          title: appConfig.DIALOG.confirm,
          message: i18n.tc("check.chk_inputDeliveryPickupDateTime"),
          screenFlag: true,
          cancelBtnFlg: false,
          okBtnTxt: i18n.tc("btn.btn_close"),
          okAction: closeAction,
        };
      } else {
        // ルートマスタから登録する場合
        if (this.screenStateFlg == 0) {
          this.modifiedConfirmDialogOption = {
            message: messsageUtil.getMessage("P-TOD-006_001_C"),
            title: appConfig.DIALOG.confirm,
            screenFlag: true,
            cancelBtnFlg: true,
            okBtnTxt: i18n.tc("btn.btn_confirm"),
            okAction: registerAction,
          };
        } else {
          // 履歴もしくは新規作成したルートを登録する場合、デフォルトにするかどうか選択させる
          this.modifiedConfirmDialogOption = {
            message: messsageUtil.getMessage("P-TOD-006_004_C"),
            title: appConfig.DIALOG.confirm,
            checkBox: true,
            checkBoxTxt: i18n.tc("label.lbl_default"),
            check: this.defaultFlg,
            screenFlag: true,
            cancelBtnFlg: true,
            okBtnTxt: i18n.tc("btn.btn_confirm"),
            okAction: registerAction,
          };
        }
      }
      this.showConfirmDialog = true;
    },

    /**
     * 集荷方法区分設定
     */
    setPickupWayDiv(relayOfficeSid, pickupPointRelaySid) {
      if (relayOfficeSid == pickupPointRelaySid) {
        return appConfig.PICKUP_WAY_DIV.SELF;
      } else {
        return appConfig.PICKUP_WAY_DIV.PICKUP;
      }
    },

    /**
     * 配達方法区分設定
     */
    setDeliveryWayDiv(relayOfficeSid, deliveryPointRelaySid) {
      if (relayOfficeSid != deliveryPointRelaySid) {
        // 担当営業所と着地が異なる場合、配達要
        return appConfig.DELIVERY_WAY_DIV.DELIVERY;
      } else {
        // 担当営業所と着地が同一の場合、営業所止め
        return appConfig.DELIVERY_WAY_DIV.STOP;
      }
    },

    /**
     * デフォルト設定
     */
    setDefault(defaultFlg) {
      this.defaultFlg = defaultFlg;
    },

    /**
     * 一覧画面遷移処理
     */
    returnToTransportList() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_004,
        params: {
          searchParam: this.$route.params.searchParam,
          checkFlg: true,
        },
      });
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },

    // 出発日日付チェック
    checkPickupScheduleDate(item, check) {
      if (check == false) {
        return true;
      }
      let result =
        this.checkDateRangeFromTo(item.pickupScheduleDateRelay, item.deliveryScheduleDateRelay) ||
        this.$t("check.chk_dateOnAndBefore", [this.$t("label.lbl_deliveryScheduleDateRelay")]);
      return result;
    },

    // 到着日日付チェック
    checkDeliveryScheduleDate(item) {
      let result =
        this.checkDateRangeFromTo(item.pickupScheduleDateRelay, item.deliveryScheduleDateRelay) ||
        this.$t("check.chk_dateSinceThen", [this.$t("label.lbl_pickupScheduleDateRelay")]);
      return result;
    },

    // 日付比較チェック
    checkDateRangeFromTo(from, to) {
      if (!from || !to) {
        return true;
      }
      const fromDate = new Date(from);
      const toDate = new Date(to);
      return fromDate <= toDate;
    },

    // 日時比較チェック
    afterThan(datetime1, datetime2, allowEquals) {
      return (
        !this.isEmpty(datetime1) &&
        !this.isEmpty(datetime2) &&
        (allowEquals ? datetime1 > datetime2 : datetime1 >= datetime2)
      );
    },

    // 相関チェックエラーメッセージが表示できるようにする
    // 一度フォーカスが当たったあとでないとバリデーションメッセージが表示されない事象の対策
    // 表示行数が増える都度実行する必要がある
    showValidateMessages: function () {
      // 表示行数をカウント
      let nDetailRows = 0;
      for (let routeDetail of this.routeDetailList) {
        nDetailRows += routeDetail.length;
      }

      let thisRef = this;
      let callback = function () {
        let refs = thisRef.$refs;

        // $refs の構築が終わっていなければ待機後再試行する
        let relayOffice = refs.relayOffice;
        if (relayOffice === undefined || relayOffice.length != nDetailRows) {
          setTimeout(callback, 100);
          return;
        }

        // 強制しないとエラーメッセージが表示されない項目に対して
        // forceにtrueを指定してvalidateメソッドを呼び出す
        const fieldNames = [
          "deliveryScheduleDateRelay",
          "deliveryScheduleTimeRelayFrom",
          "deliveryScheduleTimeRelayTo",
        ];
        for (let fieldName of fieldNames) {
          let fields = refs[fieldName];
          for (let field of fields) {
            field.validate(true);
          }
        }
      };

      setTimeout(callback, 100);
    },

    registerSection() {
      this.showRegisterSectionDialog = true;
    },

    clickDelete() {
      const okAction = () => {
        this.loadingCounter = 1;
        this.deleteLotRoutes()
          .then(() => {
            // 正常終了時、完了ダイアログを表示する
            openDeletedDialog();
          })
          .catch((e) => {
            this.modifiedConfirmDialogOption = {
              message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
            };
            this.showConfirmDialog = true;
          })
          .finally(() => {
            this.loadingCounter = 0;
          });
      };

      const openDeletedDialog = () => {
        const closeAction = () => {
          this.preDeliveryDatetimeFrom = null;
          this.defaultFlg = false;
          return;
        };
        this.modifiedConfirmDialogOption = {
          title: this.$t("label.lbl_completed"),
          message: messsageUtil.getMessage("P-TOD-006_006_S"),
          screenFlag: true,
          okBtnTxt: "受注一覧画面",
          cancelBtnFlg: true,
          cancelBtnTxt: i18n.tc("btn.btn_close"),
          okAction: this.returnToTransportList,
          cancelAction: closeAction,
        };
        this.showConfirmDialog = true;
      };

      this.modifiedConfirmDialogOption = {
        message: messsageUtil.getMessage("P-TOD-006_005_C"),
        title: appConfig.DIALOG.confirm,
        screenFlag: true,
        cancelBtnFlg: true,
        okAction: okAction,
      };
      this.showConfirmDialog = true;
    },

    async deleteLotRoutes() {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_006; // 画面ID
      body.reqIdv = {
        transportInfoList: this.$route.params.transportInfoList.map((t) => {
          return {
            freightOrderSid: t.freightOrderSid,
          };
        }),
        freightOrderList: this.freightList.map((f) => {
          return {
            invNumSid: f.invNumSid,
            freightLastUpdateDateTime: f.freightLastUpdateDateTime,
          };
        }),
        packingList: this.packingList.map((p) => {
          return {
            packingSid: p.packingSid,
            packingLastUpdateDateTime: p.packingLastUpdateDateTime,
          };
        }),
      };
      let response;
      try {
        response = await this.$httpClient.securePost(
          appConfig.API_URL.BIZ_TRANSPORT_ROUTE_DELETE,
          body,
          appConfig.APP_CONFIG
        );
      } catch (e) {
        throw new Error(messsageUtil.getMessage("P-999-999_999_E"));
      }
      const { resCom } = response.data;
      if (resCom.resComCode !== appConfig.RESCOMCODE_SUCCESS) {
        throw new Error(resCom.resComMessage);
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
@import "../../css/style.css";

#distanceAndTimeDialog {
  tr:nth-child(odd) {
    background-color: #dde5f0;
    height: 2rem;
  }
  td {
    font-size: large;
  }
  th {
    font-size: large !important;
    color: black;
    background: #effad9;
    height: 2.5rem !important;
  }
}

#listData-route {
  tr.table-row {
    height: 2rem;
    &:nth-child(odd) {
      background-color: #dde5f0;
    }
  }
  table > thead > tr > th[role="columnheader"] {
    background-color: #effad9;
    height: 3rem;
    font-size: large;
    color: black;
    &.required::after {
      content: "*";
      color: red;
    }
  }

  table > tbody > tr > td > div {
    font-size: large;
  }

  table > tbody > tr > td {
    font-size: large;
  }

  table > tbody > tr {
    height: 2rem;
    &:nth-child(odd) {
      background-color: #dde5f0;
    }
  }
}
</style>
<style lang="scss" scoped>
.v-data-table {
  white-space: nowrap;
}

.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.order-list-title {
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

.route-detail-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: large;
  padding: 10px;
}

.pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-area {
  margin: 0 0 0 auto;
  padding: 10px;
}
.link-btn {
  text-decoration: underline;
  box-shadow: none;
  color: #1976d2;
}
</style>
