<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_salesClaimDetail" />
      <!-- 売上請求詳細画面 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete-supplier first-search-item">
              <!-- 元請営業所-->
              <v-text-field
                dense
                v-model="officeOrg"
                :label="$t('label.lbl_officeOrg')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="search-autocomplete">
              <!-- 取引先-->
              <v-text-field
                dense
                id="get-supplier"
                v-model="suppliersSelectedName"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="search-autocomplete">
              <!-- 業務No-->
              <v-text-field
                dense
                v-model="businessNo"
                :label="$t('label.lbl_bussinessNo')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="date-style">
              <!-- 月度-->
              <v-text-field
                dense
                v-model="orderMonth"
                :label="$t('label.lbl_Month')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="search-autocomplete">
              <!-- 貨物受注No-->
              <v-text-field
                dense
                v-model="freightOrdersNo"
                :label="$t('label.lbl_freightOrdersNo')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
          </v-row>

          <!--貨物情報領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--開閉ボタン領域-->
              <div class="text-label" style="float: left; padding-left: 12px">
                <!--貨物情報-->
                <span class="text-font">{{ $t("label.lbl_freightInfo") }}</span>
                <!--開閉ボタン-->
                <v-btn
                  small
                  text
                  style="margin-bottom: 5px"
                  v-if="tableVisible['freightInfo']"
                  @click="expandList('freightInfo')"
                >
                  <v-icon>mdi-triangle-down-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  text
                  style="margin-bottom: 5px"
                  v-if="!tableVisible['freightInfo']"
                  @click="expandList('freightInfo')"
                >
                  <v-icon>mdi-triangle-outline</v-icon>
                </v-btn>
              </div>
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: right">
                <!--戻るボタン-->
                <v-btn class="post-btn" @click="confirmBackPage()">{{ $t("btn.btn_back") }}</v-btn>
                <!--CSV出力ボタン-->
                <v-btn v-bind:disabled="true" class="post-btn" @click="download()">{{
                  $t("btn.btn_outputcsv")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- 貨物情報データテーブル -->
        <v-container fluid class="py-1">
          <v-data-table
            id="listData"
            fixed-header
            :headers="freightHeaderItems"
            :items="freightInfoList"
            disable-filtering
            :hide-default-footer="true"
            :items-per-page="50"
            :height="tableHeights['freightInfo']"
            v-show="tableVisible['freightInfo']"
          >
            <!-- ヘッダー領域 -->
            <!-- 実重量 -->
            <template v-slot:[`header.itemWeight`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div ml-5 style="text-align: right">{{ "（kg）" }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 換算量 -->
            <template v-slot:[`header.conversionWeight`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div ml-5 style="text-align: right">{{ "（kg）" }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 適用重量 -->
            <template v-slot:[`header.chargeableWeight`]="{ header }">
              <div>
                <span>{{ header.text }}</span>
              </div>
            </template>
            <!-- 請求金額 -->
            <template v-slot:[`item.billingAmount`]="{ item }">
              <div class="align-right">{{ formatCost(item.billingAmount) }}</div>
            </template>
            <!-- 支払金額 -->
            <template v-slot:[`item.paymentAmount`]="{ item }">
              <div class="align-right">{{ formatCost(item.paymentAmount) }}</div>
            </template>
            <!-- 元請利益 -->
            <template v-slot:[`item.primeContractorBenefit`]="{ item }">
              <div class="align-right">{{ formatCost(item.primeContractorBenefit) }}</div>
            </template>
            <!-- 利益按分 -->
            <template v-slot:[`item.benefitRatio`]="{ item }">
              <div class="align-right">{{ formatCost(item.benefitRatio) }}</div>
            </template>
            <!-- 按分内訳 -->
            <template v-slot:[`item.distributionBreakdown`]="{ item }">
              <a style="text-decoration: underline" @click="openDetailInputDialog(item)">{{
                $t("label.lbl_breakDown")
              }}</a>
            </template>
            <!-- 振替率 -->
            <template v-slot:[`item.transferRation`]="{ item }">
              <div class="align-right">{{ formatRatio(item.transferRation) }}</div>
            </template>
          </v-data-table>
        </v-container>

        <v-container fluid>
          <!--運賃（請求）情報領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--開閉ボタン領域-->
              <div class="btn-search-area" style="float: left; padding-left: 12px">
                <!--貨物情報-->
                <span class="text-font">{{ $t("label.lbl_fareBilling") }}</span>
                <!--開閉ボタン-->
                <v-btn
                  small
                  text
                  style="margin-bottom: 5px"
                  v-if="tableVisible['fareBillInfo']"
                  @click="expandList('fareBillInfo')"
                >
                  <v-icon>mdi-triangle-down-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  text
                  style="margin-bottom: 5px"
                  v-if="!tableVisible['fareBillInfo']"
                  @click="expandList('fareBillInfo')"
                >
                  <v-icon>mdi-triangle-outline</v-icon>
                </v-btn>
                <!-- 行追加ボタン-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  class="post-btn"
                  @click="addItem('fareBillInfo')"
                >
                  {{ $t("btn.btn_rowsAdd") }}
                </v-btn>
              </div>
              <!--ボタン領域-->
              <div class="btn-area">
                <!-- 登録ボタン -->
                <v-btn class="post-btn" @click="viewConfirmDialog('fareBillInfo')">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- 運賃（請求）情報データテーブル -->
        <v-form ref="editedFareBillList" lazy-validation>
          <v-container fluid class="pt-2">
            <v-data-table
              id="listData"
              ref="fareBillList"
              fixed-header
              :headers="fareBillingHeaderItems"
              :items="fareBillingInfoList"
              disable-filtering
              :hide-default-footer="true"
              :items-per-page="50"
              :height="tableHeights['fareBillInfo']"
              v-show="tableVisible['fareBillInfo']"
              class="table-style"
            >
              <!-- 発地・着地 -->
              <template v-slot:[`item.departureAndLanding`]="{ item }">
                <div class="listNo-style">{{ item.departurePoint }}</div>
                <div class="listNo-style">{{ item.landingPoint }}</div>
              </template>
              <!-- 乗務員・所属営業所 -->
              <template v-slot:[`item.crewAndBelongOffice`]="{ item }">
                <div class="listNo-style">{{ item.crew }}</div>
                <div class="listNo-style">{{ item.belongOffice }}</div>
              </template>
              <!-- 社号・車両形状 -->
              <template v-slot:[`item.driverName`]="{ item }">
                <div class="listNo-style">{{ item.vehicleCd }}</div>
                <div class="listNo-style">{{ item.vehicleShape }}</div>
              </template>
              <!-- G勘定科目 -->
              <template v-slot:[`item.accountTitle`]="{ item, index }">
                <div v-if="!isPrimeContOrBenefitDistTask(item)">
                  <v-autocomplete
                    dense
                    v-model="item.accountTitle"
                    :items="accountTitleList"
                    @change="(value) => changeAccountTitle(value, index, 'fareBillInfo')"
                    class="select-box"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" style="">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div v-else></div>
              </template>
              <!-- 項目詳細 -->
              <template v-slot:[`item.itemDetail`]="{ item }">
                <div v-if="item.isNewAddRow">
                  <v-autocomplete
                    dense
                    v-model="item.itemDetailCd"
                    :items="itemDetailList"
                    class="select-box"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" style="">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div v-else class="listNo-style">{{ item.itemDetail }}</div>
              </template>
              <!-- 作業内容 -->
              <template v-slot:[`item.workContents`]="{ item }">
                <div v-if="item.isNewAddRow">
                  <v-autocomplete
                    dense
                    v-model="item.taskDiv"
                    :items="workContentsList"
                    class="select-box"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" style="">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div v-else class="listNo-style">{{ item.workContents }}</div>
              </template>
              <!-- 請求金額-->
              <template v-slot:[`item.billingAmount`]="{ item, index }">
                <div>
                  <v-text-field
                    single-line
                    outlined
                    class="input-number"
                    dense
                    v-model="item.billingAmount"
                    maxLength="16"
                    @change="changeAmount(item, index, 'fareBillInfo')"
                    :rules="[rules.isNumber, rules.inputRequired, rules.inputNumber_9_3]"
                  ></v-text-field>
                </div>
              </template>
              <!-- 削除 -->
              <template v-slot:[`item.delete`]="{ item, index }">
                <div v-if="item.isNewAddRow">
                  <v-btn x-small @click="deleteItem(index, 'fareBillInfo')" text>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div v-else></div>
              </template>
              <!-- 距離-->
              <template v-slot:[`item.distance`]="{ item }">
                <div class="align-right">{{ item.distance.toLocaleString() }}</div>
              </template>
              <!-- 距離按分 -->
              <template v-slot:[`item.distRatio`]="{ item }">
                <div class="align-right">{{ formatRatio(item.distRatio) }}</div>
              </template>
              <!-- タリフ金額 -->
              <template v-slot:[`item.tariffAmount`]="{ item }">
                <div class="align-right">{{ formatCost(item.tariffAmount) }}</div>
              </template>
            </v-data-table>
          </v-container>
        </v-form>

        <v-container fluid>
          <!--支払情報領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--開閉ボタン領域-->
              <div class="btn-search-area" style="float: left; padding-left: 12px">
                <!--貨物情報-->
                <span class="text-font">{{ $t("label.lbl_payment") }}</span>
                <!--開閉ボタン-->
                <v-btn
                  small
                  text
                  style="margin-bottom: 5px"
                  v-if="tableVisible['paymentInfo']"
                  @click="expandList('paymentInfo')"
                >
                  <v-icon>mdi-triangle-down-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  text
                  style="margin-bottom: 5px"
                  v-if="!tableVisible['paymentInfo']"
                  @click="expandList('paymentInfo')"
                >
                  <v-icon>mdi-triangle-outline</v-icon>
                </v-btn>
                <!-- 行追加ボタン-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  class="payment-addRow-btn"
                  @click="addItem('paymentInfo')"
                >
                  {{ $t("btn.btn_rowsAdd") }}
                </v-btn>
              </div>
              <!--ボタン領域-->
              <div class="btn-area">
                <!-- 登録ボタン -->
                <v-btn class="post-btn" @click="viewConfirmDialog('paymentInfo')">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
        <!-- 支払情報データテーブル -->
        <v-form ref="editedPaymentList" lazy-validation>
          <v-container fluid class="pt-2">
            <v-data-table
              id="listData"
              ref="paymentList"
              fixed-header
              :headers="paymentHeaderItems"
              :items="paymentInfoList"
              disable-filtering
              :hide-default-footer="true"
              :items-per-page="50"
              :height="tableHeights['paymentInfo']"
              v-show="tableVisible['paymentInfo']"
              class="table-style"
            >
              <!-- 発地・着地 -->
              <template v-slot:[`item.departureAndLanding`]="{ item }">
                <div class="listNo-style">{{ item.departurePoint }}</div>
                <div class="listNo-style">{{ item.landingPoint }}</div>
              </template>
              <!-- 乗務員・所属営業所 -->
              <template v-slot:[`item.crewAndBelongOffice`]="{ item }">
                <div class="listNo-style">{{ item.crew }}</div>
                <div class="listNo-style">{{ item.belongOffice }}</div>
              </template>
              <!-- 社号・車両形状 -->
              <template v-slot:[`item.driverName`]="{ item }">
                <div class="listNo-style">{{ item.vehicleCd }}</div>
                <div class="listNo-style">{{ item.vehicleShape }}</div>
              </template>
              <!-- G勘定科目 -->
              <template v-slot:[`item.accountTitle`]="{ item, index }">
                <div v-if="!isPrimeContOrBenefitDistTask(item)">
                  <v-autocomplete
                    dense
                    v-model="item.accountTitle"
                    :items="accountTitleList"
                    @change="(value) => changeAccountTitle(value, index, 'paymentInfo')"
                    class="select-box"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" style="">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div v-else></div>
              </template>
              <!-- 項目詳細 -->
              <template v-slot:[`item.itemDetail`]="{ item }">
                <div v-if="item.isNewAddRow">
                  <v-autocomplete
                    dense
                    v-model="item.itemDetailCd"
                    :items="itemDetailList"
                    class="select-box"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" style="">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div v-else class="listNo-style">{{ item.itemDetail }}</div>
              </template>
              <!-- 作業内容 -->
              <template v-slot:[`item.workContents`]="{ item }">
                <div v-if="item.isNewAddRow">
                  <v-autocomplete
                    dense
                    v-model="item.taskDiv"
                    :items="workContentsList"
                    class="select-box"
                    persistent-hint
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data" style="">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div v-else class="listNo-style">{{ item.workContents }}</div>
              </template>
              <!-- 支払金額-->
              <template v-slot:[`item.paymentAmount`]="{ item, index }">
                <div v-if="!isEditable(item)">
                  <v-text-field
                    single-line
                    outlined
                    class="input-number"
                    dense
                    v-model="item.paymentAmount"
                    disabled
                    maxLength="16"
                  ></v-text-field>
                </div>
                <div v-else-if="item.taskDiv == '97'">
                  <v-text-field
                    single-line
                    outlined
                    class="input-number"
                    dense
                    v-model="item.paymentAmount"
                    maxLength="16"
                    @change="changeAmount(item, index, 'paymentInfo')"
                    :rules="[rules.isNumber, rules.inputRequired, rules.inputMinusNumber_9_3]"
                  ></v-text-field>
                </div>
                <div v-else>
                  <v-text-field
                    single-line
                    outlined
                    class="input-number"
                    dense
                    v-model="item.paymentAmount"
                    maxLength="16"
                    @change="changeAmount(item, index, 'paymentInfo')"
                    :rules="[rules.isNumber, rules.inputRequired, rules.inputNumber_9_3]"
                  ></v-text-field>
                </div>
              </template>
              <!-- 削除 -->
              <template v-slot:[`item.delete`]="{ item, index }">
                <div v-if="item.isNewAddRow">
                  <v-btn x-small @click="deleteItem(index, 'paymentInfo')" text>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <div v-else></div>
              </template>
              <!-- 距離-->
              <template v-slot:[`item.distance`]="{ item }">
                <div class="align-right">{{ item.distance.toLocaleString() }}</div>
              </template>
              <!-- 距離按分 -->
              <template v-slot:[`item.distRatio`]="{ item }">
                <div class="align-right">{{ formatRatio(item.distRatio) }}</div>
              </template>
              <!-- タリフ金額 -->
              <template v-slot:[`item.tariffAmount`]="{ item }">
                <div class="align-right">{{ formatCost(item.tariffAmount) }}</div>
              </template>
            </v-data-table>
          </v-container>
        </v-form>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :salesClaimListFlg="infoDialog.salesClaimListFlg"
          :salesClaimDetailFlg="infoDialog.salesClaimDetailFlg"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { commonUtil } from "../../assets/scripts/js/CommonUtil.js";
import { appConfig } from "../../assets/scripts/js/AppConfig.js";
import { getParameter } from "../../assets/scripts/js/GetParameter.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil.js";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.SCREEN_ID.P_CLA_005,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 元請営業所SID
    officeOrgSid: "",
    // 元請営業所
    officeOrg: "",
    // 取引先SID
    suppliersSelected: "",
    // 取引先
    suppliersSelectedName: "",
    // 業務No
    bizNo: "",
    // 業務No(表示用)
    businessNo: "",
    // 受注日
    orderMonth: "",
    // 貨物受注No
    freightOrdersNo: "",
    // 貨物情報表示用リスト
    freightInfoList: [],
    // 換算重量リスト
    chargeableWeightList: [],
    // 運賃（請求）表示用リスト
    fareBillingInfoList: [],
    // G勘定科目リスト
    accountTitleList: [],
    // 項目詳細リスト
    itemDetailList: [],
    // 作業内容リスト
    workContentsList: [],
    // 支払表示用リスト
    paymentInfoList: [],
    tableVisible: {
      freightInfo: true,
      fareBillInfo: true,
      paymentInfo: true,
    },
    tableHeights: {
      freightInfo: 110, // 初期高さ
      fareBillInfo: 280,
      paymentInfo: 280,
    },
    baseHeights: {
      freightInfo: 110,
      fareBillInfo: 280,
      paymentInfo: 280,
    },
    // 登録対象テーブル
    registTargetTable: "",
    // AutoNoカウント
    autoNoCount: 0,
    // 運賃（請求）・支払リスト
    billPayInfoList: [],
    // 運賃（請求）テーブルの追加行アイテム
    fareBillDefaultItem: {
      no: 0,
      chargeOffice: "",
      chargeOfficeSid: "",
      departurePoint: "",
      landingPoint: "",
      departurePointSid: "",
      landingPointSid: "",
      crew: "",
      belongOffice: "",
      crewSid: "",
      belongOfficeSid: "",
      vehicleSid: "",
      vehicleCd: "",
      vehicleShape: "",
      accountTitle: "",
      itemDetailCd: "",
      itemDetail: "",
      taskDiv: "",
      workContents: "",
      title: "",
      processDiv: "01",
      billingAmount: 0,
      paymentAmount: null,
      calcDiv: "99",
      distance: "",
      distRatio: "",
      tariffAmount: "",
      freightOrderSid: "",
      pairNo: "",
      yyyymm: "",
      periodDiv: "",
      yyyymmdd: "",
      businessNo: "",
      clientSid: "",
      officeSidOrg: "",
      invoiceItemSid: "",
      unitPriceSid: "",
      unitPriceCd: "",
      unitPriceCdName: "",
      unitPriceDetail: "",
      taxDiv: "",
      updateDateTime: "",
      isNewAddRow: true,
    },
    // 支払テーブルの追加行アイテム
    paymentDefaultItem: {
      no: 0,
      chargeOffice: "",
      chargeOfficeSid: "",
      departurePoint: "",
      landingPoint: "",
      departurePointSid: "",
      landingPointSid: "",
      crew: "",
      belongOffice: "",
      crewSid: "",
      belongOfficeSid: "",
      vehicleSid: "",
      vehicleCd: "",
      vehicleShape: "",
      accountTitle: "",
      itemDetailCd: "",
      itemDetail: "",
      taskDiv: "",
      workContents: "",
      title: "",
      processDiv: "02",
      billingAmount: null,
      paymentAmount: 0,
      calcDiv: "99",
      distance: "",
      distRatio: "",
      tariffAmount: "",
      freightOrderSid: "",
      pairNo: "",
      yyyymm: "",
      periodDiv: "",
      yyyymmdd: "",
      businessNo: "",
      clientSid: "",
      officeSidOrg: "",
      invoiceItemSid: "",
      unitPriceSid: "",
      unitPriceCd: "",
      unitPriceCdName: "",
      unitPriceDetail: "",
      taxDiv: "",
      updateDateTime: "",
      isNewAddRow: true,
    },
    message: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
      salesClaimListFlg: false,
      salesClaimDetailFlg: false,
      homePageFlag: false,
      okAction: () => {},
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーション
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("￥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      inputNumber_9_3: (value) => {
        if (
          (value.includes("￥") && 0 < value.indexOf("￥")) ||
          value.indexOf("￥") != value.lastIndexOf("￥")
        ) {
          return i18n.tc("check.chk_inputNumber_9-3");
        }
        let amount = String(value).replaceAll(",", "").replaceAll("￥", "");
        var check = /^([0-9]{1,9}|0)([.][0-9]{1,3})?$/g.test(amount);
        if ((amount ?? false) && amount != "") {
          if (check == true) {
            return true;
          } else {
            return i18n.tc("check.chk_inputNumber_9-3");
          }
        } else {
          return true;
        }
      },
      inputMinusNumber_9_3: (value) => {
        if (
          (value.includes("￥") && 0 < value.indexOf("￥")) ||
          value.indexOf("￥") != value.lastIndexOf("￥")
        ) {
          return i18n.tc("check.chk_inputNumber_9-3");
        }
        let amount = String(value).replaceAll(",", "").replaceAll("￥", "");
        var check = /^[+-]?([0-9]{1,9}|0)([.][0-9]{1,3})?$/g.test(amount);
        if ((amount ?? false) && amount != "") {
          if (check == true) {
            return true;
          } else {
            return i18n.tc("check.chk_inputNumber_9-3");
          }
        } else {
          return true;
        }
      },
    },
    // 貨物情報ヘッダ
    freightHeaderItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "no",
        width: "80px",
        align: "center",
        sortable: false,
      },
      // 担当営業所
      {
        text: i18n.tc("label.lbl_chargeOffice"),
        value: "chargeOffice",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // 代表品名
      {
        text: i18n.tc("label.lbl_representItemName"),
        value: "representItemName",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // 実重量
      {
        text: i18n.tc("label.lbl_itemWeight"),
        value: "itemWeight",
        width: "120px",
        align: "right",
        sortable: false,
      },
      // 換算量
      {
        text: i18n.tc("label.lbl_conversionWeight"),
        value: "conversionWeight",
        width: "120px",
        align: "right",
        sortable: false,
      },
      // 適用重量
      {
        text: i18n.tc("label.lbl_chargeableWeight"),
        value: "chargeableWeight",
        width: "120px",
        align: "left",
        sortable: false,
      },
      // 請求金額
      {
        text: i18n.tc("label.lbl_billingAmount"),
        value: "billingAmount",
        width: "180px",
        align: "right",
        sortable: false,
      },
      // 支払金額
      {
        text: i18n.tc("label.lbl_paymentAmount"),
        value: "paymentAmount",
        width: "180px",
        align: "right",
        sortable: false,
      },
      // 元請利益
      {
        text: i18n.tc("label.lbl_primeContractorBenefit"),
        value: "primeContractorBenefit",
        width: "180px",
        align: "right",
        sortable: false,
      },
      // 利益按分
      {
        text: i18n.tc("label.lbl_benefitRatio"),
        value: "benefitRatio",
        width: "150px",
        align: "right",
        sortable: false,
      },
      // 按分内訳
      {
        text: i18n.tc("label.lbl_distributionBreakdown"),
        value: "distributionBreakdown",
        width: "120px",
        align: "left",
        sortable: false,
      },
      // 振替率
      {
        text: i18n.tc("label.lbl_transferRation"),
        value: "transferRation",
        width: "110px",
        align: "right",
        sortable: false,
      },
    ],

    // 運賃（請求）ヘッダ
    fareBillingHeaderItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "no",
        width: "80px",
        align: "center",
        sortable: false,
      },
      // 担当営業所
      {
        text: i18n.tc("label.lbl_chargeOffice"),
        value: "chargeOffice",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // 発地・着地
      {
        text: i18n.tc("label.lbl_departureAndLanding"),
        value: "departureAndLanding",
        width: "230px",
        align: "left",
        sortable: false,
      },
      // 乗務員・所属営業所
      {
        text: i18n.tc("label.lbl_crewAndBelongOffice"),
        value: "crewAndBelongOffice",
        width: "230px",
        align: "left",
        sortable: false,
      },
      // 社号
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "driverName",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // G勘定科目
      {
        text: i18n.tc("label.lbl_accounTitle"),
        value: "accountTitle",
        width: "200px",
        align: "left",
        sortable: false,
      },
      // 項目詳細
      {
        text: i18n.tc("label.lbl_itemDetail"),
        value: "itemDetail",
        width: "190px",
        align: "left",
        sortable: false,
      },
      // 作業内容
      {
        text: i18n.tc("label.lbl_workContents"),
        value: "workContents",
        width: "160px",
        align: "left",
        sortable: false,
      },
      // 請求金額
      {
        text: i18n.tc("label.lbl_billingAmount"),
        value: "billingAmount",
        width: "180px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 削除
      {
        text: i18n.tc("label.lbl_delete"),
        value: "delete",
        width: "90px",
        align: "left",
        sortable: false,
      },
      // 距離
      {
        text: i18n.tc("label.lbl_distance"),
        value: "distance",
        width: "150px",
        align: "right",
        sortable: false,
      },
      // 距離案分
      {
        text: i18n.tc("label.lbl_distRatio"),
        value: "distRatio",
        width: "150px",
        align: "right",
        sortable: false,
      },
      // タリフ金額
      {
        text: i18n.tc("label.lbl_tariffAmount"),
        value: "tariffAmount",
        width: "150px",
        align: "right",
        sortable: false,
      },
    ],
    // 支払い情報ヘッダー
    paymentHeaderItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "no",
        width: "80px",
        align: "center",
        sortable: false,
      },
      // 担当営業所
      {
        text: i18n.tc("label.lbl_chargeOffice"),
        value: "chargeOffice",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // 発地・着地
      {
        text: i18n.tc("label.lbl_departureAndLanding"),
        value: "departureAndLanding",
        width: "230px",
        align: "left",
        sortable: false,
      },
      // 乗務員・所属営業所
      {
        text: i18n.tc("label.lbl_crewAndBelongOffice"),
        value: "crewAndBelongOffice",
        width: "230px",
        align: "left",
        sortable: false,
      },
      // 社号
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "driverName",
        width: "250px",
        align: "left",
        sortable: false,
      },
      // G勘定科目
      {
        text: i18n.tc("label.lbl_accounTitle"),
        value: "accountTitle",
        width: "200px",
        align: "left",
        sortable: false,
      },
      // 項目詳細
      {
        text: i18n.tc("label.lbl_itemDetail"),
        value: "itemDetail",
        width: "190px",
        align: "left",
        sortable: false,
      },
      // 作業内容
      {
        text: i18n.tc("label.lbl_workContents"),
        value: "workContents",
        width: "160px",
        align: "left",
        sortable: false,
      },
      // 支払金額
      {
        text: i18n.tc("label.lbl_paymentAmount"),
        value: "paymentAmount",
        width: "180px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 削除
      {
        text: i18n.tc("label.lbl_delete"),
        value: "delete",
        width: "90px",
        align: "left",
        sortable: false,
      },
      // 距離
      {
        text: i18n.tc("label.lbl_distance"),
        value: "distance",
        width: "150px",
        align: "right",
        sortable: false,
      },
      // 距離案分
      {
        text: i18n.tc("label.lbl_distRatio"),
        value: "distRatio",
        width: "150px",
        align: "right",
        sortable: false,
      },
      // タリフ金額
      {
        text: i18n.tc("label.lbl_tariffAmount"),
        value: "tariffAmount",
        width: "150px",
        align: "right",
        sortable: false,
      },
    ],
    dateMenu: false,
  }),
  methods: {
    // 初期化
    async init() {
      // 画面ヘッダー項目の設定
      // 元請営業所名
      this.officeOrg = this.$route.params.officeOrgName;
      // 元請営業所SID
      this.officeOrgSid = this.$route.params.officeOrg;
      // 取引先名
      this.suppliersSelectedName = this.$route.params.supplierName;
      // 取引先SID
      this.suppliersSelected = this.$route.params.supplier;
      // 業務No(表示用)
      this.businessNo = this.$route.params.businessName;
      // 業務No
      this.bizNo = this.$route.params.businessNo;
      // 月度
      this.orderMonth = this.$route.params.month;
      // 貨物受注No
      this.freightOrdersNo = this.$route.params.freightOrderNo;
      // コードマスタ取得
      await this.getCodeMst();
      // G請求科目マスタ取得
      this.getGkamoku();
      // テーブルデータ取得
      this.getInvoiceActList();
    },

    // G請求科目マスタ取得
    getGkamoku() {
      // G請求項目マスタ
      const invoiceItemList = getParameter.getInvoiceItemList(
        "0",
        this.suppliersSelected,
        this.bizNo,
        undefined,
        this.officeOrgSid
      );
      return Promise.all([invoiceItemList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.accountTitleList = result[0];
          // 画面表示値の空白を削除
          this.accountTitleList.forEach((v, i) => {
            if (i != 0) {
              v.text = `${v.value}` + `（${v.name.trim()}）`;
            }
          });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },

    /**
     * テーブルデータ取得
     */
    getInvoiceActList() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      this.freightInfoList.splice(0);
      const config = this.$httpClient.createGetApiRequestConfig();
      // 貨物受注No
      config.params.freightOrderNo = this.freightOrdersNo;
      // console.debug("getInvoiceActList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INVOICE_ACT_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getInvoiceActList() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              // 換算量
              let conversionWeight = 0;
              const invoiceAct = jsonData.resIdv.invoiceAct;
              // 貨物情報の設定
              let freightInfo = {
                no: 1,
                chargeOffice: invoiceAct.chargeOffice,
                chargeOfficeSid: invoiceAct.chargeOfficeSid,
                representItemName: invoiceAct.representItemName,
                itemWeight: invoiceAct.itemWeight != "" ? Number(invoiceAct.itemWeight) : "",
                billingAmount:
                  invoiceAct.totalBillingAmount != "" ? Number(invoiceAct.totalBillingAmount) : "",
                paymentAmount:
                  invoiceAct.totalPaymentAmount != "" ? Number(invoiceAct.totalPaymentAmount) : "",
                freightSizeL: invoiceAct.freightSizeL != "" ? Number(invoiceAct.freightSizeL) : "",
                freightSizeW: invoiceAct.freightSizeW != "" ? Number(invoiceAct.freightSizeW) : "",
                freightSizeH: invoiceAct.freightSizeH != "" ? Number(invoiceAct.freightSizeH) : "",
                transferRation:
                  invoiceAct.transferRation != "" ? Number(invoiceAct.transferRation) : "",
                applyWeightClsCd: invoiceAct.applyWeightClsCd,
              };
              invoiceAct.billPayInfoList.forEach((row) => {
                list.push({
                  no: row.autoNo,
                  autoNo: row.autoNo,
                  chargeOffice: row.chargeOffice,
                  chargeOfficeSid: row.chargeOfficeSid,
                  departurePoint: row.departurePoint,
                  landingPoint: row.landingPoint,
                  departurePointSid: row.departurePointSid,
                  landingPointSid: row.landingPointSid,
                  crew: row.driverName,
                  belongOffice: row.belongOffice,
                  crewSid: row.driverUserSid,
                  belongOfficeSid: row.belongOfficeSid,
                  vehicleSid: row.vehicleSid,
                  vehicleCd: row.vehicleCd,
                  vehicleShape:
                    `${row.vehicleShapeMajor}` + `${row.vehicleShapeMinor}` == "" && row.vehicleCd
                      ? i18n.tc("label.lbl_vehicleShapeUnRegist")
                      : `${row.vehicleShapeMajor}` + `${row.vehicleShapeMinor}`,
                  accountTitle: row.accountTitle,
                  itemDetailCd: row.itemDetailCd,
                  itemDetail: row.itemDetail,
                  taskDiv: row.taskDiv,
                  workContents: row.workContents,
                  processDiv: row.processDiv,
                  title: row.title,
                  billingAmount:
                    row.billingAmount != null
                      ? this.formatCost(row.billingAmount)
                      : row.billingAmount,
                  paymentAmount:
                    row.paymentAmount != null
                      ? this.formatCost(row.paymentAmount)
                      : row.paymentAmount,
                  calcDiv: row.calculateDiv,
                  distance: !this.isNullOrBlank(row.distance) ? Number(row.distance) : "",
                  distRatio: !this.isNullOrBlank(row.distRatio) ? Number(row.distRatio) : "",
                  tariffAmount: !this.isNullOrBlank(row.tariffAmount)
                    ? Number(row.tariffAmount)
                    : "",
                  freightOrderSid: row.freightOrderSid,
                  pairNo: row.pairNo,
                  yyyymm: row.yyyymm,
                  periodDiv: row.periodDiv,
                  yyyymmdd: row.yyyymmdd,
                  businessNo: row.businessNo,
                  clientSid: row.clientSid,
                  officeSidOrg: row.officeSidOrg,
                  invoiceItemSid: row.invoiceItemSid,
                  unitPriceSid: row.unitPriceSid,
                  unitPriceCd: row.unitPriceCd,
                  unitPriceCdName: row.unitPriceCdName,
                  unitPriceDetail: row.unitPriceDetail,
                  taxDiv: row.taxDiv,
                  updateDateTime: row.updateDatetime,
                  isNewAddRow: row.calculateDiv == appConfig.CALCULATION_DIV.SYSTEM ? false : true,
                });
              });

              if (list.length > 0) {
                this.billPayInfoList = list;
                // AutoNoを計算
                const tmpAutoNoList = this.billPayInfoList.filter(
                  (v) =>
                    v.taskDiv != appConfig.TASK_DIV.PRIME_CONTRACTOR_BENEFIT &&
                    v.taskDiv != appConfig.TASK_DIV.PROFIT_DISTRIBUTION
                );
                this.autoNoCount = tmpAutoNoList.length;
                // 運賃（請求）一覧表示用リストへの設定
                const tmpFareBillList = this.billPayInfoList.filter(
                  (v) => v.processDiv == appConfig.PROCESS_DIV.ACCOUNTS_RECEIVABLE
                );
                this.fareBillingInfoList = this.numberingNo(tmpFareBillList);
                // 支払一覧表示用リストへの設定
                const tmpPaymentList = this.billPayInfoList.filter(
                  (v) => v.processDiv == appConfig.PROCESS_DIV.ACCOUNTS_PAYABLE
                );
                this.paymentInfoList = this.numberingNo(tmpPaymentList);
                // 換算量の計算・設定
                conversionWeight = this.calcConversionWeight(
                  freightInfo.freightSizeL,
                  freightInfo.freightSizeW,
                  freightInfo.freightSizeH
                );
                freightInfo.conversionWeight = conversionWeight;
                // 適用重量の設定
                if (!this.isNullOrBlank(freightInfo.applyWeightClsCd)) {
                  if (freightInfo.applyWeightClsCd == appConfig.APPLY_WEIGHT_CLS_DIV.AUTO_JUDGE) {
                    freightInfo.chargeableWeight =
                      freightInfo.itemWeight > freightInfo.conversionWeight
                        ? this.getChargeableWeight(appConfig.CHARGEABLE_WEIGHT_DIV.ITEM_WEIGHT)
                        : this.getChargeableWeight(
                            appConfig.CHARGEABLE_WEIGHT_DIV.CONVERSION_WEIGHT
                          );
                  } else {
                    freightInfo.chargeableWeight =
                      freightInfo.applyWeightClsCd ==
                      appConfig.APPLY_WEIGHT_CLS_DIV.ITEM_WEIGHT_CALC
                        ? this.getChargeableWeight(appConfig.CHARGEABLE_WEIGHT_DIV.ITEM_WEIGHT)
                        : this.getChargeableWeight(
                            appConfig.CHARGEABLE_WEIGHT_DIV.CONVERSION_WEIGHT
                          );
                  }
                }
                // 元請利益の設定
                freightInfo.primeContractorBenefit = this.parseStringAmount(
                  this.paymentInfoList.find(
                    (v) => v.taskDiv == appConfig.TASK_DIV.PRIME_CONTRACTOR_BENEFIT
                  ).paymentAmount
                );
                // 利益按分の設定
                freightInfo.benefitRatio = this.calcBenefitRatio(this.paymentInfoList);
                this.freightInfoList.push(freightInfo);
              }

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * No採番処理
     */
    numberingNo(list) {
      list.forEach((v, index) => {
        v.no = index + 1;
      });
      return list;
    },
    /**
     * 換算量計算処理
     */
    calcConversionWeight(freightSizeL, freightSizeW, freightSizeH) {
      // 換算量
      let conversionWeight = 0;
      // 縦・横・高さの積
      let freightMulti = freightSizeL * freightSizeW * freightSizeH * 100;
      // 換算量計算
      conversionWeight =
        Math.ceil(
          (freightMulti / appConfig.CONVERSION_WEIGHT_VAL.CONVERSIONVAL1) *
            appConfig.CONVERSION_WEIGHT_VAL.CONVERSIONVAL2 *
            appConfig.CONVERSION_WEIGHT_VAL.CONVERSIONVAL3
        ) / 100;
      return conversionWeight;
    },
    /**
     * 利益按分の合計値計算処理
     */
    calcBenefitRatio(payList) {
      let sum = 0;
      // 計算区分がシステムかつ作業区分が利益按分の要素のみにフィルター
      const payListForBenefitRatio = payList.filter(
        (v) =>
          v.calcDiv == appConfig.CALCULATION_DIV.SYSTEM &&
          v.taskDiv == appConfig.TASK_DIV.PROFIT_DISTRIBUTION
      );
      // 利益按分の金額の合計計算
      sum = payListForBenefitRatio.reduce(
        (sum, v) => sum + this.parseStringAmount(v.paymentAmount) * 1000,
        0
      );
      return Math.ceil(sum) / 1000;
    },
    /**
     * 文字列の金額値を数値に変換し、返却
     */
    parseStringAmount(amount) {
      const parseReslut = commonUtil.zen2han(amount).replaceAll(",", "").replaceAll("￥", "");
      return Number(parseReslut);
    },

    /**
     * 支払金額が編集可能かどうかの判定処理
     */
    isEditable(item) {
      return item.chargeOfficeSid == this.officeOrgSid ? true : false;
    },

    /**
     * 円マーク、カンマを付与
     */
    formatCost(num) {
      if (Math.sign(num) >= 0) {
        return Number.isInteger(num)
          ? num.toLocaleString("ja-JP", {
              style: "currency",
              currency: "JPY",
            })
          : num.toLocaleString("ja-JP", {
              style: "currency",
              currency: "JPY",
              minimumFractionDigits: 2,
            });
      } else {
        return "￥-".concat(Math.abs(num).toLocaleString());
      }
    },

    /**
     * パーセントマークを付与
     */
    formatRatio(num) {
      let calcNum = 0;
      if (num != 0) {
        calcNum = num / 100;
      } else {
        return "";
      }
      return Number.isInteger(num)
        ? calcNum.toLocaleString("ja-JP", {
            style: "percent",
          })
        : calcNum.toLocaleString("ja-JP", {
            style: "percent",
            minimumFractionDigits: 2,
          });
    },

    /**
     * テーブルリスト開閉処理
     */
    expandList(table) {
      this.tableVisible[table] = !this.tableVisible[table];
      this.updateTableHeights();
    },
    /**
     * 各テーブルの高さ自動調整処理
     */
    updateTableHeights() {
      // 各テーブルの高さを初期化
      Object.assign(this.tableHeights, this.baseHeights);
      // 各テーブルのキーを取得
      const baseTable = Object.keys(this.baseHeights).map(String);
      // 表示されているテーブルのキーを取得
      const visibleTables = Object.keys(this.tableVisible)
        .filter((key) => this.tableVisible[key])
        .map(String);
      const totalHeight = baseTable.reduce((sum, key) => sum + this.baseHeights[key], 0);
      // 全てのテーブルが非表示の場合
      if (visibleTables.length === 0) {
        return;
      }
      // 貨物情報テーブルのみ表示されている場合
      if (
        visibleTables.length === 1 &&
        visibleTables.includes(appConfig.SALES_CLAIM_TABLE.FREIGHT_INFO)
      ) {
        return;
      }
      // 2つのテーブルが表示されていて、そのうちの一つが貨物情報の場合
      if (
        visibleTables.length === 2 &&
        visibleTables.includes(appConfig.SALES_CLAIM_TABLE.FREIGHT_INFO)
      ) {
        const updateHeight =
          totalHeight - this.baseHeights[appConfig.SALES_CLAIM_TABLE.FREIGHT_INFO];
        // 貨物情報以外のテーブルに全体の高さ - 貨物情報のテーブルの高さの値を設定
        visibleTables.forEach((key) => {
          if (key !== appConfig.SALES_CLAIM_TABLE.FREIGHT_INFO) {
            this.tableHeights[key] = updateHeight;
          }
        });
        return;
      }
      // 表示されているテーブルの高さの合計
      const totalBaseHeight = visibleTables.reduce((sum, key) => sum + this.baseHeights[key], 0);
      // 表示されているテーブルの高さの合計に対するそれぞれのテーブルの元の高さの割合から高さを調整
      visibleTables.forEach((key) => {
        this.tableHeights[key] = Math.floor(
          (this.baseHeights[key] / totalBaseHeight) * totalHeight
        );
      });
    },
    /**
     * 行追加ボタン押下時処理
     */
    addItem(table) {
      // 行追加アイテム
      let addDefaultItem = {};
      // 行追加対象一覧リスト
      let addTargetList = [];
      // 行追加
      switch (table) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          this.fareBillDefaultItem.billingAmount = this.formatCost(0);
          addDefaultItem = this.fareBillDefaultItem;
          addTargetList = this.fareBillingInfoList;
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          this.paymentDefaultItem.paymentAmount = this.formatCost(0);
          addDefaultItem = this.paymentDefaultItem;
          addTargetList = this.paymentInfoList;
          break;
        default:
      }
      // リスト行追加
      this.addListItem(addDefaultItem, addTargetList);
      // 追加行まで自動スクロール
      this.scrollToEnd(table);
    },

    /**
     * リスト行追加処理
     */
    addListItem(defalutItem, list) {
      let tmpItem = {};
      let index = 0;
      this.autoNoCount = this.autoNoCount + 1;
      tmpItem = Object.assign({}, defalutItem);
      index = this.createIndex(list);
      tmpItem.no = index; // No
      tmpItem.autoNo = this.autoNoCount; // No
      tmpItem.chargeOffice = this.officeOrg.slice(4).replaceAll("）", ""); // 輸送担当営業所
      tmpItem.chargeOfficeSid = this.officeOrgSid; // 輸送担当営業所SID
      tmpItem.freightOrderSid = this.freightOrdersNo; // 貨物受注SID
      tmpItem.pairNo = index; // ペア番号
      tmpItem.yyyymm = this.orderMonth.replaceAll("/", ""); // 月度
      tmpItem.businessNo = this.bizNo; // 業務No
      tmpItem.clientSid = this.suppliersSelected; // 取引先SID
      tmpItem.officeSidOrg = this.officeOrgSid; // 元請営業所SID
      tmpItem.updateDateTime = list[0].updateDateTime; // 更新日時
      tmpItem.isNewAddRow = true;
      list.push(Object.assign({}, tmpItem));
    },

    /**
     * 最終行にスクロールを移動する
     */
    scrollToEnd(tableNm) {
      let table;
      let lastIndex = 0;
      switch (tableNm) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          table = this.$refs.fareBillList.$el.childNodes[0];
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          table = this.$refs.paymentList.$el.childNodes[0];
          break;
        default:
      }
      lastIndex = table.childNodes[0].rows.length - 1;
      // 行追加後にスクロールバーの高さが変更されるまで待機した後に処理実行
      setTimeout(function () {
        table.scrollTo(0, table.childNodes[0].rows[lastIndex].offsetTop);
      }, 100);
    },
    /**
     * インデックスを作成する
     */
    createIndex(list) {
      if (list.length == 0) {
        return 0;
      }
      return list[list.length - 1].no + 1;
    },
    /**
     * 金額変更時の処理
     */
    changeAmount(item, index, table) {
      switch (table) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          if (this.$refs.editedFareBillList.validate()) {
            let amount = item.billingAmount.replaceAll(",", "").replaceAll("￥", "");
            // 表示用リストに変更後の請求金額を設定
            this.fareBillingInfoList[index].billingAmount =
              Number(amount) != 0 ? this.formatCost(Number(amount)) : this.formatCost(0);
          }
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          if (this.$refs.editedPaymentList.validate()) {
            let amount = item.paymentAmount.replaceAll(",", "").replaceAll("￥", "");
            // 表示用リストに変更後の支払金額を設定
            this.paymentInfoList[index].paymentAmount =
              Number(amount) != 0 ? this.formatCost(Number(amount)) : this.formatCost(0);
          }
          break;
        default:
      }
      // 請求金額・支払金額の合計値計算処理
      this.sumAmount(table);
    },

    /**
     * G勘定科目変更時の処理
     */
    changeAccountTitle(val, index, table) {
      let changeList = [];
      switch (table) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          changeList = this.fareBillingInfoList;
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          changeList = this.paymentInfoList;
          break;
        default:
      }
      // G請求項目リストを変更値でフィルター
      const accountFind = this.accountTitleList.find((v) => v.value == val);
      // 対象の一覧リストの請求項目SIDを変更
      this.changeListInvoiceItemSid(accountFind, index, changeList);
    },

    /**
     * 対象リストの請求項目SID変更処理
     */
    changeListInvoiceItemSid(accountInfo, index, changeList) {
      if (accountInfo ?? false) {
        changeList[index].invoiceItemSid = accountInfo.sid;
      }
    },

    /**
     * 削除ボタン押下時処理
     */
    deleteItem(index, table) {
      let deleteTargetList = [];
      switch (table) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          deleteTargetList = this.fareBillingInfoList;
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          deleteTargetList = this.paymentInfoList;
          break;
        default:
      }
      // 行削除
      this.deleteListItem(deleteTargetList, index);
    },

    /**
     * 一覧表示テーブル行削除処理
     */
    deleteListItem(list, index) {
      list.splice(index, 1);
      list.forEach((v, index) => {
        v.no = index + 1;
      });
      this.autoNoCount = this.autoNoCount - 1;
    },

    /**
     * 合計額の計算処理
     */
    sumAmount(table) {
      let sum = 0;
      let payListForSystem = [];
      switch (table) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          sum = this.fareBillingInfoList.reduce(
            (sum, v) => sum + this.parseStringAmount(v.billingAmount) * 1000,
            0
          );
          this.freightInfoList[0].billingAmount = Math.ceil(sum) / 1000;
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          payListForSystem = this.paymentInfoList.filter(
            (v) =>
              v.calcDiv == appConfig.CALCULATION_DIV.SYSTEM && !this.isPrimeContOrBenefitDistTask(v)
          );
          sum = payListForSystem.reduce(
            (sum, v) => sum + this.parseStringAmount(v.paymentAmount) * 1000,
            0
          );
          this.freightInfoList[0].paymentAmount = Math.ceil(sum) / 1000;
          break;
        default:
      }
    },

    /**
     * 戻るボタン押下時処理
     */
    confirmBackPage() {
      // 編集破棄ダイアログを表示する。
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_004_W");
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.backPage;
    },
    /**
     * 売上請求一覧画面に戻る
     */
    backPage() {
      // 売上請求一覧画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_CLA_004,
        params: {
          officeOrg: this.$route.params.listofficeOrg,
          supplier: this.$route.params.listSupplier,
          businessNo: this.$route.params.listBusinessNo,
          month: this.$route.params.month,
        },
      });
    },

    /**
     * CSV出力ボタン押下
     */
    download() {
      console.log("CSV出力開始");
    },

    /**
     * コードマスタ情報取得
     */
    async getCodeMst() {
      // ローディング画面表示ON;
      this.loadingCounter = 1;
      // 集荷配達区分値リスト(幹線を除く)
      const pickupDeliveryList = ["01", "02"];
      // 適用重量
      const tmpChargeableWeightList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.APPLY_WEIGHT_DIV,
      });
      // 項目詳細
      const tmpItemDetailList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.PICKUP_DELIVERYL_DIV,
      });
      // 作業内容
      const tmpWorkContentsList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.TASK_DIV,
      });
      await Promise.all([tmpChargeableWeightList, tmpItemDetailList, tmpWorkContentsList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.chargeableWeightList = result[0];
          const tmpItemDetail = result[1];
          pickupDeliveryList.forEach((v) => {
            tmpItemDetail.forEach((val) => {
              if (v == val.value) {
                this.itemDetailList.push(val);
              }
            });
          });
          const tmpWorkContents = result[2];
          tmpWorkContents.forEach((v) => {
            if (
              v.value != appConfig.TASK_DIV.PRIME_CONTRACTOR_BENEFIT &&
              v.value != appConfig.TASK_DIV.PROFIT_DISTRIBUTION
            ) {
              this.workContentsList.push(v);
            }
          });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 按分内訳モーダル表示処理
     */
    openDetailInputDialog(item) {
      console.log(item);
    },

    /**
     * 本画面と備考入力ダイアログとのデータ受け渡しを行う関数
     */
    parentMethod: async function (item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1, item);
    },

    /**
     * 登録ボタン押下時の処理
     */
    viewConfirmDialog(table) {
      let message;
      let action = () => {};
      let isValidTable;
      switch (table) {
        case appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO:
          this.registTargetTable = table;
          isValidTable = this.$refs.editedFareBillList.validate();
          break;
        case appConfig.SALES_CLAIM_TABLE.PAYMENT_INFO:
          isValidTable = this.$refs.editedPaymentList.validate();
          break;
        default:
          message = "";
          isValidTable = true;
      }
      // 一覧テーブルのバリデーションエラーがある場合、処理終了
      if (!isValidTable) {
        return;
      }
      this.registTargetTable = table;
      message = messsageUtil.getMessage("P-RCV-001_003_C");
      action = this.invoiceActRegist;
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },

    /**
     * 請求実績登録
     */
    invoiceActRegist() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CLA_005;
      let isSuccess = false;
      // 登録対象リスト
      let targetList = [];
      if (this.registTargetTable == appConfig.SALES_CLAIM_TABLE.FARE_BILL_INFO) {
        // リスト対象を入れる
        targetList = this.fareBillingInfoList;
      } else {
        targetList = this.paymentInfoList;
      }
      // 請求実績リスト
      body.reqIdv.invoiceActList = this.makeBodyList(targetList);
      // 登録処理
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INVOICE_ACT_INSERT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("planRegistion() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = i18n.tc("label.lbl_result");
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.firstPageFlag = false;
              isSuccess = true;
              resolve();
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.salesClaimListFlg = false;
              this.infoDialog.salesClaimDetailFlg = false;
              this.infoDialog.outsideClickNotCloseFlg = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex.message;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.salesClaimListFlg = false;
            this.infoDialog.salesClaimDetailFlg = false;
            this.infoDialog.outsideClickNotCloseFlg = false;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            if (isSuccess) {
              this.getInvoiceActList();
              this.endEvent();
            }
          });
      });
    },

    /**
     * イベント終了ダイアログ表示
     */
    endEvent() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = i18n.tc("label.lbl_result");
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      // 画面遷移ボタン
      this.infoDialog.homePageFlag = true;
      this.infoDialog.salesClaimListFlg = true;
      this.infoDialog.salesClaimDetailFlg = true;
    },

    /**
     * リクエストボディリスト作成
     */
    makeBodyList(list) {
      let bodyList = [];
      list.forEach((v) => {
        bodyList.push(this.makeReqBody(v));
      });
      return bodyList;
    },

    /**
     * リクエストボディの設定
     */
    makeReqBody(item) {
      let inputMap = {};
      inputMap.freightOrderSid = item.freightOrderSid; // 貨物受注SID
      inputMap.autoNo = item.autoNo; // 自動連番
      inputMap.pairNo = item.pairNo; // ペア番号
      inputMap.yyyymm = item.yyyymm; // 月度
      inputMap.businessNo = item.businessNo; // 業務No
      inputMap.clientSid = item.clientSid; // 取引先SID
      inputMap.officeSidOrg = item.officeSidOrg; // 元請営業所SID
      inputMap.officeSidTrans = item.chargeOfficeSid; // 輸送担当営業所SID
      if (item.processDiv == appConfig.PROCESS_DIV.ACCOUNTS_RECEIVABLE) {
        inputMap.billingAmount = this.parseStringAmount(item.billingAmount); // 請求金額
      } else {
        inputMap.paymentAmount = this.parseStringAmount(item.paymentAmount); // 支払金額
      }
      inputMap.processDiv = item.processDiv; // 処理区分
      inputMap.calcDiv = item.calcDiv; // 計算区分
      inputMap.updateDateTime = item.updateDateTime; // 更新日時

      if (
        item.calcDiv == appConfig.CALCULATION_DIV.SYSTEM &&
        !this.isPrimeContOrBenefitDistTask(item)
      ) {
        inputMap.officeSidCrewAffiliation = item.belongOfficeSid; // 乗務員所属営業所SID
        inputMap.fromPointSid = item.departurePointSid; // 発地拠点SID
        inputMap.toPointSid = item.landingPointSid; // 到着拠点SID
        inputMap.driverUserSid = item.crewSid; // 乗務員SID
        inputMap.vehicleSid = item.vehicleSid; // 社号SID
        inputMap.invoiceItemSid = item.invoiceItemSid; // 請求項目SID
        inputMap.unitPriceSid = item.unitPriceSid; // 単価SID
        inputMap.unitPriceCd = item.unitPriceCd; // 単価CD
        inputMap.unitPriceCdName = item.unitPriceCdName; // 単価CD名称
        inputMap.unitPriceDetail = item.unitPriceDetail; // 単価内訳
        inputMap.pickupDeliveryDiv = item.itemDetailCd; // 集荷配達区分
        inputMap.taskDiv = item.taskDiv; // 作業区分
        inputMap.taxDiv = item.taxDiv; // 税区分
      } else {
        // 乗務員所属営業所SID
        if (!this.isNullOrBlank(item.belongOfficeSid)) {
          inputMap.officeSidCrewAffiliation = item.belongOfficeSid;
        }
        // 発地拠点SID
        if (!this.isNullOrBlank(item.departurePointSid)) {
          inputMap.fromPointSid = item.departurePointSid;
        }
        // 到着拠点SID
        if (!this.isNullOrBlank(item.landingPointSid)) {
          inputMap.toPointSid = item.landingPointSid;
        }
        // 乗務員SID
        if (!this.isNullOrBlank(item.crewSid)) {
          inputMap.driverUserSid = item.crewSid;
        }
        // 社号SID
        if (!this.isNullOrBlank(item.vehicleSid)) {
          inputMap.vehicleSid = item.vehicleSid;
        }
        // 請求項目SID
        if (!this.isNullOrBlank(item.invoiceItemSid)) {
          inputMap.invoiceItemSid = item.invoiceItemSid;
        }
        // 単価SID
        if (!this.isNullOrBlank(item.unitPriceSid)) {
          inputMap.unitPriceSid = item.unitPriceSid;
        }
        // 単価CD
        if (!this.isNullOrBlank(item.unitPriceCd)) {
          inputMap.unitPriceCd = item.unitPriceCd;
        }
        // 単価CD名称
        if (!this.isNullOrBlank(item.unitPriceCdName)) {
          inputMap.unitPriceCdName = item.unitPriceCdName;
        }
        // 単価内訳
        if (!this.isNullOrBlank(item.unitPriceDetail)) {
          inputMap.unitPriceDetail = item.unitPriceDetail;
        }
        // 集荷配達区分
        if (!this.isNullOrBlank(item.itemDetailCd)) {
          inputMap.pickupDeliveryDiv = item.itemDetailCd;
        }
        // 作業区分
        if (!this.isNullOrBlank(item.taskDiv)) {
          inputMap.taskDiv = item.taskDiv;
        }
        // 税区分
        if (!this.isNullOrBlank(item.taxDiv)) {
          inputMap.taxDiv = item.taxDiv;
        }
      }
      // 期区分
      if (!this.isNullOrBlank(item.periodDiv)) {
        inputMap.periodDiv = item.periodDiv;
      }
      // 処理日
      if (!this.isNullOrBlank(item.yyyymmdd)) {
        inputMap.yyyymmdd = item.yyyymmdd;
      }
      // 件名
      if (!this.isNullOrBlank(item.title)) {
        inputMap.title = item.title;
      }
      // 区間距離
      if (!this.isNullOrBlank(item.distance)) {
        inputMap.distance = item.distance;
      }
      // 按分率
      if (!this.isNullOrBlank(item.distRatio)) {
        inputMap.distRatio = item.distRatio;
      }
      // 振替率
      if (!this.isNullOrBlank(this.freightInfoList[0].transferRation)) {
        inputMap.transferRation = this.freightInfoList[0].transferRation;
      }
      // タリフ金額
      if (!this.isNullOrBlank(item.tariffAmount)) {
        inputMap.tariffAmount = item.tariffAmount;
      }
      return inputMap;
    },

    /**
     * 存在チェック
     */
    isNullOrBlank(val) {
      if ((val ?? false) && val != "") {
        return false;
      }
      return true;
    },

    /**
     * 作業区分が元請利益か利益按分かの判定
     */
    isPrimeContOrBenefitDistTask(item) {
      return [
        appConfig.TASK_DIV.PRIME_CONTRACTOR_BENEFIT,
        appConfig.TASK_DIV.PROFIT_DISTRIBUTION,
      ].includes(item.taskDiv);
    },

    /**
     * 適用重量取得処理
     */
    getChargeableWeight(val) {
      return this.chargeableWeightList.find((v) => v.value == val).text;
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    operationDateCal: {
      handler(val) {
        this.operationDate = this.formatDate(val);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.table-row-class {
  background-color: #fff2cc;
}
.text-font {
  font-weight: bold;
  font-size: 20px;
}

.select-box {
  margin: 11px 0 0 0;
  height: 2.6rem;
}

.total-area {
  float: left;
  display: flex;
  align-items: center;
  width: 66%;
  flex-direction: row-reverse;
}

.total-disp-area {
  margin-right: 40px;
  font-weight: bold;
  font-size: 22px;
  border-bottom: solid;
}

.table-style {
  overflow-x: auto;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-data-table {
  white-space: pre-line;
}
.align-right {
  text-align: right;
}
.align-right-red {
  text-align: right;
  color: red;
}
.align-left {
  text-align: left;
}
.align-left-red {
  color: red;
}

.payment-addRow-btn {
  margin: 0 0 0 93px;
  width: 7rem;
  background-color: #1976d2;
  color: #ffffff;
}
.break-down {
  border-bottom: solid;
}
</style>
