<template>
  <v-dialog v-model="trunkPointLoadingPacking" scrollable width="max-content" max-width="75vw">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="api-btn"
        style="position: absolute; top: -5px; right: -5px"
        color="primary"
        elevation="2"
        v-bind="attrs"
        v-on="on"
        @click="getTrunkPointLoadingPacking(transportSid, operationDate)"
      >
        {{ openLabel }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title id="sub-bar" class="pa-4" style="height: 60px">
        {{ dialogTitle }}
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row class="trunk-pattern">
            <div class="text-subtitle-1 text">
              <span>{{ trunkDiagramName }}</span>
            </div>
          </v-row>
          <v-row v-if="hasError">
            <v-card-text class="pa-4">
              <p style="white-space: pre-wrap; min-width: 40vw">{{ errorMessage }}</p>
            </v-card-text>
          </v-row>
          <v-row v-else class="trunk-point-list">
            <div
              class="trunk-point-data"
              v-for="trunkPoint in trunkPointList"
              :key="trunkPoint.pointOrder"
            >
              <div class="text">{{ trunkPoint.pointName }}</div>
              <div>
                <span>{{ `${$t("label.lbl_sumWeight")}:` + sumWeight(trunkPoint.lotList) }}kg</span>
                &nbsp;
                <span>{{ `${$t("label.lbl_sumVolume")}:` + sumVolume(trunkPoint.lotList) }}㎥</span>
              </div>
              <v-data-table
                class="trunk-point"
                dense
                :headers="headers"
                :items="trunkPoint.lotList"
                item-key="lotSid"
                hide-default-header
                hide-default-footer
              >
                <template v-slot:header="{ props: { headers } }">
                  <thead>
                    <tr>
                      <th v-for="h in headers" :class="h.class" :key="h.text">
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:[`item.totalWeight`]="{ item }">
                  <div>{{ item.totalWeight + `${$t("label.lbl_kg")}` }}</div>
                </template>
              </v-data-table>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- 閉じるボタン -->
        <v-btn class="other-btn" @click="close">
          {{ $t("btn.btn_close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import { i18n } from "../../lang/lang.js";

export default {
  name: "TrunkPointLoadingPacking",
  props: {
    dialogTitle: { type: String, default: "" },
    openLabel: { type: String, default: "" },
    trunkDiagramName: { type: String, default: "" },
    directionFlg: { type: String, default: "" },
    transportSid: { type: String, default: "" },
    operationDate: { type: String, default: "" },
  },
  data() {
    return {
      trunkPointLoadingPacking: false,
      trunkPointList: [],
      headers: [
        {
          text: i18n.tc("label.lbl_lotNumber"),
          value: "lotSid",
          width: "auto",
          class: "header",
          sortable: false,
        },
        {
          text: i18n.tc("label.lbl_totalLotWeight"),
          value: "totalWeight",
          width: "auto",
          class: "header",
          sortable: false,
        },
        {
          text: i18n.tc("label.lbl_packingNumInLot"),
          value: "itemCount",
          width: "auto",
          align: "center",
          class: "header",
          sortable: false,
        },
      ],
      errorMessage: "",
    };
  },
  computed: {
    hasError() {
      return !!this.errorMessage;
    },
  },
  watch: {
    trunkPointLoadingPacking: function (newValue) {
      if (newValue) {
        // ダイアログを開く際に各種プロパティを初期化する
        this.init();
      }
    },
  },
  methods: {
    init() {
      this.trunkPointList = [];
      this.errorMessage = "";
    },
    /**
     * 幹線拠点積込貨物一覧取得処理テスト用
     */
    getTrunkPointLoadingPackingTest(transportSid, operationDate) {
      console.log(transportSid);
      console.log(operationDate);
      const trunkPointData = {
        trunkDiagramName: "テスト用幹線ダイヤ",
        trunkPointList: [
          {
            pointOrder: 1,
            pointName: "狭山",
            lotList: [
              {
                lotSid: "L00001",
                lotOrder: 1,
                totalWeight: 500,
                totalVolume: 500,
                itemCount: 2,
              },
              {
                lotSid: "L00002",
                lotOrder: 2,
                totalWeight: 200,
                totalVolume: 1000,
                itemCount: 1,
              },
              {
                lotSid: "L00003",
                lotOrder: 3,
                totalWeight: 200,
                totalVolume: 1000,
                itemCount: 1,
              },
              {
                lotSid: "L00004",
                lotOrder: 4,
                totalWeight: 200,
                totalVolume: 1000,
                itemCount: 1,
              },
              {
                lotSid: "L00005",
                lotOrder: 5,
                totalWeight: 200,
                totalVolume: 1000,
                itemCount: 1,
              },
              {
                lotSid: "L00006",
                lotOrder: 6,
                totalWeight: 200,
                totalVolume: 1000,
                itemCount: 1,
              },
            ],
          },
          {
            pointOrder: 2,
            pointName: "鈴鹿",
            lotList: [
              {
                lotSid: "L00007",
                lotOrder: 1,
                totalWeight: 300,
                totalVolume: 400,
                itemCount: 3,
              },
            ],
          },
        ],
      };

      this.trunkPointList = trunkPointData.trunkPointList;
    },

    /**
     * 幹線拠点積込貨物一覧取得処理
     */
    getTrunkPointLoadingPacking(transportSid, operationDate) {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.transportSid = transportSid;
      config.params.operationDate = operationDate;
      // 接続先のAPI_URLを入力
      return this.$httpClient
        .secureGet(appConfig.API_URL.BIZ_TRUNK_POINT_LOADING_PACKING_SEARCH, config)
        .then(
          (response) => {
            const data = response.data;
            if (data.resCom.resComCode !== "000") {
              const message =
                data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
              throw new Error(message);
            }
            this.trunkPointList = data.resIdv.trunkPointList;
          },
          (error) => {
            const message =
              error.response?.data?.resCom?.resComMessage ??
              messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
        )
        .catch((e) => {
          this.errorMessage = e.message;
        });
    },
    sumWeight(lotList) {
      let sumWeight = 0;
      for (const lot of lotList) {
        sumWeight += lot.totalWeight;
      }
      const returnWeight = sumWeight == 0 ? 0 : commonFunction.getDelimiterFew(sumWeight);
      return returnWeight;
    },

    sumVolume(lotList) {
      let sumVolume = 0;
      for (const lot of lotList) {
        sumVolume += lot.totalVolume;
      }
      const returnVolume = sumVolume == 0 ? 0 : commonFunction.getDelimiterFew(sumVolume);
      return returnVolume;
    },

    close() {
      this.trunkPointLoadingPacking = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.trunk-pattern {
  padding-top: 10px;
  padding-left: 5px;
}

.trunk-point-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
}

.trunk-point-data {
  min-width: 20vw;
  padding-top: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.trunk-point {
  height: 300px;
  padding: 1%;
  --border-color: black;
  border: 2px solid !important;
  overflow-y: auto;
}

.text {
  color: black;
}

.header {
  background: $color-obj-base;
  font-size: bold;
}
</style>
