<template>
  <div class="d-flex align-start flex-nowrap">
    <v-text-field
      outline
      dense
      :value="textFieldValue"
      :label="label"
      clear-icon="mdi-close-circle"
      clearable
      :rules="[dateRule]"
      class="date-time-picker"
      @focus="focus = true"
      @blur="blurEvent($event)"
      @input="updateDateTimeText($event)"
    ></v-text-field>
    <span v-if="required" class="require asterisk-spacer">*</span>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="-100"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text x-small class="px-1">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>

      <DatePicker
        v-model="pickerDateTime"
        mode="dateTime"
        is24hr
        no-title
        @focusout="dateMenu = false"
      ></DatePicker>
    </v-menu>
  </div>
</template>

<script>
import { dateTimeHelper } from "@/assets/scripts/js/DateTimeHelper";
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import { DatePicker } from "v-calendar";

export default {
  name: "DateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    value: String,
    label: String,
    required: { type: Boolean, default: false, required: false },
  },

  computed: {
    textFieldDate: {
      get() {
        return this.$props.value?.replaceAll("-", "/") ?? "";
      },

      set(newDate) {
        this.$emit("input", newDate?.replaceAll("/", "-") ?? "");
      },
    },

    pickerDateTime: {
      get() {
        return this.$props.value ?? "";
      },

      set(newDate) {
        this.$emit("input", newDate);
      },
    },
  },

  data() {
    return {
      dateMenu: false,
      focus: false,
      textFieldValue: this.$props.value?.replaceAll("-", "/") ?? "",
      errorMessage: "yyyy/MM/dd HH:mmで入力してください。",
      dateRule: (value) => this.validRule(value) || this.errorMessage,
    };
  },
  watch: {
    value(newValue) {
      this.textFieldValue = newValue?.replaceAll("-", "/") ?? "";
    },
  },

  methods: {
    validRule(time) {
      if (!time) return this.required ? false : true;
      return this.validDateTime(time);
    },
    validDateTime(dateTimeStr) {
      const strDate = dateTimeStr.slice(0, 10);
      const strTime = dateTimeStr.slice(11);
      const isDate =
        dateTimeHelper.validDate(commonUtil.zen2han(strDate), "/") &&
        dateTimeHelper.isDate(strDate);
      const isTime = dateTimeHelper.validTime(commonUtil.zen2han(strTime), ":");
      return isDate && isTime;
    },
    blurEvent(event) {
      console.log(event);
      this.focus = false;
      this.updateDateTimeText(event.target.value);
    },
    updateDateTimeText(value) {
      this.textFieldValue = value;
      if (this.focus) {
        return;
      }
      const dateStr = value?.replaceAll("-", "/") ?? "";
      const valid = this.validDateTime(dateStr);
      this.$nextTick(() => {
        if (!valid) {
          this.textFieldValue = "";
        }
        this.$emit("input", valid ? dateStr : "");
      });
    },
  },
};
</script>
