<template>
  <v-container class="pa-0 ma-0">
    <v-row dense>
      <v-col cols="12">
        <v-card outlined width="auto" class="pa-1" :class="vehicleBackgroundColor">
          <v-row no-gutters align="end">
            <v-col cols="12" class="d-flex justify-start flex-wrap">
              <div class="text-no-wrap">
                <span>{{ trunkPattern.trunkPatternName }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2 mx-1" v-show="showBinList"></v-divider>
          <div class="d-flex flex-column align-end mx-1" v-show="showBinList">
            <v-card
              v-for="bin in trunkPattern.trunkDiagramDispatchBinList"
              :key="bin.trunkDiagramSid"
              outlined
              dense
              width="70%"
              :style="{ display: 'inline-flex' }"
              elevation="1"
              class="mb-1 transport"
            >
              <v-card-text class="pa-1 bin-card">
                <v-icon> mdi-truck-outline </v-icon>
                <span class="ml-3">{{
                  dateTimeHelper.convertUTC2JST(bin.fromDate).substring(11, 16) +
                  "~" +
                  dateTimeHelper.convertUTC2JST(bin.toDate).substring(11, 16)
                }}</span>
              </v-card-text>
              <div class="eye-toggle">
                <v-btn
                  icon
                  small
                  elevation="2"
                  @click="$emit('visible-bin', trunkPattern.trunkPatternSid, bin.trunkDiagramSid)"
                >
                  <v-icon>{{ bin.visible ? "mdi-eye" : "mdi-eye-off" }} </v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";

export default {
  name: "TrunkPatternItem",
  props: {
    // 幹線パターン情報
    trunkPattern: { type: Object, required: true },
  },
  data() {
    return {
      dateTimeHelper: dateTimeHelper,
    };
  },
  computed: {
    showBinList() {
      return this.trunkPattern.dispatchBinList?.length ?? false;
    },
    // 社有か
    isOwnVehicle() {
      return this.trunkPattern.carTransportCompDiv === "01";
    },
    vehicleBackgroundColor() {
      return this.isOwnVehicle ? "" : "chartered-vehicle";
    },
  },
  methods: {},
};
</script>

<style scoped>
.bin-card {
  margin: auto 0px;
}

.eye-toggle {
  padding: 4px;
}

.chartered-vehicle {
  background-color: rgb(from #c5cae9 r g b / 60%) !important;
  border: 1px solid #e0e0e0 !important;
}

.chartered-vehicle .transport {
  border: 1px solid #bdbdbd !important;
}
</style>
