<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_cargoStatusDetailPage" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 1行目 -->
          <v-row class="search-row">
            <!-- お客様管理番号 -->
            <div class="inquiry-number-search-item first-search-item">
              <v-text-field
                outlined
                dense
                v-model="individualNo"
                :label="$t('label.lbl_clientControlNumber')"
                :disabled="true"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <!-- お届け予定日-->
            <div class="date-style first-search-item">
              <v-text-field
                outlined
                dense
                v-model="expectedDeliveryDate"
                :label="$t('label.lbl_expectedDeliveryDate')"
                :disabled="true"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
          </v-row>

          <!--2行目-->
          <v-row>
            <div class="search-row-exeBtn">
              <div class="btn-search-area" style="float: left">
                <!-- 登録ボタン -->
                <v-btn
                  v-if="isDisplayByRule()"
                  color="primary"
                  class="api-btn"
                  @click="regist()"
                  v-bind:disabled="isBottonDisabled()"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
                <!--戻るボタン-->
                <v-btn class="other-btn" @click="backScreen()">{{ $t("btn.btn_back") }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
        <span class="item-spacer">&nbsp;&nbsp;&nbsp;</span>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :ripple="false"
            :hide-default-footer="true"
            disable-filtering
            height="510px"
          >
            <!-- 出発日時 -->
            <template v-slot:[`item.departureDateTime`]="{ item }">
              <div v-if="isTextDisabled(item.dateTimeEditFlg)">
                {{ item.departureDateTime }}
              </div>
              <div class="detail-date-row" v-if="!isTextDisabled(item.dateTimeEditFlg)">
                <v-text-field
                  outlined
                  dense
                  v-model="item.departureDateTime"
                  @input="inputDepartureDateTime(Number(item.autoNo) - 1)"
                  class="txt-single detail-date-text"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="departureDateTimeMessage[Number(item.autoNo) - 1]"
                ></v-text-field>
                <v-menu
                  v-model="item.departureDateTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="departureDateTimeCalList[Number(item.autoNo) - 1]"
                    @input="item.departureDateTimeMenu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </template>

            <!-- 到着日時 -->
            <template v-slot:[`item.arrivalDateTime`]="{ item }">
              <div v-if="isTextDisabled(item.dateTimeEditFlg)">
                {{ item.arrivalDateTime }}
              </div>
              <div class="detail-date-row" v-if="!isTextDisabled(item.dateTimeEditFlg)">
                <v-text-field
                  outlined
                  dense
                  v-model="item.arrivalDateTime"
                  @input="inputArrivalDateTime(Number(item.autoNo) - 1)"
                  class="txt-single detail-date-text"
                  clear-icon="mdi-close-circle"
                  clearable
                  :error-messages="arrivalDateTimeMessage[Number(item.autoNo) - 1]"
                ></v-text-field>
                <v-menu
                  v-model="item.arrivalDateTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="arrivalDateTimeCalList[Number(item.autoNo) - 1]"
                    @input="item.arrivalDateTimeMenu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </template>
            <!-- 貨物状況 -->
            <template v-slot:[`item.cargoStatusName`]="{ item }">
              <span :class="getStatusClass(item.cargoStatus)">
                {{ item.cargoStatusName }}
              </span>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
      />
    </v-sheet>
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { commonFunction } from "../../assets/scripts/js/CommonFunction";

import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import NavBar from "../../components/NavBar.vue";

export default {
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // お届け予定日
    expectedDeliveryDate: "",
    // お客様管理番号
    individualNo: "",
    // 明細一覧
    headerItems: [
      {
        text: i18n.tc("label.lbl_No"),
        value: "autoNo",
        width: "60px",
        sortable: false,
        align: "center",
        rowspan: "2",
      },
      // 担当営業所
      {
        text: i18n.tc("label.lbl_office"),
        value: "salesOffice",
        sortable: false,
        width: "250px",
        align: "left",
      },
      // 発地
      {
        text: i18n.tc("label.lbl_departureBase"),
        value: "departureLocation",
        sortable: false,
        width: "355px",
        align: "left",
      },
      // 着地
      {
        text: i18n.tc("label.lbl_deliveryBase"),
        value: "arrivalLocation",
        sortable: false,
        width: "355px",
        align: "left",
      },
      // 出発日時
      {
        text: i18n.tc("label.lbl_departureDateTime"),
        value: "departureDateTime",
        sortable: false,
        width: "315px",
        align: "center",
      },
      // 到着日時
      {
        text: i18n.tc("label.lbl_arrivalDateTime"),
        value: "arrivalDateTime",
        sortable: false,
        width: "315px",
        align: "center",
      },
      // 貨物状況
      {
        text: i18n.tc("label.lbl_cargoStatus"),
        value: "cargoStatusName",
        sortable: false,
        width: "100px",
        align: "center",
      },
    ],
    inputList: [],
    inputListOld: [],
    departureDateTimeCalList: [],
    arrivalDateTimeCalList: [],
    departureDateTimeMessage: [],
    arrivalDateTimeMessage: [],
    // 戻る用パラメータ
    backParam: "",
    // エラーメッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // 確認メッセージダイアログ
    confirmDialog: {
      isOpen: false,
      message: "",
      okAction: () => {},
    },
  }),
  created() {},
  methods: {
    init() {
      // 初期データの取得
      if (this.$route.params.individualNo) {
        // お届け予定日
        this.expectedDeliveryDate = this.$route.params.changeArrivalScheduledDate;
        // お客様管理番号
        this.individualNo = this.$route.params.individualNo;
        // 荷主取引先CD
        this.shipperClientCd = this.$route.params.shipperClientCd;
        // 貨物詳細情報を取得
        this.getApiList();
      }
      // 前画面へ戻る時のパラメータ
      if (this.$route.params.searchParam) {
        this.backParam = this.$route.params.searchParam;
      }
    },

    // 明細データの取得
    getApiList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // お客様管理番号
      config.params.individualNo = this.individualNo;
      // 荷主取引先CD
      config.params.shipperClientCd = this.shipperClientCd;

      this.inputList = [];
      this.inputListOld = [];
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_CARGO_STATUS_DETAIL_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              jsonData.resIdv.deliveryDetailList.forEach((item) => {
                // ステータス名
                if (item.cargoStatus == "unknown") {
                  item.cargoStatusName = "不明";
                } else {
                  item.cargoStatusName = "";
                }

                // 出発日時
                if (item.departureDateTime != "") {
                  this.departureDateTimeCalList.push(
                    dateTimeHelper.dateCalc(item.departureDateTime.substr(0, 10))
                  );
                } else {
                  this.departureDateTimeCalList.push(null);
                }

                // 到着日時
                if (item.arrivalDateTime != "") {
                  this.arrivalDateTimeCalList.push(
                    dateTimeHelper.dateCalc(item.arrivalDateTime.substr(0, 10))
                  );
                } else {
                  this.arrivalDateTimeCalList.push(null);
                }

                let itemOld = {};
                for (let key in item) {
                  itemOld[key] = item[key];
                }
                this.inputListOld.push(itemOld);
              });
              this.inputList = jsonData.resIdv.deliveryDetailList;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
            resolve(response);
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 登録ボタン押下時の処理
     */
    regist() {
      // 明細の日付チェック
      if (!this.checkDetailDate()) {
        return;
      }

      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_insert"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.okAction = this.insertOkClick;
    },
    insertOkClick() {
      this.getInputListForCheck();
      // 貨物状況実績登録
      let recordCompletedDispatchInfo = {
        // 配車実績明細
        completedDispatchList: [],
      };

      this.inputList.forEach((item, index) => {
        if (item.dateTimeEditFlg != "0") {
          if (
            this.inputListOld[index].departureDateTime != item.departureDateTime ||
            this.inputListOld[index].arrivalDateTime != item.arrivalDateTime
          ) {
            let inputInfo = {};
            // 操作画面名
            inputInfo.opeDispName = i18n.tc("label.lbl_cargoStatusDetailPage");
            // 送り状Sid
            inputInfo.invNumSid = item.invNumSid;
            // 出発日時
            if (item.departureDateTime) {
              inputInfo.departureDateTime = dateTimeHelper.convertUTC(item.departureDateTime);
            }
            // 到着日時
            if (item.arrivalDateTime) {
              inputInfo.arrivalDateTime = dateTimeHelper.convertUTC(item.arrivalDateTime);
            }
            recordCompletedDispatchInfo.completedDispatchList.push(inputInfo);
          }
        }
      });

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TRN_004;

      body.reqIdv.recordCompletedDispatchInfo = recordCompletedDispatchInfo;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(貨物状況実績登録)
          .securePost(appConfig.API_URL.BIZ_RECORD_COMPLETED_DISPATCH, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_complete"),
              ]);
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.sixPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.firstPageFlag = false;
              // 貨物詳細情報を取得
              this.getApiList();
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.dataOutPutDialog = false;
          });
      });
    },

    /**
     * 明細日付の再編集
     */
    getInputListForCheck() {
      for (let index = 0; index < this.inputList.length; index++) {
        let item = this.inputList[index];

        if (item.dateTimeEditFlg == "0") {
          continue;
        }

        // 出発日時
        item.departureDateTime = this.nullToBlank(item.departureDateTime);
        item.departureDateTimeError = false;
        if (item.departureDateTime != "") {
          if (this.checkDate_yyyyMMddhhmm(item.departureDateTime) != true) {
            item.departureDateTimeError = true;
          }
        }

        // 到着日時
        item.arrivalDateTime = this.nullToBlank(item.arrivalDateTime);
        item.arrivalDateTimeError = false;
        if (item.arrivalDateTime != "") {
          if (this.checkDate_yyyyMMddhhmm(item.arrivalDateTime) != true) {
            item.arrivalDateTimeError = true;
          }
        }
      }

      for (let index = 0; index < this.inputList.length; index++) {
        let item = this.inputList[index];
        if (item.dateTimeEditFlg == "0") {
          continue;
        }
        if (
          this.nullToBlank(item.departureDateTime) == "" &&
          this.nullToBlank(item.arrivalDateTime) == ""
        ) {
          continue;
        }
        if (item.departureDateTimeError || item.arrivalDateTimeError) {
          continue;
        }

        // 直後の出発日時
        let nextDepartureDateTime = this.getNextDepartureDateTime(index + 1);
        // 直前の到着日時
        let prevArrivalDateTime = this.getPrevArrivalDateTime(index - 1);

        // 出発日時
        if (item.isDepartureCalendarInput) {
          if (
            prevArrivalDateTime != "" &&
            item.departureDateTime.substr(0, 10) == prevArrivalDateTime.substr(0, 10)
          ) {
            item.departureDateTime = prevArrivalDateTime;
          }
        }
        item.isDepartureCalendarInput = false;

        // 到着日時
        if (item.isDeliveryCalendarInput) {
          if (
            item.departureDateTime != "" &&
            item.arrivalDateTime.substr(0, 10) == item.departureDateTime.substr(0, 10)
          ) {
            // 同じ行に同日の出発日がある場合
            item.arrivalDateTime = item.departureDateTime;
          } else if (
            nextDepartureDateTime != "" &&
            item.arrivalDateTime.substr(0, 10) == nextDepartureDateTime.substr(0, 10)
          ) {
            // 次行に同日の出発日がある場合
            item.arrivalDateTime = nextDepartureDateTime;
          }
        }
        item.isDeliveryCalendarInput = false;
      }
      return this.inputList;
    },

    /**
     * 直前の到着日時
     */
    getPrevArrivalDateTime(index) {
      for (var i = index; i >= 0; i--) {
        if (this.inputList[i].arrivalDateTime) {
          return this.inputList[i].arrivalDateTime;
        }
        if (this.inputList[i].departureDateTime && !this.inputList[i].arrivalDateTime) {
          return this.inputList[i].departureDateTime;
        }
      }
      return "";
    },

    /**
     * 直後の出発日時
     */
    getNextDepartureDateTime(index) {
      for (var i = index; i < this.inputList.length; i++) {
        if (this.inputList[i].departureDateTime) {
          return this.inputList[i].departureDateTime;
        }
        if (!this.inputList[i].departureDateTime && this.inputList[i].arrivalDateTime) {
          return this.inputList[i].arrivalDateTime;
        }
      }
      return "";
    },

    /**
     * 明細の日付チェック
     */
    checkDetailDate() {
      if (!this.isDisabledByRule()) {
        return true;
      }

      let isError = false;
      let itemList = this.getInputListForCheck();

      for (let index = 0; index < itemList.length; index++) {
        let item = itemList[index];
        if (item.dateTimeEditFlg == "0") {
          continue;
        } else {
          item.departureDateTime = this.nullToBlank(item.departureDateTime);
          item.arrivalDateTime = this.nullToBlank(item.arrivalDateTime);
          this.departureDateTimeMessage[index] = "";
          this.arrivalDateTimeMessage[index] = "";

          if (item.departureDateTime == "" && item.arrivalDateTime == "") {
            continue;
          }

          // 型チェック
          let isFormatError = false;
          if (item.departureDateTime != "") {
            let message = this.checkDate_yyyyMMddhhmm(item.departureDateTime);
            if (message != true) {
              this.departureDateTimeMessage[index] = message;
              isFormatError = true;
              isError = true;
            }
          }
          if (item.arrivalDateTime != "") {
            let message = this.checkDate_yyyyMMddhhmm(item.arrivalDateTime);
            if (message != true) {
              this.arrivalDateTimeMessage[index] = message;
              isFormatError = true;
              isError = true;
            }
          }
          if (isFormatError) {
            continue;
          }

          // 必須チェック(どちらか片方しか選択されていない場合)
          if (item.arrivalDateTime == "") {
            this.arrivalDateTimeMessage[index] = i18n.tc("check.chk_input");
            isError = true;
            continue;
          }
          if (item.departureDateTime == "") {
            this.departureDateTimeMessage[index] = i18n.tc("check.chk_input");
            isError = true;
            continue;
          }
        }

        // 出発日時
        let departureDateTime = item.departureDateTime;
        // 直後の出発日時
        let nextDepartureDateTime = this.getNextDepartureDateTime(index + 1);

        // 到着日時
        let arrivalDateTime = item.arrivalDateTime;
        // 直前の到着日時
        let prevArrivalDateTime = this.getPrevArrivalDateTime(index - 1);

        // 出発日時>到着日時の場合
        if (new Date(departureDateTime).getTime() > new Date(arrivalDateTime).getTime()) {
          this.arrivalDateTimeMessage[index] = i18n.tc("check.chk_inputFutureDate2", 0, [
            i18n.tc("label.lbl_departureDateTime"),
          ]);
          isError = true;
        }

        // 直前の到着日>出発日の場合
        if (
          this.departureDateTimeMessage[index] == "" &&
          prevArrivalDateTime != "" &&
          this.checkDate_yyyyMMddhhmm(prevArrivalDateTime) == true &&
          new Date(prevArrivalDateTime).getTime() > new Date(departureDateTime).getTime()
        ) {
          this.departureDateTimeMessage[index] = i18n.tc("check.chk_inputFutureDate2", 0, [
            i18n.tc("label.lbl_prevArrivalDateTime"),
          ]);
          isError = true;
        }

        // 直後の出発日<到着日の場合
        if (
          this.arrivalDateTimeMessage[index] == "" &&
          nextDepartureDateTime != "" &&
          this.checkDate_yyyyMMddhhmm(nextDepartureDateTime) == true &&
          new Date(nextDepartureDateTime).getTime() < new Date(arrivalDateTime).getTime()
        ) {
          this.arrivalDateTimeMessage[index] = i18n.tc("check.chk_inputPastDate2", 0, [
            i18n.tc("label.lbl_nextDepartureDateTime"),
          ]);
          isError = true;
        }
      }

      if (isError) {
        return false;
      }
      return true;
    },

    /**
     * 戻るボタン押下時の処理
     */
    backScreen() {
      if (this.isChange()) {
        this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_004_W");
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.okAction = this.backOkClick;
      } else {
        this.backOkClick();
      }
    },

    /**
     * 戻る実施
     */
    backOkClick() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TRN_003,
        params: {
          searchParam: this.backParam,
        },
      });
    },

    /**
     * 日付チェック(yyyy/MM/dd hh:mm)
     */
    checkDate_yyyyMMddhhmm(value) {
      if (value == null || value == "") {
        return true;
      }
      let strs = value.split(" ");
      if (dateTimeHelper.validDate(strs[0], "/")) {
        if (strs.length > 1 && dateTimeHelper.validTime(strs[1], ":")) {
          return true;
        }
      }
      return i18n.tc("check.chk_inputDate_yyyyMMddhhmm");
    },

    /**
     * 日付変更(出発日時)
     */
    inputDepartureDateTime(index) {
      if (this.inputList[index].departureDateTime) {
        let checkRes = this.checkDate_yyyyMMddhhmm(this.inputList[index].departureDateTime);
        if (checkRes == true) {
          this.departureDateTimeCalList[index] = dateTimeHelper.dateCalc(
            this.inputList[index].departureDateTime
          );
          this.departureDateTimeMessage[index] = "";
        } else {
          this.departureDateTimeCalList[index] = null;
          this.departureDateTimeMessage[index] = checkRes;
        }
      } else {
        this.departureDateTimeCalList[index] = null;
        this.departureDateTimeMessage[index] = "";
      }
      this.checkDetailDate();
    },

    /**
     * 日付変更(到着日時)
     */
    inputArrivalDateTime(index) {
      if (this.inputList[index].arrivalDateTime) {
        let checkRes = this.checkDate_yyyyMMddhhmm(this.inputList[index].arrivalDateTime);
        if (checkRes == true) {
          this.arrivalDateTimeCalList[index] = dateTimeHelper.dateCalc(
            this.inputList[index].arrivalDateTime
          );
          this.arrivalDateTimeMessage[index] = "";
        } else {
          this.arrivalDateTimeCalList[index] = null;
          this.arrivalDateTimeMessage[index] = checkRes;
        }
      } else {
        this.arrivalDateTimeCalList[index] = null;
        this.arrivalDateTimeMessage[index] = "";
      }
      this.checkDetailDate();
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 画面変更有無の判断
     */
    isChange() {
      var confirmFlg = false;
      this.inputList.forEach((item, index) => {
        for (let key in item) {
          if (!(key in this.inputListOld[index])) {
            continue;
          }
          if (this.nullToBlank(this.inputListOld[index][key]) != this.nullToBlank(item[key])) {
            confirmFlg = true;
            continue;
          }
        }
      });
      return confirmFlg;
    },

    /**
     * 権限によって、登録ボタン表示可否の判断
     */
    isDisplayByRule() {
      // 「貨物状況詳細の営業担当」のロールがある場合
      if (
        sessionStorage.getItem(appConfig.BTN_AUTHORITY_ID.P_TRN_CARGO_STATUS_DETAIL_SALES) == "true"
      ) {
        return true;
      }
      return false;
    },

    /**
     * 権限によって、変更可否の判断
     */
    isDisabledByRule() {
      // 「貨物状況詳細の営業担当」のロールがある場合
      if (
        sessionStorage.getItem(appConfig.BTN_AUTHORITY_ID.P_TRN_CARGO_STATUS_DETAIL_SALES) == "true"
      ) {
        return true;
      }
      return false;
    },

    /**
     * 各処理ボタン活性、非活性の判定
     */
    isBottonDisabled() {
      // 権限無しの場合、非活性にする
      if (!this.isDisabledByRule()) {
        return true;
      }

      // 変更なしの場合、非活性にする
      if (!this.isChange()) {
        return true;
      }

      return false;
    },

    /**
     * 入力欄表示、非表示の判定
     */
    isTextDisabled(dateTimeEditFlg) {
      // 変更権限無しの場合、非表示にする
      if (!this.isDisabledByRule()) {
        return true;
      }
      // 社有の場合、非表示にする
      if (dateTimeEditFlg != "1") {
        return true;
      }
      return false;
    },

    /**
     * 状況の判定
     */
    getStatusClass(status) {
      if (status == "inTransit") {
        return "inTransit";
      } else if (status == "complete") {
        return "complete";
      }
      return "";
    },
    nullToBlank(val) {
      if (val == null) {
        return "";
      }
      return val;
    },
  },
  watch: {
    // 出発日時
    departureDateTimeCalList: {
      handler(vals) {
        for (var i = 0; i < this.inputList.length; i++) {
          if (vals[i] != "" && vals[i] != null) {
            let inputDate = this.formatDate(vals[i]);
            if (inputDate != this.inputList[i].departureDateTime.substr(0, 10)) {
              this.inputList[i].departureDateTime = inputDate + " 00:00";
              this.inputList[i].isDepartureCalendarInput = true;
              break;
            }
          }
        }
        this.checkDetailDate();
      },
      deep: true,
    },
    // 到着日時
    arrivalDateTimeCalList: {
      handler(vals) {
        for (var i = 0; i < this.inputList.length; i++) {
          if (vals[i] != "" && vals[i] != null) {
            let inputDate = this.formatDate(vals[i]);
            if (inputDate != this.inputList[i].arrivalDateTime.substr(0, 10)) {
              this.inputList[i].arrivalDateTime = inputDate + " 00:00";
              this.inputList[i].isDeliveryCalendarInput = true;
              break;
            }
          }
        }
        this.checkDetailDate();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.inquiry-number-search-item {
  margin-left: 30px;
  width: 300px;
}

//データテーブル
#listData {
  overflow-x: auto;
}

.inTransit {
  position: relative;
  width: 0;
  height: 0;
}
.inTransit::before {
  content: "";
  position: absolute;
  top: -5px;
  width: 0;
  height: 25px;
  border-right: 8px solid rgb(76, 111, 175);
  border-left: 8px solid rgb(76, 111, 175);
}
.inTransit::after {
  content: "";
  position: absolute;
  top: 15px;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #4c6faf;
  transform: translateX(-52%);
}

.complete {
  position: relative;
  width: 0;
  height: 0;
}
.complete:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 15px;
  border-right: 5px solid #0a0a0a;
  border-top: 5px solid #0a0a0a;
  transform: translate(-15%, -15%) rotate(138deg);
  top: 3px;
}

.detail-date-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  float: right;
}
.detail-date-text {
  top: 13px;
}
</style>
