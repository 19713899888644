<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 配車計画のデータ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_vehiclePlanDataImport" />
      <v-form ref="searchForm" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <!-- １行目 -->
          <v-row class="search-row">
            <!-- 取り込むCSVファイル -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                v-model="selectedCsv"
                :items="csvOptions"
                dense
                :label="$t('label.lbl_csvFile')"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
                @change="changeCsvFile"
                :disabled="inputList.length > 0"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取込ファイル -->
            <div class="file-input" style="margin-left: 2rem">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
              >
              </v-file-input>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_import") }}
            </v-btn>
          </v-row>
          <!-- ２行目 -->
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left; margin-left: 1.5rem">
                <span class="toatlNum">{{
                  $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number")
                }}</span>
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>

              <div class="btn-area">
                <!-- アップロードボタン -->
                <v-btn class="post-btn" @click="confirm()" v-bind:disabled="!activate">{{
                  $t("btn.btn_upload")
                }}</v-btn>
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="clear()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-navigation-drawer absolute temporary v-model="openMenu">
            <sideMenu></sideMenu>
          </v-navigation-drawer>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          height="610px"
          :options.sync="sortOptions"
        >
          <template v-slot:[`item.requestNo`]="{ item }">
            <!-- 依頼番号 -->
            <div>{{ item.requestNo }}</div>
          </template>
          <template v-slot:[`item.supplier`]="{ item }">
            <!-- 取引先 -->
            <div>{{ item.supplier }}</div>
          </template>
          <template v-slot:[`item.deliveryDate`]="{ item }">
            <!-- 配送予定日 -->
            <div>{{ item.deliveryDate }}</div>
          </template>
          <template v-slot:[`item.departurePoint`]="{ item }">
            <!-- 発地・積地 -->
            <div>{{ item.departurePoint }}</div>
          </template>
          <template v-slot:[`item.arrivalPoint`]="{ item }">
            <!-- 着地・降地 -->
            <div>{{ item.arrivalPoint }}</div>
          </template>
          <template v-slot:[`item.driverName`]="{ item }">
            <!-- 社号 -->
            <div>{{ item.driverName }}</div>
          </template>
          <template v-slot:[`item.crewName`]="{ item }">
            <!-- 乗務員 -->
            <div>{{ item.crewName }}</div>
          </template>
          <template v-slot:[`item.itemName`]="{ item }">
            <!-- 品名 -->
            <div>{{ item.itemName }}</div>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <VehicleDispatchPlanDataImportCsvError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="csvConfirm"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "../../components/loading.vue";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import commonMixinExcel from "@/mixins/CommonMixinExcel";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import VehicleDispatchPlanDataImportCsvError from "../dispatch/VehicleDispatchPlanDataImportCsvError.vue";

export default {
  name: appConfig.SCREEN_ID.P_DVP_003,
  components: {
    NavBar,
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    VehicleDispatchPlanDataImportCsvError,
  },
  mixins: [commonMixin, commonRules, commonMixinExcel],
  data: () => ({
    loadingCounter: 0,
    // 総件数
    totalCount: 0,
    // 表示の切り替え
    activate: false,
    // 取り込むファイル
    importfile: null,
    // ダミーデータ
    inputList: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: i18n.tc("label.lbl_No"),
        value: "no",
        width: "4%",
        align: "right",
        sortable: true,
      },
      // 依頼番号
      {
        text: i18n.tc("label.lbl_requestNo"),
        value: "requestNo",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplier",
        width: "13%",
        align: "left",
        sortable: true,
      },
      // 配送予定日
      {
        text: i18n.tc("label.lbl_shipScheduleDate"),
        value: "deliveryDate",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 発地・積地
      {
        text: i18n.tc("label.lbl_departurePoint"),
        value: "departurePoint",
        width: "13%",
        align: "left",
        sortable: true,
      },
      // 着地・降地
      {
        text: i18n.tc("label.lbl_arrivalPoint"),
        value: "arrivalPoint",
        width: "13%",
        align: "left",
        sortable: true,
      },
      // 社号
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "driverName",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 乗務員
      {
        text: i18n.tc("label.lbl_driver"),
        value: "crewName",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "15%",
        align: "left",
        sortable: true,
      },
    ],
    sortOptions: {},
    // 配車計画配列
    vehiclePlanCsvData: [],
    // アップロードボタンフラグ
    confirmFlg: false,
    // メニュー
    openMenu: null,
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
    selectedCsv: null,
    csvOptions: [
      { value: "1", text: "輸送依頼CSV" },
      { value: "2", text: "配車計画CSV" },
    ],
  }),
  methods: {
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
    /**
     * 取込ボタン押下処理
     */
    search() {
      // 取込前のバリデーションチェック
      if (this.$refs.searchForm.validate()) {
        // 取込確定処理
        // 選択したファイルを読み込む
        const selectFile = this.importfile;
        // 拡張子チェック
        let fileType = selectFile.type;
        // 取り込んだファイルはCSVの場合
        if (fileType == "text/csv") {
          this.importCSV(selectFile)
            .then((result) => {
              this.vehiclePlanCsvData = result;
            })
            .then(() => {
              this.postCsv();
            });
        } else if (
          fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          fileType == "application/vnd.ms-excel"
        ) {
          // 取り込んだファイルはエクセルの場合
          this.importExcel(selectFile)
            .then((result) => {
              this.vehiclePlanCsvData = result;
            })
            .then(() => {
              this.postCsv();
            });
        } else {
          // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
          this.infoDialog.message = i18n.tc("check.chk_limitSelectFileType");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
    },
    /**
     * アップロードボタン押下
     */
    confirm() {
      // ボタン識別フラグ（trueだったら、取込確定）
      this.confirmFlg = true;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_importConfirm"),
      ]);
    },
    /**
     * 画面クリア押下
     */
    clear() {
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
    },
    /**
     * POSTAPI(仮登録POST)
     */
    postCsv() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_003;

      // ファイル名
      body.reqIdv.fileName = this.importfile.name;
      // 配車計画配列
      body.reqIdv.listCsvData = this.vehiclePlanCsvData;
      console.log(body);

      // ============================テスト用=====================================
      // const vehiclePlanCsvResults = [];
      // for (var i = 1; i <= 5; i++) {
      //   vehiclePlanCsvResults.push({
      //     no: i,
      //     ordSid: "200000000" + i,
      //     oDName: "ボルボ・カー・ジャパン㈱ 埼玉サービスセンター",
      //     deliveryDate: "2024/10/19",
      //     dptNameTrs: "VC東名横浜",
      //     arrNameTrs: "日高営業所999用",
      //     dName: "M777CR",
      //     cName: "乗務員 " + i,
      //     itemName: "埼玉→営業所止め001",
      //   });
      // }

      // const jsonData = {
      //   resCom: {
      //     resComPagination: {
      //       totalPage: 1,
      //       totalRecord: 100,
      //     },
      //     resComCode: "000",
      //   },
      //   reqCom: {
      //     reqComComponentId: "P-TOD-003",
      //     reqComCompanySid: null,
      //     reqComOfficeSid: null,
      //     reqComDeviceImei: "WEB",
      //     reqComAppVersion: "24.11.01",
      //     reqComExecUser: null,
      //     reqComExecTimestamp: "2025/01/22 04:34:59.192",
      //     reqComRequestId: "9854fab4-ce0d-431d-a637-9c2db5a27fc6",
      //     reqComLanguageCd: "ja",
      //     reqComTimeZone: "Asia/Tokyo",
      //   },
      //   resIdv: {
      //     vehiclePlanTmp: {
      //       csvErrorCheckResultList: [],
      //       vehiclePlanCsvResults: vehiclePlanCsvResults,
      //     },
      //   },
      // };

      // // 正常時
      // if (jsonData.resCom.resComCode == "000") {
      //   if (jsonData.resIdv.vehiclePlanTmp.csvErrorCheckResultList.length != 0) {
      //     const errorList = [];
      //     this.errorDetailDialogData = [];
      //     jsonData.resIdv.vehiclePlanTmp.csvErrorCheckResultList.forEach((row) => {
      //       errorList.push({
      //         requestNo: row.ordSid,
      //         row: row.row,
      //         column: row.column,
      //         message: row.message,
      //         value: row.value,
      //       });
      //       this.errorDetailDialogData = errorList;
      //     });
      //     // エラー一覧タブが開く
      //     // 詳細画面へ渡す値を設定します。
      //     this.detailDialogData.list = this.errorDetailDialogData;
      //     this.dialogDetails = true;
      //   } else {
      //     const list = [];

      //     // 画面表示項目
      //     if (this.selectedCsv == 1) {
      //       jsonData.resIdv.vehiclePlanTmp.vehiclePlanCsvResults.forEach((row) => {
      //         list.push({
      //           // No
      //           no: row.no,
      //           // 依頼番号
      //           requestNo: row.ordSid,
      //           // 取引先
      //           supplier: row.oDName,
      //           // 配送予定日
      //           deliveryDate: "",
      //           // 発地・積地
      //           departurePoint: row.dptNameTrs,
      //           // 着地・降地
      //           arrivalPoint: row.arrNameTrs,
      //           // 社号
      //           driverName: "",
      //           // 乗務員
      //           crewName: "",
      //           // 品名
      //           itemName: row.itemName,
      //         });
      //       });
      //     } else {
      //       jsonData.resIdv.vehiclePlanTmp.vehiclePlanCsvResults.forEach((row) => {
      //         list.push({
      //           // No
      //           no: row.no,
      //           // 依頼番号
      //           requestNo: row.ordSid,
      //           // 取引先
      //           supplier: row.oDName,
      //           // 配送予定日
      //           deliveryDate: row.deliveryDate,
      //           // 発地・積地
      //           departurePoint: row.dptNameTrs,
      //           // 着地・降地
      //           arrivalPoint: row.arrNameTrs,
      //           // 社号
      //           driverName: row.dName,
      //           // 乗務員
      //           crewName: row.cName,
      //           // 品名
      //           itemName: row.itemName,
      //         });
      //       });
      //     }
      //     // 画面表示用リストに格納
      //     this.inputList = list;
      //     // アップロードボタンを活性に設定
      //     this.activate = true;
      //     this.totalCount = this.inputList.length;
      //   }
      // } else {
      //   // エラー時
      //   this.infoDialog.message = jsonData.resCom.resComMessage;
      //   this.infoDialog.title = appConfig.DIALOG.title;
      //   this.infoDialog.isOpen = true;
      //   this.infoDialog.firstPageFlag = true;
      // }
      // ============================テスト用=====================================

      let apiUrl = "";
      if (this.selectedCsv == 1) {
        // 輸送依頼
        apiUrl = appConfig.API_URL.BIZ_TRANSPORT_FILE_TMP;
      } else {
        // 配車計画
        apiUrl = appConfig.API_URL.BIZ_VEHICLE_PLAN_FILE_TMP;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(apiUrl, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            let tmpData = "";
            let csvResults = "";
            if (this.selectedCsv == 1) {
              tmpData = jsonData.resIdv.transportTmp;
              csvResults = jsonData.resIdv.transportTmp.transportCsvResults;
            } else {
              tmpData = jsonData.resIdv.vehiclePlanTmp;
              csvResults = jsonData.resIdv.vehiclePlanTmp.vehiclePlanCsvResults;
            }

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (tmpData.csvErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                tmpData.csvErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    requestNo: row.ordSid,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                this.dialogDetails = true;
              } else {
                const list = [];

                // 画面表示項目
                csvResults.forEach((row) => {
                  list.push({
                    // No
                    no: row.no,
                    // 依頼番号
                    requestNo: row.ordSid,
                    // 取引先
                    supplier: row.oDName,
                    // 配送予定日
                    deliveryDate: row.deliveryDate,
                    // 発地・積地
                    departurePoint: row.dptNameTrs,
                    // 着地・降地
                    arrivalPoint: row.arrNameTrs,
                    // 社号
                    driverName: row.dName,
                    // 乗務員
                    crewName: row.cName,
                    // 品名
                    itemName: row.itemName,
                  });
                });
                // 画面表示用リストに格納
                this.inputList = list;
                // アップロードボタンを活性に設定
                this.activate = true;
                this.totalCount = this.inputList.length;
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      if (this.confirmFlg) {
        if (this.$refs.searchForm.validate()) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          const body = this.$httpClient.createRequestBodyConfig();

          //  画面ID
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_DVP_003;

          // ファイル名
          body.reqIdv.fileName = this.importfile.name;
          // 配車計画配列
          body.reqIdv.listCsvData = this.vehiclePlanCsvData;

          let apiUrl = "";
          if (this.selectedCsv == 1) {
            // 輸送依頼
            apiUrl = appConfig.API_URL.BIZ_TRANSPORT_FILE_MAIN;
          } else {
            // 配車計画
            apiUrl = appConfig.API_URL.BIZ_VEHICLE_PLAN_FILE_MAIN;
          }

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(apiUrl, body, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.homePageFlag = true;
                  this.infoDialog.firstPageFlag = false;
                  resolve(response);
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.afterUploadClear();
              });
          });
        }
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.activate = false;
        this.selectedCsv = null;
        // バリデーションチェックをリセット
        this.$refs.searchForm.resetValidation();
      }
    },
    /**
     * アップロード後画面クリア
     */
    afterUploadClear() {
      // 画面クリア処理
      this.inputList = [];
      this.importfile = null;
      this.totalCount = 0;
      this.activate = false;
      this.selectedCsv = null;
      // バリデーションチェックをリセット
      this.$refs.searchForm.resetValidation();
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;

      // 画面クリア処理
      this.inputList = [];
      this.totalCount = 0;
      this.activate = false;
    },

    changeCsvFile(value) {
      this.selectedCsv = value;
    },
  },
  watch: {
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;

          if (sortBy == "no" || sortBy == "requestNo") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a[sortBy] - b[sortBy];
              } else {
                return b[sortBy] - a[sortBy];
              }
            });
          } else if (
            sortBy == "supplier" ||
            sortBy == "deliveryDate" ||
            sortBy == "departurePoint" ||
            sortBy == "arrivalPoint" ||
            sortBy == "driverName" ||
            sortBy == "crewName"
          ) {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a[sortBy] < b[sortBy]) {
                  return -1;
                } else if (a[sortBy] > b[sortBy]) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a[sortBy] > b[sortBy]) {
                  return -1;
                } else if (a[sortBy] < b[sortBy]) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "itemName") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
/* 画面優先順位 */
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

/* 送り先登録時、色変更 */
.isD {
  color: $color-error !important;
}

/* 備考欄、テキストボックス */
.search-textbox-remark {
  width: 40rem;
}
</style>
