<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />

    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_incomeAndExpenditure" />
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="6">
                <!-- 年度月度 -->
                <v-btn-toggle v-model="fiscalPeriod.selected" color="blue darken-2" mandatory>
                  <v-btn
                    mandatory
                    class="cost-btn"
                    v-for="item in fiscalPeriod.items"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}
                  </v-btn>
                </v-btn-toggle>
                <!-- 年度入力 -->
                <div v-if="isFiscalYear()" class="date-picker-container">
                  <div class="date-picker-input">
                    <v-text-field
                      outlined
                      dense
                      v-model="year.selected"
                      :label="$t('label.lbl_fiscalYear')"
                      @change="changeYear"
                      :error-messages="year.alertMessages"
                      :rules="[rules.yyyy, rules.required]"
                      class="txt-single"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require asterisk-spacer">*</span>
                  <div class="date-picker-controls">
                    <v-menu
                      v-model="year.menuOpen"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small>
                          <v-icon class="icon-calendar">mdi-calendar</v-icon>
                        </v-btn>
                      </template>

                      <v-date-picker
                        v-model="year.selectedCalendar"
                        type="year"
                        @input="year.menuOpen = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-btn
                      id="btn-lastYear"
                      class="day-ctrl-btn"
                      @click="previousYear()"
                      text
                      x-small
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn id="btn-nextYear" class="day-ctrl-btn" @click="nextYear()" text x-small>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </div>
                <!-- 月度入力 -->
                <div v-if="isfiscalMonth()" class="date-picker-container">
                  <div class="date-picker-input">
                    <v-text-field
                      outlined
                      dense
                      v-model="month.selected"
                      :label="$t('label.lbl_fiscalMonth')"
                      @change="changeMonth"
                      :error-messages="month.alertMessages"
                      :rules="[rules.yyyymm, rules.required]"
                      class="txt-single"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require asterisk-spacer">*</span>
                  <div class="date-picker-controls">
                    <v-menu
                      v-model="month.menuOpen"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small>
                          <v-icon class="icon-calendar">mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="month.selectedCalendar"
                        type="month"
                        @input="month.menuOpen = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-btn
                      id="btn-lastMonth"
                      class="day-ctrl-btn"
                      @click="previousMonth()"
                      text
                      x-small
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      id="btn-nextMonth"
                      class="day-ctrl-btn"
                      @click="nextMonth()"
                      text
                      x-small
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <!-- 取引先社号 -->
                <v-btn-toggle v-model="clientVehicle.selected" color="blue darken-2" mandatory>
                  <v-btn
                    mandatory
                    class="cost-btn"
                    v-for="item in clientVehicle.items"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
                <!-- 取引先選択 -->
                <div v-if="isByClient()" class="autocomplete-container">
                  <v-autocomplete
                    dense
                    v-model="client.selected"
                    :items="client.items"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    :hint="getClient"
                    persistent-hint
                    margin-bottom="0"
                    pa-3
                  >
                    <template slot="item" slot-scope="data">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <!-- 社号選択 -->
                <div v-if="isByVehicleCode()" class="autocomplete-container">
                  <v-autocomplete
                    dense
                    v-model="vehicle.selected"
                    :items="vehicle.items"
                    :label="$t('label.lbl_vehicle')"
                    class="txt-single"
                    :hint="getVehicle"
                    persistent-hint
                    margin-bottom="0"
                    pa-3
                  >
                    <template slot="item" slot-scope="data">
                      <span class="auto-txt-size">
                        {{ data.item.text }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn color="primary" class="other-btn" @click="search()">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <v-btn color="primary" class="other-btn" @click="rescission()">{{
                  $t("btn.btn_outputcsv")
                }}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>{{ summary.title }}</h3>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="d-flex">
            <!-- 合計 -->
            <table border="2" class="summary-table align-self-end">
              <thead>
                <tr>
                  <th class="sales-header">
                    {{ $t("label.lbl_salesAmount") }}{{ $t("label.lbl_totalSum") }}
                  </th>
                  <th class="payment-header">
                    {{ $t("label.lbl_paymentAmount") }}{{ $t("label.lbl_totalSum") }}
                  </th>
                  <th class="balance-header">
                    {{ $t("label.lbl_balanceAmount") }}{{ $t("label.lbl_totalSum") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ total.salesAmount }}</td>
                  <td>{{ total.paymentAmount }}</td>
                  <td class="balance-amount">{{ total.balanceAmount }}</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="summary.headers"
          :items="summary.items"
          disable-filtering
          :hide-default-footer="true"
          :items-per-page="50"
        >
          <template v-slot:[`item.vehicle`]="{ item }">
            <div class="listNo-style">{{ item.vehicle }}</div>
          </template>
          <template v-slot:[`item.salesAmount`]="{ item }">
            <div class="listNo-style text-right">{{ item.salesAmount }}</div>
          </template>
          <template v-slot:[`item.paymentAmount`]="{ item }">
            <div class="listNo-style text-right">{{ item.paymentAmount }}</div>
          </template>
          <template v-slot:[`item.balanceAmount`]="{ item }">
            <div class="listNo-style text-right balance-amount">{{ item.balanceAmount }}</div>
          </template>
        </v-data-table>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil.js";
import { appConfig } from "../../assets/scripts/js/AppConfig.js";
import { getParameter } from "../../assets/scripts/js/GetParameter.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil.js";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.SCREEN_ID.P_CLA_006,

  components: {
    Loading,
    // eslint-disable-next-line vue/no-unused-components
    SimpleDialog,
    // eslint-disable-next-line vue/no-unused-components
    sideMenu,
    // eslint-disable-next-line vue/no-unused-components
    ConfirmDialog,
    NavBar,
  },

  props: {
    isMenu: { type: Boolean, default: false },
  },

  data: () => ({
    /** ローディング画面表示フラグ */
    loadingCounter: 0,

    /** メニュー */
    openMenu: null,

    /** 年度/月度 */
    fiscalPeriod: {
      selected: "year",
      items: [
        { text: i18n.tc("label.lbl_fiscalYear"), value: "year" },
        { text: i18n.tc("label.lbl_fiscalMonth"), value: "month" },
      ],
    },

    /** 年度 */
    year: {
      selected: "2025",
      menuOpen: false,
      selectedCalendar: null,
      alertMessages: "",
    },

    /** 月度 */
    month: {
      selected: "202503",
      menuOpen: false,
      selectedCalendar: null,
      alertMessages: "",
    },

    /** 取引先別/社号別 */
    clientVehicle: {
      selected: "byClient",
      items: [
        { text: i18n.tc("label.lbl_byClient"), value: "byClient" },
        { text: i18n.tc("label.lbl_byVehicleCode"), value: "byVehicleCode" },
      ],
    },

    /** 取引先別 */
    client: {
      selected: null,
      items: [],
    },

    /** 社号別 */
    vehicle: {
      selected: null,
      items: [],
    },

    /** 合計 */
    total: {
      salesAmount: "¥6,787",
      paymentAmount: "¥2,400",
      balanceAmount: "¥4,387",
    },

    /** 集計結果 */
    summary: {
      title: "集計結果",
      headers: [
        {
          text: i18n.tc("label.lbl_driverName"),
          value: "vehicle",
          width: "70%",
          align: "left",
          sortable: false,
        },
        {
          text: i18n.tc("label.lbl_salesAmount"),
          value: "salesAmount",
          width: "10%",
          align: "left",
          sortable: false,
        },
        {
          text: i18n.tc("label.lbl_paymentAmount"),
          value: "paymentAmount",
          width: "10%",
          align: "left",
          sortable: false,
        },
        {
          text: i18n.tc("label.lbl_balanceAmount"),
          value: "balanceAmount",
          width: "10%",
          align: "left",
          sortable: false,
        },
      ],
      items: [
        {
          vehicle: "XXYYYYZZZZ（大型10t）",
          salesAmount: "¥2,261",
          paymentAmount: "¥800",
          balanceAmount: "¥1,461",
        },
        {
          vehicle: "XXYYYYZZZZ（大型10t）",
          salesAmount: "¥4,526",
          paymentAmount: "¥1,600",
          balanceAmount: "¥2,926",
        },
      ],
    },

    // 取引先
    supplier: "",
    // 取引先リスト
    supplierList: [],
    supplierHint: "",

    // 集計月
    billingCutOffMonth: "",
    billingCutOffMonthCal: "",

    // 警告ダイアログ
    alertDialog: false,

    message: "",

    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
      okAction: () => {},
    },

    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },

    rules: {
      yyyy: true,
      yyyymm: true,
      required: true,
    },
  }),

  methods: {
    async init() {
      this.billingCutOffMonth = dateTimeHelper.convertJST();
      this.billingCutOffMonthCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      await this.getSupplierList();
    },

    async getSupplierList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.supplier = "";

      // 取引先
      let supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertJST(),
        false,
        null,
        sessionStorage.getItem("sales_office_sid")
      );

      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          const tmpSupplier = this.supplierList[0].value;
          if (tmpSupplier) {
            this.supplier = tmpSupplier;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    isFiscalYear() {
      return this.fiscalPeriod.selected === "year";
    },

    isfiscalMonth() {
      return this.fiscalPeriod.selected === "month";
    },

    changeYear() {
      // 年の変更処理
    },

    previousYear() {
      // 前年の処理
    },

    nextYear() {
      // 翌年の処理
    },

    changeMonth() {
      // 年月の変更処理
    },

    previousMonth() {
      // 前月の処理
    },

    nextMonth() {
      // 翌月の処理
    },

    isByClient() {
      return this.clientVehicle.selected === "byClient";
    },

    isByVehicleCode() {
      return this.clientVehicle.selected === "byVehicleCode";
    },

    search() {
      this.updateSummaryTitle();
    },

    registration() {
      if (this.$refs.form.validate()) {
        const lbl_month = "月";
        const lbl_day = "日";
        const yyyymm = this.billingCutOffMonth.split("/");
        const month = yyyymm[0] + lbl_month + yyyymm[1] + lbl_day;
        this.confirmDialog.message = messsageUtil.getMessage("P-CLA-003_001_C", [month]);
        this.confirmDialog.title = appConfig.DIALOG.title;
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.registrationOnOkClick;
      }
    },

    rescission() {
      if (this.$refs.form.validate()) {
        this.confirmDialog.message = messsageUtil.getMessage("P-CLA-003_002_C");
        this.confirmDialog.title = appConfig.DIALOG.title;
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.rescissionOnOkClick;
      }
    },

    alertCancel() {
      this.alertDialog = false;
    },

    /** 集計月を-１日 */
    preMonthOfAchieveTo() {
      if (this.billingCutOffMonthCal == null) {
        return;
      }

      let date = new Date(this.billingCutOffMonthCal);
      this.billingCutOffMonthCal = dateTimeHelper.dateCalc(date.setMonth(date.getMonth() - 1));
    },

    /** 集計月を+１日 */
    nextMonthOfAchieveTo() {
      if (this.billingCutOffMonthCal == null) {
        return;
      }

      let date = new Date(this.billingCutOffMonthCal);
      this.billingCutOffMonthCal = dateTimeHelper.dateCalc(date.setMonth(date.getMonth() + 1));
    },

    formatMonth(date) {
      if (!date) return null;

      date = commonUtil.zen2han(date);
      // 月の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])$/;
      date = date.replaceAll("／", "/");
      // 対象の文字列チェック

      if (regex.test(date)) {
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month] = date.split("-");
        return `${year}/${month}`;
      }
    },

    // 半角文字を全角文字に変換
    HalfWidth2fullWidth(str) {
      return str.replace(/[A-Za-z0-9]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
      });
    },

    clearAlertMessage() {
      // アラートメッセージを非表示にする。
      this.alertMessageMonth = "";
    },

    isNullOrBlank(val) {
      if ((val ?? false) && val != "") {
        return true;
      }

      return false;
    },

    updateSummaryTitle() {
      let title = "";
      if (this.fiscalPeriod.selected === "year") {
        title += this.year.selected + "年";
      } else if (this.fiscalPeriod.selected === "month") {
        title += this.month.selected.slice(0, 4) + "年" + this.month.selected.slice(4, 6) + "月";
      }

      if (this.clientVehicle.selected === "byClient") {
        title += "　集計結果（取引先別）";
      } else if (this.clientVehicle.selected === "byVehicleCode") {
        title += "　集計結果（社号別）";
      }

      this.summary.title = title;
    },
  },

  computed: {
    getClient() {
      return "";
    },

    getVehicle() {
      return "";
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },

    billingCutOffMonth(val) {
      if (this.isNullOrBlank(val)) {
        // アラートメッセージを非表示にする。

        this.clearAlertMessage();
      }
    },

    billingCutOffMonthCal: {
      handler(val) {
        this.billingCutOffMonth = this.formatMonth(val);
      },

      deep: true,
    },

    dialog(val) {
      val || this.close();
    },

    suppliersSelected(newValue) {
      if (newValue || null) {
        this.suppliersSelected = newValue;
      } else {
        this.suppliersSelected = newValue;
      }
    },
  },

  mounted() {
    this.init();
  },

  created() {},
};
</script>
<style>
@import "../../css/style.css";
.summary-table {
  width: 100%;
  border-collapse: collapse;
  border-color: black;
  table-layout: fixed;
}

.summary-table th {
  font-size: large;
  padding: 8px;
}

.summary-table td {
  font-size: x-large;
  padding: 8px;
  text-align: center;
}

.sales-header {
  background-color: #bed8ee;
}

.payment-header {
  background-color: #fbe4d5;
}

.balance-header {
  background-color: #e3f0db;
}

.date-picker-container {
  display: flex;
  margin-top: 32px;
}

.date-picker-input {
  flex-grow: 1;
}

.date-picker-controls {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8px;
}

.autocomplete-container {
  width: auto;
  margin-top: 32px;
}

.balance-amount {
  color: #2f5597;
}

.text-right {
  text-align: right;
}
</style>
<style lang="scss" scoped>
/* 空のスタイル定義 */
</style>
