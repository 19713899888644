export default {
  data() {
    return {
      transportCompDivList: [],
      vehicleShapeMajorList: [],
      undispatchGroupName: "undispatch",
      dispatchGroupNamePrefix: "dispatch_",
    };
  },
  computed: {
    dragArea() {
      return {
        transport: "transport",
        undistapch: "undispatch",
      };
    },
    transportCompDivMap() {
      return this.transportCompDivList.reduce(
        (map, div) => map.set(div.value, div.text),
        new Map()
      );
    },
    vehicleShapeMajorMap() {
      return this.vehicleShapeMajorList.reduce(
        (map, div) => map.set(div.value, div.text),
        new Map()
      );
    },
    // 未配車貨物エリア用のSortable.jsのオプション
    undispatchDragOptions() {
      return {
        ...this.defaultDragOptions,
        group: {
          name: "undispatch",
          put: true,
          pull: (to) => {
            const toName = to.options.group.name;
            return (
              toName === this.undispatchGroupName || toName.startsWith(this.dispatchGroupNamePrefix)
            );
          },
        },
      };
    },
    // 配車登録画面のSortable.jsのデフォルトのオプション
    defaultDragOptions() {
      return {
        animation: 150,
        group: "freight",
        disabled: false,
        ghostClass: "freight-ghost",
        fallbackOnBody: true,
        swapThreshold: 0.65,
        multiDrag: true,
        selectedClass: "selected-freight",
        dragClass: "dragging-freight",
        avoidImplicitDeselect: false,
        multiDragKey: "CTRL",
      };
    },
  },
  methods: {
    // 便の配車エリア用のSortable.jsのオプション
    dispatchDragOptions(transportSid) {
      const name = `${this.dispatchGroupNamePrefix}${transportSid}`;
      return {
        ...this.defaultDragOptions,
        group: {
          name: name,
          put: true,
          pull: (to) => {
            // 便の配車エリアか未配車貨物エリアにドラッグ可能(ロット内へのドラッグは不可)
            const toName = to.options.group.name;
            return (
              toName === this.undispatchGroupName || toName.startsWith(this.dispatchGroupNamePrefix)
            );
          },
        },
      };
    },
    /** 配達と集荷の貨物のリストをロットと集荷貨物を混合したリストに変換 */
    toLotFreightList(freightList) {
      // 結果配列
      const results = [];
      // 結果配列に登録済みのロットの情報（車両形状やロット内の貨物など）をまとめるMap
      const lotMap = new Map();
      for (const freight of freightList ?? []) {
        if (!freight.lotSid) {
          // ロットSIDがない（集荷）の場合はそのままリストに追加
          const key = `${freight.pickupDeliveryDiv}_${freight.packingSid}`;
          results.push({ key, isLot: false, ...freight });
        } else if (!lotMap.has(freight.lotSid)) {
          // ロット情報
          const lot = {
            key: freight.lotSid,
            lotSid: freight.lotSid,
            isLot: true,
            transportCompDiv: freight.transportCompDiv,
            transportCompDivName: this.transportCompDivMap.get(freight.transportCompDiv) ?? "",
            vehicleShapeMajor: freight.vehicleShapeMajor,
            vehicleShapeMajorName: this.vehicleShapeMajorMap.get(freight.vehicleShapeMajor) ?? "",
            totalWeight: freight.totalWeight ?? 0,
            smallLotsQuantity: 1,
            freightList: [{ ...freight }],
          };
          lotMap.set(freight.lotSid, lot);
          results.push(lotMap.get(freight.lotSid));
        } else {
          // ロットが結果配列に追加済みの場合、ロットの貨物リストに追加
          const lot = lotMap.get(freight.lotSid);
          lot.freightList.push({ ...freight });
          lot.totalWeight += freight.totalWeight ?? 0;
          lot.smallLotsQuantity++;
        }
      }
      return results;
    },
    /**
     * ロットと集荷貨物のリストを集荷と配達の貨物のリストに変更する
     * （toLotFreightList で変換したロットと集荷の貨物のリスト変換したものを元の貨物のリストに戻す）
     */
    toFreightListFromLotFreightList(lotFreightList) {
      if (!lotFreightList) {
        return;
      }
      return lotFreightList.flatMap((x) => (x.isLot ? x.freightList : x));
    },
  },
};
