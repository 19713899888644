<template>
  <div style="height: 100%; width: 100%">
    <v-data-table
      :headers="headers"
      :items="transportList"
      fixed-header
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
      :item-class="() => 'table-row'"
      class="table-max-height overflow-y-auto"
      id="listData-transport"
    >
      <template v-slot:top>
        <div class="d-flex justify-start align-center px-1 mb-1" style="font-size: large">
          <div class="font-weight-bold">{{ $t("label.lbl_tansportOrderList") }}</div>
        </div>
      </template>
      <template v-slot:item.number="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:item.orderDate="{ item }">
        <span>{{ toJstDateStr(item.orderDate) }}</span>
      </template>
      <template v-slot:item.pickupScheduleDate="{ item }">
        <span>{{ toJstDateStr(item.pickupScheduleDate) }}</span>
      </template>
      <template v-slot:item.pickupScheduleTime="{ item }">
        <span>{{ getFromToTimeStr(item.pickupScheduleFromDate, item.pickupScheduleToDate) }}</span>
      </template>
      <template v-slot:item.deliveryScheduleDate="{ item }">
        <span>{{ toJstDateStr(item.deliveryScheduleDate) }}</span>
      </template>
      <template v-slot:item.deliveryScheduleTime="{ item }">
        <span>{{
          getFromToTimeStr(item.deliveryScheduleFromDate, item.deliveryScheduleToDate)
        }}</span>
      </template>
      <template v-slot:item.weight="{ item }">
        <span>{{ getDelimiter(item.totalWeight) }}</span>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-end align-center px-1 mb-1">
          <div class="my-1 px-6 text-body-2">{{ `${totalWeight}kg` }}</div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";

export default {
  name: "OfficeLotRouteTransportTable",
  props: {
    transportList: { type: Array, required: true, default: () => [] },
  },
  computed: {
    headers() {
      return [
        // No
        {
          text: this.$t("label.lbl_No"),
          value: "number",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "1%",
        },
        // 受注日
        {
          text: this.$t("label.lbl_orderDate"),
          value: "orderDate",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 元請営業所
        {
          text: this.$t("label.lbl_officeOrg"),
          value: "officeOrgName",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 取引先
        {
          text: this.$t("label.lbl_supplier"),
          value: "supplierName",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 貨物受注SID
        {
          text: this.$t("label.lbl_freight_order_sid"),
          value: "freightOrderSid",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // エリア
        {
          text: this.$t("label.lbl_area"),
          value: "area",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 出荷元
        {
          text: this.$t("label.lbl_pickupPoint"),
          value: "pickupPointName",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // お届け先
        {
          text: this.$t("label.lbl_addressee"),
          value: "deliveryPointName",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 集荷予定日
        {
          text: this.$t("label.lbl_pickupScheduleDate"),
          value: "pickupScheduleDate",
          align: "center",
          class: "pr-0 pl-2",
          cellClass: "pr-0 pl-2",
          width: "auto",
        },
        // 集荷予定時間
        {
          text: this.$t("label.lbl_pickupScheduleTime"),
          value: "pickupScheduleTime",
          align: "center",
          width: "auto",
        },
        // 到着日
        {
          text: this.$t("label.lbl_deliveryScheduleDate"),
          value: "deliveryScheduleDate",
          align: "center",
          class: "pr-0 pl-2",
          cellClass: "pr-0 pl-2",
          width: "auto",
        },
        // 到着時間
        {
          text: this.$t("label.lbl_deliveryScheduleTime"),
          value: "deliveryScheduleTime",
          align: "center",
          width: "auto",
        },
        // 代表品名
        {
          text: this.$t("label.lbl_typicalItemName"),
          value: "itemName",
          align: "left",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 小口数
        {
          text: this.$t("label.lbl_smallLotsQuantity"),
          value: "smallLotsQuantity",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
        // 重量計
        {
          text: this.$t("label.lbl_totalWeight"),
          value: "weight",
          align: "center",
          class: "px-2",
          cellClass: "px-2",
          width: "auto",
        },
      ];
    },
    totalWeight() {
      let sum = 0;
      for (const { totalWeight } of this.transportList) {
        sum += totalWeight ?? 0;
      }
      return sum === 0 ? "0" : commonFunction.getDelimiterFew(sum);
    },
  },
  methods: {
    getDelimiter(num) {
      if (!num) {
        return "0";
      }
      return commonFunction.getDelimiter(num);
    },
    toJstDateStr(utcDateStr) {
      if (!utcDateStr) {
        return "";
      }
      return dateTimeHelper
        .convertUTC2JST(utcDateStr) // => yyyy/MM/dd HH:mm:ss.SSS
        .split(" ")[0]; // => yyyy/MM/dd
    },
    getFromToTimeStr(fromUtcDateStr, toUtcDateStr) {
      const fromJst = this.toJstTimeStr(fromUtcDateStr);
      const toJst = this.toJstTimeStr(toUtcDateStr);

      if (!fromJst && !toJst) {
        return "";
      }

      return `${fromJst} - ${toJst}`;
    },
    toJstTimeStr(utcDateStr) {
      if (!utcDateStr) {
        return "";
      }

      const timeStr = dateTimeHelper
        .convertUTC2JST(utcDateStr) // => yyyy/MM/dd HH:mm:ss.SSS
        .substring(11, 16); // => HH:mm

      // 時刻が0から始まる場合は先頭から0を除く(03:21の場合、3:21になる)
      return timeStr.startsWith("0") ? timeStr.substring(1) : timeStr;
    },
  },
};
</script>
<style lang="scss">
#listData-transport {
  tr.table-row {
    height: 2rem;
    &:nth-child(odd) {
      background-color: #dde5f0;
    }
  }
  table > thead > tr > th[role="columnheader"] {
    background-color: #effad9;
    height: 2rem;
    font-size: large !important;
    color: black;
  }
  table > tbody > tr > td {
    font-size: large;
  }
}
</style>
<style scoped>
.table-max-height {
  height: calc(3rem * 5);
}
</style>
