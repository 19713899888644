<template>
  <div :style="[{ width, height }, { maxHeight: '100%' }]" class="visible">
    <v-card
      width="100%"
      height="100%"
      elevation="2"
      rounded="md"
      class="d-flex flex-column justify-start pa-1"
    >
      <TrunkPointLoadingPacking
        :openLabel="$t('label.lbl_trunkPointLoadingPacking')"
        :dialogTitle="$t('label.lbl_trunkPointLoadingPacking')"
        :trunkDiagramName="binItem.trunkDiagramName"
        :directionFlg="binItem.directionFlg"
        :transportSid="binItem.transportSid"
        :operationDate="binItem.instDate"
      >
      </TrunkPointLoadingPacking>
      <v-card-title class="flex-shrink-1 flex-grow-0 pa-0 text-subtitle-1" style="margin-top: 30px">
        <v-row no-gutters align="start">
          <v-col cols="12" class="d-flex justify-center flex-wrap">
            <div class="text-no-wrap">
              <span>{{ binItem.trunkDiagramName }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row no-gutters justify="center" class="flex-shrink-1 flex-grow-0">
        <v-col cols="9" class="d-flex justify-center">
          <!-- 便開始時刻 -->
          <div>
            <v-text-field
              dense
              v-model="binScheduleTimeFrom"
              hide-details="auto"
              type="time"
              class="pa-0 ma-0 text-body-2"
              readonly
            ></v-text-field>
          </div>
          <!-- ハイフン -->
          <span class="item-spacer">&nbsp;－&nbsp;</span>
          <!-- 便終了時刻 -->
          <div>
            <v-text-field
              dense
              v-model="binScheduleTimeTo"
              hide-details="auto"
              type="time"
              class="pa-0 ma-0 text-body-2"
              readonly
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-spacer>&nbsp;</v-spacer>
      <v-row no-gutters justify="center" class="flex-shrink-1 flex-grow-0">
        <v-col cols="1"></v-col>
        <v-col cols="4" class="d-flex mt-1 car-input">
          <v-autocomplete
            v-if="firstOfficeFlg"
            dense
            v-model="car1"
            :label="$t('label.lbl_car1')"
            height="20"
            hide-details="auto"
            :items="cars"
            :error="(carError, sameCarError)"
            :error-messages="(carErrorMessage, sameCarErrorMessage)"
            item-value="vehicleSid"
            item-text="vehicleCode"
            class="text-body-2"
          >
          </v-autocomplete>
          <v-autocomplete
            v-else
            dense
            v-model="car1"
            :label="$t('label.lbl_car1')"
            height="20"
            hide-details="auto"
            :items="cars"
            item-value="vehicleSid"
            item-text="vehicleCode"
            readonly
            class="text-body-2"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="4" class="d-flex mt-1 car-input">
          <v-autocomplete
            v-if="firstOfficeFlg"
            dense
            v-model="car2"
            :label="$t('label.lbl_car2')"
            height="20"
            hide-details="auto"
            :items="car2s"
            :error="(carError, sameCarError)"
            :error-messages="(carErrorMessage, sameCarErrorMessage)"
            item-value="vehicleSid"
            item-text="vehicleCode"
            class="text-body-2"
          >
          </v-autocomplete>
          <v-autocomplete
            v-else
            dense
            v-model="car2"
            :label="$t('label.lbl_car2')"
            height="20"
            hide-details="auto"
            :items="car2s"
            item-value="vehicleSid"
            item-text="vehicleCode"
            readonly
            class="text-body-2"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-spacer>&nbsp;</v-spacer>
      <v-row no-gutters justify="center" class="flex-shrink-1 flex-grow-0">
        <v-col cols="11" class="d-flex mt-1 driver-input">
          <v-autocomplete
            v-if="firstOfficeFlg"
            dense
            :label="$t('label.lbl_driver')"
            v-model="driver"
            height="20"
            hide-details="auto"
            :items="drivers"
            :error="driverError"
            :error-messages="driverErrorMessage"
            item-value="driverUserSid"
            item-text="driverUserName"
            class="text-body-2"
          >
          </v-autocomplete>
          <v-autocomplete
            v-else
            dense
            :label="$t('label.lbl_driver')"
            v-model="driver"
            height="20"
            hide-details="auto"
            :items="drivers"
            item-value="driverUserSid"
            item-text="driverUserName"
            readonly
            class="text-body-2"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-shrink-1 flex-grow-0">
        <v-col cols="12" class="text-center text-body-2 mt-2">
          拠点出発時積載量:
          {{
            this.transitoryMaxWeight == 0 || !this.transitoryMaxWeight
              ? 0
              : commonFunction.getDelimiterFew(this.transitoryMaxWeight)
          }}
          <span class="font-italic">kg</span> /
          {{
            binItem.carPayload == 0 || !binItem.carPayload
              ? 0
              : commonFunction.getDelimiterFew(binItem.carPayload)
          }}
          <span class="font-italic">kg</span>
          <span>({{ percentOfWeight(this.transitoryMaxWeight, binItem.carPayload) }})%</span>
        </v-col>
      </v-row>
      <div v-if="this.overloading" style="font-size: 12px; color: #ff5252; text-align: center">
        {{ $t("check.chk_overloading") }}
      </div>
      <!-- 集荷・配達中または集荷・配達済みの場合は配車できないので、未集荷・未配達の場合のみペアボタンを表示する -->
      <v-row
        v-if="binItem.binStatusDiv === '0'"
        no-gutters
        justify="end"
        class="flex-shrink-1 flex-grow-0 pr-2 py-1"
      >
      </v-row>
      <v-divider class="ma-1"></v-divider>
      <v-alert
        v-if="alertMessage !== ''"
        dense
        dark
        color="warning"
        class="mb-1 py-1 px-3"
        style="line-height: normal"
      >
        <div class="d-flex align-center">
          <v-icon small left>mdi-alert</v-icon>
          <span class="text-caption">{{ alertMessage }}</span>
        </div>
      </v-alert>
      <SortableWrapper
        v-model="lotFreightList"
        :option="{
          ...dragOption,
          disabled: (binItem.binStatusDiv ?? '0') !== '0',
        }"
        class="overflow-y-auto overflow-x-hidden pa-1"
      >
        <template v-for="item in lotFreightList">
          <template v-if="item.isLot">
            <TrunkLotItem
              :key="item.key"
              :lotItem="item"
              :transportSid="binItem.transportSid"
              :transportCompDivs="transportCompDivList"
              :vehicleShapeMajors="vehicleShapeMajorList"
              :disabledDraggable="(binItem.binStatusDiv ?? '0') !== '0'"
              :disabledDeliveryDateTimeInput="
                (binItem.binStatusDiv == '1' || binItem.binStatusDiv == '2') ?? true
              "
              :dispatched="true"
              class="mb-1"
              @update:lotItem="updateLotItem(item.lotSid, $event)"
              @move:freight="$emit('move:freight', $event)"
            >
            </TrunkLotItem>
          </template>
          <template v-else>
            <FreightItem
              :key="item.key"
              :freight="item"
              :inputDisableFlg="
                (binItem.binStatusDiv == '1' || binItem.binStatusDiv == '2') ?? true
              "
              @change:showDetail="showBinRemarkFreightDetail = $event"
              @change:deliveryDateTime="
                changeDeliveryDateTime(item.packingSid, item.pickupDeliveryDiv, $event)
              "
              class="mb-1"
            ></FreightItem>
          </template>
        </template>
      </SortableWrapper>
    </v-card>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      :screenFlag="confirmDialog.screenFlag"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :changeFlag="confirmDialog.changeFlag"
      :cancelBtnFlg="confirmDialog.cancelBtnFlg"
      :outsideClickNotCloseFlg="confirmDialog.outsideClickNotCloseFlg"
    />
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonFunction } from "../../assets/scripts/js/CommonFunction.js";
import TrunkLotItem from "./TrunkLotItem.vue";
import FreightItem from "./FreightItem.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import SortableWrapper from "./SortableWrapper.vue";
import DispatchMixin from "./DispatchMixin";
import TrunkPointLoadingPacking from "./TrunkPointLoadingPacking.vue";

export default {
  name: "TrunkBinItem",
  components: {
    TrunkLotItem,
    FreightItem,
    ConfirmDialog,
    SortableWrapper,
    TrunkPointLoadingPacking,
  },
  mixins: [DispatchMixin],
  props: {
    width: String,
    height: String,
    officeAddress: String,
    // 便情報
    binItem: { type: Object, required: true },
    operationDate: { type: String, default: "" },
    transportCompDivs: { type: Array, required: true, default: () => [] },
    vehicleShapeMajors: { type: Array, required: true, default: () => [] },
  },
  mounted() {
    this.init().finally(() => {
      this.loadingCounter = 0;
    });
  },
  data() {
    return {
      showBinRemarkFreightDetail: false,
      isShowTrunkPointLoadingPacking: false,
      commonFunction: commonFunction,
      routeDialog: false,
      showConfirmDialog: false,
      confirmDialog: {
        message: "",
        redMessage: "",
        isOpen: false,
        okAction: () => {},
      },
      dateTimeHelper: dateTimeHelper,
      inputDate: this.operationDate,
      binScheduleTimeFrom:
        this.binItem.fromDate != null
          ? dateTimeHelper
              .convertUTC2JST(this.binItem.fromDate)
              .substring(this.binItem.fromDate.indexOf(" ") + 1)
          : "",
      binScheduleTimeTo:
        this.binItem.toDate != null
          ? dateTimeHelper
              .convertUTC2JST(this.binItem.toDate)
              .substring(this.binItem.toDate.indexOf(" ") + 1)
          : "",
      fromDateTime: null,
      toDateTime: null,
      cars: [],
      car2s: [],
      drivers: [],
      firstOfficeFlg: this.firstOfficeOrNot(this.binItem.firstOfficeSid),
    };
  },
  watch: {
    transportCompDivs(newValue) {
      this.transportCompDivList = newValue;
    },
    vehicleShapeMajors(newValue) {
      this.vehicleShapeMajorList = newValue;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: "freight",
        disabled: false,
        ghostClass: "freight-ghost",
        multiDrag: true,
        selectedClass: "selected-freight",
        dragClass: "dragging-freight",
        avoidImplicitDeselect: false,
        multiDragKey: "CTRL",
      };
    },
    confirmDialogOption() {
      return {
        ...this.modifiedConfirmDialogOption,
      };
    },
    dragOption() {
      return this.dispatchDragOptions(this.binItem.transportSid);
    },
    alertMessage() {
      switch (this.binItem.binStatusDiv ?? "0") {
        case "1":
          return this.$t("label.lbl_duringPickupDeliveryWarnMessage");
        case "2":
          return this.$t("label.lbl_pickupDeliveryCompleteWarnMessage");
        default:
          return "";
      }
    },
    car1: {
      get() {
        return this.binItem.carSid;
      },
      set(carSid) {
        this.$emit(
          "change-car1",
          this.binItem.trunkPatternSid,
          this.binItem.transportSid,
          this.cars,
          carSid
        );
      },
    },
    car2: {
      get() {
        return this.binItem.carSid2;
      },
      set(carSid2) {
        this.$emit(
          "change-car2",
          this.binItem.trunkPatternSid,
          this.binItem.transportSid,
          this.car2s,
          carSid2
        );
      },
    },
    driver: {
      get() {
        return this.binItem.driverUserSid;
      },
      set(driverSid) {
        this.$emit(
          "change-driver",
          this.binItem.trunkPatternSid,
          this.binItem.transportSid,
          this.drivers,
          driverSid
        );
      },
    },
    driverRules() {
      return {
        required: (value) => {
          if (!value && this.binItem?.dispatchedFreightDtl?.length) {
            return messsageUtil.getMessage("P-DVP-001_001_E");
          }
          return true;
        },
      };
    },
    carError() {
      return !this.car1 && !this.car2 && !!this.binItem?.dispatchedFreightDtl?.length;
    },
    carErrorMessage() {
      return this.carError ? messsageUtil.getMessage("P-DVP-006_001_E") : "";
    },
    sameCarError() {
      return this.car1 == this.car2;
    },
    sameCarErrorMessage() {
      return this.sameCarError ? messsageUtil.getMessage("P-DVP-006_002_E") : "";
    },
    driverError() {
      return !this.driver && !!this.binItem?.dispatchedFreightDtl?.length;
    },
    driverErrorMessage() {
      return this.driverError ? messsageUtil.getMessage("P-DVP-001_001_E") : "";
    },
    transitoryMaxWeight() {
      if (!this.binItem.dispatchedFreightDtl) {
        return 0;
      }
      let initialWeight = 0;
      for (const dispatchedFreight of this.binItem.dispatchedFreightDtl) {
        // 自拠点を出発する際に積み込む貨物の合計重量(initialWeight)を求める
        if (dispatchedFreight.pickupDeliveryDiv == "02") {
          // 集荷配達区分が配達
          if (dispatchedFreight.pickupWayDiv == "01" || dispatchedFreight.pickupWayDiv == "02") {
            // 集荷方法区分が01:自拠点出荷もしくは02:荷主出荷（持込）の場合
            initialWeight += dispatchedFreight.totalWeight;
          }
        }
      }
      let calcedWeight = initialWeight;
      let transitoryMaxWeight = initialWeight;
      for (const dispatchedFreight of this.binItem.dispatchedFreightDtl) {
        // 配車エリアの上から順番に貨物の集荷配達区分、集荷方法区分を判定
        if (dispatchedFreight.pickupDeliveryDiv == "01" && dispatchedFreight.pickupWayDiv == "03") {
          // 集荷、かつ荷主出荷（集荷）の場合、initialWeightに貨物重量を足す
          calcedWeight += dispatchedFreight.totalWeight;
        } else if (dispatchedFreight.pickupDeliveryDiv == "02") {
          // 配達の場合、initialWeightから貨物重量を引く
          calcedWeight -= dispatchedFreight.totalWeight;
        }
        // 計算結果とinitialWeightを比較し、より重い方を最大重量とする
        transitoryMaxWeight =
          transitoryMaxWeight > calcedWeight ? transitoryMaxWeight : calcedWeight;
      }
      return transitoryMaxWeight === 0 ? "0" : transitoryMaxWeight;
    },
    overloading() {
      const overloading = this.transitoryMaxWeight > this.binItem.carPayload ? true : false;
      return overloading;
    },
    waypoints() {
      const waypoints = [];

      for (const freight of this.binItem.dispatchedFreightDtl ?? []) {
        let waypoint = "";
        if (freight.address) {
          waypoint = freight.address;
        }
        if (freight.pickupDeliveryPoint) {
          waypoint = `${freight.pickupDeliveryPoint}, ${waypoint}`;
        } else if (waypoint === "") {
          waypoint = freight.area;
        }
        waypoints.push(waypoint);
      }
      return waypoints;
    },
    lotFreightList: {
      get() {
        return this.toLotFreightList(this.binItem.dispatchedFreightDtl);
      },
      set(newItems) {
        this.$emit("update:freightList", this.toFreightListFromLotFreightList(newItems));
      },
    },
  },
  methods: {
    async init() {
      try {
        const [cars, drivers] = await Promise.all([this.fetchCars(), this.fetchDrivers()]);
        this.cars = [...cars];
        this.car2s = ["", ...cars];
        this.drivers = [...drivers];
      } catch (e) {
        this.infoDialog = {
          isShow: true,
          title: appConfig.DIALOG.title,
          message: e.message ?? messsageUtil.getMessage("P-999-999_999_E"),
        };
      }
    },
    fetchCars() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = this.binItem.firstOfficeSid;
      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.BIZ_TRUNK_VEHICLE_SEARCH, config).then(
        (response) => {
          const data = response.data;
          if (data.resCom.resComCode !== "000") {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
          return data.resIdv.vehicleInfoList;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    fetchDrivers() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.reqComOfficeSid = this.binItem.firstOfficeSid;
      // 接続先のAPI_URLを入力
      return this.$httpClient.secureGet(appConfig.API_URL.BIZ_TRUNK_DRIVER_SEARCH, config).then(
        (response) => {
          const data = response.data;
          if (data.resCom.resComCode !== "000") {
            const message = data.resCom.resComMessage ?? messsageUtil.getMessage("P-999-999_999_E");
            throw new Error(message);
          }
          return data.resIdv.drivers;
        },
        (error) => {
          const message =
            error.response?.data?.resCom?.resComMessage ??
            messsageUtil.getMessage("P-999-999_999_E");
          throw new Error(message);
        }
      );
    },
    firstOfficeOrNot(firstOfficeSid) {
      return firstOfficeSid === sessionStorage.getItem("sales_office_sid");
    },
    updateLotItem(lotSid, newLotItem) {
      const index = this.lotFreightList.findIndex((x) => x.lotSid === lotSid);
      if (index === -1) {
        return;
      }
      this.lotFreightList = this.lotFreightList.toSpliced(index, 1, newLotItem);
    },
    /**
     * 便が日を跨ぐものであった場合に確認ダイアログを表示
     */
    checkTime(fromTime, toTime) {
      if (fromTime != "" && toTime != "") {
        const closeAction = () => {};
        const wd = "2000/01/01";
        const date1 = new Date(`${wd} ${fromTime}`);
        const date2 = new Date(`${wd} ${toTime}`);
        if (date1 > date2) {
          this.confirmDialog = {
            message: messsageUtil.getMessage("P-DVP-001_007_W"),
            title: appConfig.DIALOG.confirm,
            screenFlag: true,
            isOpen: true,
            okAction: closeAction,
            outsideClickNotCloseFlg: false,
          };
        }
      }
    },
    percentOfWeight(transitoryMaxWeight, carPayload) {
      return Math.round((transitoryMaxWeight / carPayload) * 100);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
.car-input label.v-label {
  font-size: 0.875rem;
}

.driver-input label.v-label {
  font-size: 0.875rem;
}
</style>
