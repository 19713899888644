<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_salesAggregation" />
      <!-- 売上集計画面 -->
      <v-form ref="form">
        <!-- 検索項目 -->
        <v-container fluid>
          <!-- 1行目 -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete-24 first-search-item">
              <v-autocomplete
                dense
                v-model="supplier"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="getSupplier"
                @change="(event) => changeSupplier(event)"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 請求月度 -->
            <div class="date-style" style="margin-left: 12px">
              <v-text-field
                outlined
                dense
                v-model="billingCutOffMonth"
                :label="$t('label.lbl_billingMonth')"
                @change="changeMonth"
                :error-messages="alertMessageMonth"
                :rules="[rules.yyyymm, rules.required]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="billingCutOffMonthMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="billingCutOffMonthCal"
                  type="billingCutOffMonth"
                  @input="billingCutOffMonthMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-lastMonth"
                class="day-ctrl-btn"
                @click="preMonthOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-nextMonth"
                class="day-ctrl-btn"
                @click="nextMonthOfAchieveTo()"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 2段目 -->
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!--実行ボタン-->
                <v-btn
                  color="primary"
                  id="btn-registration"
                  class="other-btn"
                  @click="registration()"
                  >{{ $t("btn.btn_execute") }}</v-btn
                >
                <!--解除ボタン-->
                <v-btn
                  color="primary"
                  id="btn-rescission"
                  class="other-btn"
                  @click="rescission()"
                  >{{ $t("btn.btn_rescission") }}</v-btn
                >
              </div>
              <!--ボタンエリア-->
              <div class="btn-send-area" style="float: right">
                <!--更新ボタン-->
                <v-btn
                  color="primary"
                  id="btn-update"
                  class="other-btn"
                  @click="getSalesAggInfo()"
                  >{{ $t("btn.btn_update") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <!-- 3段目 -->
          <v-row class="lastClaimMonth-row" style="padding-left: 16px; padding-top: 16px">
            <div>{{ $t("label.lbl_lastClaimMonth") }} ：{{ lastClaimMonth }}</div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            :hide-default-footer="true"
            :items-per-page="50"
            height="511px"
          >
            <!-- 処理依頼日時 -->
            <template v-slot:[`item.orderTime`]="{ item }">
              <div class="listNo-style">{{ item.orderTime }}</div>
            </template>
            <!-- 処理完了日時 -->
            <template v-slot:[`item.completeTime`]="{ item }">
              <div class="listNo-style">{{ item.completeTime }}</div>
            </template>
            <!-- ステータス -->
            <template v-slot:[`item.status`]="{ item }">
              <div class="listNo-style">{{ item.status }}</div>
            </template>
            <!-- 結果確認 -->
            <template v-slot:[`item.resultConfirm`]="{ item }">
              <a style="text-decoration: underline" @click="jumpTo(item)">{{
                item.resultConfirm
              }}</a>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
        <v-dialog v-model="alertDialog" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ message }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="alertCancel"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil.js";
import { appConfig } from "../../assets/scripts/js/AppConfig.js";
import { getParameter } from "../../assets/scripts/js/GetParameter.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil.js";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.SCREEN_ID.P_CLA_003,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 取引先
    supplier: "",
    // 取引先リスト
    supplierList: [],
    supplierHint: "",
    // 集計月
    billingCutOffMonth: "",
    billingCutOffMonthMenu: false,
    billingCutOffMonthCal: "",
    // 最終集計月度
    lastClaimMonth: "",
    inputList: [],
    // 戻る遷移時
    backflg: false,
    // 警告ダイアログ
    alertDialog: false,
    // 元請営業所アラートメッセージ
    alertMessageOfficeOrg: "",
    // 集計月アラートメッセージ
    alertMessageMonth: "",
    message: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
      okAction: () => {},
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーション
    rules: {
      // YYYY/MMチェック
      yyyymm: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            value.length == 7 &&
            new Date(value.split("/")[0] + "-" + value.split("/")[1]) != "Invalid Date"
          )
        ) {
          return i18n.tc("check.chk_billingCutOffMonth");
        }
        return true;
      },
      // 必須チェック
      required: (value) => !!value || i18n.tc("check.chk_input"),
    },
    // ヘッダ
    headerItems: [
      // 処理依頼日時
      {
        text: i18n.tc("label.lbl_orderDateTime"),
        value: "orderTime",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // 処理完了日時
      {
        text: i18n.tc("label.lbl_completeDateTime"),
        value: "completeTime",
        width: "30%",
        align: "left",
        sortable: false,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 結果確認
      {
        text: i18n.tc("label.lbl_resultConfirm"),
        value: "resultConfirm",
        width: "7%",
        align: "left",
        sortable: false,
      },
    ],
  }),
  methods: {
    /**
     * 初期値
     */
    async init() {
      // 月度
      this.billingCutOffMonth = dateTimeHelper.convertJST();
      this.billingCutOffMonthCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      await this.getSupplierList();
      await this.getSalesAggInfo();
    },

    /**
     * 取引先情報取得
     */
    async getSupplierList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.supplier = "";
      // 取引先
      let supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertJST(),
        false,
        null,
        sessionStorage.getItem("sales_office_sid")
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          const tmpSupplier = this.supplierList[0].value;
          if (tmpSupplier) {
            this.supplier = tmpSupplier;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 取引先変更
     */
    changeSupplier(val) {
      if (this.isNullOrBlank(val)) {
        this.getSalesAggInfo();
      }
    },

    // 表示情報の取得
    async getSalesAggInfo() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON;
        this.loadingCounter = 1;

        // リクエストパラメーター
        const config = this.$httpClient.createGetApiRequestConfig();
        // コンポーネントID
        config.params.reqComComponentId = appConfig.SCREEN_ID.P_CLA_003;
        // 取引先SID
        config.params.clientSid = this.supplier;
        // console.debug("executeCostMstDetailApi() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_SALES_AGG_SEARCH, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                this.lastClaimMonth = jsonData.resIdv.salesAggLastMonth;
                jsonData.resIdv.salesAggList.forEach((row) => {
                  list.push({
                    orderTime:
                      row.orderTime == "" ? row.orderTime : row.orderTime.replaceAll("-", "/"),
                    completeTime:
                      row.completeTime == ""
                        ? row.completeTime
                        : row.completeTime.replaceAll("-", "/"),
                    status: row.statusName,
                    statusDiv: row.status,
                    resultConfirm:
                      row.status == appConfig.QUE_INFO_STATUS_DIV.COMPLETE
                        ? i18n.tc("label.lbl_salesClaimList")
                        : "",
                  });
                });
                this.inputList = list;
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * 実行ボタン押下時の処理
     */
    registration() {
      if (this.$refs.form.validate()) {
        const lbl_month = "月";
        const lbl_day = "日";
        const yyyymm = this.billingCutOffMonth.split("/");
        const month = yyyymm[0] + lbl_month + yyyymm[1] + lbl_day;
        this.confirmDialog.message = messsageUtil.getMessage("P-CLA-003_001_C", [month]);
        this.confirmDialog.title = appConfig.DIALOG.title;
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.registrationOnOkClick;
      }
    },

    /**
     * 実行処理のPOSTAPI接続
     */
    registrationOnOkClick() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CLA_003;

      // reqIdv
      const salesAgg = {
        // 取引先SID
        clientSid: this.supplier,
        // 請求年月度
        invoiceDate: this.billingCutOffMonth.replaceAll("/", ""),
      };
      body.reqIdv.salesAgg = salesAgg;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_SALES_AGG_REQUEST, body, appConfig.APP_CONFIG)
          // .securePost("business/period-stock-entry", body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("registrationOnOkClick() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常の場合
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.status == 0) {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_005_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.itemsMstFlg = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              } else {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_002_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.itemsMstFlg = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              }
            } else {
              // エラーの場合
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.itemsMstFlg = true;
              this.infoDialog.closeFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 解除ボタン押下の処理
     */
    rescission() {
      if (this.$refs.form.validate()) {
        this.confirmDialog.message = messsageUtil.getMessage("P-CLA-003_002_C");
        this.confirmDialog.title = appConfig.DIALOG.title;
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.rescissionOnOkClick;
      }
    },
    /**
     *  解除処理のPOSTAPI接続
     */
    rescissionOnOkClick() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CLA_003;

      // reqIdv
      const salesAgg = {
        // 取引先SID
        clientSid: this.supplier,
        // 請求年月度
        invoiceDate: this.billingCutOffMonth.replaceAll("/", ""),
      };
      body.reqIdv.salesAgg = salesAgg;
      // console.debug("rescissionOnOkClick() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URL
          .securePost(appConfig.API_URL.BIZ_SALES_AGG_DELETE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("rescissionOnOkClick() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.status == 0) {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_005_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.itemsMstFlg = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              } else {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_004_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.closeFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 詳細画面へ
    jumpTo() {
      if (this.$refs.form.validate()) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CLA_004,
          params: {
            officeOrg: sessionStorage.getItem("sales_office_sid"),
            supplier: this.supplier,
            month: this.billingCutOffMonth,
          },
        });
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    alertCancel() {
      this.alertDialog = false;
    },

    /** 集計月を-１日 */
    preMonthOfAchieveTo() {
      if (this.billingCutOffMonthCal == null) {
        return;
      }
      let date = new Date(this.billingCutOffMonthCal);
      this.billingCutOffMonthCal = dateTimeHelper.dateCalc(date.setMonth(date.getMonth() - 1));
    },

    /** 集計月を+１日 */
    nextMonthOfAchieveTo() {
      if (this.billingCutOffMonthCal == null) {
        return;
      }
      let date = new Date(this.billingCutOffMonthCal);
      this.billingCutOffMonthCal = dateTimeHelper.dateCalc(date.setMonth(date.getMonth() + 1));
    },

    /** 集計月入力 */
    changeMonth(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(4, 6) + "/" + inputVal.slice(6, 8);
      } else {
        strDt = inputVal.slice(0, 4) + "/" + inputVal.slice(5, 7) + "/" + inputVal.slice(8, 10);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.billingCutOffMonthCal = dateTimeHelper.dateCalc(strDt);
        this.billingCutOffMonth = strDt;
      } else {
        this.billingCutOffMonthCal = null;
      }
    },

    /** カレンダコンポーネントの日付はyyyy-MMのためyyyy/MMに変換する。*/
    formatMonth(date) {
      if (!date) return null;
      date = commonUtil.zen2han(date);
      // 月の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])$/;
      date = date.replaceAll("／", "/");
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month] = date.split("-");
        return `${year}/${month}`;
      }
    },

    // 半角文字を全角文字に変換
    HalfWidth2fullWidth(str) {
      return str.replace(/[A-Za-z0-9]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
      });
    },

    /**
     * アラートメッセージクリア処理
     */
    clearAlertMessage() {
      // アラートメッセージを非表示にする。
      this.alertMessageMonth = "";
    },

    /**
     * 存在チェック
     */
    isNullOrBlank(val) {
      if ((val ?? false) && val != "") {
        return true;
      }
      return false;
    },
  },

  computed: {
    // 元請営業所ヒント取得
    getOfficeOrg() {
      let tmpOfficeOrg = this.officeOrgList.find((v) => v.value == this.officeOrg);
      if (tmpOfficeOrg) {
        return tmpOfficeOrg.name;
      }
      return "";
    },
    // 取引先ヒント取得
    getSupplier() {
      let tmpSupplier = this.supplierList.find((v) => v.value == this.supplier);
      if (tmpSupplier) {
        return tmpSupplier.name;
      }
      return "";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    billingCutOffMonth(val) {
      if (this.isNullOrBlank(val)) {
        // アラートメッセージを非表示にする。
        this.clearAlertMessage();
      }
    },
    billingCutOffMonthCal: {
      handler(val) {
        this.billingCutOffMonth = this.formatMonth(val);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected(newValue) {
      if (newValue || null) {
        this.suppliersSelected = newValue;
      } else {
        this.suppliersSelected = newValue;
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.v-data-table {
  white-space: pre-line;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-left-red {
  color: red;
}
</style>
