<template>
  <v-dialog :value="isShow" :max-width="800" :retain-focus="false" persistent>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"></span>
    </template>

    <v-card>
      <v-card-title id="sub-bar">
        <span id="lbl-screen-name">{{ dialogTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- 幹線発地 -->
            <v-col>
              <v-autocomplete
                dense
                v-model="fromTrunkBase"
                :items="trunkBases"
                class="txt-single"
                persistent-hint
                :label="$t('label.lbl_fromTrunkBase')"
                style="margin: 30px"
                clearable
                @change="getTrunkRoutes"
              >
                <template v-slot:selection="{ item }">
                  <span style="color: green">{{ item.text }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- 幹線着地 -->
            <v-col>
              <v-autocomplete
                dense
                v-model="toTrunkBase"
                :items="trunkBases"
                class="txt-single"
                persistent-hint
                :label="$t('label.lbl_toTrunkBase')"
                style="margin: 30px"
                clearable
                @change="getTrunkRoutes"
              >
                <template v-slot:selection="{ item }">
                  <span style="color: blue">{{ item.text }}</span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- ラジオボタンリスト -->
          <v-radio-group v-if="trunkRouteList.length > 0 && !errorMessage" v-model="selectedRoute">
            <v-radio v-for="(option, index) in trunkRouteList" :key="index" :value="option.value">
              <template v-slot:label>
                <span v-html="option.label"></span>
              </template>
            </v-radio>
          </v-radio-group>
          <!-- エラーメッセージの表示 -->
          <v-alert v-if="errorMessage" class="error-alert"> {{ errorMessage }} </v-alert>
        </v-container>
        <v-card-actions>
          <v-btn
            color="primary"
            elevation="2"
            @click="addTrunk()"
            :disabled="!fromTrunkBase || !toTrunkBase || errorMessage"
            style="margin: 0 0 0 auto"
            >OK
          </v-btn>
          <v-btn color="light-grey" elevation="2" @click="cancelInput()" style="margin: 0 0 0 20px"
            >キャンセル
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";

export default {
  name: "TrunkLineAddDialog",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    lineInfo: {
      index: { type: String, default: "" },
      item: null,
    },
    dialogTitle: { type: String, default: "" },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogTrunkBase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fromTrunkBase: "",
      toTrunkBase: "",
      trunkBases: [],
      selectedRoute: null,
      trunkRouteList: [],
      trunkRouteBaseInfoList: [],
      infoDialog: {
        isShow: false,
        message: "",
        title: "",
      },
      errorMessage: null,
    };
  },
  computed: {},
  methods: {
    init() {
      this.getTrunkBases();
      // this.getTrunkRouteList();
    },

    /**
     * 親画面から発着地を受け取る
     */
    setFromAndToTrunkBase() {
      this.fromTrunkBase = this.dialogTrunkBase.fromBase;
      this.toTrunkBase = this.dialogTrunkBase.toBase;
    },

    /**
     * 幹線拠点の一覧を取得（テスト用）
     */
    getTrunkBasesTest() {
      const trunkBasesTemp = [
        {
          text: "柏原混載",
          value: "2100000C97",
          name: "柏原混載",
        },
        {
          text: "焼津乗継",
          value: "2500000016",
          name: "焼津乗継",
        },
        {
          text: "浜松混載貨物センター",
          value: "2100000H28",
          name: "浜松混載貨物センター",
        },
      ];
      this.trunkBases = trunkBasesTemp;
    },

    /**
     * 幹線拠点の一覧を取得
     */
    getTrunkBases() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      // 全拠点を取得するため値は設定しない
      const config = this.$httpClient.createGetApiRequestConfig();

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_TRUNK_BASE_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.baseInfoList.forEach((row) => {
                list.push({
                  text: row.baseShortName ? row.baseShortName : row.baseName ?? "",
                  value: row.baseSid ?? "",
                  name: row.baseShortName ? row.baseShortName : row.baseName ?? "",
                });
              });
              this.trunkBases = list;
              resolve(response);
            } else {
              this.errorMessage = jsonData.resCom.resComMessage;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // 何もしない
          });
      });
    },

    /**
     * 幹線拠点の一覧を取得（テスト用）
     */
    getTrunkRouteListTest() {
      const trunkRouteBaseInfoList = [
        {
          trunkRouteBaseInfo: [
            {
              trunkPatternSid: "25000000000000000010",
              baseSid: "2100000C18",
              baseName: "狭山混載",
              diagramViaNo: 1,
              baseRoleDiv: "01",
            },
            {
              trunkPatternSid: "25000000000000000010",
              baseSid: "2100000C97",
              baseName: "柏原混載",
              diagramViaNo: 2,
              baseRoleDiv: "05",
            },
            {
              trunkPatternSid: "25000000000000000010",
              baseSid: "2500000016",
              baseName: "焼津乗継",
              diagramViaNo: 3,
              baseRoleDiv: "03",
            },
            {
              trunkPatternSid: "25000000000000000010",
              baseSid: "2100000H28",
              baseName: "浜松混載貨物センター",
              diagramViaNo: 4,
              baseRoleDiv: "05",
            },
            {
              trunkPatternSid: "25000000000000000010",
              baseSid: "2100000H80",
              baseName: "鈴鹿混載",
              diagramViaNo: 5,
              baseRoleDiv: "06",
            },
          ],
        },
      ];
      this.trunkRouteBaseInfoList = trunkRouteBaseInfoList;
    },

    /**
     * 幹線ルートの候補を取得
     */
    async getTrunkRoutes() {
      // 選択された出発地と到着地をもとにラジオボタン項目を更新
      const selectedFromBase = this.fromTrunkBase;
      const selectedToBase = this.toTrunkBase;

      // エラーメッセージをリセットする
      this.errorMessage = null;

      // 発地・着地のいずれかが選択されていない場合
      if (this.isErrorSelectedTrunkBase(selectedFromBase, selectedToBase)) {
        this.trunkRouteList = [];
        this.selectedRoute = null; // ルート選択をリセット
        return; // 両方が選択されていない場合は何もしない
      }

      // API連携を行い、発着地に応じた幹線ルートのJSONデータを取得
      await this.getTrunkPatternList(selectedFromBase, selectedToBase);
      // this.getTrunkPatternListTest();

      // 取得したJSONデータに基づいて、ラジオボタンに表示する幹線ルートを作成
      const trunkRoutes = [];
      for (const trunkRoute of this.trunkRouteBaseInfoList) {
        let routeBaseInfo = trunkRoute.trunkRouteBaseInfo;

        // 各ルート内における拠点名を繋げる
        let displayTrunkRoute = "";
        for (let i = 0; i < routeBaseInfo.length; i++) {
          let base = routeBaseInfo[i];
          let baseName = base.baseName;
          // baseRoleDivが"04"の場合、"(積替)"を追加
          if (base.baseRoleDiv === "04") {
            baseName += "(積替)";
          }
          // 最初以外は "→" を追加
          if (i !== 0) {
            displayTrunkRoute += "→";
          }

          // baseNameを追加、発地 (fromBase) は緑、着地 (toBase) は青色に設定
          if (base.baseSid === selectedFromBase) {
            displayTrunkRoute += `<span style="color: green;">${baseName}</span>`;
          } else if (base.baseSid === selectedToBase) {
            displayTrunkRoute += `<span style="color: blue;">${baseName}</span>`;
          } else {
            displayTrunkRoute += baseName;
          }
        }
        // 結果を作成
        trunkRoutes.push({
          label: displayTrunkRoute,
          value: routeBaseInfo,
        });
      }

      this.trunkRouteList = trunkRoutes;
      // 一番上に表示される幹線ルートを、初期表示で選択しておく
      this.selectedRoute = this.trunkRouteList[0].value;
    },

    /**
     * 発着地のエラーチェック
     */
    isErrorSelectedTrunkBase(selectedFromBase, selectedToBase) {
      // 幹線拠点リストに含まれていない場合は空にする
      if (!this.isValueInTrunkBases(selectedFromBase)) {
        selectedFromBase = null;
      }
      if (!this.isValueInTrunkBases(selectedToBase)) {
        selectedToBase = null;
      }

      // 発着地のいずれかが空の場合
      if (!selectedFromBase || !selectedToBase) {
        return true;
      }
      return false;
    },

    /**
     * 幹線拠点の一覧に含まれているかチェック
     */
    isValueInTrunkBases(selectedBase) {
      return this.trunkBases.some((base) => base.value === selectedBase); // idなどで確認
    },

    /**
     * 幹線ルートリスト取得
     */
    getTrunkPatternList(selectedFromBase, selectedToBase) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 発着地SIDのセット
      config.params.fromBaseSid = selectedFromBase;
      config.params.toBaseSid = selectedToBase;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_TRUNK_ROUTE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.trunkRouteBaseInfoList = jsonData.resIdv.trunkRouteList;
              resolve(response);
            } else {
              this.errorMessage = jsonData.resCom.resComMessage;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // 何もしない
          });
      });
    },

    /**
     * 幹線ルートリスト取得（テスト用）
     */
    getTrunkPatternListTest(selectedFromBase, selectedToBase) {
      console.log("selectedFromBaseTest: " + selectedFromBase);
      console.log("selectedToBaseTest: " + selectedToBase);

      const jsonData = [];
      if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
        this.trunkRouteBaseInfoList = jsonData.resIdv.trunkRouteBaseInfoList;
      } else {
        this.errorMessage = jsonData[0].resCom.resComMessage;
      }
    },

    addTrunk() {
      console.log("幹線追加");
      console.log(this.lineInfo);
      this.$emit(
        "setTrunkInfo",
        this.fromTrunkBase,
        this.toTrunkBase,
        this.lineInfo,
        this.selectedRoute
      );
      this.$emit("update:isShow", false);
    },

    cancelInput() {
      this.$emit("cancelTransportCompChange", this.lineInfo.index, this.lineInfo.item);
      this.$emit("update:isShow", false);
    },
  },

  mounted() {
    this.init();
  },

  watch: {
    isShow(newVal) {
      // モーダルの表示時に実行
      if (newVal) {
        this.setFromAndToTrunkBase();
        this.getTrunkRoutes();
      }
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.error-alert {
  color: red; /* 赤文字にする */
  text-align: center; /* 中央に配置 */
}
</style>
